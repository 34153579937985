import { doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const getReviews = async (username) => {
  try {
    const reviewRef = doc(db, "userReviews", username);
    const docSnap = await getDoc(reviewRef);

    if (docSnap.exists()) {
      const data = docSnap.data().reviews;
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Erro ao obter as avaliações:", error);
    return [];
  }
};

export const checkReviewExists = async (username) => {
  try {
    const reviewRef = doc(db, "userReviews", username);
    const docSnap = await getDoc(reviewRef);
    return docSnap.exists();
  } catch (error) {
    console.error("Erro ao verificar existência de avaliações:", error);
    throw error;
  }
};
