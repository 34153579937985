import React from "react";
import { View, Text, TouchableOpacity, Switch } from "react-native";
import moment from "moment-timezone";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { styles } from "../../../../styles/producer/producerUtils/AddEventStyles";

const SubmitEventForm = ({
  state,
  submitEvent,
  handlePrevStep,
  handleInputChange,
  navigation,
}) => (
  <View style={styles.scrollViewContent}>
    <View style={styles.sectionDivider} />
    <View style={styles.settingItemForm}>
      <MaterialCommunityIcons
        name="checkbox-marked"
        size={24}
        style={styles.settingIconForm}
      />
      <Text style={styles.screenTitle}>Confirmação dos Dados</Text>
    </View>
    <View style={styles.sectionDivider} />
    <View style={styles.detailsContainer}>
      <View style={styles.reviewRow}>
        <Text style={styles.inputLabel}>Nome do Evento:</Text>
        <Text style={styles.inputValue}>{state.name}</Text>
      </View>

      <View style={styles.sectionDivider} />

      <View style={styles.reviewRow}>
        <Text style={styles.inputLabel}>Username do Evento:</Text>
        <Text style={styles.inputValue}>{state.username}</Text>
      </View>

      <View style={styles.sectionDivider} />

      <View style={styles.reviewRow}>
        <Text style={styles.inputLabel}>Data do Evento:</Text>
        <Text style={styles.inputValue}>
          {state.dates.start
            ? moment(state.dates.start)
                .tz("America/Sao_Paulo")
                .format("DD/MM/YYYY")
            : "Selecionar Data"}
        </Text>
      </View>

      <View style={styles.sectionDivider} />

      <View style={styles.reviewRow}>
        <Text style={styles.inputLabel}>Horário de Início:</Text>
        <Text style={styles.inputValue}>
          {state.dates.start
            ? moment(state.dates.start).tz("America/Sao_Paulo").format("HH:mm")
            : "Selecionar Horário"}
        </Text>
      </View>

      <View style={styles.sectionDivider} />

      <View style={styles.reviewRow}>
        <Text style={styles.inputLabel}>Data de Término:</Text>
        <Text style={styles.inputValue}>
          {state.dates.end
            ? moment(state.dates.end)
                .tz("America/Sao_Paulo")
                .format("DD/MM/YYYY")
            : "Selecionar Data"}
        </Text>
      </View>

      <View style={styles.sectionDivider} />

      <View style={styles.reviewRow}>
        <Text style={styles.inputLabel}>Horário de Término:</Text>
        <Text style={styles.inputValue}>
          {state.dates.end
            ? moment(state.dates.end).tz("America/Sao_Paulo").format("HH:mm")
            : "Selecionar Horário"}
        </Text>
      </View>

      <View style={styles.sectionDivider} />

      <View style={styles.reviewRow}>
        <Text style={styles.inputLabel}>Cidade do Evento:</Text>
        <Text style={styles.inputValue}>{state.address.city}</Text>
      </View>

      <View style={styles.sectionDivider} />

      <View style={styles.switchRowEnd}>
        <Text style={styles.label}>Aceitar Termos de Uso:</Text>
        <Switch
          trackColor={{ false: "#767577", true: "#202940" }}
          thumbColor={state.termsAccepted ? "#052552" : "#f4f3f4"}
          ios_backgroundColor="#202940"
          value={state.termsAccepted}
          onValueChange={(value) => handleInputChange("termsAccepted", value)}
        />
      </View>

      <TouchableOpacity
        onPress={() => navigation.navigate("Terms")}
        style={styles.termsLink}
      >
        <Text style={styles.termsText}>Ver Termos de Uso</Text>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={submitEvent}
        style={styles.buttonSubmit}
        disabled={!state.termsAccepted}
      >
        <Text style={styles.buttonText}>Criar evento</Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={handlePrevStep} style={styles.buttonBack}>
        <Text style={styles.buttonText}>Voltar</Text>
      </TouchableOpacity>
    </View>
  </View>
);

export default SubmitEventForm;
