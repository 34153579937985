import React, { useState } from "react";
import {
  View,
  Image,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  StatusBar,
  Modal,
  Text,
  Alert,
  TouchableWithoutFeedback,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { auth } from "../../../services/utils/firebase/firebaseConfig";
import { addReport } from "../../../services/cruds/userReports/createReportUser";
import { checkExistingReport } from "../../../services/cruds/userReports/getReportUser";
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";

const reportOptions = [
  "É spam",
  "Simplesmente não gostei",
  "Venda de produtos regulamentados ou ilícitos",
  "Nudez ou atividade sexual",
  "Símbolos ou discurso de ódio",
  "Violência ou organizações perigosas",
  "Está fingindo ser outra pessoa/organização",
  "Enganoso ou possível golpe",
  "Informação falsa",
];

export default function Header({
  navigation,
  isHeader,
  visitedUserId,
  visitedUserUsername,
}) {
  const [modalVisible, setModalVisible] = useState(false);

  const handleReport = async (reportReason) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const existingReport = await checkExistingReport(visitedUserId, user.uid);

        if (existingReport) {
          Alert.alert("Erro", "Você já denunciou este usuário.");
          return;
        }

        const reportSuccess = await addReport(visitedUserId, visitedUserUsername, user.uid, reportReason);

        if (reportSuccess) {
          Alert.alert(
            "Denúncia Enviada",
            "Sua denúncia foi enviada com sucesso."
          );
          setModalVisible(false);
        } else {
          Alert.alert(
            "Erro",
            "Houve um erro ao enviar sua denúncia. Tente novamente mais tarde."
          );
        }
      } else {
        Alert.alert(
          "Erro",
          "Você precisa estar logado para enviar uma denúncia."
        );
      }
    } catch (error) {
      console.error("Erro ao enviar denúncia: ", error);
      Alert.alert(
        "Erro",
        "Houve um erro ao enviar sua denúncia. Tente novamente mais tarde."
      );
    }
  };

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("Home");
    }
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <StatusBar barStyle="light-content" />
      <View style={isHeader ? styles.containerVisited : styles.container}>
        <TouchableOpacity
          onPress={() => handleGoBack()}
          style={styles.backButton}
        >
          <Icon name="arrow-back" size={25} color="#FFFFFF" />
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigation.navigate("Home")}>
          <Image source={logoTexto} resizeMode="contain" style={styles.logo} />
        </TouchableOpacity>

        <TouchableOpacity onPress={() => setModalVisible(true)}>
          <Icon name="alert-circle-outline" size={25} color="white" />
        </TouchableOpacity>
      </View>

      <Modal
        visible={modalVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={() => setModalVisible(false)}
      >
        <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <TouchableOpacity
                style={styles.modalCloseButton}
                onPress={() => setModalVisible(false)}
              >
                <Icon name="close" size={24} color="#FFF" />
              </TouchableOpacity>
              <Text style={styles.modalTitle}>Denunciar Usuário</Text>
              {reportOptions.map((option, index) => (
                <TouchableOpacity
                  key={index}
                  style={styles.reportOption}
                  onPress={() => handleReport(option)}
                >
                  <Text style={styles.reportOptionText}>{option}</Text>
                </TouchableOpacity>
              ))}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    backgroundColor: "#000",
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  containerVisited: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  logo: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalContent: {
    backgroundColor: "#1C1C1C",
    borderRadius: 10,
    padding: 20,
    width: "80%",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 18,
    color: "#FFFFFF",
    marginBottom: 15,
  },
  reportOption: {
    width: "100%",
    backgroundColor: "#3D3D3D",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  reportOptionText: {
    color: "#FFF",
    fontSize: 16,
    textAlign: "center",
  },
  modalCloseButton: {
    position: "absolute",
    top: 8,
    right: 8,
    zIndex: 1,
  },
});
