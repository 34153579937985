import React from "react";
import { View, Text, Image, TouchableOpacity, StyleSheet } from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons";

const Card = ({
  evento,
  onPressView,
  onPressEdit,
  onPressStatistics,
  onPressQRCode,
  onPressManageChat,
}) => {
  const isDraft = evento.status === "Rascunho";
  const isAnalysis = evento.status === "Em Análise";
  const isFinalizado = evento.status === "Finalizado";
  const defaultImageEvent = require("../../../../assets/imgs/fundo_evento.png");
  const eventImage =
    evento.eventImage !== "default_image.png"
      ? { uri: evento.eventImage }
      : defaultImageEvent;

  return (
    <View
      style={isAnalysis ? styles.cardContainerisAnalysis : styles.cardContainer}
    >
      <Image source={eventImage} style={styles.cardImage} />
      <View style={styles.cardDetails}>
        <Text style={styles.cardTitle} numberOfLines={1}>
          {evento.name}
        </Text>
        <Text
          style={[
            styles.status,
            isAnalysis
              ? styles.statusBlocked
              : isDraft
              ? styles.statusDraft
              : styles.statusPublished,
          ]}
        >
          {evento.status}
        </Text>
        {!isAnalysis ? (
          <>
            <TouchableOpacity onPress={onPressView} style={styles.buttonView}>
              <Icon name="visibility" size={18} color="#FFF" />
              <Text style={styles.buttonText}>Visualizar Evento</Text>
            </TouchableOpacity>
            <View
              style={
                isFinalizado
                  ? styles.containerEditStatisticFinalizado
                  : styles.containerEditStatistic
              }
            >
              {!isFinalizado && (
                <TouchableOpacity
                  onPress={onPressEdit}
                  style={
                    isDraft ? styles.buttonEditRascunho : styles.buttonEdit
                  }
                >
                  <Icon name="edit" size={18} color="#FFF" />
                  <Text style={styles.buttonText}>Gerenciar</Text>
                </TouchableOpacity>
              )}
              {!isDraft && (
                <TouchableOpacity
                  onPress={onPressStatistics}
                  style={
                    isFinalizado
                      ? styles.buttonStatisticFinalizado
                      : styles.buttonStatistic
                  }
                >
                  <Icon name="bar-chart" size={18} color="#FFF" />
                  {isFinalizado && (
                    <Text style={styles.buttonText}>Estatísticas</Text>
                  )}
                </TouchableOpacity>
              )}
              <TouchableOpacity
                onPress={onPressManageChat}
                style={ styles.buttonChatFinalizado }
              >
                <Icon name="chat" size={18} color="#FFF" />
                {(isFinalizado || isDraft) && (
                  <Text style={styles.buttonText}>Chat</Text>
                )}
              </TouchableOpacity>
              {!isFinalizado ||
                (isDraft && (
                  <TouchableOpacity
                    onPress={onPressQRCode}
                    style={styles.buttonQRCode}
                  >
                    <Icon name="qr-code" size={18} color="#FFF" />
                  </TouchableOpacity>
                ))}
            </View>
          </>
        ) : (
          <>
            <TouchableOpacity onPress={onPressView} style={styles.buttonView}>
              <Icon name="visibility" size={18} color="#FFF" />
              <Text style={styles.buttonText}>Visualizar Evento</Text>
            </TouchableOpacity>
            <View
              style={
                isFinalizado
                  ? styles.containerEditStatisticFinalizado
                  : styles.containerEditStatistic
              }
            >
              <TouchableOpacity
                onPress={onPressManageChat}
                style={ styles.buttonChatFinalizado }
              >
                <Icon name="chat" size={18} color="#FFF" />
                {( <Text style={styles.buttonText}>Chat</Text> )}
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
    </View>
  );
};

export default Card;

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: "#000",
    borderRadius: 8,
    shadowOpacity: 0.15,
    shadowRadius: 5,
    shadowColor: "#000",
    shadowOffset: { height: 3, width: 0 },
    elevation: 3,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },
  cardContainerisAnalysis: {
    backgroundColor: "#000",
    borderRadius: 8,
    shadowOpacity: 0.15,
    shadowRadius: 5,
    shadowColor: "#000",
    shadowOffset: { height: 3, width: 0 },
    elevation: 3,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },
  cardImage: {
    width: 160,
    height: 90,
    borderRadius: 8,
  },
  cardDetails: {
    flex: 1,
    padding: 10,
    justifyContent: "center",
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#FFF",
  },
  status: {
    fontSize: 12,
    marginBottom: 4,
  },
  statusBlocked: {
    color: "#FF6347",
  },
  statusDraft: {
    color: "#DFC900",
  },
  statusPublished: {
    color: "#90EE90",
  },
  buttonView: {
    flexDirection: "row",
    backgroundColor: "#2B2B2B",
    paddingVertical: 3,
    paddingHorizontal: 12,
    borderRadius: 5,
    marginBottom: 6,
    alignItems: "center",
    justifyContent: "center",
  },
  containerEditStatistic: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    flex: 1,
  },
  containerEditStatisticFinalizado: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    flex: 1,
  },
  buttonEdit: {
    flex: 3,
    flexDirection: "row",
    backgroundColor: "#2B2B2B",
    paddingVertical: 3,
    paddingHorizontal: 4,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 3,
  },
  buttonEditRascunho: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#2B2B2B",
    paddingVertical: 3,
    paddingHorizontal: 12,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 3,
  },
  buttonStatistic: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#2B2B2B",
    paddingVertical: 3,
    paddingHorizontal: 4,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 3,
  },
  buttonStatisticFinalizado: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#2B2B2B",
    paddingVertical: 3,
    paddingHorizontal: 12,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 3,
  },
  buttonQRCode: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#2B2B2B",
    paddingVertical: 3,
    paddingHorizontal: 4,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonChat: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#2B2B2B",
    paddingVertical: 3,
    paddingHorizontal: 4,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 3,
  },
  buttonChatFinalizado: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#2B2B2B",
    paddingVertical: 3,
    paddingHorizontal: 4,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    color: "#FFF",
    marginLeft: 3,
    fontSize: 10,
    fontWeight: "bold",
  },
});
