import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  settingItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#373737",
    backgroundColor: "#000",
  },
  settingIcon: {
    color: "#FFFD",
    width: 24,
  },
  settingTextContainer: {
    flex: 1,
    paddingHorizontal: 10,
  },
  settingTitle: {
    fontSize: 16,
    color: "#FFF",
  },
  settingSubtitle: {
    fontSize: 14,
    color: "#AAA",
  },
  settingArrow: {
    color: "#FFF",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalContent: {
    backgroundColor: "#1C1C1C",
    borderRadius: 10,
    padding: 20,
    width: "80%",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 18,
    color: "#FFFFFF",
    marginBottom: 15,
    marginRight: 10,
  },
  modalText: {
    fontSize: 16,
    color: "#AAAAAA",
    marginBottom: 15,
    textAlign: "center",
  },
  modalInput: {
    width: "100%",
    backgroundColor: "#3D3D3D",
    color: "#FFF",
    borderRadius: 10,
    padding: 10,
    marginBottom: 20,
  },
  modalButton: {
    backgroundColor: "#1A2035",
    padding: 12,
    borderRadius: 10,
    alignItems: "center",
    width: "48%",
    marginBottom: 10,
  },
  modalButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  modalButtonCancel: {
    backgroundColor: "#3D3D3D",
  },
  modalButtonsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  modalCloseButton: {
    position: "absolute",
    top: 8,
    right: 8,
    zIndex: 1,
  },
  versionContainer: {
    padding: 20,
    alignItems: "center",
    borderTopColor: "#373737",
    backgroundColor: "#000",
  },
  versionTextIsLoading: {
    position: "absolute",
    color: "#FFF9",
    fontSize: 12,
  },
  versionText: {
    position: "absolute",
    bottom: 20,
    color: "#FFF9",
    fontSize: 12,
  },
  instagramBanner: {
    backgroundColor: "#121212",
    padding: 15,
    borderRadius: 10,
    margin: 10,
    marginVertical: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  instagramBannerText: {
    color: "#fff",
    fontWeight: "200",
    fontSize: 16,
    textAlign: "center",
    marginBottom: 10,
  },
  instagramBannerButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  instagramFollowButton: {
    backgroundColor: "#1A2035",
    padding: 7,
    borderRadius: 5,
    flex: 1,
    alignItems: "center",
    marginHorizontal: 5,
  },
  instagramFollowButtonText: {
    color: "#FFF",
    fontWeight: "bold",
  },
  instagramCloseButton: {
    backgroundColor: "#3D3D3D",
    padding: 7,
    borderRadius: 5,
    flex: 1,
    alignItems: "center",
    marginHorizontal: 5,
  },
  instagramCloseButtonText: {
    color: "#FFF",
    fontWeight: "bold",
  },
  suggestionBanner: {
    backgroundColor: "#121212",
    padding: 15,
    borderRadius: 10,
    margin: 10,
    marginVertical: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  suggestionBannerText: {
    color: "#fff",
    fontWeight: "200",
    fontSize: 16,
    textAlign: "center",
    marginBottom: 10,
  },
  suggestionInput: {
    width: "100%",
    height: 40,
    borderColor: "#999",
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    color: "#FFF",
    marginBottom: 10,
  },
  suggestionBannerButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  suggestionSubmitButton: {
    backgroundColor: "#1A2035",
    padding: 7,
    borderRadius: 5,
    flex: 1,
    alignItems: "center",
    marginHorizontal: 5,
  },
  suggestionSubmitButtonText: {
    color: "#FFF",
    fontWeight: "bold",
  },
  passwordContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  eyeIcon: {
    position: "absolute",
    right: 10,
    bottom: -2,
  },
});
