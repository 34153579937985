import { StyleSheet, Dimensions, Platform } from "react-native";

const { width } = Dimensions.get("window");

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: "center",
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  logoHeader: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  eventInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    backgroundColor: "#000",
  },
  profileImage: {
    width: 80,
    height: 80,
    borderRadius: 100,
  },
  eventDetails: {
    flex: 1,
  },
  eventName: {
    fontSize: 18,
    color: "#FFF",
    fontWeight: "bold",
  },
  eventDescription: {
    fontSize: 14,
    color: "#AAA",
  },
  settingItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#333",
    backgroundColor: "#000",
  },
  settingItemForm: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000",
  },
  settingIcon: {
    color: "#FFFD",
    width: 24,
  },
  settingIconForm: {
    color: "#FFFD",
    marginRight: 5,
    width: 24,
  },
  settingTextContainer: {
    flex: 1,
    paddingHorizontal: 10,
  },
  settingTitle: {
    fontSize: 16,
    color: "#FFF",
  },
  settingSubtitle: {
    fontSize: 14,
    color: "#AAA",
  },
  settingArrow: {
    color: "#FFF",
  },
  detailsContainer: {
    paddingHorizontal: 20,
    backgroundColor: "#000",
    borderRadius: 10,
    marginVertical: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  label: {
    color: "#A0A0A0",
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 5,
  },
  linkPreview: {
    color: "#A0A0A0",
  },
  input: {
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    borderRadius: 5,
    padding: 10,
    marginBottom: 15,
  },
  inputPhone: {
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    fontSize: 16,
    borderRadius: 5,
    padding: 10,
    flex: 1,
  },
  inputBlocked: {
    backgroundColor: "#121212",
    color: "#FFF",
    borderRadius: 5,
    padding: 10,
    marginBottom: 15,
  },
  lockedText: {
    color: "red",
    fontSize: 16,
    marginTop: 10,
  },
  descriptionInput: {
    textAlignVertical: "top",
    paddingTop: 10,
  },
  saveButton: {
    backgroundColor: "#1A2035",
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  saveButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  phoneButton: {
    backgroundColor: "#121212",
    padding: 6,
    paddingHorizontal: 20,
    marginRight: 5,
    borderRadius: 5,
    alignItems: "center",
  },
  textStyle2: {
    color: "#FFF",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  container2: {
    marginTop: 20,
  },
  editImageButton: {
    right: 20,
    top: 30,
    bottom: 0,
    width: 25,
    height: 25,
    borderRadius: 20,
    backgroundColor: "#5B2372",
    justifyContent: "center",
    alignItems: "center",
  },
  phoneInputContainer: {
    backgroundColor: "#2B2B2B",
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  socialMediaContainer: {
    backgroundColor: "#2B2B2B",
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  linkPreview: {
    fontSize: 14,
    marginLeft: 10,
    alignSelf: "center",
    color: "#FFF",
  },
  socialMediaInput: {
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    fontSize: 14,
    borderRadius: 5,
    paddingVertical: 10,
  },
  separator: {
    height: 0.5,
    backgroundColor: "#CCCCCC",
    marginVertical: 5,
    width: "80%",
    alignSelf: "center",
  },
  isPublicContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  publicIcon: {
    marginRight: 5,
  },
  publicButtonText: {
    fontWeight: "bold",
    color: "#FFF",
    fontSize: 14,
    textAlign: "center",
  },
  modalDescription: {
    fontSize: 14,
    color: "#AAA",
    marginBottom: 20,
    textAlign: "center",
  },
  countryCode: {
    fontSize: 16,
    marginLeft: 10,
    alignSelf: "center",
    color: "#FFF",
  },
  labelTitle: {
    fontSize: 20,
    fontWeight: "700",
    color: "#999",
    marginBottom: 10,
    textAlign: "center",
  },
  screenTitle: {
    color: "#FFFD",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  sectionDivider: {
    flex: 1,
    height: 0.5,
    backgroundColor: "#333",
    marginVertical: 10,
  },
  buttonGroup: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  optionButton: {
    flex: 1,
    paddingVertical: 10,
    marginRight: 5,
    borderRadius: 5,
    backgroundColor: "#1A1A1A",
    alignItems: "center",
  },
  optionButtonSecond: {
    flex: 1,
    paddingVertical: 10,
    marginRight: 5,
    borderRadius: 5,
    backgroundColor: "#121212",
    alignItems: "center",
  },
  selectedOptionButton: {
    backgroundColor: "#1A2035",
    borderColor: "#1A2035",
  },
  optionButtonText: {
    color: "#FFF",
  },
  selectedOptionButtonText: {
    color: "#FFF",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    width: "80%",
    backgroundColor: "#1A1A1A",
    padding: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFF",
    marginBottom: 20,
    textAlign: "center",
  },
  modalOptionButton: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: "#333",
    alignItems: "center",
    marginBottom: 10,
  },
  modalSelectedOptionButton: {
    backgroundColor: "#5B2372",
    borderColor: "#5B2372",
  },
  modalOptionButtonText: {
    color: "#FFF",
    fontSize: 16,
  },
  modalSelectedOptionButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  modalButton: {
    backgroundColor: "#5B2372",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginBottom: 10,
  },
  modalButtonCancel: {
    backgroundColor: "#1A2035",
  },
  modalButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  versionContainer: {
    padding: 20,
    alignItems: "center",
    borderTopColor: "#373737",
    backgroundColor: "#000",
  },
  versionText: {
    position: "absolute",
    bottom: 20,
    color: "#FFF9",
    fontSize: 12,
  },
  eventList: {
    maxHeight: 200,
  },
  eventItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 5,
    marginBottom: 5,
    borderColor: "#333",
    borderBottomWidth: 0.5,
  },
  eventText: {
    paddingLeft: 5,
    color: "#FFF9",
  },
  hiddenEventButton: {
    padding: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
    backgroundColor: "#000",
    borderColor: "#333",
    borderWidth: 1,
  },
  visibleEventButton: {
    padding: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
    backgroundColor: "#5B2372",
    borderColor: "#5B2372",
    borderWidth: 1,
  },
  visibilityButtonText: {
    color: "#fff",
  },
});
