import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  containerSpace: {
    paddingHorizontal: 20,
  },
  logoHeader: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  eventInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 20,
    backgroundColor: "#000",
  },
  eventImage: {
    width: 128,
    height: 72,
    borderRadius: 5,
  },
  eventDetails: {
    flex: 1,
    marginLeft: 15,
  },
  eventName: {
    fontSize: 18,
    color: "#FFF",
    fontWeight: "bold",
  },
  eventDescription: {
    fontSize: 14,
    color: "#AAA",
  },
  sectionHeader: {
    flexDirection: "row",
    alignItems: "center",
  },
  sectionIcon: {
    marginRight: 10,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  sectionDivider: {
    flex: 1,
    height: 1,
    backgroundColor: "#444",
    marginLeft: 10,
  },
  infoContainer: {
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  infoRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  infoText: {
    fontSize: 14,
    color: "#A0A0A0",
    marginLeft: 10,
    fontWeight: "bold",
  },
  infoTextSecond: {
    fontSize: 14,
    color: "#E6E6E6",
    marginLeft: 10,
    fontWeight: "500",
  },
  sectionContainer: {
    marginBottom: 15,
  },
  sectionContainerSecond: {
    marginTop: 15,
    marginBottom: 15,
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#1A1A1A",
    borderRadius: 10,
    marginHorizontal: 20,
    marginBottom: 20,
  },
  searchIcon: {
    marginLeft: 10,
  },
  searchBar: {
    flex: 1,
    padding: 10,
    fontSize: 16,
    color: "#FFF",
  },
  ticketContainer: {
    backgroundColor: "#000",
    borderRadius: 5,
    paddingHorizontal: 15,
    marginHorizontal: 20,
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
    borderColor: "#333",
    borderWidth: 1,
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 30,
    marginRight: 10,
  },
  ticketDetails: {
    flex: 1,
  },
  ticketName: {
    color: "#FFF",
    fontSize: 15,
    fontWeight: "bold",
    marginVertical: 5,
  },
  ticketText: {
    fontSize: 12,
    color: "#A0A0A0",
    marginBottom: 5,
    fontWeight: "bold",
  },
  expandButton: {
    padding: 10,
  },
  checkButton: {
    borderRadius: 5,
    padding: 10,
  },
  qrCodeButton: {
    flexDirection: "row",
    backgroundColor: "#141414",
    padding: 15,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
  },
  qrCodeButtonText: {
    color: "#FFF",
    fontSize: 16,
    marginLeft: 10,
  },
  loadingContainer: {
    flex: 1,
    backgroundColor: "#000",
    justifyContent: "center",
    alignItems: "center",
  },
  ticketInfoContainer: {
    padding: 20,
    backgroundColor: "#1A1A1A",
    borderRadius: 10,
    margin: 20,
  },
  ticketInfoText: {
    fontSize: 16,
    color: "#FFF",
    marginBottom: 5,
  },
});
