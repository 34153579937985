import { doc, setDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const createUser = async (userData) => {
  try {
    const userRef = doc(db, "usersData", userData.userId);
    await setDoc(userRef, userData);

  } catch (error) {
    console.error("Erro ao criar usuário com ID personalizado:", error);
  }
};
