import React from "react";
import { View, Text, TextInput, TouchableOpacity, Alert } from "react-native";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import moment from "moment-timezone";
import { getEventUsername } from "../../../../services/cruds/events/getEvent";
import { styles } from "../../../../styles/producer/producerUtils/AddEventStyles";

const EventDetailsForm = ({
  state,
  handleInputChange,
  handleDatePicking,
  togglePicker,
  handleNextStep,
}) => {
  const handleNext = async () => {
    const usernameRemove = state.username
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, "")
      .toLowerCase();
    handleInputChange("username", usernameRemove);
    try {
      const isUsernameAvailable = await getEventUsername(usernameRemove);
      if (isUsernameAvailable) {
        Alert.alert("Username já existe", "Insira um username não utilizado.");
        handleInputChange("username", "");
      } else {
        handleNextStep();
      }
    } catch (error) {
      console.error("Erro ao verificar o username do evento:", error);
      Alert.alert(
        "Erro",
        "Ocorreu um erro ao verificar o username. Por favor, tente novamente."
      );
    }
  };

  const currentDate = new Date();

  return (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <MaterialCommunityIcons
          name="file-document"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Dados Gerais</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        <Text style={styles.label}>Nome do Evento:</Text>
        <TextInput
          style={styles.input}
          placeholderTextColor="#FFF9"
          placeholder="Meu Evento"
          value={state.name}
          onChangeText={(text) => {
            handleInputChange("name", text);
            handleInputChange(
              "searchName",
              text
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/\s+/g, "")
                .toLowerCase()
            );
          }}
        />

        <Text style={styles.label}>Username do Evento:</Text>
        <TextInput
          style={styles.input}
          placeholderTextColor="#FFF9"
          placeholder="meu_evento2024"
          value={state.username}
          onChangeText={(text) => handleInputChange("username", text)}
        />

        <Text style={styles.label}>Descrição do Evento:</Text>
        <TextInput
          style={[styles.input, styles.descriptionInput]}
          placeholderTextColor="#FFF9"
          placeholder="Descrição do melhor evento"
          multiline
          numberOfLines={6}
          value={state.description}
          onChangeText={(text) => handleInputChange("description", text)}
        />

        <Text style={styles.label}>Data e Horário de Início:</Text>
        <View style={styles.containerRow}>
          <TouchableOpacity
            style={[styles.datePickerButton, styles.datePickerButtonLarge]}
            onPress={() => togglePicker("isDatePickerVisible")}
          >
            <Text style={styles.buttonText}>
              {state.dates.start
                ? moment(state.dates.start)
                    .tz("America/Sao_Paulo")
                    .format("DD/MM/YYYY")
                : "Selecionar Data"}
            </Text>
            <Icon
              name="calendar"
              size={20}
              color="#FFF9"
              style={styles.calendarIcon}
            />
          </TouchableOpacity>
          <DateTimePickerModal
            isVisible={state.isDatePickerVisible}
            mode="date"
            minimumDate={currentDate}
            onConfirm={(date) =>
              handleDatePicking(
                "start",
                moment(state.dates.start)
                  .set({
                    year: date.getFullYear(),
                    month: date.getMonth(),
                    date: date.getDate(),
                  })
                  .toDate()
              )
            }
            onCancel={() => togglePicker("isDatePickerVisible")}
          />
          <TouchableOpacity
            style={[styles.datePickerButton, styles.datePickerButtonSmall]}
            onPress={() => togglePicker("isStartTimePickerVisible")}
          >
            <Text style={styles.buttonText}>
              {state.dates.start
                ? moment(state.dates.start)
                    .tz("America/Sao_Paulo")
                    .format("HH:mm")
                : "Selecionar Horário"}
            </Text>
            <Icon
              name="clock-outline"
              size={20}
              color="#FFF9"
              style={styles.calendarIcon}
            />
          </TouchableOpacity>
          <DateTimePickerModal
            isVisible={state.isStartTimePickerVisible}
            mode="time"
            onConfirm={(time) =>
              handleDatePicking(
                "start",
                moment(state.dates.start)
                  .set({ hour: time.getHours(), minute: time.getMinutes() })
                  .toDate()
              )
            }
            onCancel={() => togglePicker("isStartTimePickerVisible")}
          />
        </View>

        <Text style={styles.label}>Data e Horário de Término:</Text>
        <View style={styles.containerRow}>
          <TouchableOpacity
            style={[styles.datePickerButton, styles.datePickerButtonLarge]}
            onPress={() => togglePicker("isEndDatePickerVisible")}
          >
            <Text style={styles.buttonText}>
              {state.dates.end
                ? moment(state.dates.end)
                    .tz("America/Sao_Paulo")
                    .format("DD/MM/YYYY")
                : "Selecionar Data"}
            </Text>
            <Icon
              name="calendar"
              size={20}
              color="#FFF9"
              style={styles.calendarIcon}
            />
          </TouchableOpacity>
          <DateTimePickerModal
            isVisible={state.isEndDatePickerVisible}
            mode="date"
            minimumDate={currentDate}
            onConfirm={(date) =>
              handleDatePicking(
                "end",
                moment(state.dates.end)
                  .set({
                    year: date.getFullYear(),
                    month: date.getMonth(),
                    date: date.getDate(),
                  })
                  .toDate()
              )
            }
            onCancel={() => togglePicker("isEndDatePickerVisible")}
          />
          <TouchableOpacity
            style={[styles.datePickerButton, styles.datePickerButtonSmall]}
            onPress={() => togglePicker("isEndTimePickerVisible")}
          >
            <Text style={styles.buttonText}>
              {state.dates.end
                ? moment(state.dates.end).tz("America/Sao_Paulo").format("HH:mm")
                : "Selecionar Horário"}
            </Text>
            <Icon
              name="clock-outline"
              size={20}
              color="#FFF9"
              style={styles.calendarIcon}
            />
          </TouchableOpacity>
          <DateTimePickerModal
            isVisible={state.isEndTimePickerVisible}
            mode="time"
            onConfirm={(time) =>
              handleDatePicking(
                "end",
                moment(state.dates.end)
                  .set({ hour: time.getHours(), minute: time.getMinutes() })
                  .toDate()
              )
            }
            onCancel={() => togglePicker("isEndTimePickerVisible")}
          />
        </View>

        <TouchableOpacity onPress={handleNext} style={styles.buttonSubmit}>
          <Text style={styles.buttonTextNext}>Próximo</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default EventDetailsForm;
