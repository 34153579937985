import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const addAccountDeletionRequest = async (userId, username, name, reason) => {
  try {
    await addDoc(collection(db, "accountDeletionRequests"), {
      uid: userId,
      username,
      name,
      reason,
      timestamp: Timestamp.now(),
    });
  } catch (error) {
    console.error("Erro ao solicitar apagamento de conta: ", error);
    throw error;
  }
};