import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 1)',
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: 10,
  },
  headerText: {
    color: '#fffD',
    fontSize: 20,
    fontWeight: 'bold',
  },
  iconButton: {
    padding: 10,
    borderRadius: 50,
  },
  scannerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scannerFrame: {
    width: 250,
    height: 250,
    borderColor: '#fff6',
    borderWidth: 2,
    borderRadius: 10,
    position: 'relative',
  },
  scannerCornerTopLeft: {
    position: 'absolute',
    top: -10,
    left: -10,
    width: 40,
    height: 40,
    borderColor: '#fff',
    borderLeftWidth: 2,
    borderTopWidth: 2,
  },
  scannerCornerTopRight: {
    position: 'absolute',
    top: -10,
    right: -10,
    width: 40,
    height: 40,
    borderColor: '#fff',
    borderRightWidth: 2,
    borderTopWidth: 2,
  },
  scannerCornerBottomLeft: {
    position: 'absolute',
    bottom: -10,
    left: -10,
    width: 40,
    height: 40,
    borderColor: '#fff',
    borderLeftWidth: 2,
    borderBottomWidth: 2,
  },
  scannerCornerBottomRight: {
    position: 'absolute',
    bottom: -10,
    right: -10,
    width: 40,
    height: 40,
    borderColor: '#fff',
    borderRightWidth: 2,
    borderBottomWidth: 2,
  },
  infoText: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'center',
    marginTop: 20,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    padding: 10,
    borderRadius: 10,
  },
  scanAgainButton: {
    paddingVertical: 10,
    paddingHorizontal: 30,
    backgroundColor: "#1A2035",
    borderRadius: 5,
    marginBottom: 20,
  },
  scanAgainText: {
    color: "#fff",
    fontSize: 16,
  },
});
