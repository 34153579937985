import React from "react";
import {
  TextInput,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";

const SearchBar = ({ placeholder, value, onChangeText }) => {
  const clearInput = () => {
    onChangeText("");
  };

  return (
    <SafeAreaView style={styles.container}>
      <Ionicons name="search" size={20} color="#FFF" />
      <TextInput
        style={styles.input}
        placeholder={placeholder}
        value={value}
        onChangeText={onChangeText}
        placeholderTextColor="#FFF"
      />
      {value ? (
        <TouchableOpacity onPress={clearInput}>
          <Ionicons name="close-circle" size={20} color="#FFF" />
        </TouchableOpacity>
      ) : null}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    backgroundColor: "#121212",
    borderRadius: 8,
    paddingHorizontal: 25,
    alignItems: "center",
    elevation: 3,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
  },
  input: {
    flex: 1,
    paddingVertical: 7,
    marginLeft: 10,
    fontWeight: "bold",
    fontSize: 14,
    color: "#FFF",
  },
});

export default SearchBar;
