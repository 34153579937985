import { StyleSheet, Dimensions, Platform } from "react-native";

const { width } = Dimensions.get("window");

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: "center",
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  logoHeader: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  currencySymbol: {
    alignSelf: "center",
    backgroundColor: "#2B2B2B",
    color: "#FFF9",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    padding: 10,
  },
  row: {
    flexDirection: "row",
    justifyContent: "center",
  },
  screenTitle: {
    color: "#FFF",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  eventInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 20,
    backgroundColor: "#000",
  },
  eventImage: {
    width: 128,
    height: 72,
    borderRadius: 5,
  },
  profileImage: {
    width: 80,
    height: 80,
    borderRadius: 100,
  },
  stickerImage: {
    width: 80,
    height: 80,
    marginTop: 10,
    marginBottom: 15,
    borderRadius: 5,
  },
  eventDetails: {
    flex: 1,
  },
  eventName: {
    fontSize: 18,
    color: "#FFF",
    fontWeight: "bold",
  },
  eventDescription: {
    fontSize: 14,
    color: "#AAA",
  },
  publishButton: {
    backgroundColor: "#1A2035",
    padding: 7,
    marginHorizontal: 10,
    borderRadius: 5,
    alignItems: "center",
  },
  publishButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  datePickerButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#333",
    padding: 10,
    borderRadius: 5,
    marginBottom: 15,
  },
  calendarIcon: {
    marginLeft: 5,
  },
  settingItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#373737",
    backgroundColor: "#000",
  },
  settingIcon: {
    color: "#FFF",
    width: 24,
  },
  settingTextContainer: {
    flex: 1,
    paddingHorizontal: 10,
  },
  settingTitle: {
    fontSize: 16,
    color: "#FFF",
  },
  settingSubtitle: {
    fontSize: 14,
    color: "#AAA",
  },
  settingArrow: {
    color: "#FFF",
  },
  scrollView: {
    flex: 1,
    backgroundColor: "#000",
  },
  formContainer: {
    paddingHorizontal: 20,
    backgroundColor: "#000",
    borderRadius: 10,
    marginVertical: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  inputGroup: {
    marginBottom: 15,
  },
  priceInput: {
    flex: 1,
  },
  detailsContainer: {
    paddingHorizontal: 20,
    backgroundColor: "#000",
    borderRadius: 10,
    marginVertical: 5,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  detailsContainerSticker: {
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 30,
    paddingTop: 0,
  },
  labelTitle: {
    fontSize: 18,
    fontWeight: "600",
    color: "#FFF",
    marginBottom: 10,
    textAlign: "center",
  },
  socialMediaContainer: {
    backgroundColor: "#2B2B2B",
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  linkPreview: {
    fontSize: 14,
    marginLeft: 10,
    alignSelf: "center",
    color: "#FFF",
  },
  socialMediaInput: {
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    fontSize: 14,
    borderRadius: 5,
    paddingVertical: 10,
  },
  label: {
    color: "#A0A0A0",
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 5,
  },
  refundText: {
    fontSize: 16,
    color: "#A0A0A0",
    marginBottom: 10,
    marginLeft: 10,
  },
  textArea: {
    height: 100,
    textAlignVertical: "top",
  },
  inputLabel: {
    color: "#A0A0A0",
    fontSize: 16,
  },
  inputValue: {
    color: "#FFF",
    fontSize: 16,
  },
  input: {
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    borderRadius: 5,
    padding: 10,
    marginBottom: 15,
  },
  inputLots: {
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    borderRadius: 5,
    padding: 5,
    paddingLeft: 10,
    marginBottom: 15,
  },
  inputPhone: {
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    borderRadius: 5,
    padding: 10,
    marginBottom: 15,
    flex: 1,
  },
  inputPrice: {
    flex: 0.2,
  },
  inputPriceSecond: {
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    padding: 5,
  },
  inputWrapper: {
    flex: 1,
    marginRight: 10,
  },
  inputWrapperSecond: {
    flex: 1,
  },
  inputBlocked: {
    backgroundColor: "#121212",
    color: "#FFF",
    borderRadius: 5,
    padding: 10,
    marginBottom: 15,
  },
  lockedText: {
    color: "red",
    fontSize: 16,
    marginTop: 10,
  },
  descriptionInput: {
    textAlignVertical: "top",
    paddingTop: 10,
  },
  button: {
    paddingVertical: 10,
    paddingHorizontal: 40,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 5,
    marginHorizontal: 40,
    backgroundColor: "#2B2B2B",
  },
  buttonText: {
    color: "#FFF",
    fontSize: 16,
  },
  countryCode: {
    fontSize: 16,
    marginRight: 8,
    marginBottom: 15,
    alignSelf: "center",
    color: "#FFF",
  },
  richToolbar: {
    backgroundColor: "#2B2B2B",
    borderColor: "#2B2B2B",
    marginBottom: 10,
  },
  richEditor: {
    backgroundColor: "#fff",
    color: "#FFF",
    borderRadius: 5,
    minHeight: "auto",
    marginBottom: 15,
    padding: 10,
  },
  saveButton: {
    backgroundColor: "#1A2035",
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  saveButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  switchRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  switchLabel: {
    fontSize: 16,
    color: "#A0A0A0",
    marginLeft: 10,
  },
  switch: {
    transform: [{ scaleX: 1.2 }, { scaleY: 1.2 }],
    trackColor: { false: "#767577", true: "#81b0ff" },
    thumbColor: "#052552",
    ios_backgroundColor: "#3e3e3e",
  },
  radioButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  radioButton: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  radioLabel: {
    color: "#FFF",
    marginLeft: 5,
  },
  lotContainer: {
    backgroundColor: "#141414",
    padding: 15,
    borderRadius: 5,
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  halfPriceLotContainer: {
    backgroundColor: "#222",
    marginTop: 10,
    paddingTop: 10,
  },
  lotHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  lotName: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
    flex: 1,
  },
  lotActions: {
    flexDirection: "row",
  },
  lotActionButton: {
    marginLeft: 10,
  },
  lotDetails: {
    color: "#A0A0A0",
    marginBottom: 5,
  },
  addLotButton: {
    backgroundColor: "#3B0E4E",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  addButton: {
    backgroundColor: "#1A2035",
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    marginBottom: 10,
    marginTop: 10,
  },
  removeButton: {
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    marginTop: 5,
  },
  requestButton: {
    backgroundColor: "#5B2372",
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  requestButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  labelWarning: {
    color: "#FFA500",
    fontSize: 14,
    marginBottom: 10,
  },
  separator: {
    height: 2,
    backgroundColor: "#CCCCCC",
    marginVertical: 20,
    width: "95%",
    alignSelf: "center",
  },
  textStyle2: {
    color: "#FFF",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  container2: {
    marginTop: 20,
  },
  addHalfPriceButton: {
    backgroundColor: "#202940",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  editImageButton: {
    right: 20,
    top: 30,
    bottom: 0,
    width: 25,
    height: 25,
    borderRadius: 20,
    backgroundColor: "#5B2372",
    justifyContent: "center",
    alignItems: "center",
  },
  absorbFeeText: {
    color: "#A0A0A0",
    fontSize: 14,
    marginTop: -10,
    marginBottom: 15,
  },
  phoneInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  readerInput: {
    flex: 1,
    marginRight: 10,
  },
  readerInputContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  readerItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#2B2B2B",
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  readerItemText: {
    color: "#FFF",
    fontSize: 16,
  },
  removeIcon: {
    padding: 5,
  },
  noReadersText: {
    color: "#A0A0A0",
    textAlign: "center",
    marginVertical: 14,
  },
  eventKeyInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    marginBottom: 15,
  },
  eventKeyInput: {
    flex: 1,
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    borderRadius: 5,
    padding: 10,
  },
  iconButtonContainer: {
    flexDirection: "row",
    marginLeft: 10,
    marginBottom: 10,
  },
  iconButton: {
    padding: 10,
  },
  sectionDivider: {
    flex: 1,
    height: 1,
    backgroundColor: "#333",
    marginVertical: 10,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalContent: {
    backgroundColor: "#1C1C1C",
    borderRadius: 10,
    padding: 20,
    width: "80%",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 18,
    color: "#FFFFFF",
    marginBottom: 15,
    textAlign: "center",
  },
  modalText: {
    fontSize: 14,
    color: "#AAAAAA",
    marginBottom: 15,
    textAlign: "center",
  },
  stickerImageModal: {
    width: 100,
    height: 100,
    borderRadius: 10,
    marginBottom: 15,
  },
  modalButtonsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  modalButton: {
    backgroundColor: "#1A2035",
    padding: 12,
    borderRadius: 10,
    alignItems: "center",
    width: "48%",
    marginBottom: 10,
  },
  modalButtonCancel: {
    backgroundColor: "#3D3D3D",
  },
  modalButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  modalCloseButton: {
    position: "absolute",
    top: 8,
    right: 8,
    zIndex: 1,
  },
  modalContainerLot: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContentLot: {
    width: "80%",
    backgroundColor: "#1A1A1A",
    padding: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  modalTitleLot: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFF",
    marginBottom: 20,
    textAlign: "center",
  },
  modalOptionButtonLot: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: "#333",
    alignItems: "center",
    marginBottom: 10,
  },
  modalSelectedOptionButtonLot: {
    backgroundColor: "#5B2372",
    borderColor: "#5B2372",
  },
  modalOptionButtonTextLot: {
    color: "#FFF",
    fontSize: 16,
  },
  modalSelectedOptionButtonTextLot: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  modalButtonLot: {
    backgroundColor: "#5B2372",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginBottom: 10,
  },
  modalButtonCancelLot: {
    backgroundColor: "#1A2035",
  },
  modalButtonTextLot: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  settingItemForm: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000",
  },
  settingIcon: {
    color: "#FFFD",
    width: 24,
  },
  settingIconForm: {
    color: "#FFFD",
    marginRight: 5,
    width: 24,
  },
  picker: {
    flex: 1,
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    borderRadius: 10,
    padding: 10,
    marginBottom: 15,
  },
  
});
