import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const createRefundRequest = async (refundRequest) => {
  try {
    await addDoc(collection(db, "refundRequests"), refundRequest);
    return true;
  } catch (error) {
    console.error("Erro ao criar solicitação de reembolso: ", error);
    return false;
  }
};
