import React from "react";
import { View, Text } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { styles } from "../../../../styles/ticket/ticketUtils/TicketResultStyles";

const TicketInfo = ({ userName, purchaseDate, lote, price, ticketId, code, formatPrice }) => (
  <>
    <View style={styles.sectionHeader}>
      <Icon name="ticket-sharp" size={22} color="#FFF" style={styles.sectionIcon} />
      <Text style={styles.sectionTitle}>Informações do Ingresso</Text>
    </View>
    <View style={styles.containerSpace}>
      <View style={styles.sectionContainer}>
        <View style={styles.content}>
          <View style={styles.infoRow}>
            <Text style={styles.infoText}>Comprador:</Text>
            <Text style={styles.infoTextSecond}>{userName}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoText}>Data da Compra:</Text>
            <Text style={styles.infoTextSecond}>{purchaseDate}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoText}>Lote:</Text>
            <Text style={styles.infoTextSecond}>{lote}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoText}>Valor:</Text>
            <Text style={styles.infoTextSecond}>{formatPrice(price)}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoText}>ID do Ingresso:</Text>
            <Text style={styles.infoTextSecond}>{ticketId}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoText}>Chave de Acesso:</Text>
            <Text style={styles.infoTextSecond}>{code}</Text>
          </View>
        </View>
      </View>
    </View>
  </>
);

export default TicketInfo;
