import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const checkExistingRequests = async (uid) => {
  const requestsQuery = query(
    collection(db, "withdrawalRequests"),
    where("uid", "==", uid),
    where("status", "==", "Pendente")
  );

  const querySnapshot = await getDocs(requestsQuery);
  return !querySnapshot.empty;
};
