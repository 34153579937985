import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  safeArea: {
    backgroundColor: "#000",
    flex: 1,
  },
  container: {
    padding: 10,
    backgroundColor: "#000",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  subheader: {
    fontSize: 18,
    fontWeight: "600",
    color: "#FFFFFF",
    marginTop: 10,
    marginBottom: 10,
  },
  text: {
    fontSize: 16,
    color: "#FFFFFF",
    marginBottom: 10,
    flex: 1,
    flexWrap: "wrap",
  },
  textContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    flexWrap: "wrap",
    maxWidth: "100%",
  },
  icon: {
    marginRight: 10,
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  logoHeader: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  sectionDivider: {
    flex: 1,
    height: 1,
    backgroundColor: "#333",
    marginVertical: 10,
  },
});
