import React, { Component } from "react";
import {
  View,
  Text,
  FlatList,
  Image,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  RefreshControl,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";
import { getUser } from "../../../services/cruds/users/getUser";
import { getProducerStatistics } from "../../../services/cruds/events/getEvent";
import { getEventTickets } from "../../../services/cruds/tickets/getTickets";
import { styles } from "../../../styles/producer/producerInfo/ProducerStatisticsStyles";
import { SafeAreaView } from "react-native-safe-area-context";

export default class ProducerStatistics extends Component {
  state = {
    eventos: [],
    rendaGeralAcumulada: 0,
    totalIngressosVendidos: 0,
    totalIngressos: 0,
    totalEventosCriados: 0,
    loading: true,
    refreshing: false,
    searchQuery: "",
    userData: null,
    expandedEvent: null,
  };

  async componentDidMount() {
    const { producerId } = this.props.route.params;
    const userData = await getUser(producerId);
    this.setState({ userData }, () => this.loadProducerStatistics(producerId));
    this._unsubscribe = this.props.navigation.addListener("focus", () => {
      this.loadProducerStatistics(producerId);
    });
  }

  componentWillUnmount() {
    this._unsubscribe();
  }

  formatCurrency = (value) => {
    return parseFloat(value).toFixed(2).replace(".", ",");
  };

  calculateTotal = (price, absorbFee, paymentMethod) => {
    let fee;
    switch (paymentMethod) {
      case "cartao":
        fee = 0.08;
        break;
      case "boleto":
        fee = 0.08 + 3.5 / price;
        break;
      case "pix":
        fee = 0.05;
        break;
      default:
        fee = 0.08;
    }
    return absorbFee ? price - (price * fee) : price;
  };

  loadProducerStatistics = async (producerId) => {
    this.setState({ loading: true });

    const eventos = await getProducerStatistics(producerId);

    let rendaGeralAcumulada = 0;
    let totalIngressosVendidos = 0;
    let totalIngressos = 0;

    const eventosFiltrados = eventos.filter(
      (evento) =>
        evento.status === "Publicado" || evento.status === "Finalizado"
    );

    for (const evento of eventosFiltrados) {
      if (!evento.username || !evento.lots) continue;

      const ingressosVendidos = await getEventTickets(evento.username);

      const valorArrecadado = ingressosVendidos.reduce((total, ingresso) => {
        if(evento.absorbFee){
          return total + this.calculateTotal(ingresso.lotPrice, evento.absorbFee, ingresso.paymentMethod);
        }else{
          return total + parseFloat(ingresso.lotPrice);
        }
      }, 0);

      const lotsArray = Object.values(evento.lots);
      const totalIngressosEvento = lotsArray.reduce(
        (total, lot) => total + parseInt(lot.sold),
        0
      );
      const ingressosDisponiveisEvento = lotsArray.reduce(
        (total, lot) => total + (parseInt(lot.quantity) - parseInt(lot.sold)),
        0
      );

      evento.valorArrecadado = valorArrecadado;
      evento.totalIngressosVendidos = totalIngressosEvento;
      evento.ingressosDisponiveis = ingressosDisponiveisEvento;

      rendaGeralAcumulada += valorArrecadado;
      totalIngressosVendidos += totalIngressosEvento;
      totalIngressos += lotsArray.reduce(
        (total, lot) => total + parseInt(lot.quantity),
        0
      );
    }

    this.setState({
      eventos: eventosFiltrados,
      rendaGeralAcumulada,
      totalIngressosVendidos,
      totalIngressos,
      totalEventosCriados: eventosFiltrados.length,
      loading: false,
      refreshing: false,
    });
  };

  handleSearch = (text) => {
    const { eventos } = this.state;
    const query = text.toLowerCase();
    const eventosFiltrados = eventos.filter(
      (evento) =>
        evento.name.toLowerCase().includes(query) ||
        evento.status.toLowerCase().includes(query)
    );
    this.setState({ searchQuery: text, eventos: eventosFiltrados });
  };

  toggleExpand = (id) => {
    this.setState((prevState) => ({
      expandedEvent: prevState.expandedEvent === id ? null : id,
    }));
  };

  renderHeader = () => {
    const { userData } = this.state;
    return (
      <>
        <View style={styles.containerHeader}>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.goBack();
            }}
            style={styles.backButtonHeader}
          >
            <Icon name="arrow-back" size={25} color="#FFFFFF" />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => this.props.navigation.navigate("Home")}
          >
            <Image
              source={logoTexto}
              resizeMode="contain"
              style={styles.logoHeader}
            />
          </TouchableOpacity>

          <Icon
            name="person-outline"
            size={25}
            color="#000"
            // onPress={() => this.props.navigation.navigate("Profile")}
            style={styles.goBackButton}
          />
        </View>
        <View style={styles.eventInfoContainer}>
          <Image
            source={
              userData && userData.avatarUrl != null
                ? { uri: userData.avatarUrl }
                : require("../../../../assets/imgs/Avatar_Macaco.png")
            }
            style={styles.eventImage}
          />
          <View style={styles.eventDetails}>
            <Text style={styles.eventName}>
              {userData ? userData.fullName : "Carregando..."}
            </Text>
            <Text style={styles.eventDescription}>
              Tela de Estatísticas e Dados Gerais
            </Text>
          </View>
        </View>
      </>
    );
  };

  renderSectionTitle = (title, icon) => (
    <View style={styles.sectionHeader}>
      <Icon name={icon} size={20} color="#FFF" style={{ marginRight: 10 }} />
      <Text style={styles.sectionTitle}>{title}</Text>
      <View style={styles.sectionDivider} />
    </View>
  );

  renderItem = ({ item }) => {
    const { expandedEvent } = this.state;
    const isExpanded = expandedEvent === item.id;
    const { address } = item;

    const defaultImageEvent = require("../../../../assets/imgs/fundo_evento.png");
    const eventImage =
      item.eventImage !== "default_image.png"
        ? { uri: item.eventImage }
        : defaultImageEvent;

    return (
      <View style={styles.eventCardContainer}>
        <TouchableOpacity
          style={styles.eventCard}
          onPress={() =>
            this.props.navigation.navigate("EventStatistics", { evento: item })
          }
        >
          <Image source={eventImage} style={styles.eventCardImage} />
          <View style={styles.eventCardDetails}>
            <Text style={styles.eventCardTitle} numberOfLines={1}>
              {item.name}
            </Text>
            <View style={styles.eventCardInfo}>
              <Icon name="cash-outline" size={16} color="#FFF" />
              <Text style={styles.eventCardValue}>
                R${this.formatCurrency(item.valorArrecadado)}
              </Text>
            </View>
            <View style={styles.eventCardInfo}>
              <Icon name="ticket-outline" size={16} color="#FFF" />
              <Text style={styles.eventCardTickets}>
                Vendidos: {item.totalIngressosVendidos}
              </Text>
            </View>
            <View style={styles.eventCardInfo}>
              <Icon name="calendar-outline" size={16} color="#FFF" />
              <Text style={styles.eventCardTickets}>
                Disponíveis: {item.ingressosDisponiveis}
              </Text>
            </View>
          </View>
          <TouchableOpacity
            style={styles.expandButton}
            onPress={() => this.toggleExpand(item.id)}
          >
            <Icon
              name={isExpanded ? "chevron-up" : "chevron-down"}
              size={24}
              color="#FFF"
            />
          </TouchableOpacity>
        </TouchableOpacity>
        {isExpanded && (
          <View style={styles.expandedDetails}>
            <Text style={styles.expandedText}>
              <Icon name="calendar-outline" size={16} color="#FFF" /> Status:{" "}
              {item.status}
            </Text>
            <Text style={styles.expandedText}>
              <Icon name="calendar-outline" size={16} color="#FFF" /> Data
              Início:{" "}
              {new Date(item.dates.start.toDate()).toLocaleDateString("pt-BR")}
            </Text>
            <Text style={styles.expandedText}>
              <Icon name="location-outline" size={16} color="#FFF" /> Local:{" "}
              {`${address.name ? address.name : null}, ${address.street}, ${
                address.number
              }, ${address.neighborhood}, ${address.city} - ${address.state}`}
            </Text>
          </View>
        )}
      </View>
    );
  };

  renderFooter = () => {
    if (!this.state.loading) return null;
    return <ActivityIndicator style={{ color: "#FFF" }} />;
  };

  render() {
    const {
      eventos,
      rendaGeralAcumulada,
      totalIngressosVendidos,
      totalIngressos,
      totalEventosCriados,
      loading,
      searchQuery,
    } = this.state;

    if (loading) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#FFF" />
        </View>
      );
    }

    return (
      <SafeAreaView style={styles.container}>
        {this.renderHeader()}
        <View style={styles.containerSpace}>
          <View style={styles.sectionContainer}>
            {this.renderSectionTitle("Informações", "information-circle")}
          </View>
          <View style={styles.infoContainer}>
            <View style={styles.infoRow}>
              <Text style={styles.infoText}>Eventos Criados:</Text>
              <Text style={styles.infoTextSecond}>{totalEventosCriados}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.infoText}>Valor Líquido Total:</Text>
              <Text style={styles.infoTextSecond}>
                R${this.formatCurrency(rendaGeralAcumulada)}
              </Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.infoText}>Ingressos Vendidos:</Text>
              <Text style={styles.infoTextSecond}>
                {totalIngressosVendidos}
              </Text>
            </View>
          </View>
          <View style={styles.sectionContainerSecond}>
            {this.renderSectionTitle("Eventos", "calendar")}
          </View>
        </View>
        <View style={styles.searchContainer}>
          <Icon
            name="search"
            size={20}
            color="#CCC"
            style={styles.searchIcon}
          />
          <TextInput
            style={styles.searchBar}
            placeholder="Pesquisar Eventos"
            placeholderTextColor="#CCC"
            value={searchQuery}
            onChangeText={this.handleSearch}
          />
        </View>
        <FlatList
          data={eventos}
          renderItem={this.renderItem}
          keyExtractor={(item) => item.id.toString()}
          ListFooterComponent={this.renderFooter}
          refreshControl={
            <RefreshControl
              refreshing={this.state.refreshing}
              onRefresh={() =>
                this.loadProducerStatistics(this.props.route.params.producerId)
              }
            />
          }
          ListEmptyComponent={() => (
            <View style={styles.emptyContainer}>
              <Text style={styles.emptyText}>Nenhum evento criado ainda.</Text>
            </View>
          )}
        />
      </SafeAreaView>
    );
  }
}
