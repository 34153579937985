import React, { Component, createRef } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  Image,
  StatusBar,
  ScrollView,
  Modal,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Alert,
} from "react-native";
import { Timestamp } from "firebase/firestore";
import { auth } from "../../../services/utils/firebase/firebaseConfig";
import { getTicketCode } from "../../../services/cruds/tickets/getTickets";
import { getUserTicketResult } from "../../../services/cruds/users/getUser";
import { createRefundRequest } from "../../../services/cruds/refundRequests/createRefundRequests";
import { checkExistingRefundRequests } from "../../../services/cruds/refundRequests/getRefundRequests";
import { qrCodeGenerator } from "../../../components/ticket/ticketUtils/QRCode";
import QRCode from "react-native-qrcode-svg";
import Icon from "react-native-vector-icons/Ionicons";
import { styles } from "../../../styles/ticket/ticketUtils/TicketResultStyles";
import macaquinho from "../../../../assets/imgs/Logo_Macaco2.png";
import { SafeAreaView } from "react-native-safe-area-context";
import TicketResultSkeleton from "../../../components/ticket/ticketUtils/ticketResult/TicketResultSkeleton";
import EventInfo from "../../../components/ticket/ticketUtils/ticketResult/EventInfo";
import TicketInfo from "../../../components/ticket/ticketUtils/ticketResult/TicketInfo";
import QRCodeSection from "../../../components/ticket/ticketUtils/ticketResult/QRCodeSection";
import RefundModal from "../../../components/ticket/ticketUtils/ticketResult/RefundModal";
import ConfirmationModal from "../../../components/ticket/ticketUtils/ticketResult/ConfirmationModal";
import Header from "../../../components/ticket/ticketUtils/ticketResult/Header";
import PDFGenerator from "../../../components/ticket/ticketUtils/ticketResult/PDFGenerator";
import TransferModal from "../../../components/ticket/ticketUtils/ticketResult/TransferModal";

export default class TicketResult extends Component {
  state = {
    ticketId: "",
    eventName: "",
    eventUsername: "",
    userName: "",
    eventDate: "",
    eventTime: "",
    eventLocation: "",
    purchaseDate: "",
    price: "",
    lote: "",
    loading: true,
    error: null,
    qrCodeModalVisible: false,
    qrCodeBase64: "",
    macaquinhoBase64: "",
    refundModalVisible: false,
    refundReason: "",
    confirmationModalVisible: false,
    refundAmount: 0,
    serviceFee: 0,
    selectedPaymentMethod: "",
    paymentDetails: {},
    code: "",
    QR: {},
    transferModalVisible: false,
  };

  qrCodeRef = createRef();

  async componentDidMount() {
    const { ticketId, event } = this.props.route.params;
    const userId = await this.getLoggedInUserId();

    if (userId) {
      try {
        const userData = await getUserTicketResult(userId);
        if (!userData) throw new Error("Dados do usuário não encontrados");

        const ticketData = this.findTicketData(userData, ticketId);
        if (!ticketData) throw new Error("Ticket não encontrado");

        const macaquinhoBase64 = await this.loadImageAsBase64(macaquinho);

        const code = await getTicketCode(ticketData.idTicket);

        const QR = await qrCodeGenerator({ ticketId });

        this.setState(
          {
            eventData: event,
            eventImage: this.resolveEventImage(event),
            ticketId: ticketData.idTicket,
            code: code,
            QR: QR,
            eventName: event.name,
            eventUsername: event.username,
            userName: userData.fullName,
            userId: userId,
            eventDate: this.formatDate(event.dates.start.seconds),
            eventTime: this.formatTime(event.dates.start.seconds),
            eventLocation: this.formatLocation(event.address),
            purchaseDate: this.formatDate(ticketData.payDay.seconds),
            price: ticketData.lotPrice,
            lote: ticketData.lotName,
            macaquinhoBase64,
            selectedPaymentMethod: userData.selectedPaymentMethod,
            paymentDetails: userData.paymentMethods[userData.selectedPaymentMethod] || {},
            loading: false,
          },
          this.generateQRCodeBase64
        );
      } catch (error) {
        console.error("Erro ao procurar os dados: ", error);
        this.setState({ loading: false, error: error.message });
      }
    } else {
      this.setState({ loading: false, error: "Usuario não está logado" });
    }
  }

  resolveEventImage(event) {
    const defaultImageEvent = require("../../../../assets/imgs/fundo_evento.png");
    return event.eventImage !== "default_image.png"
      ? { uri: event.eventImage }
      : defaultImageEvent;
  }

  formatDate(seconds) {
    return new Date(seconds * 1000).toLocaleDateString("pt-BR");
  }

  formatTime(seconds) {
    return new Date(seconds * 1000).toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  formatLocation(address) {
    return `${address.street}, ${address.number}, ${address.neighborhood}, ${address.city} - ${address.state}, Brasil, CEP: ${address.zip}`;
  }

  async loadImageAsBase64(image) {
    try {
      const imageUri = Image.resolveAssetSource(image)?.uri;
      if (!imageUri) throw new Error("Imagem não encontrada");
      const response = await fetch(imageUri);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Erro ao carregar a imagem em base64:", error);
      return "";
    }
  }

  async getLoggedInUserId() {
    const user = auth.currentUser;
    return user ? user.uid : null;
  }

  findTicketData(userData, ticketId) {
    return userData?.ticketHistory?.find((ticket) => ticket.idTicket === ticketId) || null;
  }

  toggleQrCodeModal = () => {
    this.setState((prevState) => ({
      qrCodeModalVisible: !prevState.qrCodeModalVisible,
    }));
  };

  toggleTransferModal = () => {
    this.setState((prevState) => ({
      transferModalVisible: !prevState.transferModalVisible,
    }));
  };

  formatPrice = (price) => {
    return parseFloat(price).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  generateQRCodeBase64 = () => {
    this.qrCodeRef.current.toDataURL((data) => {
      this.setState({ qrCodeBase64: data });
    });
  };

  toggleRefundModal = () => {
    this.setState((prevState) => ({
      refundModalVisible: !prevState.refundModalVisible,
    }));
  };

  toggleConfirmationModal = () => {
    this.setState((prevState) => ({
      confirmationModalVisible: !prevState.confirmationModalVisible,
    }));
  };

  normalizeUsername = (username) => {
    return username.replace(/\s+/g, "").toLowerCase();
  };

  handleConfirmTransfer = async (username) => {
    const { ticketId } = this.state;
    const normalizedUsername = this.normalizeUsername(username);

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error("Usuário não autenticado.");
      }
      const idToken = await currentUser.getIdToken();

      const response = await fetch(
        'https://us-central1-rayv-a7c48.cloudfunctions.net/api/transferTicket',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({ ticketId: ticketId, newUsername: normalizedUsername }),
        }
      );

      const responseText = await response.text();
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}. Message: ${responseText}`);
      }

      try {
        const data = JSON.parse(responseText);
        console.log("Dados recebidos do servidor:", data);
        alert("Ingresso transferido com sucesso!");
      } catch (jsonError) {
        console.error("Erro ao analisar resposta JSON:", jsonError);
        alert("Ingresso transferido com sucesso, mas a resposta não pôde ser analisada.");
      }

      this.toggleTransferModal();
    } catch (error) {
      console.error("Erro ao transferir ingresso:", error);
      alert("Erro ao transferir ingresso, tente novamente mais tarde.");
    }
  };

  handleRefundReasonChange = (text) => {
    this.setState({ refundReason: text });
  };

  calculateRefund = () => {
    const { price, eventData } = this.state;
    const paymentMethod = this.state.selectedPaymentMethod;
    const absorbFee = eventData.absorbFee;

    const priceNumber = parseFloat(price);

    if (isNaN(priceNumber)) {
      console.error("O preço não é um número válido:", price);
      alert("O preço do ingresso não é válido.");
      return;
    }

    let fee;
    switch (paymentMethod) {
      case "cartao":
        fee = 0.08;
        break;
      case "boleto":
        fee = 0.08 + 3.5 / priceNumber;
        break;
      case "pix":
        fee = 0.05;
        break;
      default:
        fee = 0.08;
    }

    let refundAmount;
    if (absorbFee) {
      refundAmount = priceNumber - priceNumber * fee;
    } else {
      refundAmount = priceNumber * (1 - fee);
    }

    const serviceFee = priceNumber * fee;

    this.setState({
      refundAmount: refundAmount.toFixed(2),
      serviceFee: serviceFee.toFixed(2),
    });
    this.toggleConfirmationModal();
  };

  processRefund = async () => {
    const {
      refundReason,
      ticketId,
      eventData,
      userName,
      eventName,
      eventUsername,
      userId,
      refundAmount,
      serviceFee,
      selectedPaymentMethod,
      paymentDetails,
    } = this.state;

    const currentDate = new Date();
    const eventDate = new Date(eventData.dates.start.seconds * 1000);

    const purchaseDateFormat = new Date(this.state.purchaseDate.seconds * 1000);
    const sevenDaysAfterPurchase = new Date(purchaseDateFormat);
    sevenDaysAfterPurchase.setDate(sevenDaysAfterPurchase.getDate() + 7);

    const twoDaysBeforeEvent = new Date(eventDate);
    twoDaysBeforeEvent.setDate(twoDaysBeforeEvent.getDate() - 2);

    const currentDateBRT = new Date(
      currentDate.toLocaleString("en-US", { timeZone: "America/Sao_Paulo" })
    );
    const sevenDaysAfterPurchaseBRT = new Date(
      sevenDaysAfterPurchase.toLocaleString("en-US", {
        timeZone: "America/Sao_Paulo",
      })
    );
    const twoDaysBeforeEventBRT = new Date(
      twoDaysBeforeEvent.toLocaleString("en-US", {
        timeZone: "America/Sao_Paulo",
      })
    );

    if (
      currentDateBRT > sevenDaysAfterPurchaseBRT &&
      currentDateBRT < twoDaysBeforeEventBRT &&
      eventData.status !== "Cancelado"
    ) {
      alert("Você não está qualificado para um reembolso.");
      return;
    }

    if (!refundReason) {
      alert("Por favor, descreva o motivo do reembolso.");
      return;
    }

    const existingRequest = await checkExistingRefundRequests(ticketId);
    if (existingRequest) {
      alert("Já existe uma solicitação de reembolso em andamento.");
      return;
    }

    const refundRequest = {
      uid: userId,
      ticketId,
      eventUsername,
      refundReason,
      requestDate: Timestamp.now(),
      status: "Pendente",
      refundAmount: parseFloat(refundAmount),
      serviceFee: parseFloat(serviceFee),
      paymentMethod: selectedPaymentMethod,
      paymentDetails,
    };

    const success = await createRefundRequest(refundRequest);
    if (success) {
      alert("Solicitação de reembolso enviada com sucesso!");
      this.toggleRefundModal();
      this.toggleConfirmationModal();
    } else {
      alert("Erro ao processar reembolso, tente novamente mais tarde.");
    }
  };

  requestRefund = async () => {
    const { selectedPaymentMethod, paymentDetails, eventData } = this.state;

    if (!selectedPaymentMethod || !paymentDetails || Object.keys(paymentDetails).length === 0) {
      alert("Por favor, preencha seu método de recebimento antes de solicitar um reembolso.");
      return;
    }

    const currentDate = new Date();
    const eventDate = new Date(eventData.dates.start.seconds * 1000);
    const purchaseDateFormat = new Date(this.state.purchaseDate.seconds * 1000);

    const sevenDaysAfterPurchase = new Date(purchaseDateFormat);
    sevenDaysAfterPurchase.setDate(sevenDaysAfterPurchase.getDate() + 7);

    const twoDaysBeforeEvent = new Date(eventDate);
    twoDaysBeforeEvent.setDate(twoDaysBeforeEvent.getDate() - 2);

    const currentDateBRT = new Date(
      currentDate.toLocaleString("en-US", { timeZone: "America/Sao_Paulo" })
    );
    const sevenDaysAfterPurchaseBRT = new Date(
      sevenDaysAfterPurchase.toLocaleString("en-US", {
        timeZone: "America/Sao_Paulo",
      })
    );
    const twoDaysBeforeEventBRT = new Date(
      twoDaysBeforeEvent.toLocaleString("en-US", {
        timeZone: "America/Sao_Paulo",
      })
    );

    if (
      currentDateBRT > sevenDaysAfterPurchaseBRT &&
      currentDateBRT < twoDaysBeforeEventBRT &&
      eventData.status !== "Cancelado"
    ) {
      alert("Você não está qualificado para um reembolso.");
      return;
    }

    this.toggleRefundModal();
  };

  render() {
    const {
      eventImage,
      ticketId,
      code,
      QR,
      eventName,
      userName,
      eventDate,
      eventTime,
      eventLocation,
      purchaseDate,
      price,
      lote,
      loading,
      error,
      qrCodeModalVisible,
      refundModalVisible,
      refundReason,
      confirmationModalVisible,
      refundAmount,
      serviceFee,
      transferModalVisible,
    } = this.state;

    if (loading) {
      return (
        <View style={styles.container}>
          <ScrollView contentContainerStyle={styles.scrollViewContent}>
            <SafeAreaView style={styles.safeAreaHeader}>
              <StatusBar barStyle="light-content" />
              <Header navigation={this.props.navigation} />
              <TicketResultSkeleton />
            </SafeAreaView>
          </ScrollView>
        </View>
      );
    }

    if (error) {
      return (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{error}</Text>
        </View>
      );
    }

    const eventFinalizado = new Date(eventDate).getTime() < new Date().getTime();

    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={styles.scrollViewContent}>
          <SafeAreaView style={styles.safeAreaHeader}>
            <StatusBar barStyle="light-content" />
            <Header navigation={this.props.navigation} />
          </SafeAreaView>
          <Image source={eventImage} style={styles.eventImage} />
          <EventInfo
            eventName={eventName}
            eventDate={eventDate}
            eventTime={eventTime}
            eventLocation={eventLocation}
          />
          <View style={styles.sectionDividerSecond}></View>
          <TicketInfo
            userName={userName}
            purchaseDate={purchaseDate}
            lote={lote}
            price={price}
            ticketId={ticketId}
            code={code}
            formatPrice={this.formatPrice}
          />
          <View style={styles.sectionDividerSecond}></View>
          <QRCodeSection
            QR={QR}
            qrCodeRef={this.qrCodeRef}
            toggleQrCodeModal={this.toggleQrCodeModal}
          />
          {parseFloat(price) > 0 && !eventFinalizado && (
            <>
              <TouchableOpacity style={styles.transferButton} onPress={this.toggleTransferModal}>
                <Icon name="swap-horizontal" size={22} color="#FFF" />
                <Text style={styles.transferButtonText}>Transferir Ingresso</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.refundButton} onPress={this.requestRefund}>
                <Icon name="cash" size={22} color="#FFF" />
                <Text style={styles.refundButtonText}>Solicitar Reembolso</Text>
              </TouchableOpacity>
            </>
          )}
        </ScrollView>
        <PDFGenerator
          eventImage={eventImage}
          ticketId={ticketId}
          eventName={eventName}
          userName={userName}
          eventDate={eventDate}
          eventTime={eventTime}
          eventLocation={eventLocation}
          price={price}
          lote={lote}
          qrCodeBase64={this.state.qrCodeBase64}
          macaquinhoBase64={this.state.macaquinhoBase64}
          formatPrice={this.formatPrice}
        />
        <Modal visible={qrCodeModalVisible} transparent={true} animationType="slide">
          <TouchableWithoutFeedback onPress={this.toggleQrCodeModal}>
            <View style={styles.modalContainer}>
              <TouchableWithoutFeedback>
                <View style={styles.modalContent}>
                  <QRCode value={QR} size={300} backgroundColor="#000" color="#FFF" getRef={this.qrCodeRef} />
                </View>
              </TouchableWithoutFeedback>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
        <RefundModal
          visible={refundModalVisible}
          refundReason={refundReason}
          handleRefundReasonChange={this.handleRefundReasonChange}
          toggleRefundModal={this.toggleRefundModal}
          calculateRefund={this.calculateRefund}
        />
        <ConfirmationModal
          visible={confirmationModalVisible}
          refundAmount={refundAmount}
          serviceFee={serviceFee}
          formatPrice={this.formatPrice}
          processRefund={this.processRefund}
          toggleConfirmationModal={this.toggleConfirmationModal}
        />
        <TransferModal
          visible={transferModalVisible}
          toggleTransferModal={this.toggleTransferModal}
          onConfirmTransfer={this.handleConfirmTransfer}
        />
      </View>
    );
  }
}
