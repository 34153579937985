import React, { useState } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Switch,
  FlatList,
  Alert,
  Modal,
} from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { styles } from "../../../../styles/producer/producerUtils/AddEventStyles";
import moment from "moment";

const formatCurrency = (value) => {
  return `R$ ${parseFloat(value).toFixed(2).replace(".", ",")}`;
};

const calculateTotal = (price, absorbFee, paymentMethod) => {
  let fee;
  switch (paymentMethod) {
    case "cartao":
      fee = 0.08;
      break;
    case "boleto":
      fee = 0.08 + 3.5 / price;
      break;
    case "pix":
      fee = 0.05;
      break;
    default:
      fee = 0.08;
  }
  return absorbFee ? price - (price * (1 + fee) - price) : price * (1 + fee);
};

const formatPriceInput = (text) => {
  return text.replace(",", ".");
};

const LotsForm = ({
  state,
  handleInputChange,
  handleNextStep,
  handlePrevStep,
}) => {
  const [isDateTimePickerVisible, setDateTimePickerVisible] = useState(false);
  const [currentDateField, setCurrentDateField] = useState(null);
  const [expandedLots, setExpandedLots] = useState([]);
  const [expandedHalfPriceLots, setExpandedHalfPriceLots] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [currentLotType, setCurrentLotType] = useState("main"); // "main" ou "halfPrice"
  const [newLot, setNewLot] = useState({
    name: "",
    quantity: "",
    sold: 0,
    price: "",
    halfPrice: false,
    mainLotId: null,
    visible: true,
    turnType: "quantity",
    start: "",
    end: "",
    previousLot: "Primeira Remessa", // nova variável
  });

  const [editingLotName, setEditingLotName] = useState(null);

  const handleDateConfirm = (date) => {
    setNewLot({
      ...newLot,
      [currentDateField]: date,
    });
    setDateTimePickerVisible(false);
  };

  const handleAddLot = () => {
    if (newLot.name && newLot.quantity && newLot.price) {
      if (editingLotName !== null) {
        state.lots[editingLotName] = newLot;
      } else {
        state.lots[newLot.name] = newLot;
      }
      setNewLot({
        name: "",
        quantity: "",
        sold: 0,
        price: "",
        halfPrice: false,
        mainLotId: null,
        visible: true,
        turnType: "quantity",
        start: "",
        end: "",
        previousLot: "Primeira Remessa", // nova variável
      });
      setEditingLotName(null);
      handleInputChange("lots", state.lots);
    } else {
      Alert.alert("Erro", "Preencha todos os campos obrigatórios.");
    }
  };

  const handleEditLot = (name) => {
    setNewLot(state.lots[name]);
    setEditingLotName(name);
  };

  const handleRemoveLot = (name) => {
    const lot = state.lots[name];
    if (lot.halfPriceLot) {
      delete state.lots[lot.halfPriceLot];
    }
    delete state.lots[name];
    handleInputChange("lots", state.lots);
  };

  const handleAddHalfPriceLot = (name) => {
    const mainLot = state.lots[name];
    if (mainLot.halfPriceLot) {
      Alert.alert("Erro", "Este lote já possui um lote de meia entrada.");
      return;
    }
    const halfPriceLot = {
      ...mainLot,
      name: `${mainLot.name} - Meia Entrada`,
      price: (mainLot.price / 2).toFixed(2),
      halfPrice: true,
      mainLotId: name,
      previousLot: "Primeira Remessa",
    };
    state.lots[halfPriceLot.name] = halfPriceLot;
    mainLot.halfPriceLot = halfPriceLot.name;
    handleInputChange("lots", state.lots);
  };

  const handleRemoveHalfPriceLot = (name) => {
    const mainLot = state.lots[name];
    if (mainLot.halfPriceLot) {
      delete state.lots[mainLot.halfPriceLot];
      mainLot.halfPriceLot = null;
      handleInputChange("lots", state.lots);
    }
  };

  const toggleExpandLot = (name) => {
    if (expandedLots.includes(name)) {
      setExpandedLots(expandedLots.filter((lot) => lot !== name));
    } else {
      setExpandedLots([...expandedLots, name]);
    }
  };

  const toggleExpandHalfPriceLot = (name) => {
    if (expandedHalfPriceLots.includes(name)) {
      setExpandedHalfPriceLots(
        expandedHalfPriceLots.filter((lot) => lot !== name)
      );
    } else {
      setExpandedHalfPriceLots([...expandedHalfPriceLots, name]);
    }
  };

  const renderLotItem = ({ item: name }) => {
    const item = state.lots[name];
    const totalValueCartao = calculateTotal(
      item.price,
      state.absorbFee,
      "cartao"
    );
    const totalValueBoleto = calculateTotal(
      item.price,
      state.absorbFee,
      "boleto"
    );
    const totalValuePix = calculateTotal(item.price, state.absorbFee, "pix");

    const isExpanded = expandedLots.includes(name);

    return (
      <View style={styles.lotContainer}>
        <View style={styles.lotHeader}>
          <Text style={styles.lotName} numberOfLines={1} ellipsizeMode="tail">
            {item.name}
          </Text>
          <View style={styles.lotActions}>
            <TouchableOpacity
              onPress={() => handleEditLot(name)}
              style={styles.lotActionButton}
            >
              <Icon name="pencil" size={20} color="#FFF9" />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => handleRemoveLot(name)}
              style={styles.lotActionButton}
            >
              <Icon name="trash-can" size={20} color="#FFF9" />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => toggleExpandLot(name)}
              style={styles.lotActionButton}
            >
              <Icon
                name={isExpanded ? "chevron-up" : "chevron-down"}
                size={20}
                color="#FFF9"
              />
            </TouchableOpacity>
          </View>
        </View>
        <Text style={styles.lotDetails}>Quantidade: {item.quantity}</Text>
        <Text style={styles.lotDetails}>
          Preço: {formatCurrency(item.price)}
        </Text>
        <Text style={styles.lotDetails}>
          Valor {state.absorbFee ? "Recebido" : "com Taxa (Cartão)"}:{" "}
          {formatCurrency(totalValueCartao)}
        </Text>
        {isExpanded && (
          <>
            <Text style={styles.lotDetails}>
              Valor com Taxa (Pix): {formatCurrency(totalValuePix)}
            </Text>
            <Text style={styles.lotDetails}>
              Valor com Taxa (Boleto): {formatCurrency(totalValueBoleto)}
            </Text>
            <Text style={styles.lotDetails}>
              Visível: {item.visible ? "Sim" : "Não"}
            </Text>
            <Text style={styles.lotDetails}>
              Virada por: {item.turnType === "quantity" ? "Quantidade" : "Data"}
            </Text>
            {item.turnType === "date" && (
              <>
                <Text style={styles.lotDetails}>
                  Início:{" "}
                  {item.start
                    ? moment(item.start).format("DD/MM/YYYY HH:mm")
                    : "N/A"}
                </Text>
                <Text style={styles.lotDetails}>
                  Fim:{" "}
                  {item.end
                    ? moment(item.end).format("DD/MM/YYYY HH:mm")
                    : "N/A"}
                </Text>
              </>
            )}
          </>
        )}
        {item.turnType === "quantity" &&
          item.previousLot &&
          item.previousLot !== "Primeira Remessa" && (
            <Text style={styles.lotDetails}>
              Lote Anterior: {item.previousLot}
            </Text>
          )}
        {!item.halfPrice && (
          <TouchableOpacity
            onPress={() => handleAddHalfPriceLot(name)}
            style={styles.addHalfPriceButton}
          >
            <Text style={styles.buttonText}>Adicionar Meia Entrada</Text>
          </TouchableOpacity>
        )}
        {item.halfPriceLot && (
          <View style={[styles.lotContainer, styles.halfPriceLotContainer]}>
            <View style={styles.lotHeader}>
              <Text
                style={styles.lotName}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {item.halfPriceLot}
              </Text>
              <View style={styles.lotActions}>
                <TouchableOpacity
                  onPress={() => handleEditLot(item.halfPriceLot)}
                  style={styles.lotActionButton}
                >
                  <Icon name="pencil" size={20} color="#FFF9" />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => handleRemoveHalfPriceLot(name)}
                  style={styles.lotActionButton}
                >
                  <Icon name="trash-can" size={20} color="#FFF9" />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => toggleExpandHalfPriceLot(item.halfPriceLot)}
                  style={styles.lotActionButton}
                >
                  <Icon
                    name={
                      expandedHalfPriceLots.includes(item.halfPriceLot)
                        ? "chevron-up"
                        : "chevron-down"
                    }
                    size={20}
                    color="#FFF9"
                  />
                </TouchableOpacity>
              </View>
            </View>
            <Text style={styles.lotDetails}>
              Quantidade: {state.lots[item.halfPriceLot].quantity}
            </Text>
            <Text style={styles.lotDetails}>
              Preço: {formatCurrency(state.lots[item.halfPriceLot].price)}
            </Text>
            <Text style={styles.lotDetails}>
              Valor {state.absorbFee ? "Recebido" : "com Taxa (Cartão)"}:{" "}
              {formatCurrency(
                calculateTotal(
                  state.lots[item.halfPriceLot].price,
                  state.absorbFee,
                  "cartao"
                )
              )}
            </Text>
            {expandedHalfPriceLots.includes(item.halfPriceLot) && (
              <>
                <Text style={styles.lotDetails}>
                  Valor com Taxa (Pix):{" "}
                  {formatCurrency(
                    calculateTotal(
                      state.lots[item.halfPriceLot].price,
                      state.absorbFee,
                      "pix"
                    )
                  )}
                </Text>
                <Text style={styles.lotDetails}>
                  Valor com Taxa (Boleto):{" "}
                  {formatCurrency(
                    calculateTotal(
                      state.lots[item.halfPriceLot].price,
                      state.absorbFee,
                      "boleto"
                    )
                  )}
                </Text>
                <Text style={styles.lotDetails}>
                  Visível:{" "}
                  {state.lots[item.halfPriceLot].visible ? "Sim" : "Não"}
                </Text>
                <Text style={styles.lotDetails}>
                  Virada por:{" "}
                  {state.lots[item.halfPriceLot].turnType === "quantity"
                    ? "Quantidade"
                    : "Data"}
                </Text>
                {state.lots[item.halfPriceLot].turnType === "date" && (
                  <>
                    <Text style={styles.lotDetails}>
                      Início:{" "}
                      {state.lots[item.halfPriceLot].start
                        ? moment(state.lots[item.halfPriceLot].start).format(
                            "DD/MM/YYYY HH:mm"
                          )
                        : "N/A"}
                    </Text>
                    <Text style={styles.lotDetails}>
                      Fim:{" "}
                      {state.lots[item.halfPriceLot].end
                        ? moment(state.lots[item.halfPriceLot].end).format(
                            "DD/MM/YYYY HH:mm"
                          )
                        : "N/A"}
                    </Text>
                  </>
                )}
                {state.lots[item.halfPriceLot].turnType === "quantity" &&
                  state.lots[item.halfPriceLot].previousLot &&
                  state.lots[item.halfPriceLot].previousLot !==
                    "Primeira Remessa" && (
                    <Text style={styles.lotDetails}>
                      Lote Anterior: {state.lots[item.halfPriceLot].previousLot}
                    </Text>
                  )}
              </>
            )}
          </View>
        )}
      </View>
    );
  };

  return (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <Ionicons
          name="ticket-sharp"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Lotes</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        <Text style={styles.label}>Nome do Ingresso:</Text>
        <TextInput
          style={styles.inputLots}
          placeholderTextColor="#FFF9"
          placeholder="Primeiro Lote"
          value={newLot.name}
          onChangeText={(text) => setNewLot({ ...newLot, name: text })}
        />

        <View style={styles.row}>
          <View style={styles.inputWrapper}>
            <Text style={styles.label}>Quantidade:</Text>
            <TextInput
              style={styles.inputLots}
              placeholderTextColor="#FFF9"
              placeholder="50"
              keyboardType="numeric"
              value={newLot.quantity}
              onChangeText={(text) => setNewLot({ ...newLot, quantity: text })}
            />
          </View>

          <View style={styles.inputWrapperSecond}>
            <Text style={styles.label}>Preço:</Text>
            <View style={styles.row}>
              <Text style={[styles.currencySymbol, styles.inputPrice]}>R$</Text>
              <TextInput
                style={[styles.inputPriceSecond, styles.priceInput]}
                placeholderTextColor="#FFF9"
                placeholder="100.00"
                keyboardType="numeric"
                value={newLot.price}
                onChangeText={(text) =>
                  setNewLot({ ...newLot, price: formatPriceInput(text) })
                }
              />
            </View>
          </View>
        </View>

        <View style={styles.switchRow}>
          <Text style={styles.label}>Defina se seu lote será visível:</Text>
          <Switch
            style={styles.switch}
            trackColor={{ false: "#767577", true: "#202940" }}
            thumbColor={newLot.visible ? "#052552" : "#f4f3f4"}
            ios_backgroundColor="#202940"
            value={newLot.visible}
            onValueChange={(value) => setNewLot({ ...newLot, visible: value })}
          />
        </View>

        <View style={styles.switchRow}>
          <Text style={styles.label}>Virada do lote por:</Text>
          <View style={styles.radioButtonContainer}>
            <TouchableOpacity
              style={styles.radioButton}
              onPress={() => setNewLot({ ...newLot, turnType: "quantity" })}
            >
              <Icon
                name={
                  newLot.turnType === "quantity"
                    ? "radiobox-marked"
                    : "radiobox-blank"
                }
                size={24}
                color="#FFF"
              />
              <Text style={styles.radioLabel}>Quantidade</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.radioButton}
              onPress={() => setNewLot({ ...newLot, turnType: "date" })}
            >
              <Icon
                name={
                  newLot.turnType === "date"
                    ? "radiobox-marked"
                    : "radiobox-blank"
                }
                size={24}
                color="#FFF"
              />
              <Text style={styles.radioLabel}>Data</Text>
            </TouchableOpacity>
          </View>
        </View>
        {newLot.turnType === "date" && (
          <>
            <Text style={styles.label}>Data de Início:</Text>
            <TouchableOpacity
              style={styles.datePickerButton}
              onPress={() => {
                setCurrentDateField("start");
                setDateTimePickerVisible(true);
              }}
            >
              <Text style={styles.buttonText}>
                {newLot.start
                  ? moment(newLot.start).format("DD/MM/YYYY HH:mm")
                  : "Selecionar Data"}
              </Text>
              <Icon
                name="calendar"
                size={20}
                color="#FFF"
                style={styles.calendarIcon}
              />
            </TouchableOpacity>
            <DateTimePickerModal
              isVisible={isDateTimePickerVisible}
              mode="datetime"
              minimumDate={new Date()}
              onConfirm={handleDateConfirm}
              onCancel={() => setDateTimePickerVisible(false)}
            />

            <Text style={styles.label}>Data de Fim:</Text>
            <TouchableOpacity
              style={styles.datePickerButton}
              onPress={() => {
                setCurrentDateField("end");
                setDateTimePickerVisible(true);
              }}
            >
              <Text style={styles.buttonText}>
                {newLot.end
                  ? moment(newLot.end).format("DD/MM/YYYY HH:mm")
                  : "Selecionar Data"}
              </Text>
              <Icon
                name="calendar"
                size={20}
                color="#FFF"
                style={styles.calendarIcon}
              />
            </TouchableOpacity>
          </>
        )}

        {newLot.turnType === "quantity" && (
          <>
            <Text style={styles.label}>Lote Anterior:</Text>
            <TouchableOpacity
              style={styles.datePickerButton}
              onPress={() => {
                setModalVisible(true);
                setCurrentLotType("main");
              }}
            >
              <Text style={styles.buttonText}>
                {newLot.previousLot && newLot.previousLot !== "Primeira Remessa"
                  ? newLot.previousLot
                  : "Não possui lote anterior"}
              </Text>
              <Icon
                name="chevron-down"
                size={20}
                color="#FFF"
                style={styles.calendarIcon}
              />
            </TouchableOpacity>
            <Modal
              visible={isModalVisible}
              transparent={true}
              animationType="slide"
              onRequestClose={() => setModalVisible(false)}
            >
              <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                  <Text style={styles.modalTitle}>Selecione o Lote Anterior</Text>
                  <TouchableOpacity
                    style={styles.modalOptionButton}
                    onPress={() => {
                      setNewLot({ ...newLot, previousLot: "Primeira Remessa" });
                      setModalVisible(false);
                    }}
                  >
                    <Text style={styles.modalOptionButtonText}>
                      Não possui lote anterior
                    </Text>
                  </TouchableOpacity>
                  {Object.keys(state.lots)
                    .filter(
                      (lotName) =>
                        state.lots[lotName].turnType === "quantity" &&
                        lotName !== newLot.name
                    )
                    .map((lotName) => (
                      <TouchableOpacity
                        key={lotName}
                        style={styles.modalOptionButton}
                        onPress={() => {
                          setNewLot({ ...newLot, previousLot: lotName });
                          setModalVisible(false);
                        }}
                      >
                        <Text style={styles.modalOptionButtonText}>
                          {lotName}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  <TouchableOpacity
                    style={[styles.modalButton, styles.modalButtonCancel]}
                    onPress={() => setModalVisible(false)}
                  >
                    <Text style={styles.modalButtonText}>Cancelar</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Modal>
          </>
        )}

        <View style={styles.sectionDivider} />

        <TouchableOpacity onPress={handleAddLot} style={styles.addLotButton}>
          <Text style={styles.buttonText}>
            {editingLotName !== null ? "Atualizar Lote" : "Adicionar Lote"}
          </Text>
        </TouchableOpacity>

        <FlatList
          data={Object.keys(state.lots).filter(
            (name) => !state.lots[name].halfPrice
          )}
          renderItem={renderLotItem}
          keyExtractor={(item) => item}
        />

        <View style={styles.sectionDivider} />

        <View style={styles.switchRow}>
          <Text style={styles.label}>Absorver Taxa de Serviço:</Text>
          <Switch
            style={styles.switch}
            trackColor={{ false: "#767577", true: "#202940" }}
            thumbColor={state.absorbFee ? "#052552" : "#f4f3f4"}
            ios_backgroundColor="#202940"
            value={state.absorbFee}
            onValueChange={(value) => handleInputChange("absorbFee", value)}
          />
        </View>
        <Text style={styles.absorbFeeText}>
          {state.absorbFee
            ? "Você está aceitando pagar a taxa de serviço."
            : "O cliente pagará a taxa de serviço."}
        </Text>
        <TouchableOpacity onPress={handleNextStep} style={styles.buttonSubmit}>
          <Text style={styles.buttonText}>Próximo</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={handlePrevStep} style={styles.buttonBack}>
          <Text style={styles.buttonText}>Voltar</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default LotsForm;
