import React from "react";
import styled from "styled-components/native";

const ProgressBarContainer = styled.View`
  width: 80%;
  height: 20px;
  background-color: #141414;
  border-radius: 5px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
`;

const Progress = styled.View`
  height: 100%;
  background-color: #5b2372;
  width: ${({ level }) => `${level}%`};
  transition: width 0.3s ease-in-out;
`;

const ProgressBar = ({ level }) => (
  <ProgressBarContainer>
    <Progress level={level} />
  </ProgressBarContainer>
);

export default ProgressBar;
