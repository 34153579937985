import React, { Component } from "react";
import { Modal, View, Text, TextInput, TouchableOpacity, StyleSheet, ActivityIndicator } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";

export default class TransferModal extends Component {
  state = {
    username: "",
    isLoading: false,
  };

  handleUsernameChange = (text) => {
    this.setState({ username: text });
  };

  handleConfirmTransfer = async () => {
    const { username } = this.state;
    if (username) {
      this.setState({ isLoading: true });
      await this.props.onConfirmTransfer(username);
      this.setState({ isLoading: false });
    } else {
      alert("Por favor, preencha o username.");
    }
  };

  render() {
    const { visible, toggleTransferModal } = this.props;
    const { username, isLoading } = this.state;

    return (
      <Modal visible={visible} transparent={true} animationType="slide">
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Transferir Ingresso</Text>
            <TextInput
              style={styles.input}
              placeholder="Username do destinatário"
              placeholderTextColor={"#FFF9"}
              value={username}
              onChangeText={this.handleUsernameChange}
            />
            <TouchableOpacity style={styles.button} onPress={this.handleConfirmTransfer} disabled={isLoading}>
              {isLoading ? (
                <ActivityIndicator size="small" color="#FFF" style={styles.buttonText}></ActivityIndicator>
              ) : (
                <>
                  <Icon name="send" size={18} color="#FFF" />
                  <Text style={styles.buttonText}>Confirmar Transferência</Text>
                </>
              )}
            </TouchableOpacity>
            <TouchableOpacity style={styles.buttonClose} onPress={toggleTransferModal} disabled={isLoading}>
              <Text style={styles.buttonCloseText}>Cancelar</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  },
  modalContent: {
    backgroundColor: "#000",
    padding: 20,
    borderRadius: 10,
    width: "80%",
    alignItems: "center",
  },
  modalTitle: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "bold",
    marginBottom: 15,
  },
  input: {
    width: "100%",
    height: 40,
    borderColor: "#FFF9",
    color: "#fff",
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
  button: {
    flexDirection: "row",
    backgroundColor: "#1A2035",
    paddingVertical: 8,
    paddingHorizontal: 40,
    borderRadius: 10,
    alignItems: "center",
    marginVertical: 10,
  },
  buttonText: {
    color: "#FFF",
    marginLeft: 10,
  },
  buttonClose: {
    marginTop: 5,
  },
  buttonCloseText: {
    color: "#fff",
  },
});
