import React from 'react';
import { View, StyleSheet } from 'react-native';
import ContentLoader, { Rect } from 'react-content-loader/native';

const TicketResultSkeleton = () => (
  <View style={styles.skeletonContainer}>
    <ContentLoader
      speed={1}
      width={300}
      height={200}
      backgroundColor="#3d3d3d"
      foregroundColor="#1c1c1c"
    >
      <Rect x="0" y="0" rx="8" ry="8" width="300" height="150" />
      <Rect x="0" y="160" rx="4" ry="4" width="200" height="20" />
      <Rect x="0" y="190" rx="4" ry="4" width="150" height="20" />
    </ContentLoader>
    <ContentLoader
      speed={1}
      width={300}
      height={150}
      backgroundColor="#3d3d3d"
      foregroundColor="#1c1c1c"
    >
      <Rect x="0" y="0" rx="4" ry="4" width="150" height="20" />
      <Rect x="0" y="30" rx="4" ry="4" width="250" height="20" />
      <Rect x="0" y="60" rx="4" ry="4" width="200" height="20" />
      <Rect x="0" y="90" rx="4" ry="4" width="220" height="20" />
      <Rect x="0" y="120" rx="4" ry="4" width="170" height="20" />
    </ContentLoader>
    <ContentLoader
      speed={1}
      width={300}
      height={100}
      backgroundColor="#3d3d3d"
      foregroundColor="#1c1c1c"
    >
      <Rect x="0" y="0" rx="4" ry="4" width="150" height="20" />
      <Rect x="0" y="30" rx="4" ry="4" width="250" height="20" />
      <Rect x="0" y="60" rx="4" ry="4" width="200" height="20" />
    </ContentLoader>
  </View>
);

const styles = StyleSheet.create({
  skeletonContainer: {
    padding: 20,
  },
});

export default TicketResultSkeleton;
