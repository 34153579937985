import React, { Component } from "react";
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  Modal,
  Alert,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";
import { styles } from "../../../styles/utils/authentication/LoginStyles";
import { login } from "../../../services/utils/secondary/AutenticacaoFuncs";
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import AuthInput from "../../../components/autenticacao/AuthInput";
import Toast from "react-native-toast-message";
import toastConfig from "../../../components/utils/ToastConfig";
import { SafeAreaView } from "react-native-safe-area-context";

export default class Login extends Component {
  state = {
    email: "",
    password: "",
    showErrorAlert: false,
    errorMessage: "",
    showPassword: false,
    emailModalVisible: false,
    resetEmail: "",
    inputErrors: {},
    admButtonsVisible: false, // Estado para controlar a visibilidade dos botões ADM
  };

  handleLogin = async () => {
    const errors = {};
    if (!this.state.email) {
      errors.email = "O email é obrigatório.";
    } else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
      errors.email = "O email é inválido.";
    }

    if (!this.state.password) {
      errors.password = "A senha é obrigatória.";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ inputErrors: errors });
      this.exibirErro("Por favor, verifique os campos destacados.");
      return;
    } else {
      this.setState({ inputErrors: {} });
    }

    try {
      const user = await login(
        this.state.email,
        this.state.password,
        this.exibirErro
      );
      if (user != null) {
        if (user.emailVerified) {
          this.props.navigation.goBack();
        } else {
          this.exibirErro(
            "Por favor, verifique seu email clicando no link enviado para sua caixa de entrada antes de fazer login."
          );
        }
      }
    } catch (error) {
      this.exibirErro(
        "Falha no login. Por favor, verifique seu email e senha."
      );
      console.error("Erro no login:", error);
    }
  };

  exibirErro = (mensagem) => {
    Toast.show({
      type: "error",
      text1: "Erro no Login",
      text2: mensagem,
      position: "bottom",
      visibilityTime: 4000,
      autoHide: true,
      topOffset: 30,
      bottomOffset: 40,
    });
  };

  toggleShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  forgotMyPassword = async () => {
    if (!this.state.resetEmail || !this.state.resetEmail.includes("@")) {
      this.exibirErro(
        "Por favor, insira um email válido para redefinir sua senha."
      );
      return;
    }

    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, this.state.resetEmail);
      this.setState({ emailModalVisible: false });
      Alert.alert(
        "Sucesso",
        "Email de redefinição de senha enviado com sucesso. Por favor, verifique sua caixa de entrada."
      );
    } catch (error) {
      this.exibirErro(
        "Erro ao enviar email de redefinição de senha. Por favor, tente novamente."
      );
      console.error("Erro ao enviar email de redefinição de senha:", error);
    }
  };

  loginAsUser = (email, password) => {
    this.setState({ email, password }, this.handleLogin);
  };

  toggleAdmButtons = () => {
    this.setState((prevState) => ({
      admButtonsVisible: !prevState.admButtonsVisible,
    }));
  };

  render() {
    return (
      <SafeAreaView style={styles.containerMain}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={styles.container}
        >
          <TouchableOpacity
            onPress={() => this.props.navigation.goBack()}
            style={styles.goBackButton}
          >
            <Icon name="arrow-back" size={25} color="#FFF" />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => this.props.navigation.navigate("Home")}
          >
            <Image source={logoTexto} resizeMode="contain" style={styles.logo} />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={this.toggleAdmButtons} // Altera visibilidade dos botões ADM
            style={styles.admButton}
          >
            <Icon name="person" size={25} color="#000" />
          </TouchableOpacity>

          <AuthInput
            icon="envelope"
            placeholder="Email"
            placeholderTextColor="#fff"
            value={this.state.email}
            style={[
              styles.input,
              this.state.inputErrors.email && styles.inputError,
            ]}
            onChangeText={(email) => this.setState({ email })}
          />
          <AuthInput
            icon="lock"
            placeholder="Senha"
            placeholderTextColor="#fff"
            secureTextEntry={!this.state.showPassword}
            value={this.state.password}
            style={[
              styles.input,
              this.state.inputErrors.password && styles.inputError,
            ]}
            onChangeText={(password) => this.setState({ password })}
            toggleSecureEntry={this.toggleShowPassword}
          />

          <TouchableOpacity onPress={this.handleLogin} style={styles.button}>
            <Text style={styles.buttonText}>Entrar</Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => this.setState({ emailModalVisible: true })}
          >
            <Text style={styles.linkText}>Esqueceu sua senha?</Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => this.props.navigation.navigate("Register")}
          >
            <Text style={styles.linkText2}>Registrar-se</Text>
          </TouchableOpacity>

          <Toast config={toastConfig} />

          <Modal
            visible={this.state.emailModalVisible}
            animationType="slide"
            transparent={true}
          >
            <View style={styles.modalContainer}>
              <View style={styles.modalContent}>
                <TouchableOpacity
                  style={styles.modalCloseButton}
                  onPress={() => this.setState({ emailModalVisible: false })}
                >
                  <Icon name="close" size={24} color="#FFF" />
                </TouchableOpacity>
                <Text style={styles.modalTitle}>
                  Digite seu email para redefinir a senha
                </Text>
                <AuthInput
                  icon="envelope"
                  placeholder="Email"
                  placeholderTextColor="#fff"
                  value={this.state.resetEmail}
                  style={styles.modalInput}
                  onChangeText={(resetEmail) => this.setState({ resetEmail })}
                />
                <TouchableOpacity
                  onPress={this.forgotMyPassword}
                  style={styles.modalButton}
                >
                  <Text style={styles.modalButtonText}>Redefinir Senha</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>

          {this.state.admButtonsVisible && (
            <View style={styles.containerADM}>
              <TouchableOpacity
                onPress={() =>
                  this.loginAsUser("messiasferes127@gmail.com", "Messias123!")
                }
                style={styles.testButton}
              >
                <Text style={styles.testButtonText}>M</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  this.loginAsUser("davigreco21@gmail.com", "Davi123$")
                }
                style={styles.testButton}
              >
                <Text style={styles.testButtonText}>D</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  this.loginAsUser("afiliamax127@gmail.com", "Messias123!")
                }
                style={styles.testButton}
              >
                <Text style={styles.testButtonText}>L</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  this.loginAsUser("messiasf.gamer@gmail.com", "Messias123!")
                }
                style={styles.testButtonProducer}
              >
                <Text style={styles.testButtonText}>L</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  this.loginAsUser("rayv.eventos@gmail.com", "Messias123!")
                }
                style={styles.testButtonProducer}
              >
                <Text style={styles.testButtonText}>R</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  this.loginAsUser("producer3@gmail.com", "Producer3Password")
                }
                style={styles.testButtonProducer}
              >
                <Text style={styles.testButtonText}>?</Text>
              </TouchableOpacity>
            </View>
          )}
        </KeyboardAvoidingView>
      </SafeAreaView>
    );
  }
}
