import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { styles } from "../../../../styles/producer/producerUtils/AddEventStyles";

const defaultImageEvent = require("../../../../../assets/imgs/fundo_evento.png");

const AdditionalDetailsForm = ({
  state,
  handleImagePicker,
  handleNextStep,
  handlePrevStep,
}) => (
  <View style={styles.scrollViewContent}>
    <View style={styles.sectionDivider} />
    <View style={styles.settingItemForm}>
      <MaterialCommunityIcons
        name="image"
        size={24}
        style={styles.settingIconForm}
      />
      <Text style={styles.screenTitle}>Imagem do Evento</Text>
    </View>
    <View style={styles.sectionDivider} />
    <View style={styles.detailsContainer}>
      <TouchableOpacity
        onPress={handleImagePicker}
        style={styles.buttonImagePicker}
      >
        <Icon name="camera" size={20} color="#FFFD" style={styles.cameraIcon} />
        <Text style={styles.buttonText}>
          Selecione a Imagem do Evento (Opcional)
        </Text>
      </TouchableOpacity>

      <View style={styles.sectionDivider} />

      {state.eventImage ? (
        <View style={styles.imagePreviewContainer}>
          <Image source={{ uri: state.eventImage }} style={styles.imagePreview} />
        </View>
      ) : (
        <View style={styles.imagePreviewContainer}>
          <Image source={defaultImageEvent} style={styles.imagePreview} />
        </View>
      )}

      <View style={styles.sectionDivider} />

      <Text style={styles.imageNote}>
        A imagem deve estar no formato 16:9 e será utilizada como capa do evento.
      </Text>
      <TouchableOpacity onPress={handleNextStep} style={styles.buttonSubmit}>
        <Text style={styles.buttonText}>Próximo</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={handlePrevStep} style={styles.buttonBack}>
        <Text style={styles.buttonText}>Voltar</Text>
      </TouchableOpacity>
    </View>
  </View>
);

export default AdditionalDetailsForm;
