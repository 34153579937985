import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import NetInfo from "@react-native-community/netinfo";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useMaintenance } from "./contexts/MaintenanceContext";
import { Ionicons } from "@expo/vector-icons";
import { styles } from "./styles/NavStyles";
import SplashScreen from "./views/utils/beginning/SplashScreen";
import Login from "./views/utils/authentication/Login";
import Register from "./views/utils/authentication/Register";
import Home from "./views/general/Home";
import Search from "./views/general/Search";
import Settings from "./views/general/Settings";
import Profile from "./views/profile/profileView/Profile";
import VisitProfile from "./views/profile/profileView/VisitProfile";
import EditProfile from "./views/profile/profileUtils/EditProfile";
import ProfileDetails from "./views/profile/profileUtils/ProfileDetails";
import EventStatistics from "./views/producer/producerInfo/EventStatistics";
import ProducerStatistics from "./views/producer/producerInfo/ProducerStatistics";
import ManageBalance from "./views/producer/producerBalance/ManageBalance";
import ManageBalanceHelp from "./views/producer/producerBalance/ManageBalanceHelp";
import AddEvent from "./views/producer/producerUtils/AddEvent";
import AddEventHelp from "./views/producer/producerUtils/AddEventHelp";
import ChatManager from "./views/producer/producerUtils/ChatManager";
import EditEvent from "./views/producer/producerUtils/EditEvent";
import EventChatManagement from "./views/producer/producerUtils/EventChatManagement";
import QRCodeScanner from "./views/ticket/ticketUtils/Scanner";
import Ticket from "./views/ticket/ticketView/Ticket";
import Payment from "./views/ticket/ticketView/Payment";
import EventLoader from "./views/ticket/ticketView/EventLoader";
import MyTickets from "./views/ticket/ticketUtils/MyTickets";
import TicketResult from "./views/ticket/ticketUtils/TicketResult";
import ReaderAutentication from "./views/utils/reader/ReaderAutentication";
import Terms from "./views/utils/terms/Terms";
import TermsOfUse from "./views/utils/terms/userTerms/TermsOfUse";
import PrivacyPolicy from "./views/utils/terms/userTerms/PrivacyPolicy";
import CookiePolicy from "./views/utils/terms/userTerms/CookiePolicy";
import ProducerCookiePolicy from "./views/utils/terms/producerTerms/CookiePolicy";
import ProducerPrivacyPolicy from "./views/utils/terms/producerTerms/PrivacyPolicy";
import ProducerTermsOfUse from "./views/utils/terms/producerTerms/TermsOfUse";
import Faq from "./views/utils/secondary/Faq";
import Maintenance from "./components/nav/Maintenance";
import UpdateRequired from "./components/nav/UpdateRequired";
import SecurityLock from "./components/nav/SecurityLock";
import NoInternet from "./components/nav/NoInternet";
import logoMascote from "../assets/imgs/Logo_Macaco2.png";

// Componentes de navegação
const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

// Função para as abas da Home
function HomeTabs() {
  const [selected, setSelected] = useState("Home"); // Estado para rastrear a aba selecionada
  const icones = {
    Search: "search",
    Home: "home",
    Profile: "person",
  };

  const renderTabBarButton = ({ name, onPress }) => (
    <TouchableOpacity
      onPress={() => {
        setSelected(name);
        onPress();
      }}
      style={styles.iconWrapper}
    >
      {name === "Home" ? (
        <Image source={logoMascote} style={styles.mascot} />
      ) : (
        <Ionicons
          name={selected === name ? icones[name] : `${icones[name]}-outline`}
          size={24}
          color={selected === name ? "#FFF" : "#FFF"}
        />
      )}
    </TouchableOpacity>
  );

  return (
    <Tab.Navigator
      initialRouteName="Home"
      screenOptions={({ route }) => ({
        headerShown: false,
        tabBarShowLabel: false,
        tabBarIcon: ({ color, size }) => {
          const iconName = icones[route.name];
          return <Ionicons name={iconName} size={size} color={color} />;
        },
        tabBarButton: (props) =>
          renderTabBarButton({ ...props, name: route.name }),
        tabBarStyle: styles.footer,
      })}
    >
      <Tab.Screen name="Search" component={Search} />
      <Tab.Screen name="Home" component={Home} />
      <Tab.Screen name="Profile" component={Profile} />
    </Tab.Navigator>
  );
}

// Configuração de Deep Linking
const linking = {
  prefixes: ["rayv://", "https://rayv.com.br", "exp://192.168.2.187:8081/--/", "https://rayv-desenvolvimento.github.io/Marketing/"],
  config: {
    screens: {
      HomeTabs: {
        screens: {
          Search: "Search",
          Home: "home",
          Profile: "Profile",
        },
      },
      EventLoader: "ticket/:id",
      Cart: "cart",
      EditEvent: "editEvent",
    },
  },
};

// Componente de navegação principal
const Nav = () => {
  const {
    isUnderMaintenance,
    maintenanceMessage,
    updateRequired,
    updateMessage,
    isLocked,
    lockMessage,
  } = useMaintenance();
  const [isConnected, setIsConnected] = useState(true); // Estado para rastrear a conectividade
  const [isCheckingConnection, setIsCheckingConnection] = useState(true); // Estado para rastrear se estamos checando a conexão

  useEffect(() => {
    const checkConnection = () => {
      NetInfo.fetch().then((state) => {
        setIsConnected(state.isConnected && state.isInternetReachable);
        setIsCheckingConnection(false);
      });
    };

    const unsubscribe = NetInfo.addEventListener((state) => {
      setIsConnected(state.isConnected && state.isInternetReachable);
    });

    setTimeout(checkConnection, 3000); // Adiciona um delay de 3 segundos para checar a conexão

    return () => unsubscribe();
  }, []);

  // Renderiza a tela de sem conexão se não estiver conectado
  if (!isConnected) {
    return (
      <NoInternet message="Sem conexão com a internet. Verifique sua conexão e tente novamente." />
    );
  }

  // Renderiza a tela de manutenção se estiver em manutenção
  if (isUnderMaintenance) {
    return <Maintenance message={maintenanceMessage} />;
  }

  // Renderiza a tela de atualização requerida se uma atualização for necessária
  if (updateRequired) {
    return <UpdateRequired message={updateMessage} />;
  }

  // Renderiza a tela de bloqueio de segurança se o app estiver bloqueado
  if (isLocked) {
    return <SecurityLock message={lockMessage} />;
  }

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator
        initialRouteName="SplashScreen"
        screenOptions={{
          animation: "slide_from_right",
          headerShown: false,
        }}
      >
        <Stack.Screen name="SplashScreen" component={SplashScreen} />
        <Stack.Screen name="HomeTabs" component={HomeTabs} />
        <Stack.Screen name="Ticket" component={Ticket} />
        <Stack.Screen name="MyTickets" component={MyTickets} />
        <Stack.Screen name="VisitProfile" component={VisitProfile} />
        <Stack.Screen name="ProfileDetails" component={ProfileDetails} />
        <Stack.Screen name="Payment" component={Payment} />
        <Stack.Screen name="Settings" component={Settings} />
        <Stack.Screen name="AddEvent" component={AddEvent} />
        <Stack.Screen name="AddEventHelp" component={AddEventHelp} />
        <Stack.Screen name="ChatManager" component={ChatManager} />
        <Stack.Screen name="EditEvent" component={EditEvent} />
        <Stack.Screen name="EventChatManagement" component={EventChatManagement} />
        <Stack.Screen name="EventStatistics" component={EventStatistics} />
        <Stack.Screen name="ProducerStatistics" component={ProducerStatistics} />
        <Stack.Screen name="EventLoader" component={EventLoader} />
        <Stack.Screen name="EditProfile" component={EditProfile} />
        <Stack.Screen name="Terms" component={Terms} />
        <Stack.Screen name="Login" component={Login} />
        <Stack.Screen name="Register" component={Register} />
        <Stack.Screen name="TicketResult" component={TicketResult} />
        <Stack.Screen name="QRCodeScanner" component={QRCodeScanner} />
        <Stack.Screen name="ManageBalance" component={ManageBalance} />
        <Stack.Screen name="ManageBalanceHelp" component={ManageBalanceHelp} />
        <Stack.Screen name="Faq" component={Faq} />
        <Stack.Screen name="TermsOfUse" component={TermsOfUse} />
        <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
        <Stack.Screen name="CookiePolicy" component={CookiePolicy} />
        <Stack.Screen
          name="ProducerTermsOfUse"
          component={ProducerTermsOfUse}
        />
        <Stack.Screen
          name="ProducerPrivacyPolicy"
          component={ProducerPrivacyPolicy}
        />
        <Stack.Screen
          name="ProducerCookiePolicy"
          component={ProducerCookiePolicy}
        />
        <Stack.Screen
          name="ReaderAutentication"
          component={ReaderAutentication}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Nav;
