import React, { useEffect, useState, useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  SafeAreaView,
  ScrollView,
} from "react-native";
import * as Progress from "react-native-progress";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { FlashList } from "@shopify/flash-list";
import ChallengeCard from "../profileUtils/ChallengeCard";
import { getEvent } from "../../../services/cruds/events/getEvent";
import getChallenge from "../../../services/cruds/challenges/getChallenge";
import { FollowContext } from "../../../contexts/FollowContext";
import Icon from "react-native-vector-icons/Ionicons";
import defaultAvatarImage from "../../../../assets/imgs/Avatar_Macaco.png";
import defaultHiddenImage from "../../../../assets/imgs/fundo_evento_quadrado.png";

const Tab = createMaterialTopTabNavigator();

const formatPhoneNumber = (phone) => {
  if (!phone) return "Não informado";
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phone;
};

const calculateLevel = (points) => {
  let level = 1;
  let pointsRequired = 100;

  while (points >= pointsRequired) {
    points -= pointsRequired;
    level++;
    pointsRequired = Math.ceil(pointsRequired * 1.2);
  }

  return { level, points, pointsRequired };
};

const Adesivos = ({
  navigation,
  stickers,
  isVisited,
  accountType,
  isPublic,
  loading,
  hiddenEvents,
  isCurrentUser,
}) => {
  const followContext = useContext(FollowContext);

  if (loading) {
    return <SafeAreaView style={styles.safeArea}></SafeAreaView>;
  }

  const sortedStickers = stickers
    ? Object.entries(stickers)
        .filter(([key]) => !hiddenEvents?.includes(key) || isCurrentUser)
        .sort((a, b) => {
          return (
            new Date(a[1].creationDate.toDate()) -
            new Date(b[1].creationDate.toDate())
          );
        })
    : [];

  const renderStickerItem = ({ item }) => {
    const [key, value] = item;
    const isHidden = hiddenEvents.includes(key);
    return (
      <View style={styles.stickerWrapper}>
        <TouchableOpacity
          style={styles.stickerButton}
          onPress={async () => {
            navigation.navigate("Ticket", {
              evento: await getEvent(key),
            });
          }}
        >
          <Image source={{ uri: value.url }} style={styles.stickerImage} />
        </TouchableOpacity>
        {isCurrentUser && isHidden && (
          <View style={styles.hiddenIconWrapper}>
            <Icon name="eye-off" size={20} color="#FFFD" />
          </View>
        )}
      </View>
    );
  };

  if (
    !isPublic &&
    !followContext?.isFollowing &&
    isVisited &&
    accountType !== "Producer"
  ) {
    return (
      <View style={styles.containerSticker}>
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>
            Apenas seguidores podem ver os adesivos.
          </Text>
        </View>
      </View>
    );
  }

  return (
    <ScrollView style={styles.containerSticker}>
      {stickers && sortedStickers.length > 0 ? (
        <View style={styles.flexContainer}>
          <FlashList
            data={sortedStickers}
            renderItem={renderStickerItem}
            keyExtractor={(item, index) => index.toString()}
            numColumns={3}
            estimatedItemSize={100}
            contentContainerStyle={styles.flashListContent}
          />
        </View>
      ) : (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>Nenhum adesivo disponível.</Text>
        </View>
      )}
    </ScrollView>
  );
};

const Shop = ({}) => (
  <ScrollView style={styles.container}>
    <View style={styles.emptyContainer}>
      <Text style={styles.emptyText}>Futuro Sistemas de Vendas.</Text>
    </View>
  </ScrollView>
);

const LevelCard = ({ rewardsPoints, avatarUrl }) => {
  const { level, points, pointsRequired } = calculateLevel(rewardsPoints);
  const progress = points / pointsRequired;

  return (
    <View style={styles.cardContainer}>
      <View style={styles.iconContainer}>
        <Image
          source={avatarUrl ? { uri: avatarUrl } : defaultAvatarImage}
          style={styles.avatar}
        />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.levelText}>Nível {level}</Text>
        <Text style={styles.levelPoints}>
          {points ? points : 0}/{pointsRequired} pontos
        </Text>
        <Progress.Bar
          progress={progress}
          width={null}
          color="#A7A7A7"
          style={styles.progressBar}
        />
      </View>
    </View>
  );
};

const Conquests = ({
  challenges,
  isVisited,
  accountType,
  avatarUrl,
  isPublic,
  rewardsPoints,
  loading,
}) => {
  const followContext = useContext(FollowContext);

  if (loading) {
    return <SafeAreaView style={styles.safeArea}></SafeAreaView>;
  }

  return (
    <ScrollView style={styles.container}>
      {isPublic ||
      followContext?.isFollowing ||
      !isVisited ||
      accountType === "Producer" ? (
        <LevelCard rewardsPoints={rewardsPoints} avatarUrl={avatarUrl} />
      ) : null}

      {!isVisited && accountType && (
        <Text style={styles.explanationText}>
          Complete os desafios para ganhar recompensas e desbloquear conquistas
          exclusivas!
        </Text>
      )}

      {isPublic ||
      followContext?.isFollowing ||
      !isVisited ||
      accountType === "Producer" ? (
        challenges && challenges.length > 0 ? (
          challenges.map((challenge) => (
            <ChallengeCard
              key={challenge.id}
              challengeId={challenge.id}
              challengeData={challenge}
              isVisited={isVisited}
            />
          ))
        ) : (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>Nenhuma conquista disponível.</Text>
          </View>
        )
      ) : (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>
            Apenas seguidores podem ver as conquistas.
          </Text>
        </View>
      )}
    </ScrollView>
  );
};

const About = ({ address, contactEmail, phoneNumber }) => (
  <ScrollView style={styles.aboutContainer}>
    <View style={styles.aboutSection}>
      <Text style={styles.aboutSectionTitle}>Contato</Text>
      <View style={styles.aboutSectionTitleLine}></View>
      <View style={styles.aboutSectionTitleLineSpace}></View>
      <View style={styles.aboutInfoRow}>
        <Icon
          name="mail-outline"
          size={20}
          color="#FFF9"
          style={styles.aboutInfoIcon}
        />
        <Text style={styles.aboutInfoLabel}>Email:</Text>
        <Text style={styles.aboutInfoValue}>
          {contactEmail ? contactEmail : "Não informado"}
        </Text>
      </View>
      <View style={styles.aboutInfoRow}>
        <Icon
          name="call-outline"
          size={20}
          color="#FFF9"
          style={styles.aboutInfoIcon}
        />
        <Text style={styles.aboutInfoLabel}>Telefone:</Text>
        <Text style={styles.aboutInfoValue}>
          {phoneNumber ? formatPhoneNumber(phoneNumber) : "Não informado"}
        </Text>
      </View>
    </View>
    <View style={styles.aboutSection}>
      <Text style={styles.aboutSectionTitle}>Endereço</Text>
      <View style={styles.aboutSectionTitleLine}></View>
      <View style={styles.aboutSectionTitleLineSpace}></View>
      <View style={styles.aboutInfoRow}>
        <Icon name="home" size={20} color="#FFF9" style={styles.aboutInfoIcon} />
        <Text style={styles.aboutInfoLabel}>Nome:</Text>
        <Text style={styles.aboutInfoValue}>
          {address.name ? address.name : "Não informado"}
        </Text>
      </View>
      <View style={styles.aboutInfoRow}>
        <Icon
          name="location-outline"
          size={20}
          color="#FFF9"
          style={styles.aboutInfoIcon}
        />
        <Text style={styles.aboutInfoLabel}>Rua:</Text>
        <Text style={styles.aboutInfoValue}>
          {address.street ? address.street : "Não informado"}
        </Text>
      </View>
      <View style={styles.aboutInfoRow}>
        <Icon
          name="home-outline"
          size={20}
          color="#FFF9"
          style={styles.aboutInfoIcon}
        />
        <Text style={styles.aboutInfoLabel}>Número:</Text>
        <Text style={styles.aboutInfoValue}>
          {address.number ? address.number : "Não informado"}
        </Text>
      </View>
      <View style={styles.aboutInfoRow}>
        <Icon
          name="location-outline"
          size={20}
          color="#FFF9"
          style={styles.aboutInfoIcon}
        />
        <Text style={styles.aboutInfoLabel}>Cidade:</Text>
        <Text style={styles.aboutInfoValue}>
          {address.city ? address.city : "Não informado"}
        </Text>
      </View>
      <View style={styles.aboutInfoRow}>
        <Icon name="map-outline" size={20} color="#FFF9" style={styles.aboutInfoIcon} />
        <Text style={styles.aboutInfoLabel}>Estado:</Text>
        <Text style={styles.aboutInfoValue}>
          {address.state ? address.state : "Não informado"}
        </Text>
      </View>
      <View style={styles.aboutInfoRow}>
        <Icon
          name="globe-outline"
          size={20}
          color="#FFF9"
          style={styles.aboutInfoIcon}
        />
        <Text style={styles.aboutInfoLabel}>País:</Text>
        <Text style={styles.aboutInfoValue}>
          {address.country ? address.country : "Brasil"}
        </Text>
      </View>
      <View style={styles.aboutInfoRow}>
        <Icon name="pin-outline" size={20} color="#FFF9" style={styles.aboutInfoIcon} />
        <Text style={styles.aboutInfoLabel}>CEP:</Text>
        <Text style={styles.aboutInfoValue}>
          {address.zip ? address.zip : "Não informado"}
        </Text>
      </View>
    </View>
  </ScrollView>
);

const ProfileBody = ({
  navigation,
  accountType,
  avatarUrl,
  hiddenEvents,
  stickers,
  rewardsPoints,
  challenges,
  address,
  contactEmail,
  phoneNumber,
  isVisited,
  isPublic,
  isCurrentUser,
}) => {
  const followContext = useContext(FollowContext);
  const [challengeDetails, setChallengeDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChallenges = async () => {
      const challengeEntries = Object.entries(challenges);
      const challengePromises = challengeEntries.map(async ([key, value]) => {
        const challengeData = await getChallenge(accountType, key);
        return {
          id: key,
          ...challengeData,
          ...value,
        };
      });
      const detailedChallenges = await Promise.all(challengePromises);
      setChallengeDetails(detailedChallenges);
      setLoading(false);
    };

    fetchChallenges();
  }, [challenges, accountType]);

  return (
    <SafeAreaView style={styles.safeArea}>
      {accountType !== "Producer" ? (
        <Tab.Navigator
          screenOptions={{
            tabBarStyle: { backgroundColor: "#000" },
            tabBarActiveTintColor: "#FFF",
            tabBarInactiveTintColor: "#A7A7A7",
            tabBarIndicatorStyle: {
              backgroundColor: "#FFF",
              height: 1,
              bottom: 10,
              alignSelf: "center",
              justifyContent: "center",
            },
            tabBarLabelStyle: {
              fontSize: 13,
              fontWeight: "bold",
              paddingBottom: 0,
            },
          }}
        >
          <Tab.Screen
            name="Adesivos"
            children={() => (
              <Adesivos
                navigation={navigation}
                stickers={stickers}
                isVisited={isVisited}
                accountType={accountType}
                isPublic={isPublic}
                loading={loading}
                hiddenEvents={hiddenEvents}
                isCurrentUser={isCurrentUser}
              />
            )}
          />
          <Tab.Screen
            name="Conquistas"
            children={() => (
              <Conquests
                challenges={challengeDetails}
                isVisited={isVisited}
                accountType={accountType}
                avatarUrl={avatarUrl}
                isPublic={isPublic}
                rewardsPoints={rewardsPoints}
                loading={loading}
              />
            )}
          />
        </Tab.Navigator>
      ) : (
        <Tab.Navigator
          screenOptions={{
            tabBarScrollEnabled: false,
            tabBarStyle: { backgroundColor: "#000" },
            tabBarActiveTintColor: "#FFF",
            tabBarInactiveTintColor: "#A7A7A7",
            tabBarIndicatorStyle: {
              backgroundColor: "#FFF",
              height: 1,
              bottom: 10,
              alignSelf: "center",
              justifyContent: "center",
            },
            tabBarLabelStyle: {
              fontSize: 13,
              fontWeight: "bold",
              paddingBottom: 0,
            },
          }}
        >
          <Tab.Screen
            name="Adesivos"
            children={() => (
              <Adesivos
                navigation={navigation}
                stickers={stickers}
                isVisited={isVisited}
                accountType={accountType}
                isPublic={isPublic}
                loading={loading}
                hiddenEvents={hiddenEvents}
                isCurrentUser={isCurrentUser}
              />
            )}
          />
          <Tab.Screen
            name="Conquistas"
            children={() => (
              <Conquests
                challenges={challengeDetails}
                isVisited={isVisited}
                accountType={accountType}
                avatarUrl={avatarUrl}
                isPublic={isPublic}
                rewardsPoints={rewardsPoints}
                loading={loading}
              />
            )}
          />
          <Tab.Screen
            name="Sobre"
            children={() => (
              <About
                address={address}
                contactEmail={contactEmail}
                phoneNumber={phoneNumber}
              />
            )}
          />
        </Tab.Navigator>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: "#000",
  },
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  aboutContainer: {
    flex: 1,
    paddingHorizontal: 15,
    backgroundColor: "#000",
  },
  aboutSection: {
    marginBottom: 20,
  },
  aboutInfoRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  aboutInfoIcon: {
    marginRight: 10,
  },
  aboutInfoLabel: {
    fontSize: 16,
    fontWeight: "600",
    color: "#333",
    flex: 1,
  },
  aboutSectionTitle: {
    fontSize: 18,
    color: "#FFF",
    fontWeight: "bold",
    marginBottom: 5,
  },
  aboutSectionTitleLine: {
    height: 0.5,
    backgroundColor: "#333",
  },
  aboutSectionTitleLineSpace: {
    marginBottom: 10,
  },
  aboutInfoLabel: {
    fontSize: 14,
    color: "#A7A7A7",
    width: 100,
  },
  aboutInfoValue: {
    fontSize: 14,
    color: "#FFF9",
    flex: 1,
  },
  containerSticker: {
    flex: 1,
    paddingHorizontal: 10,
    backgroundColor: "#000",
  },
  flexContainer: {
    flex: 1,
  },
  stickerWrapper: {
    position: "relative",
    margin: 3,
    flex: 1,
    aspectRatio: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#222",
  },
  stickerButton: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  hiddenIconWrapper: {
    position: "absolute",
    bottom: 5,
    right: 5,
    backgroundColor: "rgba(0, 0, 0, 1)",
    borderRadius: 10,
    padding: 10,
  },
  stickerImage: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
  },
  flashListContent: {
    backgroundColor: "#000",
  },
  emptyContainer: {
    flex: 1,
    paddingTop: 25,
    alignItems: "center",
  },
  emptyText: {
    fontSize: 16,
    color: "#FFF9",
  },
  addressText: {
    fontSize: 14,
    color: "#FFF",
  },
  contactText: {
    fontSize: 14,
    color: "#FFF",
  },
  explanationText: {
    fontSize: 15,
    color: "#999",
    textAlign: "center",
    marginTop: 10,
    paddingHorizontal: 25,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
  },
  cardContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    paddingHorizontal: 20,
    backgroundColor: "#121212",
    borderRadius: 2,
  },
  iconContainer: {
    marginRight: 10,
  },
  textContainer: {
    flex: 1,
  },
  levelText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  levelPoints: {
    color: "#A7A7A7",
    fontSize: 12,
  },
  progressBar: {
    marginTop: 5,
    height: 8,
    borderRadius: 4,
  },
  avatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
  },
});

export default ProfileBody;
