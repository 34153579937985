import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Platform,
  StyleSheet,
} from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import { Ionicons } from "@expo/vector-icons";

const AuthDateInput = ({
  value,
  placeholder,
  onDateChange,
  error,
  idadeMinima,
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleDateChange = (event, selectedDate) => {
    setShowDatePicker(Platform.OS === "ios");
    if (selectedDate) {
      onDateChange(selectedDate);
    }
  };

  const toggleDatePicker = () => {
    setShowDatePicker((prevState) => !prevState);
  };

  return (
    <View>
      <View style={[styles.inputContainer, error && styles.errorContainer]}>
        <TouchableOpacity
          onPress={toggleDatePicker}
          style={styles.inputWithIcon}
        >
          <Ionicons
            name="calendar-outline"
            size={24}
            color="gray"
            style={styles.inputIcon}
          />
          <Text style={styles.inputText}>
            {value ? value.toLocaleDateString("pt-BR") : "Data de nascimento"}
          </Text>
        </TouchableOpacity>
      </View>
      {showDatePicker && (
        <DateTimePicker
          testID="dateTimePicker"
          value={value || new Date()}
          mode="date"
          display="default"
          onChange={handleDateChange}
          locale="pt-BR"
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: "#2B2B2B",
    height: 40,
    borderRadius: 15,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  errorContainer: {
    borderColor: "red",
    borderWidth: 1,
  },
  inputWithIcon: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    paddingLeft: 10,
  },
  inputText: {
    color: "#FFF",
    fontSize: 16,
    flex: 1,
  },
  inputIcon: {
    marginRight: 10,
  },
});

export default AuthDateInput;
