import React, { Component } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  SafeAreaView,
  ScrollView,
  Alert,
  Image,
  BackHandler,
  Modal,
} from "react-native";
import { auth, storage } from "../../../services/utils/firebase/firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import * as ImagePicker from "expo-image-picker";
import moment from "moment-timezone";
import { styles } from "../../../styles/producer/producerUtils/EditEventStyles";
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import Icon from "react-native-vector-icons/Ionicons";
import IconMaterial from "react-native-vector-icons/MaterialCommunityIcons";
import SaveImageModal from "../../../components/producer/ModalSaveImage";
import GeneralDataForm from "../../../components/producer/producerUtils/editEvent/GeneralDataForm";
import DatesForm from "../../../components/producer/producerUtils/editEvent/DatesForm";
import LocationForm from "../../../components/producer/producerUtils/editEvent/LocationForm";
import LotsForm from "../../../components/producer/producerUtils/editEvent/LotsForm";
import StickerForm from "../../../components/producer/producerUtils/editEvent/StickerForm";
import ContactsForm from "../../../components/producer/producerUtils/editEvent/ContactsForm";
import PoliciesForm from "../../../components/producer/producerUtils/editEvent/PoliciesForm";
import ReaderForm from "../../../components/producer/producerUtils/editEvent/ReaderForm";
import DeleteEventForm from "../../../components/producer/producerUtils/editEvent/DeleteEventForm";
import CourtesyForm from "../../../components/producer/producerUtils/editEvent/CourtesyForm"; 
import { updateEvent } from "../../../services/cruds/events/updateEvent";
import { getEvent, getInAnalysisEventCount } from "../../../services/cruds/events/getEvent";

class EditEvent extends Component {
  constructor(props) {
    super(props);
    const { evento } = this.props.route.params;
    this.state = {
      ...evento,
      isDatePickerVisible: false,
      isStartTimePickerVisible: false,
      isEndTimePickerVisible: false,
      activeSection: null,
      showImageModal: false,
      showImageModalSticker: false,
      changesMade: false,
      userId: null,
      showModal: false, // Modal state
      eventUsername: evento.username,
      organizer: {}, // Inicialmente vazio, será carregado posteriormente
    };
  }

  async componentDidMount() {
    this.props.navigation.addListener("beforeRemove", (e) => {
      if (!this.state.changesMade) {
        return;
      }
      e.preventDefault();
      Alert.alert(
        "Salvar alterações?",
        "Você tem alterações não salvas. Deseja salvar antes de sair?",
        [
          {
            text: "Não",
            style: "cancel",
            onPress: () => this.props.navigation.dispatch(e.data.action),
          },
          { text: "Sim", onPress: () => this.handleSaveAndExit(e.data.action) },
        ]
      );
    });

    this.backHandler = BackHandler.addEventListener("hardwareBackPress", () => {
      if (this.state.activeSection !== null) {
        this.setActiveSection(null);
        return true; // Evitar sair do app
      }
      return false; // Permitir sair do app
    });

    await this.authenticateAndLoadEvents(); // Autenticar e carregar eventos

    await this.loadCompleteEventData(); // Carregar dados completos do evento
  }

  componentWillUnmount() {
    if (this.backHandler) {
      this.backHandler.remove();
    }
    if (this.authListener) {
      this.authListener();
    }
  }

  authenticateAndLoadEvents = async () => {
    if (this.authListener) {
      this.authListener();
    }
    this.authListener = onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.setState({ userId: user.uid }, this.verifyUserPermission);
      } else {
        console.log("Nenhum usuário conectado");
        this.props.navigation.navigate("Home");
      }
    });
  };

  verifyUserPermission = async () => {
    try {
      const { userId, organizer } = this.state;
      if (userId && organizer.organizationId) {
        if (userId !== organizer.organizationId) {
          this.props.navigation.navigate("Home");
        }
      }
    } catch (error) {
      console.error("Erro ao verificar permissão do usuário:", error);
      this.props.navigation.navigate("Home");
    }
  };

  loadCompleteEventData = async () => {
    const { eventUsername } = this.state;
    try {
      const eventDetails = await getEvent(eventUsername);
      if (eventDetails) {
        this.setState({ ...eventDetails });
      } else {
        Alert.alert("Erro", "Não foi possível carregar os detalhes do evento.");
      }
    } catch (error) {
      console.error("Erro ao carregar detalhes do evento:", error);
      Alert.alert("Erro", "Não foi possível carregar os detalhes do evento.");
    }
  };

  handleSaveAndExit = async (action) => {
    await this.saveChanges();
    this.props.navigation.dispatch(action);
  };

  saveChanges = async () => {
    const eventDetails = this.prepararDetalhesDoEvento();
    console.log(eventDetails);
    try {
      await updateEvent(this.state.username, eventDetails);
      this.setState({ changesMade: false });
      Alert.alert("Sucesso", "Evento atualizado com sucesso!");
      this.setState({ activeSection: null });
      if (this.props.route.params.onGoBack) {
        this.props.route.params.onGoBack(eventDetails);
      }
    } catch (error) {
      Alert.alert("Erro", "Não foi possível atualizar o evento.");
      console.error("Erro ao atualizar evento:", error);
    }
  };

  prepararDetalhesDoEvento = () => {
    const {
      dateVisible,
      isDatePickerVisible,
      isStartTimePickerVisible,
      isEndTimePickerVisible,
      isSalesStartDatePickerVisible,
      activeSection,
      dayVisible,
      startVisible,
      endVisible,
      showImageModal,
      showImageModalSticker,
      updateData,
      changesMade,
      eventImage,
      sticker,
      id,
      userData,
      userId,
      showModal,
      ...rest
    } = this.state;
    return {
      ...rest,
    };
  };

  togglePicker = (picker) => {
    this.setState((prevState) => ({ [picker]: !prevState[picker] }));
  };

  handleDatePicking = (type, value) => {
    this.setState((prevState) => ({
      ...prevState,
      dates: {
        ...prevState.dates,
        [type]: value,
      },
    }));
    this.togglePicker(type + "Visible");
  };

  handleInputChange = (field, value) => {
    this.setState({ [field]: value, changesMade: true });
  };

  handleNestedInputChange = (field, value, remove = false) => {
    const fields = field.split(".");
    this.setState((prevState) => {
      let newState = { ...prevState };
      let current = newState;

      for (let i = 0; i < fields.length - 1; i++) {
        if (!current[fields[i]]) {
          current[fields[i]] = {};
        } else {
          current[fields[i]] = { ...current[fields[i]] };
        }
        current = current[fields[i]];
      }

      if (remove) {
        delete current[fields[fields.length - 1]];
      } else {
        current[fields[fields.length - 1]] = value;
      }

      return { ...newState, changesMade: true };
    });
  };

  handleImagePicker = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [16, 9],
      quality: 1,
    });

    if (!result.cancelled) {
      this.setState({ eventImage: result.assets[0].uri });
      this.setState({ showImageModal: true, changesMade: true });
    } else {
      Alert.alert(
        "Carregamento cancelado",
        "A escolha da imagem foi cancelada."
      );
    }
  };

  handleImagePickerSticker = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.cancelled) {
      this.setState({ sticker: result.assets[0].uri });
      this.setState({ showImageModalSticker: true, changesMade: true });
    } else {
      Alert.alert(
        "Carregamento cancelado",
        "A escolha da imagem foi cancelada."
      );
    }
  };

  handleCloseImageModal = () => {
    this.setState({ showImageModal: false });
  };

  handleCloseImageModalSticker = () => {
    this.setState({ showImageModalSticker: false });
  };

  setActiveSection = (section) => {
    this.setState({ activeSection: section });
  };

  renderHeader = (activeSection) => {
    return (
      <View style={styles.containerHeader}>
        <TouchableOpacity
          onPress={() => {
            if (activeSection !== null) {
              this.setActiveSection(null);
            } else {
              this.props.navigation.goBack();
            }
          }}
          style={styles.backButtonHeader}
        >
          <Icon name="arrow-back" size={25} color="#FFFFFF" />
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => this.props.navigation.navigate("Home")}
        >
          <Image
            source={logoTexto}
            resizeMode="contain"
            style={styles.logoHeader}
          />
        </TouchableOpacity>

        <Icon
          name="person-outline"
          size={25}
          color="#000"
          // onPress={() => this.props.navigation.navigate("Profile")}
          style={styles.goBackButton}
        />
      </View>
    );
  };

  renderProfile = () => {
    const defaultImageEvent = require("../../../../assets/imgs/fundo_evento.png");
    const eventImage =
      this.state.eventImage !== "default_image.png"
        ? { uri: this.state.eventImage }
        : defaultImageEvent;
    return (
      <View style={styles.eventInfoContainer}>
        <TouchableOpacity onPress={() => this.handleImagePicker()}>
          <Image source={eventImage} style={styles.eventImage} />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.editImageButton}
          onPress={() => this.handleImagePicker()}
        >
          <IconMaterial name="pencil" size={14} color="white" />
        </TouchableOpacity>
        <View style={styles.eventDetails}>
          <Text style={styles.eventName}>
            {this.state.name ? this.state.name : "Carregando..."}
          </Text>
          <Text style={styles.eventDescription}>
            Tela de Gerência do Evento
          </Text>
        </View>
        <SaveImageModal
          isVisible={this.state.showImageModal}
          uri={this.state.eventImage}
          onClose={this.handleCloseImageModal}
          username={this.state.username}
          changeData={() => this.setState({ updateData: true })}
        />
      </View>
    );
  };

  publishEvent = async () => {
    const { eventImage, sticker, userId } = this.state;

    // Verificar se há mais de 5 eventos em análise
    try {
      const inAnalysisCount = await getInAnalysisEventCount(userId);
      if (inAnalysisCount >= 5) {
        Alert.alert("Limite de eventos em análise atingido", "Você não pode ter mais de 5 eventos em análise.");
        return;
      }
    } catch (error) {
      console.error("Erro ao verificar a contagem de eventos em análise:", error);
      Alert.alert("Erro", "Não foi possível verificar a contagem de eventos em análise.");
      return;
    }

    if (eventImage === "default_image.png") {
      Alert.alert(
        "Erro",
        "É necessário adicionar uma imagem ao evento antes de enviar para análise."
      );
      return;
    }

    let stickerUrl = sticker;
    if (!sticker) {
      try {
        const response = await fetch(eventImage);
        const blob = await response.blob();
        const metadata = {
          contentType: "image/jpg",
        };
        const fileName = `${this.state.username}_sticker.jpg`;
        const stickerRef = ref(storage, `stickerImages/${fileName}`);

        await uploadBytes(stickerRef, blob, metadata);
        stickerUrl = await getDownloadURL(stickerRef);
        await this.atualizarUrlStickerEvento(stickerUrl);
      } catch (error) {
        console.error("Erro ao salvar adesivo como imagem do evento:", error);
        Alert.alert(
          "Erro",
          "Não foi possível salvar o adesivo. Tente novamente."
        );
        return;
      }
    }

    this.setState({ showModal: true, sticker: stickerUrl });
  };

  atualizarUrlStickerEvento = async (url) => {
    try {
      await updateEvent(this.state.username, { sticker: url });
      console.log("Atualização bem-sucedida", "A imagem foi atualizada.");
    } catch (error) {
      console.error("Erro ao atualizar a imagem:", error);
    }
  };

  confirmPublishEvent = async () => {
    const now = moment().tz("America/Sao_Paulo").toDate();
    const eventEndDate = this.state.dates.end.toDate(); // Assumindo que end é um Timestamp do Firestore
  
    if (eventEndDate <= now) {
      Alert.alert(
        "Erro",
        "A data final do evento já passou. Não é possível enviar um evento com data passada para análise."
      );
      return;
    }
  
    try {
      // Atualizar status do evento para "Em Análise"
      await updateEvent(this.state.username, { status: "Em Análise" });
  
      Alert.alert(
        "Evento Enviado para Análise",
        "Seu evento foi enviado para análise com sucesso! O processo de análise pode levar até 3 dias úteis.",
        [
          {
            text: "OK",
            onPress: () => this.props.navigation.navigate("Home"),
          },
        ]
      );
      this.setState({ status: "Em Análise", showModal: false });
    } catch (error) {
      console.error("Erro ao enviar evento para análise:", error);
      Alert.alert("Erro", "Falha ao tentar enviar o evento para análise.");
    }
  };
  
  renderPublishModal = () => {
    const { eventImage, sticker } = this.state;
    const defaultAvatar = require("../../../../assets/imgs/fundo_adesivo.png");
    const eventImg =
      eventImage !== "default_image.png" ? { uri: eventImage } : defaultAvatar;
    const stickerImg = sticker ? { uri: sticker } : eventImg;
  
    return (
      <Modal
        visible={this.state.showModal}
        animationType="slide"
        transparent={true}
        onRequestClose={() => this.setState({ showModal: false })}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <TouchableOpacity
              style={styles.modalCloseButton}
              onPress={() => this.setState({ showModal: false })}
            >
              <Ionicons name="close" size={20} color="#FFF" />
            </TouchableOpacity>
            <Text style={styles.modalTitle}>Confirmar Envio para Análise</Text>
            <Image source={stickerImg} style={styles.stickerImageModal} />
            <Text style={styles.modalText}>
              Ao enviar este evento para análise, as informações como endereço,
              políticas e adesivo não poderão ser alteradas até a aprovação.
            </Text>
            <Text style={styles.modalText}>
              Se o adesivo não for definido, a imagem do evento será usada como
              adesivo.
            </Text>
            <Text style={styles.modalText}>
              O processo de análise pode levar até 3 dias úteis. Após a aprovação,
              o evento será publicado automaticamente.
            </Text>
            <View style={styles.modalButtonsRow}>
              <TouchableOpacity
                style={styles.modalButton}
                onPress={this.confirmPublishEvent}
              >
                <Text style={styles.modalButtonText}>Confirmar</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, styles.modalButtonCancel]}
                onPress={() => this.setState({ showModal: false })}
              >
                <Text style={styles.modalButtonText}>Cancelar</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    );
  };  

  renderAll = () => {
    return (
      <>
        {this.state.status == "Rascunho" ? (
          <TouchableOpacity
            style={styles.publishButton}
            onPress={this.publishEvent}
          >
            <Text style={styles.publishButtonText}>Publicar Evento</Text>
          </TouchableOpacity>
        ) : null}
        <TouchableOpacity
          style={styles.settingItem}
          onPress={() => this.setActiveSection("general")}
        >
          <MaterialIcons
            name="settings"
            size={24}
            style={styles.settingIcon}
          />
          <View style={styles.settingTextContainer}>
            <Text style={styles.settingTitle}>Editar Dados Gerais</Text>
            <Text style={styles.settingSubtitle}>
              Ajuste as configurações básicas do evento
            </Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={24}
            style={styles.settingArrow}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.settingItem}
          onPress={() => this.setActiveSection("dates")}
        >
          <MaterialCommunityIcons
            name="calendar-month"
            size={24}
            style={styles.settingIcon}
          />
          <View style={styles.settingTextContainer}>
            <Text style={styles.settingTitle}>Editar Datas</Text>
            <Text style={styles.settingSubtitle}>
              Gerencie a data e os horários
            </Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={24}
            style={styles.settingArrow}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.settingItem}
          onPress={() => this.setActiveSection("location")}
        >
          <MaterialCommunityIcons
            name="map-legend"
            size={24}
            style={styles.settingIcon}
          />
          <View style={styles.settingTextContainer}>
            <Text style={styles.settingTitle}>Editar Endereço</Text>
            <Text style={styles.settingSubtitle}>
              Revise e atualize o endereço do evento
            </Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={24}
            style={styles.settingArrow}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.settingItem}
          onPress={() => this.setActiveSection("lots")}
        >
          <Icon
            name="ticket-sharp"
            size={24}
            style={styles.settingIcon}
          />
          <View style={styles.settingTextContainer}>
            <Text style={styles.settingTitle}>Editar Lotes</Text>
            <Text style={styles.settingSubtitle}>
              Atualize informações dos lotes do evento
            </Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={24}
            style={styles.settingArrow}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.settingItem}
          onPress={() => this.setActiveSection("sticker")}
        >
          <Ionicons name="medal" size={24} style={styles.settingIcon} />
          <View style={styles.settingTextContainer}>
            <Text style={styles.settingTitle}>Editar adesivo</Text>
            <Text style={styles.settingSubtitle}>
              Gerencie o adesivo e as conquistas
            </Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={24}
            style={styles.settingArrow}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.settingItem}
          onPress={() => this.setActiveSection("contacts")}
        >
          <MaterialCommunityIcons name="phone-log" size={24} style={styles.settingIcon} />
          <View style={styles.settingTextContainer}>
            <Text style={styles.settingTitle}>Editar Contatos</Text>
            <Text style={styles.settingSubtitle}>
              Atualize suas informações de contato
            </Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={24}
            style={styles.settingArrow}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.settingItem}
          onPress={() => this.setActiveSection("policies")}
        >
          <Ionicons
            name="document-lock"
            size={24}
            style={styles.settingIcon}
          />
          <View style={styles.settingTextContainer}>
            <Text style={styles.settingTitle}>Editar Políticas</Text>
            <Text style={styles.settingSubtitle}>
              Revise e atualize políticas de privacidade
            </Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={24}
            style={styles.settingArrow}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.settingItem}
          onPress={() => this.setActiveSection("reader")}
        >
          <MaterialCommunityIcons
            name="qrcode-scan"
            size={24}
            style={styles.settingIcon}
          />
          <View style={styles.settingTextContainer}>
            <Text style={styles.settingTitle}>Gerenciar Readers</Text>
            <Text style={styles.settingSubtitle}>
              Adicione ou remova readers para o evento
            </Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={24}
            style={styles.settingArrow}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.settingItem}
          onPress={() => this.setActiveSection("courtesy")}
        >
          <MaterialCommunityIcons name="gift" size={24} style={styles.settingIcon} />
          <View style={styles.settingTextContainer}>
            <Text style={styles.settingTitle}>
              Adicionar Ingresso de Cortesia
            </Text>
            <Text style={styles.settingSubtitle}>
              Adicione ingressos de cortesia 
            </Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={24}
            style={styles.settingArrow}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.settingItem}
          onPress={() => this.setActiveSection("delete")}
        >
          <MaterialCommunityIcons
            name={
              this.state.status === "Publicado" ||
              this.state.status === "Finalizado"
                ? "trash-can-outline"
                : "trash-can-outline"
            }
            size={24}
            style={styles.settingIcon}
          />
          <View style={styles.settingTextContainer}>
            <Text style={styles.settingTitle}>
              {this.state.status === "Publicado" ||
              this.state.status === "Finalizado"
                ? "Solicitar Cancelamento"
                : "Deletar Evento"}
            </Text>
            <Text style={styles.settingSubtitle}>
              {this.state.status === "Publicado" ||
              this.state.status === "Finalizado"
                ? "Solicite o cancelamento do evento"
                : "Remova seu evento indesejado ou expirado"}
            </Text>
          </View>
          <Ionicons
            name="chevron-forward"
            size={24}
            style={styles.settingArrow}
          />
        </TouchableOpacity>
      </>
    );
  };

  renderActiveSection = () => {
    const { activeSection, eventUsername } = this.state;
    switch (activeSection) {
      case "general":
        return (
          <GeneralDataForm
            data={this.state}
            onInputChange={this.handleInputChange}
            onNestedInputChange={this.handleNestedInputChange}
            saveChanges={this.saveChanges}
          />
        );
      case "dates":
        return (
          <DatesForm
            data={this.state}
            onInputChange={this.handleNestedInputChange}
            saveChanges={this.saveChanges}
            togglePicker={this.togglePicker}
            handleDatePicking={this.handleDatePicking}
          />
        );
      case "location":
        return (
          <LocationForm
            data={this.state}
            onInputChange={this.handleInputChange}
            onNestedInputChange={this.handleNestedInputChange}
            saveChanges={this.saveChanges}
          />
        );
      case "lots":
        return (
          <LotsForm
            data={this.state}
            onInputChange={this.handleInputChange}
            onNestedInputChange={this.handleNestedInputChange}
            saveChanges={this.saveChanges}
          />
        );
      case "sticker":
        return (
          <StickerForm
            data={this.state}
            onImagePicker={this.handleImagePickerSticker}
            saveChanges={this.saveChanges}
            showImageModalSticker={this.state.showImageModalSticker}
            handleCloseImageModalSticker={this.handleCloseImageModalSticker}
          />
        );
      case "contacts":
        return (
          <ContactsForm
            data={this.state}
            onNestedInputChange={this.handleNestedInputChange}
            saveChanges={this.saveChanges}
          />
        );
      case "policies":
        return (
          <PoliciesForm
            data={this.state}
            onNestedInputChange={this.handleNestedInputChange}
            saveChanges={this.saveChanges}
          />
        );
      case "reader":
        return (
          <ReaderForm
            data={this.state}
            onNestedInputChange={this.handleNestedInputChange}
            saveChanges={this.saveChanges}
          />
        );
      case "courtesy":
        return (
          <CourtesyForm
            data={this.state}
            onNestedInputChange={this.handleNestedInputChange}
            saveChanges={this.saveChanges}
            eventUsername={eventUsername}
          />
        );
      case "delete":
        return (
          <DeleteEventForm
            data={this.state}
            deleteEvent={this.deleteEvent}
            requestEventCancellation={this.requestEventCancellation}
            navigation={this.props.navigation}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { activeSection, status } = this.state;
    return (
      <SafeAreaView style={styles.container}>
        {this.renderHeader(activeSection)}

        <ScrollView>
          {this.renderProfile()}

          {activeSection == null
            ? this.renderAll()
            : this.renderActiveSection()}
        </ScrollView>
        {this.renderPublishModal()}
      </SafeAreaView>
    );
  }
}

export default EditEvent;
