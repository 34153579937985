import React, { useState } from "react";
import {
  Modal,
  View,
  Text,
  Button,
  StyleSheet,
  ActivityIndicator,
  Image,
} from "react-native";
import { storage } from "../../services/utils/firebase/firebaseConfig";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { updateEvent } from "../../services/cruds/events/updateEvent";

const SaveImageModalSticker = ({
  isVisible,
  uri,
  onClose,
  username,
  uid,
  changeData,
}) => {
  const [loading, setLoading] = useState(false);

  uploadStickerEvento = async (uri) => {
    setLoading(true);
    try {
      const resposta = await fetch(uri);
      const blob = await resposta.blob();
      const fileName = `${username}_sticker.jpg`;
      const ref = storageRef(storage, `stickerImages/${fileName}`);

      await uploadBytes(ref, blob);
      const downloadURL = await getDownloadURL(ref);
      await atualizarUrlStickerEvento(downloadURL);
      setLoading(false);
      return { success: true, url: downloadURL };
    } catch (error) {
      console.error("Erro ao fazer upload da imagem:", error);
      setLoading(false);
      return { success: false };
    }
  };

  const atualizarUrlStickerEvento = async (url) => {
    try {
      await updateEvent(username, { sticker: url });
      changeData();

      console.log("Atualização bem-sucedida", "A imagem foi atualizada.");
      onClose();
    } catch (error) {
      console.error("Erro ao atualizar a imagem:", error);
    }
  };

  return (
    <Modal
      visible={isVisible}
      animationType="slide"
      onRequestClose={onClose}
      transparent={true}
    >
      <View style={styles.modalContainer}>
        <View style={styles.innerContainer}>
          <Text style={styles.title}>Confirmar Modificação</Text>
          <Image source={{ uri: uri }} style={styles.profileImage} />
          {loading ? (
            <ActivityIndicator size="large" color="#00ff00" />
          ) : (
            <View style={styles.buttonContainer}>
              <Button title="Cancelar" onPress={onClose} color="#666" />
              <Button
                title="Confirmar"
                onPress={() => uploadStickerEvento(uri)}
                color="#000"
              />
            </View>
          )}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  profileImage: {
    width: 80,
    height: 80,
    borderRadius: 5,
    marginBottom: 25,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  innerContainer: {
    width: "90%",
    backgroundColor: "#3D3D3D",
    padding: 10,
    borderRadius: 10,
    alignItems: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#fff",
    marginBottom: 20,
  },
});

export default SaveImageModalSticker;
