import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import SaveImageModalSticker from "../../../../components/producer/ModalSaveImageSticker";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { styles } from "../../../../styles/producer/producerUtils/EditEventStyles";

const StickerForm = ({
  data,
  onImagePicker,
  saveChanges,
  showImageModalSticker,
  handleCloseImageModalSticker,
}) => {
  // Verificação para determinar a imagem correta a ser exibida
  const defaultAvatar = require("../../../../../assets/imgs/fundo_adesivo.png");
  const eventImage =
    data.eventImage !== "default_image.png"
      ? { uri: data.eventImage }
      : defaultAvatar;
  const stickerImage =
    data.sticker && data.sticker !== "" ? { uri: data.sticker } : eventImage;

  return (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <Ionicons
          name="medal"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Editar Adesivo</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainerSticker}>
        {data.status !== "Publicado" ? (
          <Text style={styles.label}>Visualizar/Modificar Adesivo</Text>
        ) : (
          <Text style={styles.label}>Visualizar Adesivo</Text>
        )}
        <Image source={stickerImage} style={styles.stickerImage} />

        {data.status !== "Publicado" ? (
          <TouchableOpacity style={styles.button} onPress={onImagePicker}>
            <Text style={styles.buttonText}>Atualizar Adesivo</Text>
          </TouchableOpacity>
        ) : (
          <Text style={styles.labelWarning}>
            Edição bloqueada após publicação
          </Text>
        )}

        <SaveImageModalSticker
          isVisible={showImageModalSticker}
          uri={data.sticker}
          onClose={handleCloseImageModalSticker}
          username={data.username}
          uid={data.organizer.organizationId}
          changeData={saveChanges}
        />
      </View>
    </View>
  );
};

export default StickerForm;
