import React from "react";
import { View, Text } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { styles } from "../../../../styles/ticket/ticketUtils/TicketResultStyles";

const EventInfo = ({ eventName, eventDate, eventTime, eventLocation }) => (
  <>
    <View style={styles.sectionHeader}>
      <Icon name="ticket-outline" size={22} color="#FFF" style={styles.sectionIcon} />
      <Text style={styles.sectionTitle}>Informações do Evento</Text>
    </View>
    <View style={styles.containerSpace}>
      <View style={styles.sectionContainer}>
        <View style={styles.content}>
          <View style={styles.infoRow}>
            <Text style={styles.infoText}>Evento:</Text>
            <Text style={styles.infoTextSecond}>{eventName}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoText}>Data:</Text>
            <Text style={styles.infoTextSecond}>{eventDate}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoText}>Começa às:</Text>
            <Text style={styles.infoTextSecond}>{eventTime}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoText}>Local:</Text>
            <Text style={styles.infoTextSecond}>{eventLocation}</Text>
          </View>
        </View>
      </View>
    </View>
  </>
);

export default EventInfo;
