import { doc, getDoc, collection, query, where, limit, startAfter, getDocs } from "firebase/firestore";
import { db, auth } from "../../utils/firebase/firebaseConfig";
import { getUser } from "../users/getUser";
import axios from 'axios';

const API_URL = "https://us-central1-rayv-a7c48.cloudfunctions.net/api";

const getIdToken = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("Usuário não autenticado.");
  }
  return await user.getIdToken();
};

export const getTickets = async (userId) => {
  try {
    const ticketRef = doc(db, "ticketsData", userId);
    const docSnap = await getDoc(ticketRef);

    if (docSnap.exists()) {
      const data = docSnap.data().tickets;
      return Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));
    } else {
      console.log("Nenhum ticket encontrado para o usuário:", userId);
      return [];
    }
  } catch (error) {
    console.error("Erro ao obter os tickets:", error);
    return [];
  }
};

export const getTicketsStatistics = async (usernameEvent, lastDoc = null, limitNumber = 10) => {
  const ticketsQuery = lastDoc
    ? query(collection(db, "ticketsData"), where("usernameEvent", "==", usernameEvent), startAfter(lastDoc), limit(limitNumber))
    : query(collection(db, "ticketsData"), where("usernameEvent", "==", usernameEvent), limit(limitNumber));

  const ticketDocs = await getDocs(ticketsQuery);
  const tickets = await Promise.all(
    ticketDocs.docs.map(async (doc) => {
      const ticket = { id: doc.id, ...doc.data() };
      const user = await getUser(ticket.uid);
      return { ...ticket, userData: user };
    })
  );

  return { tickets, lastDoc: ticketDocs.docs[ticketDocs.docs.length - 1] };
};

export const getEventTickets = async (username) => {
  const ingressosVendidosQuery = query(
    collection(db, "ticketsData"),
    where("usernameEvent", "==", username)
  );

  const ingressosSnapshot = await getDocs(ingressosVendidosQuery);
  const ingressosVendidos = ingressosSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return ingressosVendidos;
};

export const getTicketById = async (ticketId) => {
  try {
    const ticketRef = doc(db, "ticketsData", ticketId);
    const docSnap = await getDoc(ticketRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      console.log("Dados do ticket:", data);
      return { id: ticketId, ...data };
    } else {
      console.log("Nenhum ticket encontrado para o ID:", ticketId);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter os tickets:", error);
    throw error;
  }
};

export const getTicketCode = async (ticketId) => {
  try {
    const token = await getIdToken();
    const response = await axios.post(
      `${API_URL}/getTicketCode`,
      { ticketId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );
    return (response.data);
  } catch (error) {
    console.error('Erro ao acessar o ticket: ', error);
    throw error; 
  }
};
