import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  Modal,
  StyleSheet,
  Platform,
} from "react-native";
import { getBuyersData, getUserProfile } from "../../../services/cruds/users/getUser"; 
import defaultAvatarImage from "../../../../assets/imgs/Avatar_Macaco.png";
import { SafeAreaView } from "react-native-safe-area-context";
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const BuyersList = ({ evento, uid, navigation}) => {
  const [uniqueBuyers, setUniqueBuyers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const insets = useSafeAreaInsets();

  useEffect(() => {
    loadBuyers();
  }, []);

  const loadBuyers = async () => {
    if (!evento || !evento.ticketHistory) {
      setIsLoading(false);
      return;
    }

    const ticketHistory = Object.values(evento.ticketHistory);
    const buyersData = await getBuyersData(ticketHistory);

    setUniqueBuyers(buyersData);
    setIsLoading(false);
  };

  const renderAvatars = () => {
    const avatars = uniqueBuyers
      .slice(0, 5)
      .map((buyer, index) => {
        const isPublicOrFollowing =
          buyer.uid === uid ||
          buyer.isPublic ||
          buyer.followers.includes(uid);
        const isEventHidden = buyer.hiddenEvents.includes(evento.username);
        const isCurrentUser = buyer.uid === uid;

        return (
          <Image
            key={index}
            source={
              isPublicOrFollowing && !isEventHidden
                ? buyer.avatarUrl != "" ? { uri: buyer.avatarUrl } : defaultAvatarImage
                : (isCurrentUser ? { uri: buyer.avatarUrl } : defaultAvatarImage)
            }
            style={styles.avatar}
          />
        );
      });
    return avatars;
  };

  const renderRemainingBuyersCount = () => {
    const totalTickets = uniqueBuyers.reduce(
      (acc, buyer) => acc + buyer.tickets,
      0
    );
    const displayedTickets = uniqueBuyers
      .slice(0, 5)
      .reduce((acc, buyer) => acc + buyer.tickets, 0);
    const remainingTickets = totalTickets - displayedTickets;
    let roundedCount;
    if (remainingTickets > 0) {
      if(remainingTickets >= 0 && remainingTickets < 10){
        roundedCount = "unidades";
      }else if(remainingTickets >= 10 && remainingTickets < 100){
        roundedCount = "dezenas";
      }else if(remainingTickets >= 100){
        roundedCount = "centenas";
      }
      return (
        <Text style={styles.remainingCount}>
          + algumas {roundedCount} vendidas
        </Text>
      );
    } else {
      return (
        <Text style={styles.remainingCount}>+ alguns ingressos vendidos</Text>
      );
    }
  };

  const openBuyersModal = () => {
    setModalVisible(true);
  };

  const handleProfileNavigation = async (buyer) => {
    try {
      const completeBuyerData = await getUserProfile(buyer.uid);
      if (completeBuyerData) {
        navigation.navigate("VisitProfile", { usuario: completeBuyerData, currentUser: uid });
      } else {
        console.error("Dados completos do usuário não encontrados");
      }
    } catch (error) {
      console.error("Erro ao obter dados completos do usuário:", error);
    }
  };
  
  const renderBuyerItem = ({ item }) => {
    const isPublicOrFollowing =
      item.uid === uid || item.isPublic || item.followers.includes(uid);
    const isCurrentUser = item.uid === uid;
    const isEventHidden = item.hiddenEvents.includes(evento.username);

    return (
      <TouchableOpacity
        style={styles.buyerItem}
        onPress={() => handleProfileNavigation(item)}
      >
        <Image
          source={
            isPublicOrFollowing && !isEventHidden
              ? item.avatarUrl ? { uri: item.avatarUrl } : defaultAvatarImage
              : (isCurrentUser ? { uri: item.avatarUrl } : defaultAvatarImage)
          }
          style={styles.buyerAvatar}
        />
        <View style={styles.buyerInfo}>
          <Text style={styles.buyerName}>
            {isPublicOrFollowing && !isEventHidden
              ? `${item.fullName}${isCurrentUser ? " (eu)" : ""}`
              : `${isCurrentUser ? `${item.fullName} (eu)` : "Privado"}`}
          </Text>
          <Text style={styles.buyerUsername}>
            @{isPublicOrFollowing && !isEventHidden ? item.username : `${isCurrentUser ? `${item.username}` : "Privado"}`}
          </Text>
          {item.tickets > 1 && (
            <Text style={styles.ticketCount}>Mais de um ingresso</Text>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  if (uniqueBuyers.length === 0) {
    return null; // Não exibe nada se não houver compradores
  }

  return (
    <SafeAreaView style={styles.container}>
      <TouchableOpacity
        style={styles.avatarsContainer}
        onPress={openBuyersModal}
      >
        {renderAvatars()}
        {renderRemainingBuyersCount()}
      </TouchableOpacity>
      <Modal
        visible={modalVisible}
        animationType="slide"
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={[
          styles.modalContainer,
          Platform.OS === 'ios' && {
            paddingTop: insets.top,
          },
        ]}>
          <FlatList
            data={uniqueBuyers}
            renderItem={renderBuyerItem}
            keyExtractor={(item) => item.uid}
          />
          <TouchableOpacity
            style={styles.closeModalButton}
            onPress={() => setModalVisible(false)}
          >
            <Text style={styles.closeModalText}>Fechar</Text>
          </TouchableOpacity>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#000",
    paddingTop: 10,
    paddingHorizontal: 10,
  },
  avatarsContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    backgroundColor: "#000",
    borderRadius: 5,
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 20,
    marginRight: -10,
    borderWidth: 2,
    borderColor: "#000",
  },
  remainingCount: {
    color: "#FFFD",
    marginLeft: 15,
    fontSize: 14,
  },
  buyerItem: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#333",
  },
  buyerAvatar: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 10,
  },
  buyerInfo: {
    justifyContent: "center",
  },
  buyerName: {
    color: "#FFF",
    fontSize: 16,
  },
  buyerUsername: {
    color: "#999",
    fontSize: 14,
  },
  ticketCount: {
    color: "#999",
    fontSize: 14,
  },
  closeModalButton: {
    padding: 10,
    backgroundColor: "#444",
    alignItems: "center",
  },
  closeModalText: {
    color: "#FFF",
  },
  loadingText: {
    color: "#FFFD",
    textAlign: "center",
    marginTop: 20,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "#000",
  },
});

export default BuyersList;
