import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";

const ItemContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 50px;
`;

const Container = styled.View`
  margin-top: 20px;
`;

const ColorIndicator = styled.View`
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

const ItemText = styled.Text`
  font-size: 16px;
  color: #333;
`;

// Componente CustomList recebendo duas variáveis booleanas como props
const CustomList = ({ isFirstItemComplete, isSecondItemComplete }) => {
  // Define os itens fixos com uma função para obter a cor baseada nas props
  const items = [
    { id: 1, label: "Dados iniciais", isComplete: true },
    { id: 2, label: "Número de telefone", isComplete: isSecondItemComplete },
    { id: 3, label: "Endereço válido", isComplete: isFirstItemComplete }, // Este item sempre tem a cor padrão
  ];

  const getColor = (isComplete) => {
    return isComplete ? "#5B2372" : "#141414"; // Verde se completo, vermelho se não
  };

  return (
    <Container>
      {items.map((item) => (
        <ItemContainer key={item.id}>
          <ColorIndicator color={getColor(item.isComplete)} />
          <ItemText>{item.label}</ItemText>
        </ItemContainer>
      ))}
    </Container>
  );
};

export default CustomList;
