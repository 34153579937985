import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  headerContainer: {
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  background: {
    backgroundColor: "#000",
    paddingHorizontal: 10,
  },
  searchBar: {
    paddingTop: 10,
    paddingHorizontal: 10,
    backgroundColor: "#000",
  },
  searchBarSecond: {
    paddingTop: 10,
    paddingHorizontal: 10,
    paddingBottom: 10,
    backgroundColor: "#000",
  },
  emptyContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
    padding: 20,
  },
  emptyText: {
    fontSize: 18,
    color: "#bbb",
    textAlign: "center",
  },
});
