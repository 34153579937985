import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  ScrollView,
  TouchableOpacity,
  SafeAreaView,
  Alert,
  Modal,
  ActivityIndicator,
  TouchableWithoutFeedback,
  StyleSheet,
  Platform,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Header from "../../../components/ticket/Header";
import { styles } from "../../../styles/ticket/ticketView/PaymentStyles";
import { openBrowserAsync } from "expo-web-browser";
import { auth } from "../../../services/utils/firebase/firebaseConfig";

export default function Payment({ route, navigation }) {
  const { items, uid, evento } = route.params;
  const [paymentMethod, setPaymentMethod] = useState("pix");
  const [loading, setLoading] = useState(false);
  const [feesModalVisible, setFeesModalVisible] = useState(false);
  const [processingModalVisible, setProcessingModalVisible] = useState(false);

  const defaultImageEvent = require("../../../../assets/imgs/fundo_evento.png");
  const eventImage =
    evento.eventImage !== "default_image.png"
      ? { uri: evento.eventImage }
      : defaultImageEvent;

  useEffect(() => {
    setPaymentMethod("pix");
  }, []);

  const openWebBrowser = async (url) => {
    try {
      await openBrowserAsync(url);
    } catch (error) {
      console.error("Erro ao abrir o navegador:", error);
    }
  };

  const enviarDadosParaServidorEabrirLink = async () => {
    if (Platform.OS === "android") {
      setLoading(true);
      setProcessingModalVisible(true);
    }

    console.log("Enviando dados para o servidor...");
    console.log(items, uid, evento.id, paymentMethod);
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("Usuário não autenticado.");
      }
      const idToken = await user.getIdToken();

      const response = await fetch(
        "https://us-central1-rayv-a7c48.cloudfunctions.net/api/preference",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            items: items.map((item) => ({
              title: item.title,
              quantity: item.quantity,
              unit_price: item.unit_price,
            })),
            uid: uid,
            absorbFee: evento.absorbFee,
            eventId: evento.username,
            paymentMethod: paymentMethod,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Dados recebidos do servidor:", data);

      if (data && data.initPoint) {
        if (Platform.OS === "android") {
          setLoading(false);
          setProcessingModalVisible(false);
        }
        openWebBrowser(data.initPoint);
      } else {
        if (Platform.OS === "android") {
          setLoading(false);
          setProcessingModalVisible(false);
        }
        console.error("URL de pagamento não recebida.");
        Alert.alert("Erro", "URL de pagamento não recebida.");
      }
    } catch (error) {
      if (Platform.OS === "android") {
        setLoading(false);
        setProcessingModalVisible(false);
      }
      console.error("Erro ao enviar dados para o servidor:", error);
      Alert.alert(
        "Erro",
        "Erro ao enviar dados para o servidor: " + error.message
      );
    }
  };

  const calculateTotal = () => {
    const subtotal = items.reduce(
      (total, item) => total + item.quantity * item.unit_price,
      0
    );

    if (evento.absorbFee) {
      return subtotal;
    }

    let total = 0;

    switch (paymentMethod) {
      case "pix":
        total = subtotal * 1.05; // adding 5% for pix
        break;
      case "card":
        total = subtotal * 1.08; // adding 8% for card
        break;
      case "boleto":
        total = subtotal * 1.08 + 3.5; // adding 8% + 3.50 for boleto
        break;
      default:
        total = subtotal;
        break;
    }

    return total;
  };

  const renderHeader = () => {
    return (
      <View style={styles.headerContainer}>
        <View style={styles.titleAndSubtitleContainer}>
          <Text style={styles.titleText}>{evento.name}</Text>
          <View style={styles.infoContainer}>
            <MaterialCommunityIcons
              name="card-account-details"
              size={13}
              color="#C0C0C0"
              style={styles.infoIcon}
            />
            <Text style={styles.subtitleText}>{evento.username}</Text>
          </View>
        </View>
      </View>
    );
  };

  const renderLot = (item, index) => {
    if (item.quantity > 0) {
      return (
        <View key={index} style={styles.lotCard}>
          <View style={styles.lotDetailContainer}>
            <Text style={styles.lotName}>{item.title}</Text>
            <Text style={styles.lotPrice}>
              R$ {Number(item.unit_price).toFixed(2)}
            </Text>
          </View>
          <View style={styles.lotControls}>
            <Text style={styles.quantity}>{item.quantity}</Text>
          </View>
        </View>
      );
    }
    return null;
  };

  const renderTotal = () => {
    return (
      <View style={styles.totalContainer}>
        <Text style={styles.totalValue}>R$ {calculateTotal().toFixed(2)}</Text>
      </View>
    );
  };

  const renderPayment = () => {
    return (
      <View style={styles.section}>
        <View style={styles.paymentMethods}>
          <TouchableOpacity
            style={
              paymentMethod === "pix"
                ? styles.paymentMethodSelect
                : styles.paymentMethod
            }
            onPress={() => setPaymentMethod("pix")}
          >
            <Icon
              name="qr-code-outline"
              size={24}
              color="#FFF"
              style={styles.iconButton}
            />
            <Text style={styles.paymentText}>Pix</Text>
          </TouchableOpacity>
          <View style={styles.row}>
            <TouchableOpacity
              style={
                paymentMethod === "card"
                  ? styles.paymentMethodSecondSelect
                  : styles.paymentMethodSecond
              }
              onPress={() => setPaymentMethod("card")}
            >
              <Icon
                name="card-outline"
                size={24}
                color="#FFF"
                style={styles.iconButton}
              />
              <Text style={styles.paymentText}>Cartão</Text>
            </TouchableOpacity>
            <View style={styles.space}></View>
            <TouchableOpacity
              style={
                paymentMethod === "boleto"
                  ? styles.paymentMethodSecondSelect
                  : styles.paymentMethodSecond
              }
              onPress={() => setPaymentMethod("boleto")}
            >
              <Icon
                name="barcode-outline"
                size={24}
                color="#FFF"
                style={styles.iconButton}
              />
              <Text style={styles.paymentText}>Boleto</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  const renderFeesSection = () => (
    <View>
      <View style={styles.sectionDividerSecond}></View>
      <TouchableOpacity onPress={() => setFeesModalVisible(true)}>
        <View style={styles.feesContainer}>
          <Icon name="cash-outline" size={24} color="#FFFD" />
          <View style={styles.feesDetails}>
            <Text style={styles.feesTitle}>Detalhes das Taxas</Text>
            <Text style={styles.feesDescription}>
              Clique aqui para ver as taxas de pagamento
            </Text>
          </View>
        </View>
      </TouchableOpacity>
      <Modal
        visible={feesModalVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={() => setFeesModalVisible(false)}
      >
        <TouchableWithoutFeedback onPress={() => setFeesModalVisible(false)}>
          <View style={modalStyles.modalContainer}>
            <View style={modalStyles.modalContent}>
              <TouchableOpacity
                style={modalStyles.modalCloseButton}
                onPress={() => setFeesModalVisible(false)}
              >
                <Icon name="close" size={24} color="#FFF" />
              </TouchableOpacity>
              <Text style={modalStyles.modalTitle}>Detalhes das Taxas</Text>
              {evento.absorbFee ? (
                <Text style={modalStyles.feesText}>
                  As taxas estão inclusas no valor do ingresso.
                </Text>
              ) : (
                <>
                  <Text style={modalStyles.feesText}>
                    Taxas para Cartão: 8%
                  </Text>
                  <Text style={modalStyles.feesText}>Taxas para Pix: 5%</Text>
                  <Text style={modalStyles.feesText}>
                    Taxas para Boleto: 8% + R$3,50
                  </Text>
                </>
              )}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
      <View style={styles.sectionDividerSecond}></View>
    </View>
  );

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <Header navigation={navigation} />
        <Image source={eventImage} style={styles.eventImage} />

        {renderHeader()}

        <View style={styles.sectionDividerSecond}></View>

        <View style={styles.sectionHeader}>
          <Icon
            name="ticket-sharp"
            size={22}
            color="#FFF"
            style={styles.sectionIcon}
          />
          <Text style={styles.lotTitleText}>Lotes Selecionados</Text>
        </View>

        <View style={styles.lotSelectionContainer}>{items.map(renderLot)}</View>

        <View style={styles.sectionDividerSecond}></View>

        <View style={styles.sectionHeader}>
          <Icon
            name="card-sharp"
            size={22}
            color="#FFF"
            style={styles.sectionIcon}
          />
          <Text style={styles.lotTitleText}>Método de Pagamento</Text>
        </View>

        {renderPayment()}

        {!evento.absorbFee && renderFeesSection()}

        <View style={styles.sectionDividerSecond}></View>

        <View style={styles.sectionHeader}>
          <Icon
            name="cash-sharp"
            size={22}
            color="#FFF"
            style={styles.sectionIcon}
          />
          <Text style={styles.lotTitleText}>Valor Total</Text>
          <Text style={styles.lotTitleTextSecond}>
            {" "}
            (
            {paymentMethod == "pix"
              ? "Pix"
              : paymentMethod == "card"
              ? "Cartão"
              : "Boleto"}
            )
          </Text>
        </View>

        {renderTotal()}

        <View style={styles.sectionDividerSecond}></View>

        <TouchableOpacity
          onPress={enviarDadosParaServidorEabrirLink}
          style={styles.payButton}
          disabled={loading}
        >
          <Text style={styles.payButtonText}>REALIZAR PAGAMENTO</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <Text style={styles.backButtonText}>VOLTAR</Text>
        </TouchableOpacity>
        <View style={styles.watermarkContainer}>
          <Text style={styles.watermarkText}>
            Pagamento feito pelo Mercado Pago
          </Text>
        </View>

        <Modal
          visible={processingModalVisible}
          transparent={true}
          animationType="slide"
        >
          <View style={modalStyles.processingModalContainer}>
            <View style={modalStyles.processingModalContent}>
              <ActivityIndicator size="large" color="#FFF" />
              <Text style={modalStyles.processingModalText}>
                Processando pagamento...
              </Text>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </SafeAreaView>
  );
}

const modalStyles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalContent: {
    backgroundColor: "#1C1C1C",
    borderRadius: 10,
    padding: 20,
    width: "80%",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 18,
    color: "#FFFFFF",
    marginBottom: 15,
  },
  reportOption: {
    width: "100%",
    backgroundColor: "#3D3D3D",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  reportOptionText: {
    color: "#FFF",
    fontSize: 16,
    textAlign: "center",
  },
  modalCloseButton: {
    position: "absolute",
    top: 8,
    right: 8,
    zIndex: 1,
  },
  feesText: {
    color: "#FFF",
    fontSize: 16,
    textAlign: "center",
    marginVertical: 5,
  },
  processingModalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  processingModalContent: {
    backgroundColor: "#1C1C1C",
    borderRadius: 10,
    padding: 20,
    width: "80%",
    alignItems: "center",
  },
  processingModalText: {
    color: "#FFF",
    fontSize: 18,
    marginTop: 15,
  },
});
