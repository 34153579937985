import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: 150,
    height: 150,
  },
  text: {
    width: 150,
    height: 37.5,
    resizeMode: "contain",
  },
  versionText: {
    position: "absolute",
    bottom: 20,
    color: "#FFF9",
    fontSize: 12,
  },
});
