import { doc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { db, storage } from "../../utils/firebase/firebaseConfig";

export const deleteEvent = async (eventUsernameDoc, organizerId) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const userRef = doc(db, "usersData", organizerId);
    const eventDoc = await getDoc(eventRef);

    if (!eventDoc.exists()) {
      throw new Error("Evento não encontrado.");
    }

    const eventData = eventDoc.data();

    // Remover imagem do evento do Firebase Storage se não for a imagem padrão
    if (eventData.eventImage !== "default_image.png") {
      const eventImageRef = ref(storage, eventData.eventImage);
      await deleteObject(eventImageRef);
    }

    // Remover adesivo do evento do Firebase Storage, se existir
    if (eventData.sticker) {
      const stickerRef = ref(storage, eventData.sticker);
      await deleteObject(stickerRef);
    }

    // Remover a referência do adesivo do usuário
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();
    const stickers = userData.stickers || {};
    if (stickers[eventUsernameDoc]) {
      delete stickers[eventUsernameDoc];
      await updateDoc(userRef, { stickers });
    }

    // Deletar documento do evento no Firestore
    await deleteDoc(eventRef);

    return true;
  } catch (error) {
    if (error.code === "storage/object-not-found") {
      console.log(
        "Imagem não encontrada no storage, prosseguindo com a exclusão do evento."
      );
      await deleteDoc(eventRef);
      return true;
    } else {
      console.error("Erro ao deletar evento:", error);
      throw error;
    }
  }
};
