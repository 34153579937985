import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  TextInput,
  RefreshControl,
  Alert,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";
import { auth } from "../../../services/utils/firebase/firebaseConfig";
import { getEvent } from "../../../services/cruds/events/getEvent";
import { updateEvent } from "../../../services/cruds/events/updateEvent";
import { getTicketsStatistics } from "../../../services/cruds/tickets/getTickets";
import { styles } from "../../../styles/producer/producerInfo/EventStatisticsStyles";
import { onAuthStateChanged } from "firebase/auth";
import { desvalidarTicketProdutor, validarTicketProdutor } from "../../../services/cruds/tickets/ticketFuncs";
import { SafeAreaView } from "react-native-safe-area-context";

export default class EventStatistics extends Component {
  state = {
    evento: null,
    ingressosVendidos: [],
    valorTotalArrecadado: 0,
    valorLiquido: 0,
    ingressosFiltrados: [],
    ultimoDocumento: null,
    carregandoMais: false,
    loading: true,
    refreshing: false,
    searchQuery: "",
    totalIngressos: 0,
    ingressosDisponiveis: 0,
    userData: {},
    scannedTicket: null,
    userId: null,
    expandedTickets: {},
  };

  async componentDidMount() {
    const { evento } = this.props.route.params;
    this.setState({ evento });
    this.loadEventStatistics(evento.username);

    this._unsubscribe = this.props.navigation.addListener("focus", () => {
      this.loadEventStatistics(evento.username);
    });

    this.authenticateAndLoadEvents();
  }

  componentWillUnmount() {
    if (this._unsubscribe) {
      this._unsubscribe();
    }
    if (this.unsubscribeEvent) {
      this.unsubscribeEvent();
    }
    if (this.unsubscribeIngressosVendidos) {
      this.unsubscribeIngressosVendidos();
    }
    if (this.authListener) {
      this.authListener();
    }
  }

  authenticateAndLoadEvents = async () => {
    if (this.authListener) {
      this.authListener();
    }
    this.authListener = onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.setState({ userId: user.uid }, this.verifyUserPermission);
      } else {
        console.log("Nenhum usuário conectado");
        this.props.navigation.navigate("Home");
      }
    });
  };

  verifyUserPermission = async () => {
    try {
      const { userId, evento } = this.state;
      if (userId && evento && evento.organizer) {
        if (userId !== evento.organizer.organizationId) {
          this.props.navigation.navigate("Home");
        }
      }
    } catch (error) {
      console.error("Erro ao verificar permissão do usuário:", error);
      this.props.navigation.navigate("Home");
    }
  };

  formatCurrency = (value) => {
    return parseFloat(value).toFixed(2).replace(".", ",");
  };

  calculateTotal = (price, absorbFee, paymentMethod) => {
    let fee;
    switch (paymentMethod) {
      case "cartao":
        fee = 0.08;
        break;
      case "boleto":
        fee = 0.08 + 3.5 / price;
        break;
      case "pix":
        fee = 0.05;
        break;
      default:
        fee = 0.08;
    }
    return absorbFee ? price - (price * fee) : price;
  };

  loadEventStatistics = async (username) => {
    if (!username) return;

    try {
      const evento = await getEvent(username);
      let tickets = [];
      let lastDoc = null;

      const fetchTickets = async (startAfter) => {
        const { tickets: newTickets, lastDoc: newLastDoc } = await getTicketsStatistics(username, startAfter);
        tickets = [...tickets, ...newTickets];
        lastDoc = newLastDoc;
      };

      do {
        await fetchTickets(lastDoc);
      } while (lastDoc);

      const valorTotalArrecadado = tickets.reduce(
        (total, ticket) => total + parseFloat(ticket.lotPrice),
        0
      );

      const valorLiquido = tickets.reduce((total, ticket) => {
        if(evento.absorbFee){
          return total = total + this.calculateTotal(ticket.lotPrice, evento.absorbFee, ticket.paymentMethod);
        }else{
          return total = total + parseFloat(ticket.lotPrice);
        }
      }, 0);

      const totalIngressos = Object.values(evento.lots).reduce(
        (total, lot) => total + parseInt(lot.sold),
        0
      );

      const ingressosDisponiveis = Object.values(evento.lots).reduce(
        (total, lot) => total + (parseInt(lot.quantity) - parseInt(lot.sold)),
        0
      );

      this.setState({
        evento,
        ingressosVendidos: tickets,
        ingressosFiltrados: tickets,
        valorTotalArrecadado,
        valorLiquido,
        totalIngressos,
        ingressosDisponiveis,
        ultimoDocumento: lastDoc,
        loading: false,
      });
    } catch (error) {
      console.error("Erro ao carregar estatísticas do evento:", error);
    }
  };

  loadMoreTickets = async () => {
    const { evento, ultimoDocumento } = this.state;
    if (!ultimoDocumento) return;

    this.setState({ carregandoMais: true });

    try {
      const { tickets, lastDoc } = await getTicketsStatistics(evento.username, ultimoDocumento);

      this.setState((prevState) => ({
        ingressosFiltrados: [...prevState.ingressosFiltrados, ...tickets],
        ultimoDocumento: lastDoc,
        carregandoMais: false,
      }));
    } catch (error) {
      console.error("Erro ao carregar mais ingressos:", error);
      this.setState({ carregandoMais: false });
    }
  };

  handleSearch = (text) => {
    const { ingressosVendidos } = this.state;
    const query = text.toLowerCase();
    const ingressosFiltrados = ingressosVendidos.filter(
      (ingresso) =>
        ingresso.userData?.fullName.toLowerCase().includes(query) ||
        ingresso.lotName.toLowerCase().includes(query) ||
        ingresso.userData?.username.toLowerCase().includes(query) ||
        ingresso.id.toLowerCase().includes(query)
    );
    this.setState({ searchQuery: text, ingressosFiltrados });
  };

  handleCheck = async (ticketId) => {
    const { evento } = this.state;
    try {
      const eventDoc = await getEvent(evento.username);
      if (eventDoc) {
        const eventData = eventDoc;
        const ticketHistory = eventData.ticketHistory || {};
        const ticket = ticketHistory[ticketId];

        if (ticket?.QR_CODE) {
          Alert.alert(
            "Ingresso já verificado",
            "Este ingresso já foi verificado. Deseja desverificar?",
            [
              {
                text: "Cancelar",
                style: "cancel",
              },
              {
                text: "Desverificar",
                onPress: async () => {
                  await desvalidarTicketProdutor(ticketId);
                  this.loadEventStatistics(evento.username);
                },
              },
            ]
          );
        } else {
          Alert.alert(
            "Confirmação",
            "Deseja marcar este ingresso como utilizado?",
            [
              {
                text: "Cancelar",
                style: "cancel",
              },
              {
                text: "Confirmar",
                onPress: async () => {
                  await validarTicketProdutor(ticketId);
                  this.loadEventStatistics(evento.username);
                },
              },
            ]
          );
        }
      } else {
        Alert.alert("Erro", "Evento não encontrado.");
      }
    } catch (error) {
      console.error("Erro ao verificar ingresso:", error);
      Alert.alert("Erro", "Não foi possível verificar o ingresso.");
    }
  };

  toggleExpand = (id) => {
    this.setState((prevState) => ({
      expandedTickets: {
        ...prevState.expandedTickets,
        [id]: !prevState.expandedTickets[id],
      },
    }));
  };

  renderHeader = () => (
    <View style={styles.containerHeader}>
      <TouchableOpacity
        onPress={() => {
          this.props.navigation.goBack();
        }}
        style={styles.backButtonHeader}
      >
        <Icon name="arrow-back" size={25} color="#FFFFFF" />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => this.props.navigation.navigate("Home")}>
        <Image
          source={logoTexto}
          resizeMode="contain"
          style={styles.logoHeader}
        />
      </TouchableOpacity>
      <Icon
        name="person-outline"
        size={25}
        color="#000"
        style={styles.goBackButton}
      />
    </View>
  );

  renderProfile = () => {
    const { evento } = this.state;
    if (!evento) return null;

    const defaultImageEvent = require("../../../../assets/imgs/fundo_evento.png");
    const eventImage =
      evento.eventImage !== "default_image.png"
        ? { uri: evento.eventImage }
        : defaultImageEvent;

    return (
      <View style={styles.eventInfoContainer}>
        <Image source={eventImage} style={styles.eventImage} />
        <View style={styles.eventDetails}>
          <Text style={styles.eventName}>
            {evento.name ? evento.name : "Carregando..."}
          </Text>
          <Text style={styles.eventDescription}>
            Tela de Estatísticas e Dados do Evento
          </Text>
        </View>
      </View>
    );
  };

  renderSectionTitle = (title, iconName) => (
    <View style={styles.sectionHeader}>
      <Icon name={iconName} size={22} color="#FFF" style={styles.sectionIcon} />
      <Text style={styles.sectionTitle}>{title}</Text>
      <View style={styles.sectionDivider} />
    </View>
  );

  renderItem = ({ item }) => {
    const { expandedTickets } = this.state;
    const isExpanded = expandedTickets[item.id];
    const { evento } = this.state;
    const eventData = evento.ticketHistory?.[item.id] || {};
    const isChecked = eventData.QR_CODE;

    return (
      <View style={styles.ticketContainer}>
        <Image
          source={
            item.userData?.avatarUrl !== ""
              ? { uri: item.userData.avatarUrl }
              : require("../../../../assets/imgs/Avatar_Macaco.png")
          }
          style={styles.avatar}
        />
        <View style={styles.ticketDetails}>
          <Text style={styles.ticketName}>
            {item.userData?.fullName || "Nome não disponível"}
          </Text>
          <Text style={styles.ticketText}>
            <Icon name="person" size={12} color="#A0A0A0" /> Usuário:{" "}
            {item.userData?.username || "Nome não disponível"}
          </Text>
          <Text style={styles.ticketText}>
            <Icon name="pricetag" size={12} color="#A0A0A0" /> Lote:{" "}
            {item.lotName}
          </Text>
          {isExpanded && (
            <>
              <Text style={styles.ticketText}>
                <Icon name="cash" size={12} color="#A0A0A0" /> Pago: R$
                {this.formatCurrency(item.lotPrice)}
              </Text>
              <Text style={styles.ticketText}>
                <Icon name="calendar" size={12} color="#A0A0A0" /> Dia:{" "}
                {item.payDay.toDate().toLocaleDateString()}
              </Text>
              <Text style={styles.ticketText}>
                <Icon name="card" size={12} color="#A0A0A0" /> Método:{" "}
                {item.paymentMethod}
              </Text>
              <Text style={styles.ticketText}>
                <Icon name="checkmark-circle" size={12} color="#A0A0A0" />{" "}
                Status: {item.status}
              </Text>
              <Text style={styles.ticketText}>
                <Icon name="id-card" size={12} color="#A0A0A0" /> ID: {item.id}
              </Text>
            </>
          )}
        </View>
        <TouchableOpacity
          style={styles.expandButton}
          onPress={() => this.toggleExpand(item.id)}
        >
          <Icon
            name={isExpanded ? "chevron-up" : "chevron-down"}
            size={24}
            color="#FFF"
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.checkButton}
          onPress={() => this.handleCheck(item.id)}
        >
          <Icon
            name={isChecked ? "checkmark-circle" : "square-outline"}
            size={24}
            color={isChecked ? "#00C851" : "#FFF"}
          />
        </TouchableOpacity>
      </View>
    );
  };

  renderFooter = () => {
    if (!this.state.carregandoMais) return null;
    return <ActivityIndicator style={{ color: "#FFF" }} />;
  };

  render() {
    const {
      evento,
      ingressosFiltrados,
      valorTotalArrecadado,
      valorLiquido,
      loading,
      searchQuery,
      totalIngressos,
      ingressosDisponiveis,
      scannedTicket,
    } = this.state;

    if (loading) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#FFF" />
        </View>
      );
    }

    return (
      <SafeAreaView style={styles.container}>
        {this.renderHeader()}
        {this.renderProfile()}
        <View style={styles.containerSpace}>
          <View style={styles.sectionContainer}>
            {this.renderSectionTitle("Informações", "information-circle")}
          </View>
          <View style={styles.infoContainer}>
            <View style={styles.infoRow}>
              <Text style={styles.infoText}>Valor Total Arrecadado:</Text>
              <Text style={styles.infoTextSecond}>
                R${this.formatCurrency(valorTotalArrecadado)}
              </Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.infoText}>Valor Líquido:</Text>
              <Text style={styles.infoTextSecond}>
                R${this.formatCurrency(valorLiquido)}
              </Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.infoText}>Ingressos Vendidos:</Text>
              <Text style={styles.infoTextSecond}>{totalIngressos}</Text>
            </View>
            <View style={styles.infoRow}>
              <Text style={styles.infoText}>Ingressos Disponíveis:</Text>
              <Text style={styles.infoTextSecond}>{ingressosDisponiveis}</Text>
            </View>
          </View>
          <View style={styles.sectionContainerSecond}>
            {this.renderSectionTitle("Ingressos", "ticket")}
          </View>
        </View>
        <View style={styles.searchContainer}>
          <Icon
            name="search"
            size={20}
            color="#CCC"
            style={styles.searchIcon}
          />
          <TextInput
            style={styles.searchBar}
            placeholder="Pesquisar Ingressos"
            placeholderTextColor="#CCC"
            value={searchQuery}
            onChangeText={this.handleSearch}
          />
        </View>
        <FlatList
          data={ingressosFiltrados}
          renderItem={this.renderItem}
          keyExtractor={(item) => item.id.toString()}
          onEndReached={this.loadMoreTickets}
          onEndReachedThreshold={0.1}
          ListFooterComponent={this.renderFooter}
          refreshControl={
            <RefreshControl
              refreshing={this.state.refreshing}
              onRefresh={() => this.loadEventStatistics(this.state.evento.username)}
            />
          }
        />
        <TouchableOpacity
          style={styles.qrCodeButton}
          onPress={() =>
            this.props.navigation.navigate("QRCodeScanner", { evento: { id: evento.username }})
          }
        >
          <Icon name="qr-code" size={24} color="#FFF" />
          <Text style={styles.qrCodeButtonText}>Escanear QR Code</Text>
        </TouchableOpacity>
        {scannedTicket && (
          <View style={styles.ticketInfoContainer}>
            <Text style={styles.ticketInfoText}>
              Ticket ID: {scannedTicket.id}
            </Text>
            <Text style={styles.ticketInfoText}>
              User:{" "}
              {scannedTicket.userData
                ? scannedTicket.userData.fullName
                : "Nome não disponível"}
            </Text>
            <Text style={styles.ticketInfoText}>
              Price: {scannedTicket.lotPrice}
            </Text>
            <Text style={styles.ticketInfoText}>
              Lot: {scannedTicket.lotName}
            </Text>
            <Text style={styles.ticketInfoText}>
              Date: {scannedTicket.payDay.toDate().toLocaleDateString()}
            </Text>
          </View>
        )}
      </SafeAreaView>
    );
  }
}
