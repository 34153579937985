import React, { useState } from "react";
import { View, TextInput, StyleSheet, TouchableOpacity } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import Toast from "react-native-toast-message";

export default function AuthInput(props) {
  const [secureEntry, setSecureEntry] = useState(props.secureTextEntry);

  const showInfoToast = () => {
    if (props.error) {
      Toast.show({
        type: "info",
        text1: "Informação",
        text2: props.error,
        position: "bottom",
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
    }
  };

  return (
    <View
      style={[
        styles.container,
        props.style,
        props.error && styles.errorContainer,
      ]}
    >
      <Icon name={props.icon} size={20} style={styles.icon} />
      <TextInput
        {...props}
        style={styles.input}
        secureTextEntry={secureEntry}
      />
      {props.secureTextEntry && (
        <TouchableOpacity onPress={() => setSecureEntry(!secureEntry)}>
          <Icon
            name={secureEntry ? "eye-slash" : "eye"}
            size={20}
            style={styles.icon}
          />
        </TouchableOpacity>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 40,
    backgroundColor: "#3D3D3D",
    borderRadius: 15,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 10,
  },
  errorContainer: {
    borderColor: "red",
    borderWidth: 1,
  },
  icon: {
    color: "gray",
  },
  input: {
    flex: 1,
    color: "#FFF",
    marginLeft: 10,
  },
});
