import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: "#000",
  },
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  backButtonHeader: {
    padding: 0,
  },
  logoHeader: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  goBackButton: {
    padding: 0,
  },
  itemContainer: {
    flexDirection: "row",
    padding: 10,
    borderBottomWidth: 2,
    borderBottomColor: "#121212",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pendingContainer: {
    backgroundColor: "#121212",
  },
  userInfo: {
    flex: 1,
    marginLeft: 10,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  itemText: {
    color: "#fff",
    fontWeight: "bold",
  },
  itemSubText: {
    color: "#aaa",
  },
  button: {
    backgroundColor: "#121212",
    padding: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
    borderColor: "#121212r",
    borderWidth: 1,
  },
  buttonsContainer: {
    flexDirection: "row",
  },
  buttonApprove: {
    backgroundColor: "#1A2035",
    padding: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
    marginRight: 5,
  },
  buttonDecline: {
    backgroundColor: "#000",
    padding: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
    borderColor: "#000",
    borderWidth: 1,
  },
  buttonText: {
    fontWeight: "bold",
    color: "#fff",
  },
  toggleButton: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    backgroundColor: "#121212",
    justifyContent: "center",
  },
  toggleButtonText: {
    color: "#fff",
    fontWeight: "bold",
    marginLeft: 5,
  },
  emptyContainer: {
    flex: 1,
    alignItems: "center",
    padding: 20,
  },
  emptyText: {
    color: "#fff9",
    fontSize: 16,
    textAlign: "center",
  },
  eventImageHidden: {
    width: 60,
    height: 60,
    borderRadius: 5,
  },
  eventImage: {
    width: 60,
    height: 60,
    borderRadius: 5,
  },
  eventInfo: {
    flex: 1,
    marginLeft: 10,
  },
});
