import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, TouchableOpacity, Alert, Platform } from "react-native";
import { BarCodeScanner } from "expo-barcode-scanner";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { useIsFocused } from "@react-navigation/native";
import { getEventTicketHistory } from "../../../services/cruds/events/getEvent";
import { updateEvent } from "../../../services/cruds/events/updateEvent";
import { styles } from "../../../styles/producer/producerUtils/ScannerStyles";
import { validarQrCode } from "../../../services/cruds/tickets/ticketFuncs";
import { SafeAreaView } from "react-native-safe-area-context";
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const QRCodeScanner = ({ route }) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [flashOn, setFlashOn] = useState(false);
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const insets = useSafeAreaInsets();

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === "granted");
    };

    getBarCodeScannerPermissions();
  }, []);

  const handleBarCodeScanned = async ({ type, data }) => {
    setScanned(true);

    try {
      const ticketData = JSON.parse(data);
      const eventData = await getEventTicketHistory(route.params.evento.id);

      console.log(ticketData)

      if (eventData) {
        const ticketHistory = eventData;
        if (ticketHistory && ticketHistory[ticketData.ticketId]) {
          if (ticketHistory[ticketData.ticketId].QR_CODE) {
            Alert.alert("Ticket já utilizado", "Este ticket já foi validado.");
          } else {
            // const updatedTicketHistory = {
            //   ...ticketHistory,
            //   [ticketData.ticketId]: {
            //     ...ticketHistory[ticketData.ticketId],
            //     QR_CODE: true,
            //   },
            // };

            // await updateEvent(route.params.evento.id, {
            //   ticketHistory: updatedTicketHistory,
            // });

            await validarQrCode(ticketData.ticketId, ticketData.key);

            Alert.alert("Sucesso", "Ticket encontrado e validado.");
          }
          navigation.goBack();
        } else {
          Alert.alert("Erro", "Ticket não encontrado.");
          navigation.goBack();
        }
      } else {
        Alert.alert("Erro", "Evento não encontrado.");
        navigation.goBack();
      }
    } catch (error) {
      console.error("Erro validando o QR code:", error);
      Alert.alert("Error", "Houve um erro ao validar o QR CODE.");
      navigation.goBack();
    }
  };

  if (hasPermission === null) {
    return <Text>Pedindo acesso à câmera</Text>;
  }
  if (hasPermission === false) {
    return <Text>Sem acesso à câmera do dispositivo</Text>;
  }

  return (
    <SafeAreaView style={styles.container}>
      {isFocused && (
        <BarCodeScanner
          onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
          style={StyleSheet.absoluteFillObject}
          torchMode={flashOn ? "on" : "off"}
        />
      )}
      <View style={[
        styles.overlay,
        Platform.OS === 'ios' && {
          paddingTop: insets.top,
        },
      ]}>
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={styles.iconButton}>
            <Ionicons name="arrow-back" size={28} color="#fffd" />
          </TouchableOpacity>
          <Text style={styles.headerText}>Validação de Ingresso</Text>
        </View>
        <View style={styles.scannerContainer}>
          <View style={styles.scannerFrame}>
            <View style={styles.scannerCornerTopLeft} />
            <View style={styles.scannerCornerTopRight} />
            <View style={styles.scannerCornerBottomLeft} />
            <View style={styles.scannerCornerBottomRight} />
          </View>
          <Text style={styles.infoText}>Escaneie o QR Code</Text>
        </View>
        {scanned && (
          <TouchableOpacity
            onPress={() => setScanned(false)}
            style={styles.scanAgainButton}
          >
            <Text style={styles.scanAgainText}>
              Clique para escanear novamente
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </SafeAreaView>
  );
};

export default QRCodeScanner;
