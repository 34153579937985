import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  FlatList,
  TouchableOpacity,
  SafeAreaView,
  RefreshControl,
  Animated,
  Easing,
  Alert,
} from "react-native";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import Header from "../../../components/ticket/ticketUtils/HeaderMyTickets";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import ListEventUCard from "../../../components/general/home_search/ListEventUCard";
import { styles } from "../../../styles/ticket/ticketUtils/MyTicketsStyles";
import { auth } from "../../../services/utils/firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { getUserMyTicket } from "../../../services/cruds/users/getUser";
import { getEventMyTicket } from "../../../services/cruds/events/getEvent";

const Tab = createMaterialTopTabNavigator();

const PAGE_SIZE = 15;

export default function Ticket({ route, navigation }) {
  const [uid, setUid] = useState("");
  const [userData, setUserData] = useState(null);
  const [ticketsData, setTicketsData] = useState([]);
  const [favoriteEvents, setFavoriteEvents] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [loadingFavorites, setLoadingFavorites] = useState(false);
  const [ticketPage, setTicketPage] = useState(1);
  const [favoritePage, setFavoritePage] = useState(1);
  const [eventDataMap, setEventDataMap] = useState(new Map());
  const [frozeLoad, setFrozeLoad] = useState(false);
  const [frozeLoadF, setFrozeLoadF] = useState(false);

  const fadeAnim = useState(new Animated.Value(0))[0];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUid(user.uid);
        await subscribeToUserData(user.uid);
      } else {
        navigation.navigate("Login");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      easing: Easing.inOut(Easing.ease),
      useNativeDriver: true,
    }).start();
  }, [ticketsData, favoriteEvents]);

  const subscribeToUserData = async (userId) => {
    const user = await getUserMyTicket(userId);
    if (user) {
      setUserData(user);
      await loadTickets(user);
      await loadFavorites(user);
    }
  };

  const fetchEventData = async (tickets) => {
    const events = await Promise.all(
      tickets.map(async (ticket) => {
        const existingEvent = eventDataMap.get(ticket.usernameEvent);

        if (existingEvent) {
          return existingEvent;
        }

        const event = await getEventMyTicket(ticket.usernameEvent);
        if (event) {
          setEventDataMap((prevMap) =>
            new Map(prevMap.set(ticket.usernameEvent, event))
          );
        }
        return event;
      })
    );

    return events.filter((event) => event != null);
  };

  const fetchFavoriteEvents = async (usernames) => {
    const events = await Promise.all(
      usernames.map(async (username) => {
        const existingEvent = eventDataMap.get(username);

        if (existingEvent) {
          return existingEvent;
        }

        const event = await getEventMyTicket(username);
        if (event) {
          setEventDataMap((prevMap) => new Map(prevMap.set(username, event)));
        }
        return event;
      })
    );
    return events.filter((event) => event != null);
  };

  const renderTicketCard = ({ item }) => {
    const eventDetails = eventDataMap.get(item.usernameEvent);

    if (!eventDetails) return null;

    const defaultImageEvent = require("../../../../assets/imgs/fundo_evento.png");
    const eventImage =
      eventDetails.eventImage !== "default_image.png"
        ? { uri: eventDetails.eventImage }
        : defaultImageEvent;

    const handlePressTicket = () => {
      if (userData.progressProfile < 2) {
        Alert.alert(
          "Cadastro Incompleto",
          "Para acessar este ingresso, você precisa completar seu cadastro.",
          [
            {
              text: "Cancelar",
              style: "cancel",
            },
            {
              text: "Completar Cadastro",
              onPress: () => navigation.navigate("EditProfile"),
            },
          ]
        );
        return;
      }

      navigation.navigate("TicketResult", {
        ticketId: item.idTicket,
        event: eventDetails,
      });
    };

    return (
      <TouchableOpacity
        style={styles.ticketCard}
        onPress={handlePressTicket}
      >
        <View style={styles.ticketCardRow}>
          <Image source={eventImage} style={styles.cardImage} />
          <View style={styles.cardDetails}>
            <Text style={styles.cardTitle}>{eventDetails.name}</Text>
            <View style={styles.cardInfo}>
              <MaterialCommunityIcons
                name="ticket-confirmation-outline"
                size={12}
                color="#FFFD"
              />
              <Text style={styles.cardText}> {item.lotName}</Text>
            </View>
            <View style={styles.cardInfo}>
              <MaterialIcons name="location-on" size={12} color="#FFFD" />
              <Text style={styles.cardText}> {eventDetails.address.city}</Text>
            </View>
            <View style={styles.cardInfo}>
              <MaterialCommunityIcons
                name="calendar-month-outline"
                size={12}
                color="#FFFD"
              />
              <Text style={styles.cardText}>
                {" "}
                {new Date(
                  eventDetails.dates.start.seconds * 1000
                ).toLocaleDateString("pt-BR")}
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const renderCard = (item) => {
    if (!item) return null;
    return (
      <Animated.View style={[styles.background, { opacity: fadeAnim }]}>
        <ListEventUCard
          evento={item}
          onPressCard={() => navigation.navigate("Ticket", { evento: item })}
          isFavorite={"Retirar"}
        />
      </Animated.View>
    );
  };

  const onRefresh = async () => {
    setRefreshing(true);
    setTicketPage(1);
    setFavoritePage(1);
    await subscribeToUserData(uid);
    setFrozeLoad(false);
    setFrozeLoadF(false);
    setRefreshing(false);
  };

  const loadMoreTickets = async () => {
    if (loadingTickets || !userData || frozeLoad) return;
    setLoadingTickets(true);
    const nextPage = ticketPage + 1;
    const newTickets = userData.ticketHistory
      ? userData.ticketHistory.slice(0, nextPage * PAGE_SIZE)
      : [];
    setTicketsData((prevTickets) => [
      ...prevTickets,
      ...newTickets.slice((nextPage - 1) * PAGE_SIZE, nextPage * PAGE_SIZE),
    ]);
    await fetchEventData(newTickets);
    setTicketPage(nextPage);
    if (userData.ticketHistory.length <= nextPage * PAGE_SIZE) {
      setFrozeLoad(true);
    }
    setLoadingTickets(false);
  };

  const loadMoreFavorites = async () => {
    if (loadingFavorites || !userData || frozeLoadF) return;
    setLoadingFavorites(true);
    const nextPage = favoritePage + 1;
    const newFavorites = userData.favoriteEvents
      ? userData.favoriteEvents.slice(0, nextPage * PAGE_SIZE)
      : [];
    const fetchedFavoriteEvents = await fetchFavoriteEvents(newFavorites);
    setFavoriteEvents((prevFavorites) => [
      ...prevFavorites,
      ...fetchedFavoriteEvents.slice(
        (nextPage - 1) * PAGE_SIZE,
        nextPage * PAGE_SIZE
      ),
    ]);
    setFavoritePage(nextPage);
    if (userData.favoriteEvents.length <= nextPage * PAGE_SIZE) {
      setFrozeLoadF(true);
    }
    setLoadingFavorites(false);
  };

  const loadTickets = async (user) => {
    if (loadingTickets || !user) return;
    setLoadingTickets(true);
    const allTickets = user.ticketHistory ? user.ticketHistory : [];
    if (allTickets.length <= PAGE_SIZE) {
      setFrozeLoad(true);
    }
    const sortedTickets = allTickets.sort(
      (a, b) => b.payDay.seconds - a.payDay.seconds
    );
    setTicketsData(sortedTickets.slice(0, PAGE_SIZE));
    await fetchEventData(sortedTickets.slice(0, PAGE_SIZE));
    setTicketPage(1);
    setLoadingTickets(false);
  };

  const loadFavorites = async (user) => {
    if (loadingFavorites || !user) return;
    setLoadingFavorites(true);
    const allFavorites = user.favoriteEvents ? user.favoriteEvents : [];
    if (allFavorites.length <= PAGE_SIZE) {
      setFrozeLoadF(true);
    }
    const sortedFavorites = allFavorites; // Assuming favorites don't need sorting
    setFavoriteEvents(
      await fetchFavoriteEvents(sortedFavorites.slice(0, PAGE_SIZE))
    );
    setFavoritePage(1);
    setLoadingFavorites(false);
  };

  return (
    <SafeAreaView style={styles.container}>
      <Header navigation={navigation} />
      <Tab.Navigator
        screenOptions={{
          tabBarStyle: { backgroundColor: "#000" },
          tabBarActiveTintColor: "#FFF",
          tabBarInactiveTintColor: "#A7A7A7",
          tabBarIndicatorStyle: {
            backgroundColor: "#FFF",
            height: 1,
            bottom: 10,
          },
          tabBarLabelStyle: {
            fontSize: 13,
            fontWeight: "bold",
            paddingBottom: 0,
          },
        }}
      >
        <Tab.Screen
          name="Meus Ingressos"
          children={() => (
            <View style={styles.container}>
              <FlatList
                data={ticketsData}
                renderItem={renderTicketCard}
                keyExtractor={(item) => item.idTicket.toString()}
                onEndReached={loadMoreTickets}
                onEndReachedThreshold={0.1}
                refreshControl={
                  <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                  />
                }
                ListEmptyComponent={
                  <Text style={styles.emptyText}>
                    Nenhum ingresso encontrado
                  </Text>
                }
              />
            </View>
          )}
        />
        <Tab.Screen
          name="Meus Favoritos"
          children={() => (
            <View style={styles.container}>
              <FlatList
                data={favoriteEvents}
                renderItem={({ item }) => renderCard(item)}
                keyExtractor={(item) => item.username.toString()}
                onEndReached={loadMoreFavorites}
                onEndReachedThreshold={0.1}
                refreshControl={
                  <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                  />
                }
                ListEmptyComponent={
                  <Text style={styles.emptyText}>
                    Nenhum evento favorito encontrado
                  </Text>
                }
              />
            </View>
          )}
        />
      </Tab.Navigator>
    </SafeAreaView>
  );
}
