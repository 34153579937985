import React, { useState, useEffect } from "react";
import {
  View,
  TextInput,
  Text,
  TouchableOpacity,
  ScrollView,
  Clipboard,
  Alert,
} from "react-native";
import IconMaterial from "react-native-vector-icons/MaterialCommunityIcons";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { styles } from "../../../../styles/producer/producerUtils/EditEventStyles";
import { getUserByUsername } from "../../../../services/cruds/users/getUser";

const generateEventKey = () => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let key = "";
  for (let i = 0; i < 20; i++) {
    key += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return key;
};

const normalizeUsername = (username) => {
  return username
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, "")
    .toLowerCase();
};

const ReaderForm = ({ data, onNestedInputChange, saveChanges }) => {
  const [username, setUsername] = useState("");
  const [eventKey, setEventKey] = useState(data.eventKey || generateEventKey());
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    if (!data.eventKey) {
      onNestedInputChange("eventKey", eventKey);
    }
  }, []);

  const handleCopyToClipboard = () => {
    Clipboard.setString(eventKey);
    Alert.alert(
      "Copiado",
      "Chave de acesso copiada para a área de transferência."
    );
  };

  const handleGenerateNewKey = () => {
    const newKey = generateEventKey();
    setEventKey(newKey);
    onNestedInputChange("eventKey", newKey);
  };

  const handleAddReader = async () => {
    if (username) {
      setIsVerifying(true);
      const normalizedUsername = normalizeUsername(username);
      try {
        const user = await getUserByUsername(normalizedUsername);
        if (!user) {
          Alert.alert(
            "Erro",
            "Username não existe. Insira um username válido."
          );
          setIsVerifying(false);
          return;
        }

        const readers = data.readers || [];
        if (!readers.includes(normalizedUsername)) {
          onNestedInputChange("readers", [...readers, normalizedUsername]);
          setUsername("");
        } else {
          Alert.alert("Erro", "Reader já está cadastrado.");
        }
        setIsVerifying(false);
      } catch (error) {
        console.error("Erro ao verificar o username do evento:", error);
        Alert.alert(
          "Erro",
          "Ocorreu um erro ao verificar o username. Por favor, tente novamente."
        );
        setIsVerifying(false);
      }
    } else {
      Alert.alert("Erro", "Por favor, insira um username.");
    }
  };

  const handleRemoveReader = (reader) => {
    const readers = data.readers || [];
    const updatedReaders = readers.filter((r) => r !== reader);
    onNestedInputChange("readers", updatedReaders);
  };

  const isPublished = data.status === "Publicado";

  return (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <MaterialCommunityIcons
          name="qrcode-scan"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Gerenciar Readers</Text>
      </View>
      <View style={styles.sectionDivider} />
      <ScrollView contentContainerStyle={styles.detailsContainer}>
        <Text style={styles.label}>Adicionar Reader</Text>
        <View style={styles.readerInputContainer}>
          <TextInput
            style={[styles.input, styles.readerInput]}
            placeholderTextColor="#FFF9"
            placeholder="Digite o username do reader"
            value={username}
            onChangeText={setUsername}
            editable={!isVerifying}
          />
          <TouchableOpacity
            onPress={handleAddReader}
            style={styles.saveButton}
            disabled={isVerifying}
          >
            {isVerifying ? (
              <Text style={[styles.addButtonText, { color: "#FFF" }]}>
                Verificando...
              </Text>
            ) : (
              <Text style={[styles.addButtonText, { color: "#FFF" }]}>
                Adicionar
              </Text>
            )}
          </TouchableOpacity>
        </View>

        {/* Lista de Readers */}
        <Text style={styles.label}>Readers Cadastrados</Text>
        {data.readers && data.readers.length > 0 ? (
          data.readers.map((reader, index) => (
            <View key={index} style={styles.readerItemContainer}>
              <Text style={styles.readerItemText}>{reader}</Text>
              <TouchableOpacity
                onPress={() => handleRemoveReader(reader)}
                style={styles.removeIcon}
              >
                <IconMaterial name="trash-can-outline" size={20} color="#FFF" />
              </TouchableOpacity>
            </View>
          ))
        ) : (
          <Text style={styles.noReadersText}>Nenhum reader cadastrado.</Text>
        )}

        {/* Chave de Acesso do Evento */}
        <Text style={styles.label}>Chave de Acesso do Evento</Text>
        <View style={styles.eventKeyInputContainer}>
          <TextInput
            style={[styles.input, styles.eventKeyInput]}
            placeholderTextColor="#FFF9"
            value={eventKey}
            editable={false}
          />
          <View style={styles.iconButtonContainer}>
            <TouchableOpacity
              onPress={handleCopyToClipboard}
              style={styles.iconButton}
            >
              <IconMaterial name="content-copy" size={20} color="#FFF9" />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={handleGenerateNewKey}
              style={styles.iconButton}
            >
              <IconMaterial name="refresh" size={20} color="#FFF9" />
            </TouchableOpacity>
          </View>
        </View>

        <TouchableOpacity onPress={saveChanges} style={styles.saveButton}>
          <Text style={styles.saveButtonText}>Salvar Alterações</Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );
};

export default ReaderForm;
