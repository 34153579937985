import React from "react";
import {
  View,
  Text,
  Modal,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import { styles } from "../../../../styles/ticket/ticketUtils/TicketResultStyles";
import Icon from "react-native-vector-icons/Ionicons";

const RefundModal = ({
  visible,
  refundReason,
  handleRefundReasonChange,
  toggleRefundModal,
  calculateRefund,
}) => (
  <Modal visible={visible} transparent={true} animationType="slide">
    <TouchableWithoutFeedback onPress={toggleRefundModal}>
      <View style={styles.modalContainer}>
        <TouchableWithoutFeedback>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Motivo do Reembolso</Text>
            <TextInput
              style={styles.refundInput}
              placeholder="Descreva o motivo do reembolso"
              placeholderTextColor="#666"
              multiline
              value={refundReason}
              onChangeText={handleRefundReasonChange}
            />
            <TouchableOpacity style={styles.refundSubmitButton} onPress={calculateRefund}>
              <Icon name="send" size={18} color="#FFF" />
              <Text style={styles.refundSubmitButtonText}>Enviar</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.buttonClose} onPress={toggleRefundModal}>
              <Text style={styles.buttonCloseText}>Cancelar</Text>
            </TouchableOpacity>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  </Modal>
);

export default RefundModal;
