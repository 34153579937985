import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  containerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#000",
  },
  detailsContainer: {
    paddingHorizontal: 10,
    backgroundColor: "#000",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  title: {
    color: "#999",
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 15,
  },
  label: {
    color: "#A0A0A0",
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 5,
  },
  input: {
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    borderRadius: 5,
    padding: 10,
    marginBottom: 15,
  },
  inputPrice: {
    flex: 0.2,
  },
  inputPriceSecond: {
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    padding: 5,
  },
  inputWrapper: {
    flex: 1,
    marginRight: 10,
  },
  inputWrapperSecond: {
    flex: 1,
  },
  priceInput: {
    flex: 1,
  },
  descriptionInput: {
    textAlign: "justify",
    textAlignVertical: "top",
    height: 'auto',
  },
  currencySymbol: {
    alignSelf: "center",
    backgroundColor: "#2B2B2B",
    color: "#FFF9",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    padding: 10,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputBlocked: {
    backgroundColor: "#121212",
    color: "#FFF",
    borderRadius: 5,
    padding: 10,
    marginBottom: 15,
  },
  switchRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  switchRowEnd: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  switch: {
    transform: [{ scaleX: 1.2 }, { scaleY: 1.2 }],
    trackColor: { false: "#767577", true: "#81b0ff" },
    thumbColor: "#052552",
    ios_backgroundColor: "#3e3e3e",
  },
  radioButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  radioButton: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  radioLabel: {
    color: "#FFF",
    marginLeft: 5,
  },
  inputLots: {
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    borderRadius: 5,
    padding: 5,
    paddingLeft: 10,
    marginBottom: 15,
  },
  lotContainer: {
    backgroundColor: "#141414",
    padding: 15,
    borderRadius: 5,
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  halfPriceLotContainer: {
    backgroundColor: "#222",
    marginTop: 10,
    paddingTop: 10,
  },
  lotHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  lotName: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
    flex: 1,
  },
  lotActions: {
    flexDirection: "row",
  },
  lotActionButton: {
    marginLeft: 10,
  },
  lotDetails: {
    color: "#A0A0A0",
    marginBottom: 5,
  },
  addLotButton: {
    backgroundColor: "#3B0E4E",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  datePickerButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#333",
    padding: 10,
    borderRadius: 5,
    marginBottom: 15,
  },
  datePickerButtonLarge: {
    width: "65%",
  },
  datePickerButtonSmall: {
    width: "30%",
  },
  buttonText: {
    color: "#E6E6E6",
    fontWeight: "bold",
  },
  buttonTextNext: {
    color: "#FFF",
    fontSize: 15,
    fontWeight: "bold",
  },
  buttonSubmit: {
    backgroundColor: "#1A2035",
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  buttonBack: {
    backgroundColor: "#121212",
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  calendarIcon: {
    marginLeft: 5,
  },
  addHalfPriceButton: {
    backgroundColor: "#202940",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  buttonImagePicker: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2B2B2B",
    padding: 15,
    borderRadius: 5,
    marginBottom: 5,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  cameraIcon: {
    marginRight: 10,
  },
  imageNote: {
    color: "#A0A0A0",
    fontSize: 14,
    textAlign: "center",
    marginBottom: 20,
  },
  imagePreviewContainer: {
    alignItems: "center",
  },
  imagePreview: {
    width: 240,
    height: 135,
    borderRadius: 10,
  },
  calendarIcon: {
    marginLeft: 5,
  },
  reviewRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputLabel: {
    color: "#A0A0A0",
    fontSize: 16,
  },
  inputValue: {
    color: "#FFF",
    fontSize: 16,
  },
  scrollView: {
    flex: 1,
    padding: 10,
    backgroundColor: "#000",
  },
  eventInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#000",
  },
  eventImage: {
    width: 60,
    height: 60,
    borderRadius: 15,
    marginRight: 20,
  },
  eventDetails: {
    flex: 1,
  },
  eventName: {
    fontSize: 18,
    color: "#FFF",
    fontWeight: "bold",
  },
  eventDescription: {
    fontSize: 14,
    color: "#AAA",
  },
  absorbFeeText: {
    color: "#A0A0A0",
    fontSize: 14,
    marginTop: -10,
    marginBottom: 15,
  },
  termsLink: {
    alignItems: "center",
    marginBottom: 15,
  },
  termsText: {
    color: "#999",
    textDecorationLine: "underline",
  },
  sectionDivider: {
    flex: 1,
    height: 1,
    backgroundColor: "#333",
    marginVertical: 10,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    width: "80%",
    backgroundColor: "#1A1A1A",
    padding: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 5,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFF",
    marginBottom: 20,
    textAlign: "center",
  },
  modalOptionButton: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: "#333",
    alignItems: "center",
    marginBottom: 10,
  },
  modalSelectedOptionButton: {
    backgroundColor: "#5B2372",
    borderColor: "#5B2372",
  },
  modalOptionButtonText: {
    color: "#FFF",
    fontSize: 16,
  },
  modalSelectedOptionButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  modalButton: {
    backgroundColor: "#5B2372",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginBottom: 10,
  },
  modalButtonCancel: {
    backgroundColor: "#1A2035",
  },
  modalButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  settingItemForm: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000",
  },
  settingIconForm: {
    color: "#FFFD",
    marginRight: 5,
    width: 24,
  },
  screenTitle: {
    color: "#FFFD",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
});
