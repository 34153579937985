import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  ticketCard: {
    backgroundColor: "#000",
    borderRadius: 8,
    overflow: "hidden",
    margin: 10,
  },
  ticketCardRow: {
    flexDirection: "row",
  },
  cardImage: {
    width: 160,
    height: 90,
    borderRadius: 8,
  },
  cardDetails: {
    flex: 1,
    paddingHorizontal: 10,
    justifyContent: "center",
  },
  cardTitle: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  cardInfo: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 2,
  },
  cardText: {
    color: "#FFFD",
    fontSize: 12,
    marginLeft: 5,
  },
  ratingContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#000",
    borderRadius: 8,
  },
  ratingText: {
    color: "white",
    fontSize: 14,
    marginBottom: 5,
  },
  rating: {
    marginLeft: 10,
  },
  background: {
    backgroundColor: "#000",
  },
  containerHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 15,
    backgroundColor: "#000",
  },
  backButtonHeader: {
    padding: 5,
  },
  logoHeader: {
    height: 40,
  },
  publishButton: {
    backgroundColor: "#5B2372",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 4,
    alignSelf: "center",
    marginVertical: 10,
  },
  publishButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  settingItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
    paddingHorizontal: 15,
    backgroundColor: "#1C1C1E",
    borderRadius: 10,
    marginVertical: 10,
    marginHorizontal: 15,
  },
  settingIcon: {
    marginRight: 10,
  },
  settingTextContainer: {
    flex: 1,
  },
  settingTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFF",
  },
  settingSubtitle: {
    fontSize: 14,
    color: "#A9A9A9",
  },
  settingArrow: {
    marginLeft: 10,
  },
  filterContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  filterButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 4,
    backgroundColor: "#333",
  },
  filterButtonActive: {
    backgroundColor: "#5B2372",
  },
  filterButtonText: {
    color: "#FFF",
    fontSize: 14,
    fontWeight: "bold",
  },
  emptyText: {
    color: "#fff9",
    fontSize: 16,
    textAlign: "center",
    marginTop: 20,
  },
});
