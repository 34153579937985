import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const addUserSuggestion = async (userId, suggestion) => {
  try {
    await addDoc(collection(db, "userSuggestions"), {
      uid: userId,
      suggestion,
      timestamp: Timestamp.now(),
    });
  } catch (error) {
    console.error("Erro ao enviar sugestão: ", error);
    throw error;
  }
};
