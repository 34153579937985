import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Alert,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { getReviews } from "../../../services/cruds/userReviews/getUserReviews";
import { updateReview } from "../../../services/cruds/users/updateUser";

const ReviewSection = ({ username, userId, isCurrentUser, currentUser }) => {
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState("");
  const [newRating, setNewRating] = useState(0);
  const [showReviewInput, setShowReviewInput] = useState(false);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    const data = await getReviews(username);
    setReviews(data);
  };

  const handleAddReview = async () => {
    if (newRating === 0 || newReview.trim() === "") {
      Alert.alert("Erro", "Por favor, preencha todos os campos.");
      return;
    }

    const hasReviewed = reviews.some((review) => review.reviewer === currentUser);
    if (hasReviewed) {
      Alert.alert("Erro", "Você já avaliou este usuário.");
      return;
    }

    const reviewData = {
      reviewer: currentUser,
      rating: newRating,
      comment: newReview,
      date: new Date(),
    };

    setNewReview("");
    setNewRating(0);
    setShowReviewInput(false);
    fetchReviews();
    try {
      await updateReview(userId, reviewData);
    } catch (error) {
      console.error("Erro ao atualizar usuário: ", error);
      Alert.alert("Erro ao atualizar usuário", error.message);
    }
  };

  const renderStars = (rating) => {
    const filledStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - filledStars - (halfStar ? 1 : 0);

    return (
      <View style={styles.starsContainer}>
        {Array(filledStars)
          .fill(0)
          .map((_, index) => (
            <MaterialCommunityIcons key={index} name="star" size={20} color="#FFF" />
          ))}
        {halfStar && <MaterialCommunityIcons name="star-half-full" size={20} color="#FFF" />}
        {Array(emptyStars)
          .fill(0)
          .map((_, index) => (
            <MaterialCommunityIcons key={index} name="star-outline" size={20} color="#FFF" />
          ))}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {currentUser && (currentUser !== userId) && (
        <TouchableOpacity
          style={styles.addReviewButton}
          onPress={() => setShowReviewInput(!showReviewInput)}
        >
          <Text style={styles.addReviewText}>Avaliar Usuário</Text>
        </TouchableOpacity>
      )}
      {showReviewInput && (
        <View style={styles.reviewInputContainer}>
          <TextInput
            style={styles.textInput}
            placeholder="Escreva sua avaliação"
            placeholderTextColor="#888"
            value={newReview}
            onChangeText={(text) => setNewReview(text)}
          />
          <View style={styles.starsContainer}>
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => setNewRating(index + 1)}
                >
                  <MaterialCommunityIcons
                    name={index < newRating ? "star" : "star-outline"}
                    size={30}
                    color="#FFF"
                  />
                </TouchableOpacity>
              ))}
          </View>
          <TouchableOpacity style={styles.submitButton} onPress={handleAddReview}>
            <Text style={styles.submitButtonText}>Enviar Avaliação</Text>
          </TouchableOpacity>
        </View>
      )}
      <Text style={styles.reviewsTitle}>Últimas Avaliações</Text>
      <FlatList
        data={reviews}
        renderItem={({ item }) => (
          <View style={styles.reviewItem}>
            <Text style={styles.reviewText}>{item.comment}</Text>
            <View style={styles.reviewStars}>{renderStars(item.rating)}</View>
            <Text style={styles.reviewDate}>
              {new Date(item.date.seconds * 1000).toLocaleDateString("pt-BR")}
            </Text>
          </View>
        )}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  addReviewButton: {
    backgroundColor: "#1A2035",
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
    alignItems: "center",
  },
  addReviewText: {
    color: "#FFF",
    fontSize: 16,
  },
  reviewInputContainer: {
    marginBottom: 20,
    padding: 10,
    backgroundColor: "#1A1A1A",
    borderRadius: 5,
  },
  textInput: {
    borderColor: "#333",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    backgroundColor: "#2A2A2A",
    color: "#FFF",
  },
  starsContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  submitButton: {
    backgroundColor: "#1A2035",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
  },
  submitButtonText: {
    color: "#FFF",
    fontSize: 16,
  },
  reviewsTitle: {
    fontSize: 18,
    color: "#FFF",
    fontWeight: "bold",
    marginBottom: 10,
    marginHorizontal: 10,
  },
  reviewItem: {
    backgroundColor: "#FFF1",
    padding: 15,
    borderRadius: 5,
    marginBottom: 10,
    marginHorizontal: 10,
  },
  reviewText: {
    fontSize: 16,
    color: "#FFF",
    marginBottom: 5,
  },
  reviewStars: {
    flexDirection: "row",
  },
  reviewDate: {
    position: "absolute",
    fontSize: 12,
    right: 15,
    bottom: 10,
    color: "#888",
  },
});

export default ReviewSection;
