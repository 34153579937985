import React from "react";
import { View, TextInput, Text, TouchableOpacity, Alert } from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { styles } from "../../../../styles/producer/producerUtils/EditEventStyles";

const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const formatPhone = (phone) => {
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phone;
};

const ContactsForm = ({ data, onNestedInputChange, saveChanges }) => {
  const organizer = data.organizer || {};
  const contact = organizer.contact || {};
  const socialMedia = organizer.socialMedia || {};

  const handleSaveChanges = () => {
    const { email, phone } = contact;
    if (!validateEmail(email)) {
      Alert.alert("Erro", "Insira um e-mail válido.");
      return;
    }

    if (!phone.match(/^\(\d{2}\) \d{5}-\d{4}$/)) {
      Alert.alert(
        "Erro",
        "Insira um número de telefone válido no formato (11) 91234-5678."
      );
      return;
    }

    saveChanges();
  };

  return (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <MaterialCommunityIcons
          name="phone-log"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Editar Contatos</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        <Text style={styles.label}>E-mail</Text>
        <TextInput
          style={styles.input}
          placeholderTextColor="#FFF9"
          placeholder="exemplo@dominio.com"
          keyboardType="email-address"
          value={contact.email || ""}
          onChangeText={(text) =>
            onNestedInputChange("organizer.contact.email", text)
          }
        />
        <Text style={styles.label}>Telefone</Text>
        <TextInput
          style={styles.input}
          placeholderTextColor="#FFF9"
          placeholder="(11) 91234-5678"
          keyboardType="phone-pad"
          value={formatPhone(contact.phone || "")}
          onChangeText={(text) =>
            onNestedInputChange("organizer.contact.phone", formatPhone(text))
          }
        />

        <Text style={styles.label}>WhatsApp</Text>
        <View style={styles.socialMediaContainer}>
          <Text style={styles.linkPreview}>https://wa.me/</Text>
          <TextInput
            style={styles.socialMediaInput}
            placeholder="5511999999999"
            placeholderTextColor="#FFF9"
            value={socialMedia.whatsapp || ""}
            onChangeText={(text) =>
              onNestedInputChange("organizer.socialMedia.whatsapp", text)
            }
          />
        </View>

        <Text style={styles.label}>Instagram</Text>
        <View style={styles.socialMediaContainer}>
          <Text style={styles.linkPreview}>https://instagram.com/</Text>
          <TextInput
            style={styles.socialMediaInput}
            placeholder="seuusuario"
            placeholderTextColor="#FFF9"
            value={socialMedia.instagram || ""}
            onChangeText={(text) =>
              onNestedInputChange("organizer.socialMedia.instagram", text)
            }
          />
        </View>

        <Text style={styles.label}>Facebook</Text>
        <View style={styles.socialMediaContainer}>
          <Text style={styles.linkPreview}>https://facebook.com/</Text>
          <TextInput
            style={styles.socialMediaInput}
            placeholder="seuusuario"
            placeholderTextColor="#FFF9"
            value={socialMedia.facebook || ""}
            onChangeText={(text) =>
              onNestedInputChange("organizer.socialMedia.facebook", text)
            }
          />
        </View>

        <Text style={styles.label}>Telegram</Text>
        <View style={styles.socialMediaContainer}>
          <Text style={styles.linkPreview}>https://t.me/</Text>
          <TextInput
            style={styles.socialMediaInput}
            placeholder="seuusuario"
            placeholderTextColor="#FFF9"
            value={socialMedia.telegram || ""}
            onChangeText={(text) =>
              onNestedInputChange("organizer.socialMedia.telegram", text)
            }
          />
        </View>

        <Text style={styles.label}>Reddit</Text>
        <View style={styles.socialMediaContainer}>
          <Text style={styles.linkPreview}>https://reddit.com/user/</Text>
          <TextInput
            style={styles.socialMediaInput}
            placeholder="seuusuario"
            placeholderTextColor="#FFF9"
            value={socialMedia.reddit || ""}
            onChangeText={(text) =>
              onNestedInputChange("organizer.socialMedia.reddit", text)
            }
          />
        </View>

        <Text style={styles.label}>Outros</Text>
        <TextInput
          style={styles.input}
          placeholderTextColor="#FFF9"
          placeholder="https://outrosocial.com/seuusuario"
          value={socialMedia.other || ""}
          onChangeText={(text) =>
            onNestedInputChange("organizer.socialMedia.other", text)
          }
        />

        <TouchableOpacity onPress={handleSaveChanges} style={styles.saveButton}>
          <Text style={styles.saveButtonText}>Salvar Alterações</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ContactsForm;
