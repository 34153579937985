import React, { useEffect } from "react";
import {
  View,
  StatusBar,
  Image,
  Animated,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { styles } from "../../../styles/utils/beginning/SplashScreenStyles";
import Constants from "expo-constants";

const SplashScreen = () => {
  const navigation = useNavigation();
  const logoScale = new Animated.Value(0);
  const titleOpacity = new Animated.Value(0);
  const versionOpacity = new Animated.Value(0);

  useEffect(() => {
    Animated.sequence([
      Animated.timing(logoScale, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }),
      Animated.timing(titleOpacity, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }),
      Animated.timing(versionOpacity, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start();

    setTimeout(() => {
      navigation.replace("HomeTabs");
    }, 3000);
  }, [logoScale, titleOpacity, versionOpacity, navigation]);

  return (
    <View style={styles.container}>
      <StatusBar barStyle="light-content" />
      <Animated.View
        style={[styles.logoContainer, { transform: [{ scale: logoScale }] }]}
      >
        <Image
          source={require("../../../../assets/imgs/Logo_Macaco2.png")}
          style={styles.logo}
        />
      </Animated.View>
      <Animated.View style={[{ opacity: titleOpacity }]}>
        <Image
          source={require("../../../../assets/imgs/Logo_Texto3.png")}
          style={styles.text}
        />
      </Animated.View>
      <Animated.Text style={[styles.versionText, { opacity: versionOpacity }]}>
        Versão {Constants.expoConfig.version}
      </Animated.Text>
    </View>
  );
};

export default SplashScreen;
