import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  ScrollView,
  TouchableOpacity,
  SafeAreaView,
  Linking,
  Alert,
  Share,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import Loading from "../../../components/utils/Loading";
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";
import { auth } from "../../../services/utils/firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { getUser } from "../../../services/cruds/users/getUser";
import { getEvent } from "../../../services/cruds/events/getEvent";
import { updateUser } from "../../../services/cruds/users/updateUser";
import { createReport } from "../../../services/cruds/eventsReports/createEventsReports";
import { checkExistingEventReport } from "../../../services/cruds/eventsReports/getEventReport";
import { getEventReportCount } from "../../../services/cruds/eventsReports/getEventReport";
import moment from "moment-timezone";
import "moment/locale/pt-br";

import Header from "../../../components/ticket/ticketView/Header";
import BuyersList from "../../../components/ticket/ticketView/BuyersList";
import EventDetailsSection from "../../../components/ticket/ticketView/EventDetailsSection";
import OrganizationSection from "../../../components/ticket/ticketView/OrganizationSection";
import LotSelection from "../../../components/ticket/ticketView/LotSelection";
import PastLots from "../../../components/ticket/ticketView/PastLots";
import FeesSection from "../../../components/ticket/ticketView/FeesSection";
import StickerSection from "../../../components/ticket/ticketView/StickerSection";
import ReportModal from "../../../components/ticket/ticketView/ReportModal";
import ButtonNavigator from "../../../components/ticket/ticketView/ButtonNavigator";
import BuyButton from "../../../components/ticket/ticketView/BuyButton";

export default function Ticket({ route, navigation }) {
  const [isLoading, setIsLoading] = useState(true);
  const [evento, setEvento] = useState(null);
  const [uid, setUid] = useState("");
  const [selectedLots, setSelectedLots] = useState({});
  const [userDataProducer, setUserDataProducer] = useState("");
  const [fullName, setFullName] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteEvents, setFavoriteEvents] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [feesModalVisible, setFeesModalVisible] = useState(false);
  const [expandPastLots, setExpandPastLots] = useState(false);
  const [animationActive, setAnimationActive] = useState(false);
  const [user, setUser] = useState(null); // Adicionei essa linha

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const defaultImageEvent = require("../../../../assets/imgs/fundo_evento.png");
  const eventImage =
    evento && evento.eventImage !== "default_image.png"
      ? { uri: evento.eventImage }
      : defaultImageEvent;

  useEffect(() => {
    if (route.params?.evento?.username) {
      fetchEvent(route.params.evento.username);
    }

    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUid(user.uid);
        setUser(user); // Definindo o usuário atual
        await getUserData(user.uid);
      } else {
        await sleep(500);
        setIsLoading(false);
      }
    });

    return () => {
      if (typeof unsubscribeAuth === "function") {
        unsubscribeAuth();
      }
    };
  }, [route.params?.evento]);

  const fetchEvent = async (username) => {
    try {
      const eventData = await getEvent(username);
      setEvento(eventData);

      await getUserDataProducer(eventData.organizer?.organizationId);
    } catch (error) {
      console.error("Erro ao carregar os dados do evento:", error);
    }
  };

  const getUserData = async (uid) => {
    try {
      const userData = await getUser(uid);
      setIsFavorite(
        userData.favoriteEvents.includes(route.params?.evento?.username)
      );
      setFavoriteEvents(userData.favoriteEvents);
      await sleep(500);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao carregar os dados do usuário:", error);
    }
  };

  const getUserDataProducer = async (organizerId) => {
    if (organizerId) {
      const userDataProducer = await getUser(organizerId);
      setUserDataProducer(userDataProducer);
      setFullName(userDataProducer.fullName);
      setAvatarUrl(userDataProducer.avatarUrl);
    }
  };

  const sendDates = async () => {
    if (!uid) {
      navigation.navigate("Login");
      return;
    }
    const currentDate = moment();
    const endDate = evento?.dates?.end?.toDate
      ? moment(evento.dates.end.toDate())
      : moment(evento.dates.end);
    if (currentDate.isAfter(endDate)) {
      Alert.alert("Evento Finalizado", "Este evento já foi finalizado.");
      return;
    }
    try {
      const reportCount = await getEventReportCount(evento.username);
      if (reportCount > 20) {
        Alert.alert(
          "Compras Paralisadas",
          "As compras para este evento estão paralisadas pois o evento está em análise."
        );
        return;
      }
    } catch (error) {
      console.error("Erro ao verificar a contagem de denúncias:", error);
      Alert.alert(
        "Erro",
        "Houve um problema ao verificar a contagem de denúncias. Tente novamente mais tarde."
      );
      return;
    }

    if (evento.organizer?.organizationId === uid) {
      Alert.alert("Função Bloqueada", "Não é possível acessar.");
      return;
    }

    const items = Object.entries(selectedLots).map(([name, details]) => ({
      title: name,
      quantity: details.quantity,
      unit_price: details.unitPrice,
      absorbFee: evento.absorbFee,
    }));

    navigation.navigate("Payment", {
      items: items,
      uid: uid,
      evento: evento,
    });
  };

  const removeDates = () => {
    setSelectedLots({});
  };

  const handleFavoritePress = async () => {
    if (uid === evento.organizer?.organizationId || uid === "") {
      Alert.alert("Sem acesso", "Você não tem acesso a essa funcionalidade.");
      return;
    }

    try {
      const updatedFavorites = isFavorite
        ? favoriteEvents.filter(
            (username) => username !== evento.username
          )
        : [...favoriteEvents, evento.username];

      setFavoriteEvents(updatedFavorites);
      setIsFavorite(!isFavorite);

      await updateUser(uid, {
        favoriteEvents: updatedFavorites,
      });
    } catch (error) {
      console.error("Erro ao atualizar favoritos: ", error);
      Alert.alert("Erro", "Não é possível atualizar seus favoritos.");
    }
  };

  const handleSelectLot = (lote, increment) => {
    const availableQuantity = Number(lote.quantity) - Number(lote.sold);
    setSelectedLots((prevSelectedLots) => {
      const currentEntry = prevSelectedLots[lote.name] || {
        quantity: 0,
        unitPrice: lote.price,
      };

      const totalQuantity = Object.values(prevSelectedLots).reduce(
        (total, { quantity }) => total + quantity,
        0
      );

      const newQuantity = increment
        ? Math.min(currentEntry.quantity + 1, availableQuantity)
        : Math.max(currentEntry.quantity - 1, 0);

      if (totalQuantity >= 5 && increment) {
        Alert.alert("Limite Excedido", "Você só pode comprar até 5 ingressos.");
        return prevSelectedLots;
      }

      if (increment && newQuantity > 0) {
        setAnimationActive(true);
      } else {
        setAnimationActive(false);
      }

      return {
        ...prevSelectedLots,
        [lote.name]: { ...currentEntry, quantity: newQuantity },
      };
    });
  };

  const getTotal = () => {
    return Object.entries(selectedLots).reduce(
      (total, [name, { quantity, unitPrice }]) => total + unitPrice * quantity,
      0
    );
  };

  const abrirMapa = () => {
    const address = encodeURIComponent(
      `${evento.address.street}, ${evento.address.number}, ${evento.address.city}, ${evento.address.state}`
    );
    const url = `https://www.google.com/maps/search/?api=1&query=${address}`;
    Linking.openURL(url);
  };

  const abrirInstagram = () => {
    Linking.openURL(
      `https://instagram.com/${evento.organizer.socialMedia.instagram}`
    );
  };

  const compartilharEvento = async () => {
    console.log("Botão Compartilhar Perfil clicado");
    try {
      const result = await Share.share({
        message: `Confira o perfil do evento ${evento.username} em rayv.com.br/${evento.username}`,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          console.log("Compartilhado via", result.activityType);
        } else {
          console.log("Perfil compartilhado");
        }
      } else if (result.action === Share.dismissedAction) {
        console.log("Compartilhamento cancelado");
      }
    } catch (error) {
      Alert.alert("Erro ao compartilhar", error.message);
    }
  };

  const handleReport = async (reportReason) => {
    try {
      const user = auth.currentUser;

      if (user) {
        const existingReport = await checkExistingEventReport(
          evento.name,
          user.uid
        );

        if (existingReport) {
          Alert.alert("Erro", "Você já denunciou este evento.");
          return;
        }
        console.log(user.uid);
        console.log(evento.name);
        console.log(reportReason);
        const reportData = {
          reportingUserId: user.uid,
          eventName: evento.name,
          eventUsername: evento.username,
          message: reportReason,
          timestamp: new Date(),
        };

        const reportSuccess = await createReport(reportData);

        if (reportSuccess) {
          Alert.alert(
            "Denúncia Enviada",
            "Sua denúncia foi enviada com sucesso."
          );
          setReportModalVisible(false);
        } else {
          Alert.alert(
            "Erro",
            "Houve um erro ao enviar sua denúncia. Tente novamente mais tarde."
          );
        }
      } else {
        Alert.alert(
          "Erro",
          "Você precisa estar logado para enviar uma denúncia."
        );
      }
    } catch (error) {
      console.error("Erro ao enviar denúncia: ", error);
      Alert.alert(
        "Erro",
        error.message ||
          "Houve um erro ao enviar sua denúncia. Tente novamente mais tarde."
      );
    }
  };

  const renderHeader = () => (
    <View style={styles.headerContainer}>
      <View style={styles.titleAndSubtitleContainer}>
        <Text style={styles.titleText}>{evento?.name}</Text>
        <View style={styles.infoContainer}>
          <MaterialCommunityIcons
            name="card-account-details"
            size={13}
            color="#999"
            style={styles.infoIcon}
          />
          <Text style={styles.subtitleText}>{evento?.username}</Text>
        </View>
      </View>
      <View
        style={
          evento?.organizer?.socialMedia?.instagram
            ? styles.iconsContainer
            : styles.iconsContainerSecond
        }
      >
        <TouchableOpacity onPress={handleFavoritePress}>
          <Icon
            name={isFavorite ? "heart" : "heart-outline"}
            size={24}
            color="#FFFD"
          />
        </TouchableOpacity>
        {evento?.organizer?.socialMedia?.instagram && (
          <TouchableOpacity onPress={abrirInstagram}>
            <Icon name="logo-instagram" size={24} color="#FFFD" />
          </TouchableOpacity>
        )}
        <TouchableOpacity onPress={compartilharEvento}>
          <Icon name="share-social-outline" size={24} color="#FFFD" />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setReportModalVisible(true)}>
          <Icon name="alert-circle-outline" size={24} color="#FFFD" />
        </TouchableOpacity>
      </View>
    </View>
  );

  if (isLoading) {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#000" }}>
        <Header navigation={navigation} />
        <Loading />
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <Header navigation={navigation} />
        <Image source={eventImage} style={styles.eventImage} />

        {renderHeader()}
        <BuyersList evento={evento} uid={uid} navigation={navigation}/> 
        <EventDetailsSection evento={evento} abrirMapa={abrirMapa} />

        <OrganizationSection userData={userDataProducer} uid={uid} fullName={fullName} avatarUrl={avatarUrl} navigation={navigation} />

        <LotSelection evento={evento} selectedLots={selectedLots} setSelectedLots={setSelectedLots} handleSelectLot={handleSelectLot} setAnimationActive={setAnimationActive} />
        <PastLots evento={evento} expandPastLots={expandPastLots} setExpandPastLots={setExpandPastLots} />

        {!evento?.absorbFee && <FeesSection evento={evento} feesModalVisible={feesModalVisible} setFeesModalVisible={setFeesModalVisible} />}

        <StickerSection evento={evento} modalVisible={modalVisible} setModalVisible={setModalVisible} />

        <ButtonNavigator evento={evento} abrirMapa={abrirMapa} />
      </ScrollView>

      <BuyButton selectedLots={selectedLots} evento={evento} getTotal={getTotal} sendDates={sendDates} removeDates={removeDates} />

      <ReportModal reportModalVisible={reportModalVisible} setReportModalVisible={setReportModalVisible} handleReport={handleReport} />
    </SafeAreaView>
  );
}
