import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import QRCode from "react-native-qrcode-svg";
import Icon from "react-native-vector-icons/Ionicons";
import { styles } from "../../../../styles/ticket/ticketUtils/TicketResultStyles";

const QRCodeSection = ({ QR, qrCodeRef, toggleQrCodeModal }) => (
  <>
    <View style={styles.sectionHeader}>
      <Icon name="qr-code-outline" size={22} color="#FFF" style={styles.sectionIcon} />
      <Text style={styles.sectionTitle}>QR Code</Text>
    </View>
    <View style={styles.containerSpace}>
      <View style={styles.sectionContainerSecond}>
        <View style={styles.content}>
          <TouchableOpacity style={styles.qrCodeContainer} onPress={toggleQrCodeModal}>
            <QRCode value={QR} size={200} backgroundColor="#000" color="#FFF" getRef={qrCodeRef} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  </>
);

export default QRCodeSection;
