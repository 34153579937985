import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Image,
  SafeAreaView,
  ActivityIndicator,
  Alert,
  RefreshControl,
} from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import {
  unfollowUser,
  approveFollowRequest,
  declineFollowRequest,
  removeFollower,
} from "../../../services/cruds/users/updateUser";
import { getUserEssential } from "../../../services/cruds/users/getUser";
import { getEventEssential } from "../../../services/cruds/events/getEvent";
import { styles } from "../../../styles/profile/profileUtils/ProfileDetailsStyles";
import Icon from "react-native-vector-icons/Ionicons";
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";
import ReviewSection from "../../../components/profile/profileUtils/ReviewSection";

const Tab = createMaterialTopTabNavigator();

const defaultImageEvent = require("../../../../assets/imgs/Avatar_Macaco.png");
const defaultHiddenImage = require("../../../../assets/imgs/fundo_evento_quadrado.png");

const UserItem = ({
  user,
  userId,
  isVisited,
  currentUser,
  accountType,
  handleRemoveFollower,
  handleUnfollow,
  navigation,
}) => {
  const confirmAction = (message, action) => {
    Alert.alert("Confirmação", message, [
      { text: "Cancelar", style: "cancel" },
      { text: "Sim", onPress: action },
    ]);
  };

  return (
    <TouchableOpacity
      onPress={() => {
        (accountType === "Producer" && (currentUser === userId)) ? null : (navigation.navigate("VisitProfile", { usuario: user, currentUser: currentUser }));
      }}
    >
      <View style={styles.itemContainer}>
        <Image
          source={
            user.avatarUrl !== "" ? { uri: user.avatarUrl } : defaultImageEvent
          }
          style={styles.avatar}
        />
        <View style={styles.userInfo}>
          <Text style={styles.itemText}>{user.fullName}</Text>
          <Text style={styles.itemSubText}>@{user.username}</Text>
        </View>
        {!isVisited && (
          <TouchableOpacity
            style={styles.button}
            onPress={() =>
              handleRemoveFollower
                ? confirmAction(
                    "Deseja realmente remover este seguidor?",
                    async () => await handleRemoveFollower(userId, user.userId)
                  )
                : confirmAction(
                    "Deseja realmente deixar de seguir este usuário?",
                    async () => await handleUnfollow(userId, user.userId)
                  )
            }
          >
            <Text style={styles.buttonText}>
              {handleRemoveFollower ? "Remover" : "Deixar de Seguir"}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </TouchableOpacity>
  );
};

const PendingRequestItem = ({ user, onApprove, onDecline, navigation, userId }) => (
  <TouchableOpacity
    onPress={() => {
      navigation.navigate("VisitProfile", { usuario: user, currentUser: userId });
    }}
  >
    <View style={[styles.itemContainer, styles.pendingContainer]}>
      <Image source={{ uri: user.avatarUrl }} style={styles.avatar} />
      <View style={styles.userInfo}>
        <Text style={styles.itemText}>{user.fullName}</Text>
        <Text style={styles.itemSubText}>@{user.username}</Text>
      </View>
      <View style={styles.buttonsContainer}>
        <TouchableOpacity style={styles.buttonApprove} onPress={onApprove}>
          <Text style={styles.buttonText}>Aprovar</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttonDecline} onPress={onDecline}>
          <Text style={styles.buttonText}>Recusar</Text>
        </TouchableOpacity>
      </View>
    </View>
  </TouchableOpacity>
);

const FollowersTab = ({
  followers,
  pendingFollowers,
  userId,
  accountType,
  isVisited,
  isPublic,
  navigation,
  currentUser,
}) => {
  const [followersData, setFollowersData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [showPending, setShowPending] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState(0);

  useEffect(() => {
    fetchFollowersData();
  }, [followers]);

  useEffect(() => {
    fetchPendingData();
  }, [pendingFollowers]);

  const fetchFollowersData = async (loadMore = false) => {
    const start = loadMore ? lastVisible : 0;
    const end = start + 10;
    if (!loadMore) setLoading(true);
    const data = await Promise.all(
      followers.slice(start, end).map((id) => getUserEssential(id))
    );
    setFollowersData((prevData) => {
      const newData = loadMore
        ? [...prevData, ...data.filter((user) => user !== null)]
        : data.filter((user) => user !== null);
      const uniqueData = Array.from(
        new Set(newData.map((user) => user.userId))
      ).map((id) => {
        return newData.find((user) => user.userId === id);
      });
      return uniqueData;
    });
    setLastVisible(end);
    setLoading(false);
  };

  const fetchPendingData = async () => {
    const data = await Promise.all(
      pendingFollowers.map((id) => getUserEssential(id))
    );
    setPendingData(data.filter((user) => user !== null));
  };

  const handleApprove = async (pendingUserId) => {
    setPendingData(pendingData.filter((user) => user.userId !== pendingUserId));
    setFollowersData((prevData) => [
      ...prevData,
      pendingData.find((user) => user.userId === pendingUserId),
    ]);
    await approveFollowRequest(userId, pendingUserId);
    Alert.alert(
      "Solicitação Aprovada",
      "Você aprovou a solicitação de seguidor."
    );
  };

  const handleDecline = async (pendingUserId) => {
    setPendingData(pendingData.filter((user) => user.userId !== pendingUserId));
    await declineFollowRequest(userId, pendingUserId);
    Alert.alert(
      "Solicitação Recusada",
      "Você recusou a solicitação de seguidor."
    );
  };

  const handleLoadMore = () => {
    if (!loadingMore && lastVisible < followers.length) {
      setLoadingMore(true);
      fetchFollowersData(true).finally(() => setLoadingMore(false));
    }
  };

  const onRefresh = () => {
    setRefreshing(true);
    setLastVisible(0); // Reset lastVisible to reload from the start
    fetchFollowersData().then(() => setRefreshing(false));
    fetchPendingData().then(() => setRefreshing(false));
  };

  return (
    <View style={styles.container}>
      {!isVisited && userId && accountType == "User" && !isPublic && (
        <TouchableOpacity
          onPress={() => setShowPending(!showPending)}
          style={styles.toggleButton}
        >
          <Icon
            name={showPending ? "eye-off-outline" : "eye-outline"}
            size={20}
            color="#FFF9"
          />
          <Text style={styles.toggleButtonText}>
            {showPending
              ? "Esconder Solicitações Pendentes"
              : "Mostrar Solicitações Pendentes"}
          </Text>
        </TouchableOpacity>
      )}
      {showPending && (
        <FlatList
          data={pendingData}
          renderItem={({ item }) => (
            <PendingRequestItem
              user={item}
              onApprove={() => handleApprove(item.userId)}
              onDecline={() => handleDecline(item.userId)}
              navigation={navigation}
              userId={userId}
            />
          )}
          keyExtractor={(item) => item.userId}
          ListEmptyComponent={
            <View style={styles.emptyContainer}>
              <Text style={styles.emptyText}>Nenhuma solicitação pendente</Text>
            </View>
          }
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        />
      )}
      <FlatList
        data={followersData}
        renderItem={({ item }) => (
          <UserItem
            user={item}
            userId={userId}
            isVisited={isVisited}
            currentUser={currentUser}
            accountType={accountType}
            handleRemoveFollower={async (userId, followerId) => {
              setFollowersData(
                followersData.filter((user) => user.userId !== followerId)
              );
              await removeFollower(userId, followerId);
            }}
            navigation={navigation}
          />
        )}
        keyExtractor={(item) => item.userId}
        onEndReached={handleLoadMore}
        onEndReachedThreshold={0.1}
        ListFooterComponent={
          loadingMore && <ActivityIndicator size="small" color="#FFF9" />
        }
        ListEmptyComponent={
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>Nenhum seguidor encontrado</Text>
          </View>
        }
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      />
    </View>
  );
};

const FollowingTab = ({ following, userId, isVisited, navigation, currentUser, accountType }) => {
  const [followingData, setFollowingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState(0);

  useEffect(() => {
    fetchFollowingData();
  }, [following]);

  const fetchFollowingData = async (loadMore = false) => {
    const start = loadMore ? lastVisible : 0;
    const end = start + 10;
    if (!loadMore) setLoading(true);
    const data = await Promise.all(
      following.slice(start, end).map((id) => getUserEssential(id))
    );
    setFollowingData((prevData) => {
      const newData = loadMore
        ? [...prevData, ...data.filter((user) => user !== null)]
        : data.filter((user) => user !== null);
      const uniqueData = Array.from(
        new Set(newData.map((user) => user.userId))
      ).map((id) => {
        return newData.find((user) => user.userId === id);
      });
      return uniqueData;
    });
    setLastVisible(end);
    setLoading(false);
  };

  const handleLoadMore = () => {
    if (!loadingMore && lastVisible < following.length) {
      setLoadingMore(true);
      fetchFollowingData(true).finally(() => setLoadingMore(false));
    }
  };

  const onRefresh = () => {
    setRefreshing(true);
    setLastVisible(0); // Reset lastVisible to reload from the start
    fetchFollowingData().then(() => setRefreshing(false));
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#FFF" />
        </View>
      ) : followingData.length > 0 ? (
        <FlatList
          data={followingData}
          renderItem={({ item }) => (
            <UserItem
              user={item}
              userId={userId}
              isVisited={isVisited}
              currentUser={currentUser}
              accountType={accountType}
              handleUnfollow={async (userId, followingId) => {
                setFollowingData(
                  followingData.filter((user) => user.userId !== followingId)
                );
                await unfollowUser(userId, followingId);
              }}
              navigation={navigation}
            />
          )}
          keyExtractor={(item) => item.userId}
          onEndReached={handleLoadMore}
          onEndReachedThreshold={0.1}
          ListFooterComponent={
            loadingMore && <ActivityIndicator size="small" color="#FFF9" />
          }
          ListEmptyComponent={
            <View style={styles.emptyContainer}>
              <Text style={styles.emptyText}>Nenhum seguindo encontrado</Text>
            </View>
          }
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        />
      ) : (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>Nenhum seguindo encontrado</Text>
        </View>
      )}
    </View>
  );
};

const EventItem = ({ event, isHidden, navigation }) => (
  <TouchableOpacity
    onPress={() => navigation.navigate("Ticket", { evento: event })}
  >
    <View style={styles.itemContainer}>
      <Image
        source={isHidden ? defaultHiddenImage : { uri: event.sticker }}
        style={isHidden ? styles.eventImageHidden : styles.eventImage}
      />
      <View style={styles.eventInfo}>
        <Text style={styles.itemText}>{isHidden ? "Privado" : event.name}</Text>
        <Text style={styles.itemSubText}>{isHidden ? "@privado" : `@${event.username}`}</Text>
        <Text style={styles.itemSubText}>{isHidden ? "Desconhecido" : event.address.city}</Text>
      </View>
    </View>
  </TouchableOpacity>
);

const EventsTab = ({ eventHistory, hiddenEvents, isPrivate, isCurrentUser, navigation }) => {
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [visibleEvents, setVisibleEvents] = useState(10); // Número inicial de eventos visíveis

  useEffect(() => {
    if (eventHistory && eventHistory.length > 0) {
      const fetchEventData = async () => {
        const data = await Promise.all(
          eventHistory.map((id) => getEventEssential(id))
        );
        setEventData(data.filter((event) => event !== null));
        setLoading(false);
      };
      fetchEventData();
    } else {
      setLoading(false);
    }
  }, [eventHistory]);

  const onRefresh = () => {
    setRefreshing(true);
    if (eventHistory && eventHistory.length > 0) {
      const fetchEventData = async () => {
        const data = await Promise.all(
          eventHistory.map((id) => getEventEssential(id))
        );
        setEventData(data.filter((event) => event !== null));
        setRefreshing(false);
      };
      fetchEventData();
    } else {
      setRefreshing(false);
    }
  };

  const handleLoadMore = () => {
    if (visibleEvents < eventData.length) {
      setVisibleEvents((prev) => prev + 10);
    }
  };

  const filteredEvents = eventData.filter(
    (event) => !hiddenEvents.includes(event.username) || isCurrentUser
  ).slice(0, visibleEvents); // Limita o número de eventos visíveis

  return (
    <View style={styles.container}>
      {loading ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#FFF" />
        </View>
      ) : filteredEvents.length > 0 ? (
        <FlatList
          data={filteredEvents}
          renderItem={({ item }) => (
            <EventItem
              event={item}
              isHidden={isPrivate && hiddenEvents.includes(item.username)}
              navigation={navigation}
            />
          )}
          keyExtractor={(item, index) => index.toString()}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          onEndReached={handleLoadMore}
          onEndReachedThreshold={0.1}
          ListFooterComponent={
            visibleEvents < eventData.length && <ActivityIndicator size="small" color="#FFF9" />
          }
        />
      ) : (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>Nenhum evento encontrado</Text>
        </View>
      )}
    </View>
  );
};

const ProfileDetails = ({ route, navigation }) => {
  const {
    followers = [],
    following = [],
    pendingFollowers = [],
    eventHistory = [],
    hiddenEvents = [],
    reviews = [],
    userId,
    username,
    isVisited,
    accountType,
    initialTab,
    isPublic,
    currentUser,
    isCurrentUser,
  } = route.params;

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.containerHeader}>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButtonHeader}
        >
          <Icon name="arrow-back" size={25} color="#FFFFFF" />
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigation.navigate("Home")}>
          <Image source={logoTexto} resizeMode="contain" style={styles.logoHeader} />
        </TouchableOpacity>

        <Icon name="person-outline" size={25} color="#000" style={styles.goBackButton} />
      </View>
      <Tab.Navigator
        initialRouteName={initialTab}
        screenOptions={{
          tabBarStyle: { backgroundColor: "#000" },
          tabBarActiveTintColor: "#FFF",
          tabBarInactiveTintColor: "#A7A7A7",
          tabBarIndicatorStyle: {
            backgroundColor: "#FFF",
            height: 1,
            bottom: 10,
          },
          tabBarLabelStyle: {
            fontSize: 13,
            fontWeight: "bold",
            paddingBottom: 0,
          },
        }}
      >
        {(accountType === "User" || accountType === "") && (
          <>
            <Tab.Screen
              name="Eventos"
              children={() => (
                <EventsTab
                  eventHistory={eventHistory}
                  hiddenEvents={hiddenEvents}
                  isPrivate={!isPublic}
                  isCurrentUser={isCurrentUser}
                  navigation={navigation}
                />
              )}
            />
            <Tab.Screen
              name="Seguidores"
              children={() => (
                <FollowersTab
                  followers={followers}
                  pendingFollowers={pendingFollowers}
                  userId={userId}
                  accountType={accountType}
                  isVisited={isVisited}
                  isPublic={isPublic}
                  navigation={navigation}
                  currentUser={currentUser}
                />
              )}
            />
            <Tab.Screen
              name="Seguindo"
              children={() => (
                <FollowingTab
                  following={following}
                  userId={userId}
                  isVisited={isVisited}
                  navigation={navigation}
                  currentUser={currentUser}
                  accountType={accountType}
                />
              )}
            />
          </>
        )}
        {accountType === "Producer" && (
          <>
            <Tab.Screen
              name="Reviews"
              children={() => (
                <ReviewSection
                  reviews={reviews}
                  username={username}
                  userId={userId}
                  isCurrentUser={isCurrentUser}
                  currentUser={currentUser}
                />
              )}
            />
            <Tab.Screen
              name="Eventos"
              children={() => (
                <EventsTab
                  eventHistory={eventHistory}
                  hiddenEvents={hiddenEvents}
                  isPrivate={!isPublic}
                  isCurrentUser={isCurrentUser}
                  navigation={navigation}
                />
              )}
            />
            <Tab.Screen
              name="Seguidores"
              children={() => (
                <FollowersTab
                  followers={followers}
                  pendingFollowers={pendingFollowers}
                  userId={userId}
                  accountType={accountType}
                  isVisited={isVisited}
                  isPublic={isPublic}
                  navigation={navigation}
                  currentUser={currentUser}
                />
              )}
            />
          </>
        )}
      </Tab.Navigator>
    </SafeAreaView>
  );
};

export default ProfileDetails;
