import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  TextInput,
  Modal,
} from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import Toast from "react-native-toast-message";
import Header from "../../components/general/settings/Header";
import { auth } from "../../services/utils/firebase/firebaseConfig";
import {
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { getUserEssential } from "../../services/cruds/users/getUser";
import { addAccountDeletionRequest } from "../../services/cruds/accountDeletionRequests/createAccountDeletionRequests";
import { addUserSuggestion } from "../../services/cruds/userSuggestions/createUserSuggestions";
import { getAppConfigContact } from "../../services/cruds/appConfig/contact/getAppConfigContact";
import { getEventByNormalizedName } from "../../services/cruds/events/getEvent";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Linking from "expo-linking";
import Constants from "expo-constants";
import { styles } from "../../styles/general/SettingsStyles";
import toastConfig from "../../components/utils/ToastConfig";
import SettingsSkeleton from "../../components/general/settings/SettingsSkeleton"; // Importar o esqueleto

const Settings = ({ navigation }) => {
  const [state, setState] = useState({
    userId: "",
    accountType: "",
    email: "",
    modalVisible: false,
    deletionReason: "",
    username: "",
    name: "",
    showInstagramBanner: true,
    suggestion: "",
    eventName: "",
    eventKey: "",
    readerModalVisible: false,
    currentVersion: "",
    passwordResetModalVisible: false,
    isLoading: true,
    showEventKey: false, // Estado para controlar a visibilidade da senha
  });

  useEffect(() => {
    const authListener = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const email = user.email;
        setState((prevState) => ({ ...prevState, userId: uid, email }));
        await loadUserProfile(uid);
      }
      setState((prevState) => ({
        ...prevState,
        currentVersion: Constants.expoConfig.version,
        isLoading: false,
      }));
    });

    const unsubscribeFocus = navigation.addListener('focus', () => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const checkAuth = async () => {
        const user = auth.currentUser;
        if (user) {
          const uid = user.uid;
          const email = user.email;
          setState((prevState) => ({ ...prevState, userId: uid, email }));
          await loadUserProfile(uid);
        }
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      };
      checkAuth();
    });

    return () => {
      if (authListener) {
        authListener();
      }
      unsubscribeFocus();
    };
  }, [navigation]);

  const loadUserProfile = async (userId) => {
    const userData = await getUserEssential(userId);
    if (!userData) {
      console.error(`Nenhum documento encontrado para o usuário: ${userId}`);
      return;
    }
    if (userData) {
      setState((prevState) => ({
        ...prevState,
        accountType: userData.accountType,
        username: userData.username || "",
        name: userData.fullName || "",
      }));
    }
  };

  const handleLogout = async () => {
    try {
      await AsyncStorage.clear();
      await signOut(auth);
      navigation.reset({
        index: 0,
        routes: [{ name: "Home" }],
      });
      navigation.navigate("Home");
    } catch (error) {
      console.error("Erro ao deslogar: ", error);
      showToast("error", "Erro", "Não foi possível deslogar.");
    }
  };

  const handleResetPassword = () => {
    setState((prevState) => ({
      ...prevState,
      passwordResetModalVisible: true,
      modalVisible: false,
      readerModalVisible: false,
    }));
  };

  const confirmResetPassword = async () => {
    const { email } = state;
    try {
      await sendPasswordResetEmail(auth, email);
      setState((prevState) => ({
        ...prevState,
        passwordResetModalVisible: false,
      }));
      showToast(
        "success",
        "Sucesso",
        "Email de redefinição de senha enviado com sucesso. Por favor, verifique sua caixa de entrada."
      );
    } catch (error) {
      console.error("Erro ao enviar email de redefinição de senha:", error);
      showToast(
        "error",
        "Erro",
        "Erro ao enviar email de redefinição de senha. Por favor, tente novamente."
      );
    }
  };

  const handleDeleteAccountRequest = () => {
    setState((prevState) => ({
      ...prevState,
      modalVisible: true,
      passwordResetModalVisible: false,
      readerModalVisible: false,
    }));
  };

  const handleLogin = () => {
    navigation.navigate("Login");
  };

  const submitDeleteAccountRequest = async () => {
    const { userId, deletionReason, username, name } = state;
    if (!deletionReason) {
      showToast(
        "error",
        "Erro",
        "Por favor, forneça um motivo para a solicitação."
      );
      return;
    }
    try {
      await addAccountDeletionRequest(userId, username, name, deletionReason);
      showToast(
        "success",
        "Solicitação Enviada",
        "Sua solicitação de apagamento de conta foi enviada com sucesso. Entraremos em contato em breve."
      );
      setState((prevState) => ({
        ...prevState,
        modalVisible: false,
        deletionReason: "",
      }));
    } catch (error) {
      console.error("Erro ao solicitar apagamento de conta: ", error);
      showToast(
        "error",
        "Erro",
        "Não foi possível solicitar o apagamento da conta."
      );
    }
  };

  const openWhatsApp = async () => {
    try {
      const configData = await getAppConfigContact();
      if (configData) {
        const phoneNumber = configData.whatsappNumber;
        if (phoneNumber) {
          const url = `https://wa.me/${phoneNumber}`;
          Linking.canOpenURL(url)
            .then((supported) => {
              if (supported) {
                return Linking.openURL(url);
              } else {
                showToast(
                  "error",
                  "Erro",
                  "Não foi possível abrir o WhatsApp."
                );
              }
            })
            .catch((err) => console.error("Erro ao abrir o WhatsApp", err));
        } else {
          console.error("Número de WhatsApp não disponível.");
        }
      } else {
        console.error(
          "Documento de configuração do aplicativo não encontrado."
        );
      }
    } catch (err) {
      console.error("Erro ao buscar número de WhatsApp", err);
    }
  };

  const handleFollowInstagram = async () => {
    try {
      const configData = await getAppConfigContact();
      if (configData) {
        const instagramUrl = configData.instagramLink;
        if (instagramUrl) {
          Linking.openURL(instagramUrl).catch((err) =>
            console.error("Erro ao abrir Instagram", err)
          );
        } else {
          console.error("Link do Instagram não disponível.");
        }
      } else {
        console.error(
          "Documento de configuração do aplicativo não encontrado."
        );
      }
    } catch (err) {
      console.error("Erro ao buscar link do Instagram", err);
    }
  };

  const closeInstagramBanner = () => {
    setState((prevState) => ({ ...prevState, showInstagramBanner: false }));
  };

  const submitSuggestion = async () => {
    const { userId, suggestion } = state;
    if (!suggestion) {
      showToast("error", "Erro", "Por favor, forneça uma sugestão.");
      return;
    }
    try {
      await addUserSuggestion(userId, suggestion);
      showToast(
        "success",
        "Sugestão Enviada",
        "Sua sugestão foi enviada com sucesso. Agradecemos seu feedback!"
      );
      setState((prevState) => ({ ...prevState, suggestion: "" }));
    } catch (error) {
      console.error("Erro ao enviar sugestão: ", error);
      showToast("error", "Erro", "Não foi possível enviar sua sugestão.");
    }
  };

  const handleReader = () => {
    setState((prevState) => ({
      ...prevState,
      readerModalVisible: true,
      modalVisible: false,
      passwordResetModalVisible: false,
    }));
  };

  const submitReaderRequest = async () => {
    const { eventName, eventKey, username } = state;
    if (!eventName || !eventKey) {
      showToast(
        "error",
        "Erro",
        "Por favor, forneça o nome do evento e a chave do evento."
      );
      return;
    }

    const normalizedEventName = eventName.trim().toLowerCase();

    try {
      const eventDoc = await getEventByNormalizedName(normalizedEventName);
      if (eventDoc) {
        const event = eventDoc;
        if (event.eventKey === eventKey) {
          if (event.readers && event.readers.includes(username)) {
            setState({
              readerModalVisible: false,
              modalVisible: false,
              passwordResetModalVisible: false,
              eventName: "",
              eventKey: "",
            });
            navigation.navigate("ReaderAutentication", {
              eventId: eventDoc.id,
              username,
            });
          } else {
            showToast("error", "Erro", "Reader não autorizado.");
          }
        } else {
          showToast("error", "Erro", "Chave do evento incorreta.");
        }
      } else {
        showToast("error", "Erro", "Evento não encontrado.");
      }
    } catch (error) {
      console.error("Erro ao procurar evento: ", error);
      showToast("error", "Erro", "Erro ao procurar evento.");
    }
  };

  const showToast = (type, text1, text2) => {
    Toast.show({
      type,
      text1,
      text2,
      position: "bottom",
      visibilityTime: 4000,
      autoHide: true,
      topOffset: 30,
      bottomOffset: 40,
    });
  };

  const {
    accountType,
    modalVisible,
    deletionReason,
    showInstagramBanner,
    suggestion,
    readerModalVisible,
    eventName,
    eventKey,
    passwordResetModalVisible,
    isLoading,
  } = state;
  const showSuggestionBanner = !showInstagramBanner;
  const [showEventKey, setShowEventKey] = useState(false);

  return (
    <SafeAreaView style={styles.container}>
      <View>
        <Header navigation={navigation} />
      </View>
      {isLoading ? (
        <SettingsSkeleton /> // Usando o esqueleto durante o carregamento
      ) : (
        <ScrollView>
          {accountType && (
            <>
              <TouchableOpacity
                style={styles.settingItem}
                onPress={() => navigation.navigate("EditProfile")}
              >
                <MaterialIcons
                  name="personal-injury"
                  size={24}
                  style={styles.settingIcon}
                />
                <View style={styles.settingTextContainer}>
                  <Text style={styles.settingTitle}>Editar Perfil</Text>
                  <Text style={styles.settingSubtitle}>
                    Atualize suas informações pessoais
                  </Text>
                </View>
                <Ionicons
                  name="chevron-forward"
                  size={24}
                  style={styles.settingArrow}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.settingItem}
                onPress={handleResetPassword}
              >
                <MaterialCommunityIcons
                  name="key-variant"
                  size={24}
                  style={styles.settingIcon}
                />
                <View style={styles.settingTextContainer}>
                  <Text style={styles.settingTitle}>Redefinir Senha</Text>
                  <Text style={styles.settingSubtitle}>
                    Envie um email de redefinição de senha
                  </Text>
                </View>
                <Ionicons
                  name="chevron-forward"
                  size={24}
                  style={styles.settingArrow}
                />
              </TouchableOpacity>
              {accountType === "User" && (
                <TouchableOpacity
                  style={styles.settingItem}
                  onPress={handleReader}
                >
                  <MaterialCommunityIcons
                    name="qrcode-scan"
                    size={24}
                    style={styles.settingIcon}
                  />
                  <View style={styles.settingTextContainer}>
                    <Text style={styles.settingTitle}>Reader</Text>
                    <Text style={styles.settingSubtitle}>
                      Atue como reader de um evento
                    </Text>
                  </View>
                  <Ionicons
                    name="chevron-forward"
                    size={24}
                    style={styles.settingArrow}
                  />
                </TouchableOpacity>
              )}
              {accountType === "Producer" && (
                <TouchableOpacity
                  style={styles.settingItem}
                  onPress={() => navigation.navigate("ManageBalance")}
                >
                  <Ionicons
                    name="wallet-outline"
                    size={24}
                    style={styles.settingIcon}
                  />
                  <View style={styles.settingTextContainer}>
                    <Text style={styles.settingTitle}>Gerenciar Saldo</Text>
                    <Text style={styles.settingSubtitle}>
                      Gerencie seu saldo e transações
                    </Text>
                  </View>
                  <Ionicons
                    name="chevron-forward"
                    size={24}
                    style={styles.settingArrow}
                  />
                </TouchableOpacity>
              )}
            </>
          )}
          <TouchableOpacity
            style={styles.settingItem}
            onPress={() => navigation.navigate("Faq")}
          >
            <MaterialCommunityIcons
              name="help-network-outline"
              size={24}
              style={styles.settingIcon}
            />
            <View style={styles.settingTextContainer}>
              <Text style={styles.settingTitle}>FAQ</Text>
              <Text style={styles.settingSubtitle}>
                Obtenha ajuda e encontre respostas
              </Text>
            </View>
            <Ionicons
              name="chevron-forward"
              size={24}
              style={styles.settingArrow}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.settingItem} onPress={openWhatsApp}>
            <MaterialIcons name="chat" size={24} style={styles.settingIcon} />
            <View style={styles.settingTextContainer}>
              <Text style={styles.settingTitle}>Suporte</Text>
              <Text style={styles.settingSubtitle}>
                Entre em contato conosco para obter ajuda
              </Text>
            </View>
            <Ionicons
              name="chevron-forward"
              size={24}
              style={styles.settingArrow}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.settingItem}
            onPress={() => navigation.navigate("Terms")}
          >
            <MaterialCommunityIcons
              name="file-document-multiple-outline"
              size={24}
              style={styles.settingIcon}
            />
            <View style={styles.settingTextContainer}>
              <Text style={styles.settingTitle}>Termos e Condições</Text>
              <Text style={styles.settingSubtitle}>
                Leia nossos termos de serviço
              </Text>
            </View>
            <Ionicons
              name="chevron-forward"
              size={24}
              style={styles.settingArrow}
            />
          </TouchableOpacity>
          {accountType ? (
            <>
              <TouchableOpacity
                style={styles.settingItem}
                onPress={handleLogout}
              >
                <MaterialCommunityIcons
                  name="exit-run"
                  size={24}
                  style={styles.settingIcon}
                />
                <View style={styles.settingTextContainer}>
                  <Text style={styles.settingTitle}>Sair</Text>
                  <Text style={styles.settingSubtitle}>
                    Desconecte-se da aplicação
                  </Text>
                </View>
                <Ionicons
                  name="chevron-forward"
                  size={24}
                  style={styles.settingArrow}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.settingItem}
                onPress={handleDeleteAccountRequest}
              >
                <MaterialCommunityIcons
                  name="trash-can-outline"
                  size={24}
                  style={[styles.settingIcon, { color: "#FF6347" }]}
                />
                <View style={styles.settingTextContainer}>
                  <Text style={[styles.settingTitle, { color: "#FF6347" }]}>
                    Apagar Conta
                  </Text>
                  <Text style={[styles.settingSubtitle, { color: "#FF6347" }]}>
                    Solicitar apagamento da conta
                  </Text>
                </View>
                <Ionicons
                  name="chevron-forward"
                  size={24}
                  style={[styles.settingArrow, { color: "#FF6347" }]}
                />
              </TouchableOpacity>
            </>
          ) : (
            <TouchableOpacity style={styles.settingItem} onPress={handleLogin}>
              <Ionicons
                name="log-in-outline"
                size={24}
                style={styles.settingIcon}
              />
              <View style={styles.settingTextContainer}>
                <Text style={styles.settingTitle}>Fazer Login</Text>
                <Text style={styles.settingSubtitle}>Acesse sua conta</Text>
              </View>
              <Ionicons
                name="chevron-forward"
                size={24}
                style={styles.settingArrow}
              />
            </TouchableOpacity>
          )}
          {showInstagramBanner && (
            <View style={styles.instagramBanner}>
              <Text style={styles.instagramBannerText}>
                Venha seguir nosso Instagram! Prêmios e recompensas aguardam
                você!
              </Text>
              <View style={styles.instagramBannerButtons}>
                <TouchableOpacity
                  style={styles.instagramFollowButton}
                  onPress={handleFollowInstagram}
                >
                  <Text style={styles.instagramFollowButtonText}>Seguir</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.instagramCloseButton}
                  onPress={closeInstagramBanner}
                >
                  <Text style={styles.instagramCloseButtonText}>Fechar</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
          {showSuggestionBanner && (
            <View style={styles.suggestionBanner}>
              <Text style={styles.suggestionBannerText}>
                Encontrou algum erro ou quer que adicionemos alguma informação?
                Sugira no campo abaixo:
              </Text>
              <TextInput
                style={styles.suggestionInput}
                placeholder="Digite sua sugestão aqui"
                placeholderTextColor="#999"
                value={suggestion}
                onChangeText={(text) =>
                  setState((prevState) => ({ ...prevState, suggestion: text }))
                }
              />
              <View style={styles.suggestionBannerButtons}>
                <TouchableOpacity
                  style={styles.suggestionSubmitButton}
                  onPress={submitSuggestion}
                >
                  <Text style={styles.suggestionSubmitButtonText}>Enviar</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </ScrollView>
      )}
      <View style={styles.versionContainer}>
        <Text
          style={isLoading ? styles.versionTextIsLoading : styles.versionText}
        >
          Versão {state.currentVersion}
        </Text>
      </View>
      <Modal
        visible={modalVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={() =>
          setState((prevState) => ({ ...prevState, modalVisible: false }))
        }
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <TouchableOpacity
              style={styles.modalCloseButton}
              onPress={() =>
                setState((prevState) => ({ ...prevState, modalVisible: false }))
              }
            >
              <Ionicons name="close" size={24} color="#FFF" />
            </TouchableOpacity>
            <Text style={styles.modalTitle}>
              Solicitação de Apagamento de Conta
            </Text>
            <TextInput
              style={styles.modalInput}
              placeholder="Motivo da solicitação"
              placeholderTextColor="#999"
              value={deletionReason}
              onChangeText={(text) =>
                setState((prevState) => ({
                  ...prevState,
                  deletionReason: text,
                }))
              }
            />
            <View style={styles.modalButtonsRow}>
              <TouchableOpacity
                style={[styles.modalButton, { marginRight: 10 }]}
                onPress={submitDeleteAccountRequest}
              >
                <Text style={styles.modalButtonText}>Enviar</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, styles.modalButtonCancel]}
                onPress={() =>
                  setState((prevState) => ({
                    ...prevState,
                    modalVisible: false,
                  }))
                }
              >
                <Text style={styles.modalButtonText}>Cancelar</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        visible={readerModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() =>
          setState((prevState) => ({ ...prevState, readerModalVisible: false }))
        }
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <TouchableOpacity
              style={styles.modalCloseButton}
              onPress={() =>
                setState((prevState) => ({
                  ...prevState,
                  readerModalVisible: false,
                }))
              }
            >
              <Ionicons name="close" size={24} color="#FFF" />
            </TouchableOpacity>
            <Text style={styles.modalTitle}>Reader de Evento</Text>
            <TextInput
              style={styles.modalInput}
              placeholder="Nome do Evento"
              placeholderTextColor="#999"
              value={eventName}
              onChangeText={(text) =>
                setState((prevState) => ({ ...prevState, eventName: text }))
              }
            />
            <View style={styles.passwordContainer}>
              <TextInput
                style={styles.modalInput}
                placeholder="Chave do Evento"
                placeholderTextColor="#999"
                value={eventKey}
                onChangeText={(text) =>
                  setState((prevState) => ({ ...prevState, eventKey: text }))
                }
                secureTextEntry={!showEventKey}
              />
              <TouchableOpacity
                onPress={() => setShowEventKey(!showEventKey)}
              >
                <Ionicons
                  name={showEventKey ? "eye-off" : "eye"}
                  size={24}
                  color="#FFF9"
                  style={styles.eyeIcon}
                />
              </TouchableOpacity>
            </View>
            <TouchableOpacity
              onPress={submitReaderRequest}
              style={styles.modalButton}
            >
              <Text style={styles.modalButtonText}>Enviar</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <Modal
        visible={passwordResetModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() =>
          setState((prevState) => ({
            ...prevState,
            passwordResetModalVisible: false,
          }))
        }
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <TouchableOpacity
              style={styles.modalCloseButton}
              onPress={() =>
                setState((prevState) => ({
                  ...prevState,
                  passwordResetModalVisible: false,
                }))
              }
            >
              <Ionicons name="close" size={24} color="#FFF" />
            </TouchableOpacity>
            <Text style={styles.modalTitle}>
              Confirmar Redefinição de Senha
            </Text>
            <Text style={styles.modalText}>
              Você receberá um link para redefinir sua senha no email
              cadastrado.
            </Text>
            <View style={styles.modalButtonsRow}>
              <TouchableOpacity
                style={[styles.modalButton, { marginRight: 10 }]}
                onPress={confirmResetPassword}
              >
                <Text style={styles.modalButtonText}>Confirmar</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, styles.modalButtonCancel]}
                onPress={() =>
                  setState((prevState) => ({
                    ...prevState,
                    passwordResetModalVisible: false,
                  }))
                }
              >
                <Text style={styles.modalButtonText}>Cancelar</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Toast config={toastConfig} />
    </SafeAreaView>
  );
};

export default Settings;
