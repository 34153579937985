import React, { useState } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Alert,
  ActivityIndicator,
} from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { styles } from "../../../../styles/producer/producerUtils/AddEventStyles";

const ContactInformationForm = ({
  state,
  handleInputChange,
  handleNextStep,
  handlePrevStep,
}) => {
  const [loadingCep, setLoadingCep] = useState(false);
  const [addressFieldsVisible, setAddressFieldsVisible] = useState(false);

  const fetchAddress = async (cep) => {
    const url = `https://viacep.com.br/ws/${cep}/json/`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.erro) {
        console.error("CEP não encontrado.");
        Alert.alert("Erro", "CEP não encontrado.");
        return null;
      }
      return data;
    } catch (error) {
      console.error("Erro ao buscar o CEP:", error);
      Alert.alert("Erro", "Erro ao buscar o CEP.");
      return null;
    }
  };

  const handleCepChange = async (text) => {
    handleInputChange("address.zip", text);
    if (text.length === 8) {
      setLoadingCep(true);
      const addressData = await fetchAddress(text);
      if (addressData) {
        fillAddressFields(addressData);
        setAddressFieldsVisible(true);
      } else {
        setAddressFieldsVisible(false);
      }
      setLoadingCep(false);
    } else if (text.length < 8) {
      setAddressFieldsVisible(false);
      clearAddressFields();
    }
  };

  const fillAddressFields = (data) => {
    handleInputChange("address.street", data.logradouro);
    handleInputChange("address.neighborhood", data.bairro);
    handleInputChange("address.city", data.localidade);
    handleInputChange("address.state", data.uf);
  };

  const clearAddressFields = () => {
    handleInputChange("address.street", "");
    handleInputChange("address.neighborhood", "");
    handleInputChange("address.city", "");
    handleInputChange("address.state", "");
  };

  const formatPhone = (phone) => {
    // Remove all non-numeric characters
    const cleaned = ("" + phone).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phone;
  };

  const validateForm = () => {
    const { email, phone } = state.organizer.contact;
    const {
      zip,
      street,
      number,
      neighborhood,
      city,
      state: addressState,
    } = state.address;

    if (!email.includes("@")) {
      Alert.alert("Erro", "Insira um e-mail válido.");
      return false;
    }

    const phonePattern = /^\(\d{2}\) \d{5}-\d{4}$/;
    if (!phonePattern.test(phone)) {
      Alert.alert(
        "Erro",
        "Insira um número de telefone válido no formato (11) 91234-5678."
      );
      return false;
    }

    if (
      !email ||
      !phone ||
      !zip ||
      !street ||
      !number ||
      !neighborhood ||
      !city ||
      !addressState
    ) {
      Alert.alert("Erro", "Todos os campos são obrigatórios.");
      return false;
    }

    return true;
  };

  const handleNext = async () => {
    const formattedPhone = await formatPhone(state.organizer.contact.phone);
    handleInputChange("organizer.contact.phone", formattedPhone);

    if (validateForm()) {
      handleNextStep();
    }
  };

  return (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <MaterialCommunityIcons
          name="phone-log"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Dados Gerais</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        <Text style={styles.label}>Email para Contato:</Text>
        <TextInput
          style={styles.input}
          placeholder="exemplo@dominio.com"
          placeholderTextColor="#FFF9"
          keyboardType="email-address"
          value={state.organizer.contact.email}
          onChangeText={(text) =>
            handleInputChange("organizer.contact.email", text)
          }
        />
        <Text style={styles.label}>Telefone para Contato:</Text>
        <TextInput
          style={styles.input}
          placeholder="(11) 91234-5678"
          placeholderTextColor="#FFF9"
          keyboardType="phone-pad"
          value={state.organizer.contact.phone}
          onChangeText={(text) =>
            handleInputChange("organizer.contact.phone", text)
          }
        />
        <Text style={styles.label}>Nome do Local:</Text>
        <TextInput
          style={styles.input}
          placeholder="Espaço After"
          placeholderTextColor="#FFF9"
          value={state.address.name}
          onChangeText={(text) => handleInputChange("address.name", text)}
        />
        <Text style={styles.label}>CEP:</Text>
        <TextInput
          style={styles.input}
          placeholder="12345678"
          placeholderTextColor="#FFF9"
          keyboardType="numeric"
          value={state.address.zip}
          onChangeText={handleCepChange}
        />
        {loadingCep && <ActivityIndicator size="small" color="#FFF9" />}
        {addressFieldsVisible && (
          <>
            <Text style={styles.label}>Rua/Av:</Text>
            <TextInput
              style={styles.input}
              placeholder="Rua Exemplo"
              placeholderTextColor="#FFF9"
              value={state.address.street}
              onChangeText={(text) => handleInputChange("address.street", text)}
            />
            <Text style={styles.label}>Número:</Text>
            <TextInput
              style={styles.input}
              placeholder="123"
              placeholderTextColor="#FFF9"
              keyboardType="numeric"
              value={state.address.number}
              onChangeText={(text) => handleInputChange("address.number", text)}
            />
            <Text style={styles.label}>Complemento:</Text>
            <TextInput
              style={styles.input}
              placeholder="Apt 101"
              placeholderTextColor="#FFF9"
              value={state.address.complement}
              onChangeText={(text) =>
                handleInputChange("address.complement", text)
              }
            />
            <Text style={styles.label}>Bairro:</Text>
            <TextInput
              style={styles.input}
              placeholder="Bairro Exemplo"
              placeholderTextColor="#FFF9"
              value={state.address.neighborhood}
              onChangeText={(text) =>
                handleInputChange("address.neighborhood", text)
              }
            />
            <Text style={[styles.label, styles.labelBlocked]}>Cidade:</Text>
            <TextInput
              style={[styles.input, styles.inputBlocked]}
              placeholder="Cidade Exemplo"
              placeholderTextColor="#FFF9"
              value={state.address.city}
              editable={false}
            />
            <Text style={[styles.label, styles.labelBlocked]}>Estado:</Text>
            <TextInput
              style={[styles.input, styles.inputBlocked]}
              placeholder="SP"
              placeholderTextColor="#FFF9"
              value={state.address.state}
              editable={false}
            />
          </>
        )}
        <TouchableOpacity onPress={handleNext} style={styles.buttonSubmit}>
          <Text style={styles.buttonText}>Próximo</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={handlePrevStep} style={styles.buttonBack}>
          <Text style={styles.buttonText}>Voltar</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ContactInformationForm;
