import { StyleSheet } from "react-native";
import { auth } from "../../../services/utils/firebase/firebaseConfig";
import axios from 'axios';

const API_URL = "https://us-central1-rayv-a7c48.cloudfunctions.net/api";

const getIdToken = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("Usuário não autenticado.");
  }
  return await user.getIdToken();
};

export async function qrCodeGenerator({ ticketId }) {
  if (!ticketId) {
    return "dados inválidos";
  }

  const token = await getIdToken();
  const response = await axios.post(
    `${API_URL}/getQR`,
    { ticketId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }
  );

  const key = response.data;

  const qrData = JSON.stringify({
    ticketId,
    key,
  });

  return qrData;
}

const styles = StyleSheet.create({
  qrContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
    padding: 20,
    borderRadius: 10,
    margin: 20,
  },
});

export default qrCodeGenerator;
