import React from "react";
import {
  View,
  Image,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  StatusBar,
} from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialIcons";
import Icon from "react-native-vector-icons/Ionicons";

// Assets
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";

const Header = ({ onSettingsPress, onHomePress, onTicketPress, visible }) => {
  return (
    <SafeAreaView style={styles.safeArea}>
      <StatusBar barStyle="light-content" />
      <View style={styles.container}>
        {visible !== "false" && (
          <>
            <TouchableOpacity onPress={onSettingsPress}>
              <MaterialCommunityIcons
                name="settings"
                size={25}
                color="#FFFFFF"
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={onHomePress}>
              <Image
                source={logoTexto}
                resizeMode="contain"
                style={styles.logo}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={onTicketPress}>
              <Icon name="ticket-sharp" size={25} color="#FFFFFF" />
            </TouchableOpacity>
          </>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    backgroundColor: "#000",
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
    paddingHorizontal: 10,
    backgroundColor: "#000",
  },
  logo: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
});

export default Header;
