import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StatusBar,
  TouchableOpacity,
  Image,
  SafeAreaView,
  FlatList,
  Modal,
  Alert,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import {
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { auth } from "../../../services/utils/firebase/firebaseConfig";
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";
import { styles } from "../../../styles/producer/producerBalance/ManageBalanceStyles";
import { getUserTicketResult } from "../../../services/cruds/users/getUser";
import {
  getProducerBalance,
  onProducerBalanceSnapshot,
} from "../../../services/cruds/producersBalance/getProducersBalance";
import { checkExistingRequests } from "../../../services/cruds/withdrawalRequests/getWithdrawalRequests";
import { createWithdrawalRequest } from "../../../services/cruds/withdrawalRequests/createWithdrawalRequests";

const ManageBalance = ({ navigation }) => {
  const [balance, setBalance] = useState(0);
  const [user, setUser] = useState();
  const [transactions, setTransactions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [accountDetails, setAccountDetails] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const userData = await getUserTicketResult(currentUser.uid);
          setUser(userData);
          setSelectedPaymentMethod(userData.selectedPaymentMethod || "");
          setAccountDetails(
            userData.paymentMethods?.[userData.selectedPaymentMethod] || {}
          );

          const producerBalance = await getProducerBalance(currentUser.uid);
          setBalance(producerBalance.valorLiquidoTotal);
          setTransactions(producerBalance.historico || []);
        }
      } catch (error) {
        console.error("Erro ao buscar dados iniciais: ", error);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (auth.currentUser) {
      const unsubscribe = onProducerBalanceSnapshot(
        auth.currentUser.uid,
        (data) => {
          if (data) {
            setBalance(data.valorLiquidoTotal);
            setTransactions(data.historico || []);
          }
        }
      );

      return () => unsubscribe();
    }
  }, []);

  const handleWithdrawalRequest = async () => {
    if (balance <= 0) {
      Alert.alert(
        "Erro",
        "Você não pode solicitar retirada de valor 0 ou negativo."
      );
      return;
    }

    if (
      !selectedPaymentMethod ||
      (!accountDetails.pixKey && !accountDetails.accountNumber)
    ) {
      Alert.alert(
        "Erro",
        "Por favor, preencha seu método de recebimento antes de solicitar uma retirada."
      );
      return;
    }

    const currentUser = auth.currentUser;
    if (currentUser) {
      const existingRequest = await checkExistingRequests(currentUser.uid);
      if (existingRequest) {
        Alert.alert(
          "Erro",
          "Já existe uma solicitação de retirada em andamento."
        );
        return;
      }
    }

    setModalVisible(true);
  };

  const confirmWithdrawal = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const success = await createWithdrawalRequest(
        currentUser.uid,
        balance,
        selectedPaymentMethod,
        selectedPaymentMethod === "pix"
          ? accountDetails
          : {
              bank: accountDetails.bank,
              agency: accountDetails.agency,
              accountNumber: accountDetails.accountNumber,
              cpfCnpj: accountDetails.cpfCnpj,
              accountName: accountDetails.accountName,
            }
      );

      if (success) {
        Alert.alert(
          "Solicitação de Retirada",
          "Sua solicitação foi enviada com sucesso! O valor será creditado em até 3 dias úteis."
        );
        setModalVisible(false);
      } else {
        Alert.alert("Erro", "Não foi possível processar sua solicitação.");
      }
    }
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return `${date.toLocaleDateString()} às ${date.toLocaleTimeString()}`;
  };

  const renderTransaction = ({ item }) => (
    <View style={styles.transactionItem}>
      <View style={styles.transactionIconContainer}>
        <Icon
          name={item.valor > 0 ? "arrow-down-circle" : "arrow-up-circle"}
          size={30}
          color={item.valor > 0 ? "#5B2372" : "#052552"}
        />
      </View>
      <View style={styles.transactionDetails}>
        <Text style={styles.transactionTitle}>
          {item.valor > 0 ? "Recebido" : "Enviado"}
        </Text>
        <Text style={styles.transactionDate}>{formatDate(item.data)}</Text>
        <Text style={styles.transactionText}>
          @{item.fonte} → @{item.destino}
        </Text>
      </View>
      <Text
        style={[
          styles.transactionValue,
          item.valor > 0 ? styles.positive : styles.negative,
        ]}
      >
        {item.valor > 0
          ? `+ ${formatCurrency(Math.abs(item.valor))}`
          : `- ${formatCurrency(Math.abs(item.valor))}`}
      </Text>
    </View>
  );

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle="light-content" />
      <View style={styles.containerHeader}>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <Icon name="arrow-back" size={25} color="#FFFFFF" />
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigation.navigate("Home")}>
          <Image
            source={logoTexto}
            resizeMode="contain"
            style={styles.logoHeader}
          />
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => navigation.navigate("ManageBalanceHelp")}
          style={styles.helpButton}
        >
          <MaterialCommunityIcons name="help-network" size={25} color="white" />
        </TouchableOpacity>
      </View>
      <View style={styles.balanceContainer}>
        <Text style={styles.balanceTitle}>Saldo Disponível</Text>
        <TouchableOpacity
          onPress={() => setIsBalanceVisible(!isBalanceVisible)}
        >
          <Text style={styles.balanceAmount}>
            {isBalanceVisible ? formatCurrency(balance) : "****"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.withdrawButton}
          onPress={handleWithdrawalRequest}
        >
          <Icon
            name="wallet-outline"
            size={20}
            color="#FFFFFF"
            style={styles.buttonIcon}
          />
          <Text style={styles.withdrawButtonText}>Solicitar Retirada</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.sectionDivider}></View>
      <View style={styles.sectionHeader}>
        <Icon name="cash" size={22} color="#FFF" style={styles.sectionIcon} />
        <Text style={styles.sectionTitleText}>Método de Recebimento</Text>
        {selectedPaymentMethod && (
          <TouchableOpacity
            onPress={() => setIsExpanded(!isExpanded)}
            style={styles.expandIcon}
          >
            <Icon
              name={isExpanded ? "chevron-up" : "chevron-down"}
              size={22}
              color="#FFF"
            />
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.accountDetailsContainer}>
        <Text style={styles.accountDetailsText}>
          Método Selecionado:{" "}
          {selectedPaymentMethod
            ? selectedPaymentMethod === "pix"
              ? "PIX"
              : "Transferência Bancária"
            : "Nenhum método selecionado"}
        </Text>
        {isExpanded &&
          selectedPaymentMethod &&
          (selectedPaymentMethod === "pix" && accountDetails.pixKey ? (
            <>
              <Text style={styles.accountDetailsText}>
                Tipo da Chave: {accountDetails.pixKeyType}
              </Text>
              <Text style={styles.accountDetailsText}>
                Chave: {accountDetails.pixKey}
              </Text>
              <Text style={styles.accountDetailsText}>
                Nome Completo: {accountDetails.accountName}
              </Text>
            </>
          ) : (
            <>
              <Text style={styles.accountDetailsText}>
                Banco: {accountDetails.bank || ""}
              </Text>
              <Text style={styles.accountDetailsText}>
                Agência: {accountDetails.agency || ""}
              </Text>
              <Text style={styles.accountDetailsText}>
                Conta: {accountDetails.accountNumber || ""}
              </Text>
              <Text style={styles.accountDetailsText}>
                Nome Completo: {accountDetails.accountName}
              </Text>
            </>
          ))}
        <TouchableOpacity
          style={styles.changePaymentButton}
          onPress={() =>
            navigation.navigate("EditProfile", { section: "payment" })
          }
        >
          <Icon name="create-outline" size={20} color="#FFFFFF" />
          <Text style={styles.changePaymentText}>
            Alterar Método de Pagamento
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.sectionDivider}></View>
      <View style={styles.sectionHeader}>
        <Icon
          name="bar-chart"
          size={22}
          color="#FFF"
          style={styles.sectionIcon}
        />
        <Text style={styles.sectionTitleText}>Histórico de Transações</Text>
      </View>
      <View style={styles.transactionsContainer}>
        {transactions.length === 0 ? (
          <Text style={styles.noTransactionsText}>
            Nenhuma transação encontrada.
          </Text>
        ) : (
          <FlatList
            data={transactions}
            renderItem={renderTransaction}
            keyExtractor={(item, index) => index.toString()}
          />
        )}
      </View>
      <Modal
        visible={modalVisible}
        transparent={true}
        animationType="slide"
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalBackground}>
          <View style={styles.modalContainer}>
            <Text style={styles.modalTitle}>Confirmar Retirada</Text>
            <Text style={styles.modalText}>
              Você está prestes a retirar {formatCurrency(balance)} para a conta
              cadastrada:
            </Text>
            {selectedPaymentMethod === "pix" && accountDetails.pixKey ? (
              <Text style={styles.accountDetailsText}>
                {`Conta PIX: ${accountDetails.pixKey}`}
              </Text>
            ) : (
              <Text style={styles.accountDetailsText}>
                {`Conta Bancária: ${accountDetails.bank || ""} - Agência: ${
                  accountDetails.agency || ""
                } - Conta: ${accountDetails.accountNumber || ""}`}
              </Text>
            )}
            <View style={styles.modalButtons}>
              <TouchableOpacity
                style={styles.modalButton}
                onPress={confirmWithdrawal}
              >
                <Text style={styles.modalButtonText}>Confirmar</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, styles.modalButtonCancel]}
                onPress={() => setModalVisible(false)}
              >
                <Text style={styles.modalButtonText}>Cancelar</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

export default ManageBalance;
