import React from "react";
import { View, Text, StyleSheet, Button, Image, BackHandler } from "react-native";
import Constants from "expo-constants";

const exitApp = () => {
  BackHandler.exitApp();
};

const Maintenance = ({ message }) => (
  <View style={styles.container}>
    <Image
      source={require("../../../assets/imgs/Mascote_Retrato_Preto.png")}
      style={styles.image}
    />
    <Text style={styles.title}>Em Manutenção</Text>
    <Text style={styles.message}>{message}</Text>
    <Button
      title="Sair"
      onPress={(exitApp)}
      color="#1A2035"
      style={styles.button}
    />
    <Text style={styles.version}>Versão {Constants.expoConfig.version}</Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
    padding: 20,
  },
  image: {
    width: 200,
    height: 200,
    marginBottom: 20,
  },
  title: {
    color: "#FFF",
    fontSize: 28,
    fontWeight: "bold",
    marginBottom: 10,
  },
  message: {
    color: "#FFF",
    fontSize: 18,
    textAlign: "center",
    marginBottom: 20,
    paddingHorizontal: 20,
  },
  button: {
    paddingHorizontal: 40,
    paddingVertical: 10,
    borderRadius: 5,
  },
  version: {
    position: "absolute",
    bottom: 20,
    color: "#FFF9",
    fontSize: 12,
  },
});

export default Maintenance;
