import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  logoHeader: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  profileButton: {
    padding: 10,
  },
  accountDetailsContainer: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: "#000",
  },
  accountDetailsText: {
    color: "#FFF",
    fontSize: 16,
    marginBottom: 5,
  },
  expandText: {
    color: "#5B2372",
    fontSize: 14,
    marginTop: 5,
  },
  changePaymentButton: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  changePaymentText: {
    color: "#FFFD",
    fontSize: 14,
    marginLeft: 5,
  },
  noPaymentMethodText: {
    color: "#FFFD",
    fontSize: 12,
  },
  expandIcon: {
    marginLeft: "auto",
  },
  balanceContainer: {
    paddingHorizontal: 10,
    paddingTop: 20,
    paddingBottom: 10,
    backgroundColor: "#000",
    alignItems: "center",
  },
  balanceTitle: {
    color: "#FFF",
    fontSize: 18,
    marginBottom: 10,
  },
  balanceAmount: {
    color: "#FFF",
    fontSize: 34,
    fontWeight: "bold",
  },
  withdrawButton: {
    marginTop: 15,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#1A2035",
    paddingVertical: 8,
    paddingHorizontal: 30,
    borderRadius: 5,
  },
  buttonIcon: {
    marginRight: 10,
  },
  withdrawButtonText: {
    color: "#FFF",
    fontSize: 16,
  },
  sectionDivider: {
    height: 1,
    backgroundColor: "#333",
    marginVertical: 10,
  },
  sectionHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 15,
    paddingVertical: 8,
  },
  sectionIcon: {
    marginRight: 10,
  },
  sectionTitleText: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFF",
  },
  transactionsContainer: {
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  transactionsTitle: {
    color: "#FFF",
    fontSize: 20,
    marginBottom: 10,
  },
  transactionItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#000",
    padding: 15,
    marginBottom: 10,
    borderRadius: 8,
    borderColor: "#333",
    borderWidth: 1,
  },
  transactionIconContainer: {
    marginRight: 10,
  },
  transactionDetails: {
    flex: 1,
  },
  transactionTitle: {
    color: "#FFF",
    fontSize: 14,
    marginBottom: 5,
  },
  transactionText: {
    color: "#FFFD",
    fontSize: 12,
    marginBottom: 5,
  },
  transactionDate: {
    color: "#FFFD",
    fontSize: 12,
    marginBottom: 5,
  },
  transactionValue: {
    color: "#FFFD",
    fontSize: 16,
    fontWeight: "bold",
  },
  positive: {
    color: "#FFF",
  },
  negative: {
    color: "#FFF",
  },
  noTransactionsText: {
    color: "#FFFD",
    fontSize: 16,
    textAlign: "center",
    marginTop: 20,
  },
  modalBackground: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    backgroundColor: "#1C1C1C",
    borderRadius: 10,
    padding: 20,
    width: "80%",
    alignItems: "center",
  },
  modalTitle: {
    fontSize: 20,
    color: "#FFF",
    marginBottom: 15,
  },
  modalText: {
    color: "#FFF",
    fontSize: 16,
    marginBottom: 20,
    textAlign: "center",
  },
  modalButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  modalButton: {
    backgroundColor: "#5B2372",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    flex: 1,
    alignItems: "center",
    marginHorizontal: 5,
  },
  modalButtonCancel: {
    backgroundColor: "#373737",
  },
  modalButtonText: {
    color: "#FFF",
    fontSize: 16,
  },
});
