import React, { useState, useEffect } from "react";
import {
  View,
  TextInput,
  Text,
  TouchableOpacity,
  ScrollView,
  Alert,
} from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { Picker } from "@react-native-picker/picker";
import { styles } from "../../../../styles/producer/producerUtils/EditEventStyles";
import { getUserByUsername } from "../../../../services/cruds/users/getUser";
import { getEvent } from "../../../../services/cruds/events/getEvent";
import { getEventLots } from "../../../../services/cruds/events/getEvent";
import { auth } from "../../../../services/utils/firebase/firebaseConfig";

const normalizeUsername = (username) => {
  return username
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, "")
    .toLowerCase();
};

const CourtesyForm = ({ eventUsername }) => {
  const [username, setUsername] = useState("");
  const [lot, setLot] = useState("");
  const [lots, setLots] = useState([]);
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    const fetchLots = async () => {
      try {
        if (!eventUsername) {
          console.error("eventUsername não fornecido");
          return;
        }
        const lotNames = await getEventLots(eventUsername);
        setLots(lotNames);
      } catch (error) {
        console.error("Erro ao buscar lotes: ", error);
      }
    };

    fetchLots();
  }, [eventUsername]);

  const handleAddCourtesyTicket = async () => {
    setIsVerifying(true);
    try {
      const normalizedUsername = normalizeUsername(username);
      const user = await getUserByUsername(normalizedUsername);
      if (!user) {
        Alert.alert("Erro", "Usuário não encontrado.");
        setIsVerifying(false);
        return;
      }

      const event = await getEvent(eventUsername);
      if (!event) {
        Alert.alert("Erro", "Evento não encontrado.");
        setIsVerifying(false);
        return;
      }

      if(event.status !== "Publicado") {
        Alert.alert("Ação não permitida", "Você só pode adicionar novos itens quando o evento estiver publicado.");
        setIsVerifying(false);
        return;
    }

      const ticketData = {
        user: user,
        event: event,
        lot: lot,
      };

      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error("Usuário não autenticado.");
      }
      const idToken = await currentUser.getIdToken();

      const response = await fetch(
        'https://us-central1-rayv-a7c48.cloudfunctions.net/api/addCourtesyTicket',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(ticketData),
        }
      );

      const responseText = await response.text();
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}. Message: ${responseText}`);
      }

      try {
        const data = JSON.parse(responseText);
        console.log("Dados recebidos do servidor:", data);
        Alert.alert("Sucesso", "Ingresso de cortesia adicionado.");
      } catch (jsonError) {
        console.error("Erro ao analisar resposta JSON:", jsonError);
        Alert.alert("Sucesso", "Ingresso de cortesia adicionado, mas a resposta não pôde ser analisada.");
      }

      // Limpar campos
      setUsername("");
      setLot("");
    } catch (error) {
      console.error("Erro ao adicionar ingresso de cortesia:", error);
      Alert.alert("Erro", `Não foi possível adicionar o ingresso de cortesia. ${error.message}`);
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <View style={styles.sectionDivider} />
        <View style={styles.settingItemForm}>
          <MaterialCommunityIcons
            name="gift"
            size={24}
            style={styles.settingIconForm}
          />
          <Text style={styles.screenTitle}>Adicionar Ingresso de Cortesia</Text>
        </View>
        <View style={styles.sectionDivider} />
        <View style={styles.detailsContainer}>
          <Text style={styles.label}>Username do Usuário</Text>
          <View style={styles.readerInputContainer}>
            <TextInput
              style={[styles.input, styles.readerInput]}
              placeholderTextColor="#FFF9"
              placeholder="Digite o username do usuário"
              value={username}
              onChangeText={setUsername}
              editable={!isVerifying}
            />
          </View>

          <Text style={styles.label}>Selecione o Lote</Text>
          <View style={styles.readerInputContainer}>
            <Picker
              selectedValue={lot}
              style={[styles.picker]}
              onValueChange={(itemValue) => setLot(itemValue)}
              enabled={!isVerifying}
            >
              <Picker.Item label="Selecione um lote" value="" />
              {lots.map((lotName, index) => (
                <Picker.Item key={index} label={lotName} value={lotName} />
              ))}
            </Picker>
          </View>

          <TouchableOpacity
            onPress={handleAddCourtesyTicket}
            style={styles.saveButton}
            disabled={isVerifying}
          >
            {isVerifying ? (
              <Text style={[styles.saveButtonText, { color: "#FFF" }]}>
                Verificando...
              </Text>
            ) : (
              <Text style={[styles.saveButtonText, { color: "#FFF" }]}>
                Adicionar Ingresso
              </Text>
            )}
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  );
};

export default CourtesyForm;
