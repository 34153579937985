import React from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";

export const ToggleSwitch = ({ options, value, onSelect }) => {
  return (
    <View style={styles.toggleContainer}>
      {options.map((option) => (
        <TouchableOpacity
          key={option.key}
          onPress={() => onSelect(option.key)}
          style={[
            styles.toggleOption,
            { backgroundColor: value === option.key ? "#FFF" : "transparent" },
          ]}
        >
          <Text
            style={[
              styles.toggleText,
              { color: value === option.key ? "#000" : "#FFF" },
            ]}
          >
            {option.label}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  toggleContainer: {
    flexDirection: "row",
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#FFF",
    overflow: "hidden",
    alignSelf: "center",
    width: "90%",
    marginBottom: 25,
  },
  toggleOption: {
    flex: 1,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  toggleText: {
    fontSize: 16,
    fontWeight: "bold",
  },
});

export default ToggleSwitch;
