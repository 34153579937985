import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    padding: 15,
    backgroundColor: "#141414",
  },
  goBackButton: {
    marginRight: 15,
  },
  headerTitle: {
    fontSize: 18,
    color: "#FFF",
    fontWeight: "bold",
  },
  bannerContainer: {
    width: "100%",
    height: 200,
    backgroundColor: "#1e1e1e",
  },
  bannerImage: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
  },
  bannerPlaceholder: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#333",
  },
  bannerPlaceholderText: {
    color: "#FFF",
    fontSize: 16,
  },
  searchContainer: {
    flexDirection: "row",
    padding: 10,
    alignItems: "center",
    marginHorizontal: 10,
    backgroundColor: "#000",
    borderRadius: 10,
  },
  input: {
    flex: 1,
    padding: 10,
    color: "#FFF",
    backgroundColor: "#2B2B2B",
    borderRadius: 5,
  },
  searchButton: {
    marginLeft: 10,
    padding: 10,
    backgroundColor: "#2B2B2B",
    borderRadius: 5,
  },
  loadingContainer: {
    padding: 20,
    alignItems: "center",
  },
  ticketContainer: {
    backgroundColor: "#000",
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginHorizontal: 20,
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
    borderColor: "#333",
    borderWidth: 1,
  },
  avatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: 10,
  },
  ticketDetails: {
    flex: 1,
  },
  ticketText: {
    fontSize: 13,
    color: "#FFF9",
    marginBottom: 5,
  },
  expandIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
    alignSelf: "flex-end",
  },
  expandedTicketDetails: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: "#000",
    borderRadius: 5,
    marginHorizontal: 20,
    marginBottom: 10,
    borderColor: "#333",
    borderWidth: 1,
  },
  verifyButton: {
    backgroundColor: "#1A2035",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 10,
  },
  verifyButtonText: {
    color: "#FFF",
    fontWeight: "bold",
  },
  scanButtonContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderColor: "#333",
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.25,
    shadowRadius: 5,
    elevation: 5,
    backgroundColor: "#121212",
  },
  scanButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  scanIcon: {
    marginRight: 10,
    width: 50,
    height: 50,
  },
  scanButtonText: {
    color: "#FFF",
    fontWeight: "bold",
    fontSize: 18,
  },
  safeArea: {
    backgroundColor: "#000",
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  logoHeader: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  eventInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 20,
    backgroundColor: "#000",
  },
  eventImage: {
    width: 128,
    height: 72,
    borderRadius: 5,
  },
  eventDetails: {
    flex: 1,
    marginLeft: 20,
  },
  eventName: {
    fontSize: 18,
    color: "#FFF",
    fontWeight: "bold",
  },
  eventDescription: {
    fontSize: 14,
    color: "#AAA",
  },
  sectionDivider: {
    height: 1,
    backgroundColor: "#333",
    marginVertical: 10,
  },
  settingItemForm: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000",
  },
  settingIcon: {
    color: "#FFFD",
    width: 24,
  },
  settingIconForm: {
    color: "#FFFD",
    marginRight: 5,
    width: 24,
  },
  screenTitle: {
    color: "#FFF",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  textInput: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    marginVertical: 10,
    paddingHorizontal: 10,
    color: 'gray',
  },
});
