import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  logoHeader: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  eventInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 20,
    backgroundColor: "#000",
  },
  eventImage: {
    width: 80,
    height: 80,
    borderRadius: 15,
    marginRight: 20,
  },
  eventDetails: {
    flex: 1,
  },
  eventName: {
    fontSize: 18,
    color: "#FFF",
    fontWeight: "bold",
  },
  eventDescription: {
    fontSize: 14,
    color: "#AAA",
  },
  sectionHeader: {
    flexDirection: "row",
    alignItems: "center",
  },
  sectionIcon: {
    marginRight: 10,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  sectionDivider: {
    flex: 1,
    height: 1,
    backgroundColor: "#444",
    marginLeft: 10,
  },
  containerSpace: {
    paddingHorizontal: 20,
  },
  sectionContainer: {
    marginBottom: 15,
  },
  sectionContainerSecond: {
    marginTop: 15,
    marginBottom: 15,
  },
  infoContainer: {
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  infoRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  infoText: {
    fontSize: 14,
    color: "#A0A0A0",
    fontWeight: "bold",
  },
  infoTextSecond: {
    fontSize: 14,
    color: "#E6E6E6",
    marginLeft: 10,
    fontWeight: "500",
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#1A1A1A",
    borderRadius: 10,
    marginHorizontal: 20,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  searchIcon: {
    marginRight: 10,
  },
  searchBar: {
    flex: 1,
    padding: 10,
    fontSize: 16,
    color: "#FFF",
  },
  eventCardContainer: {
    marginHorizontal: 20,
    marginBottom: 10,
    backgroundColor: "#000",
    borderRadius: 10,
  },
  eventCard: {
    flexDirection: "row",
    alignItems: "center",
  },
  eventCardImage: {
    width: 144,
    height: 81,
    borderRadius: 8,
    marginRight: 15,
  },
  eventCardDetails: {
    flex: 1,
  },
  eventCardTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFF",
    marginBottom: 5,
  },
  eventCardInfo: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  eventCardValue: {
    fontSize: 14,
    color: "#FFF",
    marginLeft: 5,
  },
  eventCardTickets: {
    fontSize: 14,
    color: "#FFF",
    marginLeft: 5,
  },
  eventCardStatus: {
    fontSize: 14,
    color: "#FFF",
  },
  expandButton: {
    padding: 10,
  },
  expandedDetails: {
    paddingHorizontal: 15,
    paddingBottom: 15,
  },
  expandedText: {
    fontSize: 14,
    color: "#FFF",
    marginBottom: 5,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    backgroundColor: "#000",
  },
  emptyText: {
    fontSize: 18,
    color: "#bbb",
    textAlign: "center",
  },
});
