import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const checkExistingRefundRequests = async (ticketId) => {
  const requestsQuery = query(
    collection(db, "refundRequests"),
    where("ticketId", "==", ticketId),
    where("status", "==", "Pendente")
  );

  const querySnapshot = await getDocs(requestsQuery);
  return !querySnapshot.empty;
};
