import React from "react";
import { View, Text, TouchableOpacity, StyleSheet, SafeAreaView, StatusBar, Platform } from "react-native";
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Icon from "react-native-vector-icons/Ionicons";

const Header = ({ navigation }) => {
  const insets = useSafeAreaInsets();

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("Home");
    }
  };

  return (
    <SafeAreaView style={[styles.safeArea, { paddingTop: insets.top }]}>
      <StatusBar barStyle="light-content" />
      <View style={styles.container}>
        <TouchableOpacity onPress={() => handleGoBack()} style={styles.iconButton}>
          <View style={styles.iconBackground}>
            <Icon name="arrow-back" size={25} color="#FFFFFF" />
          </View>
        </TouchableOpacity>
        <View style={styles.spacer} />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    backgroundColor: "transparent",
    top: 15,
    zIndex: 1,
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 10,
    backgroundColor: "transparent",
  },
  iconButton: {
    paddingHorizontal: 5,
  },
  iconBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: 20,
    padding: 5,
  },
  headerTitle: {
    flex: 1,
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
  },
  spacer: {
    width: 25,
  },
});

export default Header;
