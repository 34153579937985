import React, { useState, useEffect } from "react";
import {
  View,
  TextInput,
  Text,
  TouchableOpacity,
  Switch,
  Alert,
  ActivityIndicator,
} from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { styles } from "../../../../styles/producer/producerUtils/EditEventStyles";

const LocationForm = ({ data, onNestedInputChange, saveChanges }) => {
  const [loadingCep, setLoadingCep] = useState(false);
  const [previousCep, setPreviousCep] = useState(data.address.zip);

  useEffect(() => {
    setPreviousCep(data.address.zip);
  }, [data.address.zip]);

  const fetchAddress = async (cep) => {
    const url = `https://viacep.com.br/ws/${cep}/json/`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.erro) {
        console.error("CEP não encontrado.");
        Alert.alert("Erro", "CEP não encontrado.");
        return null;
      }
      return data;
    } catch (error) {
      console.error("Erro ao buscar o CEP:", error);
      Alert.alert("Erro", "Erro ao buscar o CEP.");
      return null;
    }
  };

  const handleCepChange = async (text) => {
    setPreviousCep(data.address.zip);
    onNestedInputChange("address.zip", text);
    if (text.length === 8) {
      setLoadingCep(true);
      const addressData = await fetchAddress(text);
      if (addressData) {
        if (
          data.status === "Publicado" &&
          (addressData.localidade !== data.address.city ||
            addressData.uf !== data.address.state)
        ) {
          Alert.alert(
            "Erro",
            "Você não pode alterar a cidade de criação do evento. Para alterar, crie um novo evento."
          );
          onNestedInputChange("address.zip", previousCep); // Restaurar o CEP anterior
        } else {
          fillAddressFields(addressData);
        }
      } else {
        onNestedInputChange("address.zip", previousCep); // Restaurar o CEP anterior se não encontrar
      }
      setLoadingCep(false);
    }
  };

  const fillAddressFields = (data) => {
    onNestedInputChange("address.street", data.logradouro);
    onNestedInputChange("address.neighborhood", data.bairro);
    onNestedInputChange("address.city", data.localidade);
    onNestedInputChange("address.state", data.uf);
  };

  const validateForm = () => {
    const { zip, street, number, neighborhood, city, state } = data.address;
    if (!zip || !street || !number || !neighborhood || !city || !state) {
      Alert.alert("Erro", "Todos os campos são obrigatórios.");
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (validateForm()) {
      saveChanges();
    }
  };

  return (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <MaterialCommunityIcons
          name="map-legend"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Editar Localização</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        <Text style={styles.label}>Nome</Text>
        <TextInput
          style={styles.input}
          placeholderTextColor="#FFF9"
          value={data.address.name}
          onChangeText={(text) => onNestedInputChange("address.name", text)}
          placeholder="Nome"
        />

        <Text style={styles.label}>CEP</Text>
        <TextInput
          style={styles.input}
          placeholderTextColor="#FFF9"
          keyboardType="numeric"
          value={data.address.zip}
          onChangeText={handleCepChange}
          placeholder="CEP"
        />
        {loadingCep && <ActivityIndicator size="small" color="#FFF" />}

        <Text style={styles.label}>Rua/Av</Text>
        <TextInput
          style={styles.input}
          placeholderTextColor="#FFF9"
          value={data.address.street}
          onChangeText={(text) => onNestedInputChange("address.street", text)}
          placeholder="Rua/Av"
        />

        <Text style={styles.label}>Número</Text>
        <TextInput
          style={styles.input}
          placeholderTextColor="#FFF9"
          keyboardType="numeric"
          value={data.address.number}
          onChangeText={(text) => onNestedInputChange("address.number", text)}
          placeholder="Número"
        />

        <Text style={styles.label}>Complemento</Text>
        <TextInput
          style={styles.input}
          placeholderTextColor="#FFF9"
          value={data.address.complement}
          onChangeText={(text) =>
            onNestedInputChange("address.complement", text)
          }
          placeholder="Complemento"
        />

        <Text style={styles.label}>Bairro</Text>
        <TextInput
          style={styles.input}
          placeholderTextColor="#FFF9"
          value={data.address.neighborhood}
          onChangeText={(text) =>
            onNestedInputChange("address.neighborhood", text)
          }
          placeholder="Bairro"
        />

        <Text style={[styles.label, styles.labelBlocked]}>Cidade</Text>
        <TextInput
          style={[styles.input, styles.inputBlocked]}
          placeholderTextColor="#FFF9"
          value={data.address.city}
          editable={false}
        />

        <Text style={[styles.label, styles.labelBlocked]}>Estado</Text>
        <TextInput
          style={[styles.input, styles.inputBlocked]}
          placeholderTextColor="#FFF9"
          value={data.address.state}
          editable={false}
        />

        <TouchableOpacity onPress={handleSave} style={styles.saveButton}>
          <Text style={styles.saveButtonText}>Salvar Localização</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default LocationForm;
