import React, { Component } from "react";
import {
  ScrollView,
  View,
  Text,
  Alert,
  Image,
  TouchableOpacity,
  Switch,
} from "react-native";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../services/utils/firebase/firebaseConfig";
import { getUser } from "../../../services/cruds/users/getUser";
import { getEventUsername } from "../../../services/cruds/events/getEvent";
import { createEvent } from "../../../services/cruds/events/createEvent";
import EventDetailsForm from "../../../components/producer/producerUtils/addEvent/EventDetailsForm";
import ContactInformationForm from "../../../components/producer/producerUtils/addEvent/ContactInformationForm";
import LotsForm from "../../../components/producer/producerUtils/addEvent/LotsForm";
import AdditionalDetailsForm from "../../../components/producer/producerUtils/addEvent/AdditionalDetailsForm";
import SubmitEventForm from "../../../components/producer/producerUtils/addEvent/SubmitEventForm";
import {
  updateEventImage,
  uploadEventImage,
} from "../../../services/cruds/events/updateEvent";
import Header from "../../../components/producer/Header";
import { styles } from "../../../styles/producer/producerUtils/AddEventStyles";
import { SafeAreaView } from "react-native-safe-area-context";
import * as ImagePicker from "expo-image-picker";

class AddEvent extends Component {
  state = {
    currentStep: 1,
    name: "",
    username: "",
    description: "",
    status: "Rascunho",
    privacy: "public",
    dates: {
      start: new Date(),
      end: new Date(),
    },
    address: {
      name: "",
      zip: "",
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
    },
    organizer: {
      contact: {
        email: "",
        phone: "",
      },
      organizationId: "",
    },
    lots: {},
    absorbFee: false,
    eventImage: null,
    isDatePickerVisible: false,
    isStartTimePickerVisible: false,
    isEndTimePickerVisible: false,
    citySelectorVisible: false,
    termsAccepted: false,
  };

  componentDidMount() {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        this.setState({
          organizer: { ...this.state.organizer, organizationId: uid },
        });
        const userData = await getUser(uid);
        this.setState({ userData });
      }
    });
  }

  handleInputChange = (field, value) => {
    const fields = field.split(".");
    this.setState((prevState) => {
      let newState = { ...prevState };
      let current = newState;

      for (let i = 0; i < fields.length - 1; i++) {
        if (!current[fields[i]]) {
          current[fields[i]] = {};
        }
        current = current[fields[i]];
      }

      current[fields[fields.length - 1]] = value;
      return newState;
    });
  };

  handleDatePicking = (type, value) => {
    this.setState((prevState) => ({
      ...prevState,
      dates: {
        ...prevState.dates,
        [type]: value,
      },
    }));
    this.togglePicker(type + "Visible");
  };

  togglePicker = (field) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));
  };

  handleSelectCity = (city) => {
    this.handleInputChange("address.city", city);
    this.setState({ citySelectorVisible: false });
  };

  toggleCitySelector = () => {
    this.setState((prevState) => ({
      citySelectorVisible: !prevState.citySelectorVisible,
    }));
  };

  handleImagePicker = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [16, 9],
      quality: 1,
    });

    if (!result.canceled) {
      this.setState({ eventImage: result.assets[0].uri });
    } else {
      Alert.alert(
        "Carregamento cancelado",
        "A escolha da imagem foi cancelada."
      );
    }
  };

  handleNextStep = async () => {
    if (this.state.currentStep === 1) {
      const { name, username, description, dates } = this.state;
      if (!name || !username || !description || !dates.start || !dates.end) {
        Alert.alert("Erro", "Preencha todos os campos obrigatórios.");
        return;
      }
      const isUsernameAvailable = await getEventUsername(username);
      if (isUsernameAvailable) {
        Alert.alert(
          "Erro",
          "O username já está em uso. Por favor, escolha outro."
        );
        return;
      }
    } else if (this.state.currentStep === 2) {
      const { email, phone } = this.state.organizer.contact;
      const { zip, street, number, neighborhood, city } = this.state.address;
      if (
        !email ||
        !phone ||
        !zip ||
        !street ||
        !number ||
        !neighborhood ||
        !city
      ) {
        Alert.alert("Erro", "Preencha todos os campos obrigatórios.");
        return;
      }
    } else if (this.state.currentStep === 3) {
      const { lots } = this.state;
      if (Object.keys(lots).length === 0) {
        Alert.alert("Erro", "Adicione pelo menos um lote de ingressos.");
        return;
      }
    }

    if (this.state.currentStep < 5) {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep + 1,
      }));
    }
  };

  handlePrevStep = () => {
    if (this.state.currentStep > 1) {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep - 1,
      }));
    }
  };

  submitEvent = async () => {
    if (!this.state.termsAccepted) {
      Alert.alert(
        "Erro",
        "Você deve aceitar os termos de uso para enviar o evento."
      );
      return;
    }

    const uploadResult = this.state.eventImage
      ? await uploadEventImage(this.state.eventImage, this.state.username)
      : null;
    if (this.state.eventImage && !uploadResult) {
      Alert.alert(
        "Erro de Upload",
        "Não foi possível fazer upload da imagem do evento."
      );
      return;
    }

    const eventDetails = this.prepararDetalhesDoEvento(uploadResult);
    try {
      await createEvent(eventDetails);
      Alert.alert("Criado com Sucesso", "Evento está agora como rascunho!");
      this.props.navigation.goBack();
    } catch (error) {
      Alert.alert("Erro", "Falha ao adicionar evento.");
      console.error("Erro ao adicionar evento:", error);
    }
  };

  prepararDetalhesDoEvento = (imageUrl) => {
    const {
      isDatePickerVisible,
      isStartTimePickerVisible,
      isEndTimePickerVisible,
      citySelectorVisible,
      startVisible,
      endVisible,
      isEndDatePickerVisible,
      userData,
      currentStep,
      termsAccepted,
      ...rest
    } = this.state;
    return {
      ...rest,
      eventImage: imageUrl || "default_image.png",
    };
  };

  renderHeader = (userData) => (
    <>
      <View style={styles.eventInfoContainer}>
        <Image
          source={
            userData && userData.avatarUrl != null
              ? { uri: userData.avatarUrl }
              : require("../../../../assets/imgs/Avatar_Macaco.png")
          }
          style={styles.eventImage}
        />
        <View style={styles.eventDetails}>
          <Text style={styles.eventName}>
            {userData ? userData.fullName : "Carregando..."}
          </Text>
          <Text style={styles.eventDescription}>Tela de Criação do Evento</Text>
        </View>
      </View>
    </>
  );

  renderStep = () => {
    switch (this.state.currentStep) {
      case 1:
        return (
          <EventDetailsForm
            state={this.state}
            handleInputChange={this.handleInputChange}
            handleDatePicking={this.handleDatePicking}
            togglePicker={this.togglePicker}
            handleNextStep={this.handleNextStep}
          />
        );
      case 2:
        return (
          <ContactInformationForm
            state={this.state}
            handleInputChange={this.handleInputChange}
            handleNextStep={this.handleNextStep}
            handlePrevStep={this.handlePrevStep}
          />
        );
      case 3:
        return (
          <LotsForm
            state={this.state}
            handleInputChange={this.handleInputChange}
            handleNextStep={this.handleNextStep}
            handlePrevStep={this.handlePrevStep}
          />
        );
      case 4:
        return (
          <AdditionalDetailsForm
            state={this.state}
            handleImagePicker={this.handleImagePicker}
            handleNextStep={this.handleNextStep}
            handlePrevStep={this.handlePrevStep}
          />
        );
      case 5:
        return (
          <SubmitEventForm
            state={this.state}
            submitEvent={this.submitEvent}
            handlePrevStep={this.handlePrevStep}
            handleInputChange={this.handleInputChange}
            navigation={this.props.navigation}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { userData } = this.state;
    return (
      <SafeAreaView style={styles.container}>
        <Header navigation={this.props.navigation} />
        {this.renderHeader(userData)}
        <ScrollView style={styles.scrollView}>{this.renderStep()}</ScrollView>
      </SafeAreaView>
    );
  }
}

export default AddEvent;
