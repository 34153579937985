import React, { Component } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Alert,
  SafeAreaView,
  Image,
  ActivityIndicator,
  FlatList,
  StatusBar,
} from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import Icon from "react-native-vector-icons/Ionicons";
import IconMaterial from "react-native-vector-icons/MaterialCommunityIcons";
import { styles } from "../../../styles/utils/reader/ReaderAutenticationStyles";
import logo from "../../../../assets/imgs/Logo_Macaco2.png";
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";
import { getEvent } from "../../../services/cruds/events/getEvent";
import { updateEvent } from "../../../services/cruds/events/updateEvent";
import { getTicketById } from "../../../services/cruds/tickets/getTickets";
import { validarTicket } from "../../../services/cruds/tickets/ticketFuncs";

class ReaderAutentication extends Component {
  state = {
    searchTicketId: "",
    ticketData: null,
    loading: false,
    eventImage: "",
    username: "",
    code: "",
  };

  async componentDidMount() {
    const { eventId, username } = this.props.route.params;
    this.setState({ username });

    try {
      const eventData = await getEvent(eventId);

      if (eventData) {
        this.setState({ eventImage: eventData.eventImage || "", eventName: eventData.name || "" });
      } else {
        Alert.alert("Erro", "Evento não encontrado.");
      }
    } catch (error) {
      console.error("Erro ao buscar evento:", error);
      Alert.alert("Erro", "Houve um erro ao buscar o evento.");
    }
  }

  handleSearch = async () => {
    const { searchTicketId } = this.state;
    const { eventId } = this.props.route.params;

    if (!searchTicketId) {
      Alert.alert("Erro", "Por favor, insira um ID de ingresso.");
      return;
    }

    this.setState({ loading: true });

    try {
      const eventData = await getEvent(eventId);

      if (eventData) {
        const ticketHistoryData = eventData.ticketHistory[searchTicketId];

        if (ticketHistoryData) {
          const ticketData = await getTicketById(searchTicketId);

          if (ticketData) {
            this.setState({
              ticketData: {
                ...ticketData,
                id: searchTicketId,
                verified: ticketHistoryData.verified || false,
                ...ticketHistoryData,
              },
              loading: false,
            });
          } else {
            this.setState({ ticketData: null, loading: false });
            Alert.alert("Erro", "Detalhes do ingresso não encontrados.");
          }
        } else {
          this.setState({ ticketData: null, loading: false });
          Alert.alert("Erro", "Ingresso não encontrado para este evento.");
        }
      } else {
        this.setState({ ticketData: null, loading: false });
        Alert.alert("Erro", "Evento não encontrado.");
      }
    } catch (error) {
      console.error("Erro ao buscar o ingresso:", error);
      this.setState({ loading: false });
      Alert.alert("Erro", "Houve um erro ao buscar o ingresso.");
    }
  };

  renderProfile = () => {
    const defaultImageEvent = require("../../../../assets/imgs/fundo_evento.png");
    const eventImage =
      this.state.eventImage ? { uri: this.state.eventImage }
        : defaultImageEvent;
    return (
      <View style={styles.eventInfoContainer}>
        <Image source={eventImage} style={styles.eventImage} />
        <View style={styles.eventDetails}>
          <Text style={styles.eventName}>
            {this.state.eventName ? this.state.eventName : "Carregando..."}
          </Text>
          <Text style={styles.eventDescription}>
            Tela de Colaborador do Evento
          </Text>
        </View>
      </View>
    );
  };

  handleTicketClick = async () => {
    const { ticketData, username } = this.state;
    const { eventId } = this.props.route.params;

    if (ticketData.QR_CODE) {
      Alert.alert(
        "Ingresso já verificado",
        "Este ingresso já foi verificado anteriormente."
      );
      return;
    }

    Alert.alert(
      "Verificar Ingresso",
      "Deseja verificar este ingresso?",
      [
        {
          text: "Cancelar",
          style: "cancel",
        },
        {
          text: "Sim",
          onPress: async () => {
            try {
                await validarTicket(ticketData.id, this.state.code);

                this.setState({ searchTicketId: "", code: "", ticketData: null });
                Alert.alert("Ingresso verificado com sucesso!");
            } catch (error) {
              console.error("Erro ao atualizar o status do ingresso:", error);
              Alert.alert("Erro", "Houve um erro ao verificar o ingresso.");
            }
          },
        },
      ],
      { cancelable: false }
    );
  };

  toggleExpandTicket = (ticketId) => {
    this.setState((prevState) => ({
      expandedTicketId: prevState.expandedTicketId === ticketId ? null : ticketId,
    }));
  };

  renderTicket = ({ item }) => {
    const isExpanded = this.state.expandedTicketId === item.id;

    return (
      <View>
        <TouchableOpacity
          onPress={() => this.toggleExpandTicket(item.id)}
          style={styles.ticketContainer}
        >
          <Image
            source={require("../../../../assets/imgs/Avatar_Macaco.png")}
            style={styles.avatar}
          />
          <View style={styles.ticketDetails}>
            <Text style={styles.ticketText}>
              <Ionicons name="qr-code" size={12} color="#A0A0A0" /> QR Code:{" "}
              {item.QR_CODE ? "Sim" : "Não"}
            </Text>
            <Text style={styles.ticketText}>
              <Ionicons name="pricetag" size={12} color="#A0A0A0" /> Lote:{" "}
              {item.lotName}
            </Text>
            <Text style={styles.ticketText}>
              <Ionicons name="cash" size={12} color="#A0A0A0" /> Preço: R$
              {parseFloat(item.lotPrice).toFixed(2)}
            </Text>
            <Ionicons
              name={isExpanded ? "chevron-up" : "chevron-down"}
              size={24}
              color="#FFF9"
              style={styles.expandIcon}
            />
          </View>
        </TouchableOpacity>
        {isExpanded && (
          <View style={styles.expandedTicketDetails}>
            {item.payDay && (
              <Text style={styles.ticketText}>
                <Ionicons name="calendar" size={12} color="#A0A0A0" /> Data de
                Pagamento: {new Date(item.payDay.seconds * 1000).toLocaleString()}
              </Text>
            )}
            <Text style={styles.ticketText}>
              <Ionicons name="card" size={12} color="#A0A0A0" /> Método de
              Pagamento: {item.paymentMethod}
            </Text>
            <Text style={styles.ticketText}>
              <Ionicons name="checkmark-circle" size={12} color="#A0A0A0" /> Status:{" "}
              {item.status}
            </Text>
            <Text style={styles.ticketText}>
              <Ionicons name="person" size={12} color="#A0A0A0" /> Usuário do
              Evento: {item.usernameEvent}
            </Text>
            <Text style={styles.ticketText}>
              <Ionicons name="person" size={12} color="#A0A0A0" /> Usuário do
              Ingresso: {item.usernameUser}
            </Text>
            <TextInput
              style={styles.textInput}
              placeholder="Digite a chave de acesso"
              placeholderTextColor="#AAA"
              value={this.state.code}
              onChangeText={(text) => this.setState({ code: text })}
            />
            <TouchableOpacity
              style={styles.verifyButton}
              onPress={this.handleTicketClick}
            >
              <Text style={styles.verifyButtonText}>Verificar Ingresso</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  };

  render() {
    const { searchTicketId, ticketData, loading, eventImage } = this.state;
    const { navigation } = this.props;
    const { eventId } = this.props.route.params;

    return (
      <SafeAreaView style={styles.container}>
        <View>
          <SafeAreaView style={styles.safeArea}>
            <StatusBar barStyle="light-content" />
            <View style={styles.containerHeader}>
              <TouchableOpacity
                onPress={() => navigation.goBack()}
                style={styles.backButton}
              >
                <Icon name="arrow-back" size={25} color="#FFFFFF" />
              </TouchableOpacity>

              <TouchableOpacity onPress={() => navigation.navigate("Home")}>
                <Image source={logoTexto} resizeMode="contain" style={styles.logoHeader} />
              </TouchableOpacity>

              <TouchableOpacity>
                <Icon
                  name="person-outline"
                  size={25}
                  color="#000"
                  style={styles.goBackButton}
                />
              </TouchableOpacity>
            </View>
          </SafeAreaView>
        </View>

        {this.renderProfile()}

        <View style={styles.sectionDivider} />
        <View style={styles.settingItemForm}>
          <MaterialIcons
            name="personal-injury"
            size={24}
            style={styles.settingIconForm}
          />
          <Text style={styles.screenTitle}>Reader Colaborador</Text>
        </View>
        <View style={styles.sectionDivider} />

        <View style={styles.searchContainer}>
          <TextInput
            style={styles.input}
            placeholder="Digite o ID do ingresso"
            placeholderTextColor="#888"
            value={searchTicketId}
            onChangeText={(text) => this.setState({ searchTicketId: text })}
          />
          <TouchableOpacity
            style={styles.searchButton}
            onPress={this.handleSearch}
          >
            <Ionicons name="search" size={24} color="#FFF" />
          </TouchableOpacity>
        </View>
        {loading && (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color="#FFF" />
          </View>
        )}
        {ticketData && (
          <FlatList
            data={[ticketData]}
            renderItem={this.renderTicket}
            keyExtractor={(item) => item.id}
            contentContainerStyle={{ 
              paddingBottom: 100,
              marginTop: 20
            }}
          />
        )}
        <View style={styles.scanButtonContainer}>
          <TouchableOpacity
            style={styles.scanButton}
            onPress={() =>
              navigation.navigate("QRCodeScanner", { evento: { id: eventId } })
            }
          >
            <Image source={logo} style={styles.scanIcon} />
            <Text style={styles.scanButtonText}>Escanear QR Code</Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    );
  }
}

export default ReaderAutentication;
