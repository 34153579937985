import { db, auth } from "../firebase/firebaseConfig";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  deleteUser,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  collection,
  query,
  where,
  doc,
  getDocs,
  serverTimestamp,
  updateDoc,
  increment,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { createUser } from "../../cruds/users/createUser";

const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const cleanName = (name) => {
  return removeAccents(name)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, ""); // Remove caracteres especiais
};

export const cadastrar = async (
  email,
  password,
  confirmPassword,
  fullName,
  registration,
  username,
  dataDeNascimento,
  exibirErro,
  termsAcept,
  accountType,
  onUserVerified
) => {
  // State and verification logic
  let state = {
    userId: "",
    fullName: fullName,
    searchName: cleanName(fullName),
    avatarUrl: "",
    registration: registration,
    registrationDate: "",
    username: username,
    accountType: accountType,
    isPublic: true,
    progressProfile: 0,
    phoneNumber: "",
    birthDate: "",
    gender: "",
    termsAcept: "true",
    descriptionProfile: "",
    following: [],
    followers: [],
    address: {
      name: "",
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
    reviews: {
      rating: 0,
      usersRating: 0,
    },
    stickers: [],
    favoriteEvents: [],
    eventHistory: [],
    rewardsPoints: 0,
    reportCount: 0,
  };

  let verificacaoRegistration = false;

  if (accountType == "Producer") {
    verificacaoRegistration =
      verificarCpf(registration) || verificarCNPJ(registration);
  } else if (accountType == "User") {
    verificacaoRegistration = verificarCpf(registration);
  }

  const cpfJaCadastrado = await verificarCpfNoBanco(registration, accountType);
  const usernameJaCadastrado = await verificarUsernameNoBanco(username);

  if (
    verificarSenha(password, confirmPassword) &&
    verificacaoRegistration &&
    isUsernameValid(username) &&
    cpfJaCadastrado &&
    usernameJaCadastrado
  ) {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await sendEmailVerification(user);
      console.log(
        `Email de verificação enviado para ${email}. Por favor, verifique sua caixa de entrada.`
      );

      let emailVerified = false;
      const maxRetries = 300; // Max retries (30 seconds) 5 minutos
      let retries = 0;

      const interval = setInterval(async () => {
        try {
          await user.reload();
        } catch (error) {
          if (error.code === "auth/user-token-expired") {
            return;
          }
        }

        retries++;

        if (user.emailVerified) {
          clearInterval(interval);
          emailVerified = true;

          registration = cripCpf(registration);
          state.userId = user.uid;
          state.registration = registration;
          state.registrationDate = serverTimestamp();
          state.birthDate = Timestamp.fromDate(new Date(dataDeNascimento));

          await createUser({ ...state });
          await assignChallenges(state.userId, state.accountType);
          onUserVerified();
        }

        if (retries >= maxRetries) {
          clearInterval(interval);
          if (!emailVerified) {
            await deleteUser(user);
            exibirErro(
              "Email não verificado a tempo. Tente se registrar novamente."
            );
          }
        }
      }, 1000);

      return user;
    } catch (error) {
      let message = "";
      console.error("Erro durante o registro:", error);

      if (error.message.includes("auth/invalid-email"))
        message = "Email inválido.";
      else if (error.message.includes("auth/email-already-in-use"))
        message = "Este email já está em uso.";
      else message = error.message;

      exibirErro(message);
      return null;
    }
  } else {
    console.log("Campos inválidos!");

    let message = "Campos inválidos!";

    if (!isUsernameValid(username)) message = "Username inválido.";
    else if (!verificarCpf(registration) && accountType == "User")
      message = "CPF inválido.";
    else if (
      accountType == "Producer" &&
      !verificarCpf(registration) &&
      !verificarCNPJ(registration)
    )
      message = "CPF / CNPJ inválido.";
    else if (!verificarSenha(password, confirmPassword))
      message = "As senhas não conferem.";
    else if (!usernameJaCadastrado) message = "Username já utilizado.";
    else if (accountType == "User" && !cpfJaCadastrado)
      message = "CPF já cadastrado.";
    else if (accountType == "Producer" && !cpfJaCadastrado)
      message = "CPF / CNPJ já cadastrado.";

    throw new Error(message);
  }
};

export const login = (email, password, exibirErro) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      console.log("Usuário logado com sucesso! UID:", user.uid);
      return user;
    })
    .catch((error) => {
      console.error("Erro no login:", error);
      if (error.code === "auth/user-disabled") {
        exibirErro("Conta temporariamente suspensa");
      } else {
        exibirErro("Email ou senha inválidos!");
      }
      return null;
    });
};

export function verificarSenha(password, confirmPassword) {
  if (password === confirmPassword) {
    if (password.length >= 8) {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_#])[A-Za-z\d@$!%*?&_#]+$/;

      if (regex.test(password)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function verificarCpf(cpf) {
  cpf = cpf.replace(/[^\d]+/g, ""); // Remove caracteres não numéricos

  if (cpf.length !== 11) return false; // Verifica se tem 11 números após remover caracteres não numéricos

  if (/^(\d)\1{10}$/.test(cpf)) return false; // Verifica se todos os números são iguais, o que invalida o CPF

  let soma = 0;
  let resto;

  // Primeira parte da validação
  for (let i = 1; i <= 9; i++)
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;

  // Segunda parte da validação
  for (let i = 1; i <= 10; i++)
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;

  return true; // O CPF é válido
}

export function verificarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, ""); // Remove caracteres não numéricos

  // Verifica se o CNPJ está no formato correto
  if (cnpj.length !== 14) return false;

  // Lista de CNPJs inválidos conhecidos
  if (
    [
      "00000000000000",
      "11111111111111",
      "22222222222222",
      "33333333333333",
      "44444444444444",
      "55555555555555",
      "66666666666666",
      "77777777777777",
      "88888888888888",
      "99999999999999",
    ].indexOf(cnpj) !== -1
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

export function isUsernameValid(username) {
  if (username.length < 4) return false;

  const regex = /^[a-z0-9_.-]+$/;

  return regex.test(username);
}

export function cripCpf(cpf) {
  const crip = "5726193408";
  cpf = cpf.replace(/[^\d]+/g, "");

  let arrayCpf = cpf.split("");
  let arrayCrip = crip.split("");

  for (let indice = 0; indice <= cpf.length; indice++) {
    let caractereComoInt = parseInt(cpf.charAt(indice), 10);
    arrayCpf[indice] = arrayCrip[caractereComoInt];
  }

  return arrayCpf.join("");
}

export function decripCpf(cpf) {
  const decrip = "8426703195";
  cpf = cpf.replace(/[^\d]+/g, "");

  let arrayCpf = cpf.split("");
  let arrayCrip = decrip.split("");

  for (let indice = 0; indice <= cpf.length; indice++) {
    let caractereComoInt = parseInt(cpf.charAt(indice), 10);
    arrayCpf[indice] = arrayCrip[caractereComoInt];
  }

  return arrayCpf.join("");
}

export function verificaIdade(dataDeNascimento) {
  const dataNascimento = new Date(dataDeNascimento);
  const dataAtual = new Date();
  const diff = dataAtual.getTime() - dataNascimento.getTime();
  const idade = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));

  return idade;
}

export async function verificarCpfNoBanco(cpf, accountType) {
  const cpfCod = cripCpf(cpf);
  const usuariosRef = collection(db, "usersData");

  const queryCpf = query(
    usuariosRef,
    where("registration", "==", cpfCod),
    where("accountType", "==", accountType)
  );
  const querySnapshot = await getDocs(queryCpf);

  let cpfUnico = true;
  querySnapshot.forEach((doc) => {
    cpfUnico = false;
  });

  return cpfUnico;
}

export async function verificarUsernameNoBanco(username) {
  const usuariosRef = collection(db, "usersData");

  const querySnapshot = await getDocs(query(usuariosRef));

  let usernameUnico = true;
  querySnapshot.forEach((doc) => {
    if (doc.data().username == username) {
      usernameUnico = false;
    }
  });

  return usernameUnico;
}

export async function verificarEmailNoBanco(email) {
  const usuariosRef = collection(db, "usersData");

  const queryEmail = query(usuariosRef, where("email", "==", email));
  const querySnapshot = await getDocs(queryEmail);

  let emailUnico = true;
  querySnapshot.forEach((doc) => {
    emailUnico = false;
  });

  return emailUnico;
}

export const assignChallenges = async (userId, accountType) => {
  const type = accountType === "User" ? "client" : "producer";
  const challengesRef = doc(db, "challengesData", type);

  const challengesSnapshot = await getDoc(challengesRef);

  if (challengesSnapshot.exists()) {
    const challengesData = challengesSnapshot.data();

    let newChallenges = {};

    if (accountType === "Producer") {
      if (challengesData.publishEvent) {
        newChallenges.publishEvent = {
          progress: 0,
          completed: false,
        };
      }
      for (let i = 1; i <= 3; i++) {
        const pioneerKey = `pioneer${i > 1 ? i : ""}`;
        if (
          challengesData[pioneerKey] &&
          parseInt(challengesData[pioneerKey].available) > 0
        ) {
          newChallenges[pioneerKey] = { completed: true };
          await updateDoc(challengesRef, {
            [`${pioneerKey}.available`]: increment(-1),
          });
          break;
        }
      }
    } else if (accountType === "User") {
      if (challengesData.buyTicket) {
        newChallenges.buyTicket = {
          progress: 0,
          completed: false,
        };
      }
      for (let i = 1; i <= 3; i++) {
        const pioneerKey = `pioneer${i > 1 ? i : ""}`;
        if (
          challengesData[pioneerKey] &&
          parseInt(challengesData[pioneerKey].available) > 0
        ) {
          newChallenges[pioneerKey] = { completed: true };
          await updateDoc(challengesRef, {
            [`${pioneerKey}.available`]: increment(-1),
          });
          break;
        }
      }
    }

    await updateDoc(doc(db, "usersData", userId), {
      challenges: newChallenges,
    });
  }
};
