import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const createWithdrawalRequest = async (uid, amount, paymentMethod, paymentDetails) => {
  try {
    const withdrawalData = {
      uid,
      requestDate: Timestamp.now(),
      status: "Pendente",
      amount,
      paymentMethod,
      paymentDetails,
    };

    await addDoc(collection(db, "withdrawalRequests"), withdrawalData);
    return true;
  } catch (error) {
    console.error("Erro ao solicitar retirada: ", error);
    return false;
  }
};
