import { doc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const addMessageToChat = async (eventId, chatType, message) => {
  try {
    const chatId = `${eventId}${chatType}`;
    const chatRef = doc(db, "eventsChats", chatId);
    await updateDoc(chatRef, {
      messages: arrayUnion(message)
    });
  } catch (error) {
    console.error("Erro ao adicionar mensagem ao chat:", error);
    throw error;
  }
};

export const updateMessageInChat = async (eventId, chatType, messageId, newText) => {
  try {
    const chatId = `${eventId}${chatType}`;
    const chatRef = doc(db, "eventsChats", chatId);
    const chatSnap = await getDoc(chatRef);
    const messages = chatSnap.data().messages;
    const updatedMessages = messages.map(msg => (msg.id === messageId ? { ...msg, message: newText } : msg));
    await updateDoc(chatRef, { messages: updatedMessages });
  } catch (error) {
    console.error("Erro ao atualizar mensagem no chat:", error);
    throw error;
  }
};