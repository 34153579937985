import { doc, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const deleteChat = async (eventId, chatType) => {
  try {
    const chatId = `${eventId}${chatType}`;
    const chatRef = doc(db, "eventsChats", chatId);
    await deleteDoc(chatRef);
  } catch (error) {
    console.error("Erro ao excluir chat:", error);
    throw error;
  }
};

export const deleteMessageFromChat = async (eventId, chatType, messageId) => {
  try {
    const chatId = `${eventId}${chatType}`;
    const chatRef = doc(db, "eventsChats", chatId);
    const chatSnap = await getDoc(chatRef);
    const messages = chatSnap.data().messages;
    const updatedMessages = messages.filter(msg => msg.id !== messageId);
    await updateDoc(chatRef, { messages: updatedMessages });
  } catch (error) {
    console.error("Erro ao deletar mensagem no chat:", error);
    throw error;
  }
};