import React, { useState } from "react";
import { View, Text, TouchableOpacity, Alert, TextInput } from "react-native";
import { styles } from "../../../../styles/producer/producerUtils/EditEventStyles";
import {
  deleteEvent,
} from "../../../../services/cruds/events/deleteEvent";
import {
  requestEventCancellation,
} from "../../../../services/cruds/cancellationRequests/createCancellationRequests";
import {
  MaterialCommunityIcons,
} from "@expo/vector-icons";

const DeleteEventForm = ({ data, navigation }) => {
  const canDelete = data.status === "Rascunho";
  const [cancelReason, setCancelReason] = useState("");

  const handleDeleteEvent = async () => {
    Alert.alert(
      "Confirmar Exclusão",
      "Tem certeza que deseja deletar este evento? Esta ação é irreversível.",
      [
        { text: "Cancelar", style: "cancel" },
        {
          text: "Deletar",
          onPress: async () => {
            try {
              await deleteEvent(data.username, data.organizer.organizationId);
              Alert.alert("Sucesso", "Evento deletado com sucesso!");
              navigation.navigate("Home");
            } catch (error) {
              Alert.alert("Erro", "Falha ao tentar deletar o evento.");
            }
          },
        },
      ]
    );
  };

  const handleRequestEventCancellation = async () => {
    if (cancelReason.trim() === "") {
      Alert.alert(
        "Erro",
        "Por favor, forneça um motivo para a solicitação de cancelamento."
      );
      return;
    }

    try {
      await requestEventCancellation(data.username, cancelReason, data.organizer.organizationId);
      Alert.alert(
        "Solicitação Enviada",
        "Sua solicitação de cancelamento foi enviada para análise."
      );
      navigation.navigate("Home");
    } catch (error) {
      Alert.alert(
        "Erro",
        "Falha ao tentar enviar solicitação de cancelamento."
      );
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <MaterialCommunityIcons
          name="trash-can-outline"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>
          {!canDelete ? "Solicitar Cancelamento" : "Deletar Evento"}
        </Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        <Text style={styles.label}>
          {canDelete
            ? "Tem certeza que deseja deletar este evento?"
            : "Tem certeza que deseja solicitar o cancelamento deste evento?"}
        </Text>
        {!canDelete && (
          <View>
            <TextInput
              style={styles.input}
              placeholder="Motivo do cancelamento"
              placeholderTextColor="#FFF9"
              multiline
              value={cancelReason}
              onChangeText={setCancelReason}
            />
          </View>
        )}
        <TouchableOpacity
          onPress={
            canDelete ? handleDeleteEvent : handleRequestEventCancellation
          }
          style={styles.saveButton}
        >
          <Text style={styles.saveButtonText}>
            {canDelete ? "Deletar Evento" : "Solicitar Cancelamento"}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default DeleteEventForm;
