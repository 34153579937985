import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const getProducerBalance = async (userId) => {
  try {
    const docRef = doc(db, "producersBalance", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return {
        valorLiquidoTotal: data.valorLiquidoTotal,
        historico: data.historico || [],
      };
    } else {
      console.error("Nenhum documento encontrado para o produtor:", userId);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter o saldo do produtor:", error);
    return null;
  }
};

export const onProducerBalanceSnapshot = (userId, callback) => {
  const docRef = doc(db, "producersBalance", userId);
  return onSnapshot(docRef, (doc) => {
    if (doc.exists()) {
      const data = doc.data();
      callback({
        valorLiquidoTotal: data.valorLiquidoTotal,
        historico: data.historico || [],
      });
    } else {
      console.error("Nenhum dado encontrado para o produtor:", userId);
      callback(null);
    }
  });
};
