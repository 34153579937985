import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    top: 0,
    backgroundColor: "#000",
  },
  scrollContainer: {
    paddingBottom: 20,
  },
  eventImage: {
    top: -30,
    width: "100%",
    height: 200,
    resizeMode: "cover",
    marginBottom: -30,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#000",
    paddingTop: 5,
    paddingHorizontal: 15,
    marginBottom: 10,
  },
  titleAndSubtitleContainer: {
    flexDirection: "column",
  },
  titleText: {
    textAlign: "left",
    fontSize: 20,
    fontWeight: "bold",
    color: "#FFFFFF",
    marginRight: 8,
  },
  infoContainer: {
    paddingLeft: 9,
    flexDirection: "row",
  },
  infoIcon: {
    right: 4,
    top: 4,
  },
  subtitleText: {
    textAlign: "left",
    fontSize: 14,
    color: "#FFFFFF",
  },
  iconsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 100,
  },
  sectionHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 15,
    paddingVertical: 8,
  },
  sectionIcon: {
    marginRight: 6,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  sectionDivider: {
    flex: 1,
    height: 1,
    backgroundColor: "#FFFFFF",
    marginLeft: 15,
  },
  lotSelectionContainer: {
    flex: 1,
    backgroundColor: "#000",
  },
  lotCard: {
    backgroundColor: "#121212",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 10,
    marginVertical: 5,
    marginHorizontal: 5,
    borderColor: "#121212",
    borderWidth: 1,
  },
  lotDetailContainer: {
    flex: 1,
  },
  lotName: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  lotPrice: {
    color: "#999",
    fontWeight: "bold",
    fontSize: 14,
  },
  taxInfo: {
    color: "#888",
    fontSize: 10,
    marginTop: 2,
  },
  lotControls: {
    flexDirection: "row",
    alignItems: "center",
  },
  quantity: {
    color: "#FFF",
    fontSize: 16,
    marginHorizontal: 10,
  },
  totalContainer: {
    backgroundColor: "#121212",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 10,
    marginVertical: 5,
    marginHorizontal: 5,
    borderColor: "#121212",
    borderWidth: 1,
  },
  totalValue: {
    color: "#FFF",
    fontSize: 20,
    fontWeight: "bold",
  },
  paymentMethods: {
    flexDirection: "column",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 10,
  },
  paymentMethodSelect: {
    backgroundColor: "#202940",
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 10,
    marginHorizontal: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderColor: "#202940",
    borderWidth: 1,
  },
  paymentMethod: {
    backgroundColor: "#000",
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 10,
    marginHorizontal: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderColor: "#121212",
    borderWidth: 1,
  },
  paymentMethodSecondSelect: {
    flex: 1,
    backgroundColor: "#202940",
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 10,
    marginHorizontal: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 10,
    borderColor: "#202940",
    borderWidth: 1,
  },
  paymentMethodSecond: {
    flex: 1,
    backgroundColor: "#000",
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 10,
    marginHorizontal: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 10,
    borderColor: "#121212",
    borderWidth: 1,
  },
  paymentMethodHalf: {
    width: "45%",
  },
  paymentText: {
    flex: 1,
    marginLeft: 10,
    color: "#FFF",
    fontSize: 14,
    fontWeight: "500",
  },
  row: {
    flexDirection: "row",
    justifyContent: "center",
  },
  sectionDividerSecond: {
    flex: 1,
    height: 1,
    backgroundColor: "#121212",
    marginVertical: 5,
  },
  lotTitleText: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFF",
  },
  lotTitleTextSecond: {
    textAlign: "center",
    fontSize: 13,
    fontWeight: "bold",
    color: "#999",
  },
  payButton: {
    backgroundColor: "#052552",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 20,
    borderColor: "#052552",
    borderWidth: 1,
  },
  payButtonText: {
    color: "#FFF",
    fontSize: 18,
    fontWeight: "bold",
  },
  backButton: {
    backgroundColor: "#000",
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 70,
    borderColor: "#333",
    borderWidth: 1,
  },
  backButtonText: {
    color: "#FFF",
    fontSize: 15,
    fontWeight: "bold",
  },
  watermarkContainer: {
    alignItems: "center",
    marginVertical: 10,
  },
  watermarkText: {
    color: "#AAA",
    fontSize: 12,
  },
  feesContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#000",
    paddingHorizontal: 15,
    paddingVertical: 5,
    borderRadius: 10,
    marginHorizontal: 10,
  },
  feesDetails: {
    flex: 1,
  },
  feesTitle: {
    color: "#FFF",
    marginLeft: 15,
    fontSize: 16,
    fontWeight: "bold",
  },
  feesDescription: {
    color: "#A7A7A7",
    marginLeft: 15,
    fontSize: 14,
  },
});
