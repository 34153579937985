import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const requestEventCancellation = async (eventUsernameDoc, reason, organizerId) => {
  try {
    const cancelRequestRef = collection(db, "cancellationRequests");
    await addDoc(cancelRequestRef, {
      eventUsername: eventUsernameDoc,
      reason,
      requestDate: new Date(),
      organizerId
    });
    return true;
  } catch (error) {
    console.error("Erro ao solicitar cancelamento:", error);
    throw error;
  }
};
