import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  SafeAreaView,
  ScrollView,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { styles } from "../../../styles/producer/producerBalance/ManageBalanceHelpStyles";
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";

const ManageBalanceHelp = ({ navigation }) => {
  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.containerHeader}>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButtonHeader}
        >
          <Icon name="arrow-back" size={25} color="#FFFFFF" />
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => navigation.navigate("Home")}
          style={styles.logoContainer}
        >
          <Image
            source={logoTexto}
            resizeMode="contain"
            style={styles.logoHeader}
          />
        </TouchableOpacity>

        <TouchableOpacity style={styles.profileButtonHeader}>
          <Icon name="person-outline" size={25} color="#000" />
        </TouchableOpacity>
      </View>

      <ScrollView contentContainerStyle={styles.container}>
        <Text style={styles.header}>Gerenciamento de Saldo</Text>

        <Text style={styles.subheader}>Informações sobre a retirada:</Text>
        <View style={styles.textContainer}>
          <Icon
            name="information-circle-outline"
            size={20}
            color="#FFFFFF"
            style={styles.icon}
          />
          <Text style={styles.text}>
            O saldo disponível só será liberado quando o evento for finalizado.
          </Text>
        </View>
        <View style={styles.textContainer}>
          <Icon
            name="information-circle-outline"
            size={20}
            color="#FFFFFF"
            style={styles.icon}
          />
          <Text style={styles.text}>
            Após solicitar a retirada, o valor será creditado em até 3 dias
            úteis na conta cadastrada no momento da solicitação.
          </Text>
        </View>
        <View style={styles.textContainer}>
          <Icon
            name="information-circle-outline"
            size={20}
            color="#FFFFFF"
            style={styles.icon}
          />
          <Text style={styles.text}>
            O saldo sempre será retirado totalmente.
          </Text>
        </View>
        <View style={styles.textContainer}>
          <Icon
            name="information-circle-outline"
            size={20}
            color="#FFFFFF"
            style={styles.icon}
          />
          <Text style={styles.text}>
            Por favor, certifique-se de que seu método de recebimento está
            corretamente preenchido para evitar problemas com a retirada.
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ManageBalanceHelp;
