import React from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { styles } from "../../../../styles/producer/producerUtils/EditEventStyles";

const GeneralDataForm = ({ data, onInputChange, saveChanges }) => {
  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : undefined}
    >
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <View style={styles.sectionDivider} />  
        <View style={styles.settingItemForm}>
          <MaterialIcons
            name="settings"
            size={24}
            style={styles.settingIconForm}
          />
          <Text style={styles.screenTitle}>Editar Dados Gerais</Text>
        </View>
        <View style={styles.sectionDivider} />
        <View style={styles.formContainer}>
          <Text style={styles.label}>Nome do Evento</Text>
          <TextInput
            style={styles.input}
            placeholderTextColor="#FFF9"
            placeholder="Insira o nome"
            value={data.name}
            onChangeText={(text) => {
              onInputChange("name", text);
              onInputChange(
                "searchName",
                text
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replace(/\s+/g, "")
                  .toLowerCase()
              );
            }}
          />

          <Text style={styles.label}>Descrição do Evento</Text>
          <TextInput
            style={[styles.input, styles.descriptionInput]}
            placeholderTextColor="#FFF9"
            placeholder="Escreva a descrição do evento..."
            multiline
            numberOfLines={10}
            value={data.description}
            onChangeText={(text) => onInputChange("description", text)}
          />

          <TouchableOpacity onPress={saveChanges} style={styles.saveButton}>
            <Text style={styles.saveButtonText}>Salvar Alterações</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default GeneralDataForm;
