import React, { useState, useEffect, useRef } from "react";
import { View, Text, TouchableOpacity, TextInput, ScrollView, StyleSheet, Alert } from "react-native";
import { createChat } from "../../../services/cruds/eventsChats/createEventsChats";
import { getChat } from "../../../services/cruds/eventsChats/getEventsChats";
import { addMessageToChat, updateMessageInChat } from "../../../services/cruds/eventsChats/updateEventsChats";
import { deleteMessageFromChat } from "../../../services/cruds/eventsChats/deleteEventsChats";
import { Timestamp } from "firebase/firestore";
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import Icon from "react-native-vector-icons/Ionicons";

const ChatManager = ({ eventId, chatType, userId, eventStatus }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [chatExists, setChatExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastLoadedMessage, setLastLoadedMessage] = useState(null);
  const scrollViewRef = useRef();

  useEffect(() => {
    const fetchInitialChat = async () => {
      setLoading(true);
      try {
        const chatData = await getChat(eventId, chatType, null);
        if (chatData && chatData.messages) {
          setMessages(chatData.messages);
          setChatExists(true);
          if (chatData.messages.length > 0) {
            setLastLoadedMessage(chatData.messages[chatData.messages.length - 1].timestamp);
          }
        } else {
          setChatExists(false);
        }
      } catch (error) {
        console.error("Erro ao obter chat:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchInitialChat();
  }, [eventId, chatType]);

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      if (editingMessageId) {
        await updateMessageInChat(eventId, chatType, editingMessageId, newMessage);
        setMessages(prevMessages =>
          prevMessages.map(msg =>
            msg.id === editingMessageId ? { ...msg, message: newMessage } : msg
          )
        );
        setEditingMessageId(null);
      } else {
        const message = {
          id: new Date().getTime().toString(), // Cria um ID único para a mensagem
          userId,
          message: newMessage,
          timestamp: Timestamp.now()
        };
        await addMessageToChat(eventId, chatType, message);
        setMessages(prevMessages => [...prevMessages, message]);
      }
      setNewMessage("");
      scrollViewRef.current.scrollToEnd({ animated: true });
    }
  };

  const handleEditMessage = (messageId, messageText) => {
    setNewMessage(messageText);
    setEditingMessageId(messageId);
  };

  const handleDeleteMessage = async (messageId) => {
    Alert.alert(
      "Confirmação",
      "Você tem certeza que deseja apagar esta mensagem?",
      [
        {
          text: "Cancelar",
          style: "cancel"
        },
        {
          text: "Apagar",
          onPress: async () => {
            await deleteMessageFromChat(eventId, chatType, messageId);
            setMessages(prevMessages =>
              prevMessages.filter(msg => msg.id !== messageId)
            );
          },
          style: "destructive"
        }
      ]
    );
  };

  const formatDateToBrasilia = (timestamp) => {
    const date = moment(timestamp.toDate()).tz("America/Sao_Paulo").locale("pt-br");
    return date.format("DD/MM/YYYY [às] HH:mm");
  };

  const loadMoreMessages = async () => {
    if (loading || !lastLoadedMessage) return;
    setLoading(true);
    try {
      const chatData = await getChat(eventId, chatType, lastLoadedMessage);
      if (chatData && chatData.messages.length > 0) {
        setMessages(prevMessages => [...chatData.messages, ...prevMessages]);
        setLastLoadedMessage(chatData.messages[chatData.messages.length - 1].timestamp);
      }
    } catch (error) {
      console.error("Erro ao carregar mais mensagens:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateChat = async () => {
    try {
      await createChat(eventId, chatType, eventStatus);
      setChatExists(true);
    } catch (error) {
      console.error("Erro ao criar chat:", error);
    }
  };

  return (
    <View style={styles.container}>
      {!chatExists ? (
        <View style={styles.createChatContainer}>
          <Text style={styles.placeholderText}>Chat não existe.</Text>
          {eventStatus === "Publicado" ? (
            <TouchableOpacity style={styles.createChatButton} onPress={handleCreateChat}>
              <Text style={styles.createChatButtonText}>Permitir Chat</Text>
            </TouchableOpacity>
          ) : <Text style={styles.placeholderText}>Publique o Evento para Liberar</Text>}
        </View>
      ) : (
        <>
          <ScrollView
            style={styles.messagesContainer}
            ref={scrollViewRef}
            onContentSizeChange={() => scrollViewRef.current.scrollToEnd({ animated: true })}
            onScroll={({ nativeEvent }) => {
              if (nativeEvent.contentOffset.y === 0) {
                loadMoreMessages();
              }
            }}
            scrollEventThrottle={400}
          >
            {messages.map((msg, index) => (
              <View key={index} style={styles.message}>
                <Text style={styles.messageText}>{msg.message}</Text>
                <Text style={styles.messageTimestamp}>{formatDateToBrasilia(msg.timestamp)}</Text>
                <View style={styles.messageActions}>
                  <TouchableOpacity onPress={() => handleEditMessage(msg.id, msg.message)}>
                    <Icon name="create-outline" size={20} color="#FFFD" />
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => handleDeleteMessage(msg.id)}>
                    <Icon name="trash-outline" size={20} color="#FFFD" />
                  </TouchableOpacity>
                </View>
              </View>
            ))}
          </ScrollView>
          <View style={styles.inputContainer}>
            <TextInput
              style={styles.input}
              value={newMessage}
              onChangeText={setNewMessage}
              placeholder="Digite uma mensagem..."
              placeholderTextColor="#999"
            />
            <TouchableOpacity style={styles.sendButton} onPress={handleSendMessage}>
              <Icon name="send" size={20} color="#FFF" />
            </TouchableOpacity>
          </View>
        </>
      )}
    </View>
  );
};

export default ChatManager;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: "#000",
  },
  createChatContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  createChatButton: {
    padding: 10,
    paddingHorizontal: 30,
    backgroundColor: "#1A2035",
    borderRadius: 5,
  },
  createChatButtonText: {
    color: "#fff",
    fontSize: 16,
  },
  messagesContainer: {
    flex: 1,
    marginBottom: 10,
  },
  message: {
    padding: 10,
    backgroundColor: "#121212",
    borderRadius: 5,
    marginBottom: 5,
  },
  messageText: {
    fontSize: 14,
    color: "#fff",
  },
  messageTimestamp: {
    fontSize: 10,
    marginTop: 5,
    color: "#666",
    textAlign: "right",
  },
  messageActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 5,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    flex: 1,
    borderColor: "#333",
    borderWidth: 1,
    padding: 10,
    borderRadius: 5,
    marginRight: 10,
    color: "#fff",
  },
  sendButton: {
    padding: 10,
    backgroundColor: "#052552",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  placeholderText: {
    textAlign: "center",
    marginBottom: 5,
    fontSize: 16,
    color: "#666",
  },
});
