import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000000",
  },
  contentContainer: {
    padding: 20,
    justifyContent: "center",
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  logoHeader: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  input: {
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    height: 40,
    marginBottom: 4,
    borderRadius: 15,
    paddingHorizontal: 10,
  },
  inputContainer: {
    position: "relative",
    marginBottom: 10,
  },
  inputError: {
    borderColor: "red",
    borderWidth: 1,
  },
  helpIcon: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  toggleSwitch: {
    marginBottom: 20,
    backgroundColor: "#2B2B2B",
    borderRadius: 10,
  },
  optionLabel: {
    fontSize: 16,
    color: "white",
    marginBottom: 10,
  },
  optionButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  optionButton: {
    backgroundColor: "#606060",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    width: "48%",
    alignItems: "center",
  },
  selectedOptionButton: {
    backgroundColor: "#808080",
  },
  optionButtonText: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
  },
  selectedOptionButtonText: {
    color: "black",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "#1A2035",
    padding: 12,
    borderRadius: 10,
    alignItems: "center",
    marginTop: 0,
    width: "60%",
    alignSelf: "center",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    backgroundColor: "transparent",
    borderColor: "#000",
    borderWidth: 1,
    padding: 0,
  },
  checkboxError: {
    backgroundColor: "transparent",
    borderColor: "red",
    borderWidth: 1,
    borderRadius: 5,
  },
  termsRow: {
    flexDirection: "row",
  },
  termsText: {
    color: "#fff",
    fontSize: 16,
  },
  termsTextUnderline: {
    color: "#fff",
    textDecorationLine: "underline",
    fontSize: 16,
  },
  errorText: {
    color: "red",
    fontSize: 11,
    marginLeft: 10,
  },
  passwordHelperText: {
    color: "#BBB",
    fontSize: 12,
    marginTop: 4,
    textAlign: "left",
    width: "90%",
    alignSelf: "center",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "#1C1C1C",
    borderRadius: 10,
    padding: 20,
    width: "80%",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 18,
    color: "#FFFFFF",
    marginBottom: 15,
    marginRight: 10,
  },
  modalText: {
    color: "#FFFFFF",
    fontSize: 16,
    textAlign: "center",
    marginBottom: 15,
  },
  modalButton: {
    backgroundColor: "#808080",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 10,
    width: "100%",
  },
  modalButtonText: {
    color: "#FFFFFF",
    fontSize: 16,
  },
});
