import React, { Component } from "react";
import { View, SafeAreaView } from "react-native";
import Header from "../../../components/general/home_search/Header";
import ProfileTop from "../../../components/profile/profileGeneral/ProfileTop";
import ProfileBody from "../../../components/profile/profileGeneral/ProfileBody";
import { styles } from "../../../styles/profile/profileView/ProfileStyles";
import { auth } from "../../../services/utils/firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { onUserProfileSnapshot } from "../../../services/cruds/users/getUser";

export default class Perfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      fullName: "",
      avatarUrl: "",
      registration: "",
      registrationDate: "",
      username: "",
      accountType: "",
      isPublic: "",
      progressProfile: "",
      contactEmail: "",
      phoneNumber: "",
      birthDate: "",
      gender: "",
      descriptionProfile: "",
      following: [],
      followers: [],
      pendingFollowers: [],
      address: {
        street: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        state: "",
        country: "",
        zip: "",
      },
      reviews: {
        rating: 0,
        usersRating: 0,
      },
      stickers: [],
      favoriteEvents: [],
      eventHistory: [],
      hiddenEvents: [],
      rewardsPoints: "",
      challenges: {},
    };
  }

  componentDidMount() {
    this.focusListener = this.props.navigation.addListener("focus", () => {
      const updatedUserData = this.props.route.params?.updatedUserData;
      if (updatedUserData) {
        this.identifyProfileToLoad();
      }
    });
    this.identifyProfileToLoad();
  }

  componentWillUnmount() {
    if (this.focusListener) {
      this.focusListener();
    }
    if (this.unsubscribeUser) {
      this.unsubscribeUser();
    }
    if (this.authListener) {
      this.authListener();
    }
  }

  identifyProfileToLoad = () => {
    if (this.authListener) {
      this.authListener();
    }
    this.authListener = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("Usuário autenticado:", user.uid);
        this.setState({ userId: user.uid });
        this.loadUserProfile(user.uid);
      } else {
        console.log("Nenhum usuário autenticado");
      }
    });
  };

  loadUserProfile = (userId) => {
    if (this.unsubscribeUser) {
      this.unsubscribeUser();
    }
    this.unsubscribeUser = onUserProfileSnapshot(userId, (userData) => {
      if (userData) {
        this.setState({ ...userData });
      } else {
        console.log("Nenhum dado encontrado para o usuário:", userId);
      }
    });
  };

  onSettingsPress = async () => {
    this.props.navigation.navigate("Settings");
  };

  onHomePress = () => {
    this.props.navigation.navigate("Home");
  };

  onTicketPress = () => {
    const { userId, accountType } = this.state;
    if (accountType === "Producer") {
      this.props.navigation.navigate("ProducerStatistics", {
        producerId: userId,
      });
    } else if (userId !== "") {
      this.props.navigation.navigate("MyTickets");
    } else {
      this.props.navigation.navigate("Login");
    }
  };

  render() {
    const {
      userId,
      fullName,
      avatarUrl,
      username,
      progressProfile,
      descriptionProfile,
      accountType,
      isPublic,
      eventHistory,
      hiddenEvents,
      followers,
      following,
      pendingFollowers,
      registrationDate,
      rewardsPoints,
      stickers,
      address,
      reviews,
      contactEmail,
      phoneNumber,
      challenges,
    } = this.state;

    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.headerContainer}>
          <Header
            onSettingsPress={this.onSettingsPress}
            onHomePress={this.onHomePress}
            onTicketPress={this.onTicketPress}
            visible="true"
          />
        </View>
        <ProfileTop
          isVisited={false}
          userId={userId}
          avatarUrl={avatarUrl}
          fullName={fullName}
          username={username}
          progressProfile={progressProfile}
          descriptionProfile={descriptionProfile}
          accountType={accountType}
          isPublic={isPublic}
          eventHistory={eventHistory}
          hiddenEvents={hiddenEvents}
          followers={followers}
          following={following}
          pendingFollowers={pendingFollowers}
          registrationDate={registrationDate}
          rewardsPoints={rewardsPoints}
          reviews={reviews}
          navigation={this.props.navigation}
          currentUser={userId}  
          isCurrentUser={true}
        />
        <ProfileBody
          navigation={this.props.navigation}
          accountType={accountType}
          avatarUrl={avatarUrl}
          isPublic={isPublic}
          hiddenEvents={hiddenEvents}
          stickers={stickers}
          rewardsPoints={rewardsPoints}
          challenges={challenges}
          address={address}
          contactEmail={contactEmail}
          phoneNumber={phoneNumber}
          isVisited={false}
          isCurrentUser={true}  
        />
      </SafeAreaView>
    );
  }
}
