import React from "react";
import { View, TouchableOpacity } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { styles } from "../../../../styles/ticket/ticketUtils/TicketResultStyles";

export default function Header({ navigation }) {
  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("Home");
    }
  };
  return (
  <View style={styles.containerHeader}>
    <TouchableOpacity onPress={() => handleGoBack()} style={styles.iconButton}>
      <View style={styles.iconBackgroundHeader}>
        <Icon name="arrow-back" size={25} color="#FFFFFF" />
      </View>
    </TouchableOpacity>
    <View style={styles.spacerHeader} />
  </View>);
}

