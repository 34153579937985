import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 10,
    backgroundColor: "#000",
  },
  safeArea: {
    backgroundColor: "#000",
  },
  logoHeader: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  containerSpace: {
    paddingHorizontal: 15,
  },
  scrollViewContent: {
    paddingBottom: 80, // Espaço extra para o botão fixo
  },
  loadingContainer: {
    flex: 1,
    backgroundColor: "#000",
  },
  errorContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1e1e1e",
  },
  sectionContainer: {
    marginTop: 15,
  },
  sectionDividerSecond: {
    flex: 1,
    height: 1,
    backgroundColor: "#333",
    marginVertical: 5,
  },
  sectionContainerSecond: {
    marginTop: 15,
    marginBottom: 15,
  },
  content: {
    marginBottom: 10,
  },
  infoRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  infoText: {
    fontSize: 14,
    color: "#A0A0A0",
    marginLeft: 10,
    fontWeight: "bold",
  },
  infoTextSecond: {
    fontSize: 14,
    color: "#E6E6E6",
    marginLeft: 10,
    fontWeight: "500",
    flex: 1, // Ajuste para evitar texto cortado
  },
  qrCodeContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
    padding: 10,
    borderRadius: 10,
  },
  mascotImage: {
    width: 50,
    height: 50,
    resizeMode: "contain",
    marginRight: 10,
  },
  downloadButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderColor: "#333",
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.25,
    shadowRadius: 5,
    elevation: 5,
    backgroundColor: "#121212",
  },
  downloadButtonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "bold",
  },
  errorText: {
    fontSize: 18,
    color: "red",
    textAlign: "center",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  },
  modalContent: {
    backgroundColor: "#000",
    padding: 20,
    borderRadius: 10,
    width: "80%",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  safeAreaHeader: {
    backgroundColor: "transparent", // Tornando a SafeAreaView transparente
    top: 15,
    zIndex: 1,
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 10,
    backgroundColor: "transparent",
  },
  iconBackgroundHeader: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: 20,
    padding: 5,
  },
  spacerHeader: {
    width: 25,
  },
  eventImage: {
    top: -30,
    width: "100%",
    height: 200,
    resizeMode: "cover",
    marginBottom: -30,
  },
  sectionHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 15,
    paddingTop: 8,
  },
  sectionIcon: {
    marginRight: 10,
  },
  sectionTitle: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFF",
  },
  refundButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 8,
    paddingHorizontal: 15,
    backgroundColor: "#121212",
    borderRadius: 8,
    marginBottom: 10,
    marginHorizontal: 10,
  },
  refundButtonText: {
    color: "#fff",
    fontSize: 18,
    marginLeft: 10,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#fff",
    marginBottom: 15,
    textAlign: "center",
  },
  refundInput: {
    width: "100%",
    height: 40,
    borderColor: "#FFF9",
    color: "#fff",
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
  refundSubmitButton: {
    flexDirection: "row",
    backgroundColor: "#1A2035",
    paddingVertical: 8,
    paddingHorizontal: 40,
    borderRadius: 10,
    alignItems: "center",
    marginVertical: 10,
  },
  refundSubmitButtonText: {
    color: "#FFF",
    marginLeft: 10,
  },
  confirmationText: {
    color: "#fff",
    fontSize: 16,
    marginVertical: 5,
  },
  confirmButton: {
    backgroundColor: "#1A2035",
    paddingVertical: 8,
    paddingHorizontal: 40,
    borderRadius: 10,
    alignItems: "center",
    marginVertical: 10,
  },
  confirmButtonText: {
    color: "#fff",
    fontSize: 18,
  },
  cancelButton: {
    backgroundColor: "#121212",
  },
  transferButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 8,
    paddingHorizontal: 15,
    backgroundColor: "#202940",
    borderRadius: 8,
    marginBottom: 10,
    marginHorizontal: 10,
  },
  transferButtonText: {
    color: "#fff",
    fontSize: 18,
    marginLeft: 10,
  },
  buttonClose: {
    marginTop: 5,
  },
  buttonCloseText: {
    color: "#fff",
  },
});
