import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    backgroundColor: "#000",
    borderTopWidth: 0,
  },
  iconWrapper: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#121212",
    borderTopWidth: 2,
  },
  mascot: {
    width: 50,
    height: 50,
    resizeMode: "contain",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
  },
  loadingText: {
    color: "#999",
    fontSize: 18,
    textAlign: "center",
  },

  noInternetContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
  },
  noInternetText: {
    fontSize: 18,
    color: "#999",
  },
});
