import React from 'react';
import { View, Text, TouchableOpacity, Image, ScrollView, SafeAreaView, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Ionicons from "react-native-vector-icons/Ionicons";
import { styles } from "../../../styles/producer/producerUtils/AddEventHelpStyles";
import logoTexto from '../../../../assets/imgs/Logo_Texto3.png'; 

const AddEventHelp = ({ navigation }) => {
  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.containerHeader}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButtonHeader}>
          <Ionicons name="arrow-back" size={25} color="#FFFFFF" />
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigation.navigate('Home')} style={styles.logoContainer}>
          <Image source={logoTexto} resizeMode="contain" style={styles.logoHeader} />
        </TouchableOpacity>

        <TouchableOpacity style={styles.profileButtonHeader}>
          <Icon name="user-circle" size={25} color="#000" />
        </TouchableOpacity>
      </View>

      <ScrollView contentContainerStyle={styles.container}>
        <Text style={styles.header}>Ajuda para Adicionar Evento</Text>
        <Text style={styles.subheader}>Passo a Passo:</Text>
        
        {renderStep("calendar-plus", "Nome do Evento", "Insira o nome do seu evento. Este será o título que aparecerá para os usuários.")}
        {renderStep("user-tag", "Username do Evento", "O username deve ser único e em letras minúsculas, sem espaços ou caracteres especiais.")}
        {renderStep("file-alt", "Descrição do Evento", "Descreva seu evento detalhadamente. Isso ajuda os usuários a entenderem o que esperar.")}
        {renderStep("clock", "Data e Horário", "Selecione as datas e horários de início e término do evento. Certifique-se de que estão corretos.")}
        {renderStep("map-marked-alt", "Local do Evento", "Preencha o CEP para buscar automaticamente o endereço, ou insira manualmente os detalhes do local.")}
        {renderStep("phone-alt", "Contato", "Insira um e-mail e telefone válidos para contato. O telefone deve estar no formato (11) 91234-5678.")}
        {renderStep("ticket-alt", "Lotes de Ingressos", "Adicione os diferentes lotes de ingressos, definindo preços, quantidades e visibilidade.")}
        {renderStep("percentage", "Taxas de Serviço", "Escolha se deseja absorver as taxas de serviço ou repassá-las ao cliente.")}
        {renderStep("file-contract", "Termos de Uso", "Certifique-se de ler e aceitar os termos de uso antes de enviar o evento para análise.")}

        <Text style={styles.subheader}>Informações Adicionais:</Text>
        {renderInfo("envelope-open-text", "CEP", "O CEP deve ser válido e conter 8 dígitos. Ao inserir, o endereço será preenchido automaticamente.")}
        {renderInfo("user-secret", "Username", "O username do evento deve ser único e em letras minúsculas, sem espaços ou caracteres especiais. Isso facilita a busca e o compartilhamento do evento.")}
        {renderInfo("image", "Imagem do Evento", "A imagem deve estar no formato 16:9 e será usada como capa do evento.")}
        {renderInfo("credit-card", "Taxas de Serviço", "As taxas podem variar dependendo do método de pagamento. Por exemplo, 8% para cartão de crédito, 8% + R$ 3,50 para boleto e 5% para PIX.")}

        <Text style={styles.finalText}>
          Seguindo essas instruções, você garantirá que seu evento seja adicionado corretamente e sem problemas.
        </Text>
      </ScrollView>
    </SafeAreaView>
  );

  function renderStep(iconName, title, description) {
    return (
      <View style={styles.textContainer}>
        <Icon name={iconName} size={20} color="#FFFC" style={styles.icon} />
        <Text style={styles.text}>{title}: {description}</Text>
      </View>
    );
  }

  function renderInfo(iconName, title, description) {
    return (
      <View style={styles.textContainer}>
        <Icon name={iconName} size={20} color="#FFFC" style={styles.icon} />
        <Text style={styles.text}>{title}: {description}</Text>
      </View>
    );
  }
};

export default AddEventHelp;