import React, { useState } from "react";
import {
  View,
  TextInput,
  Text,
  Switch,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { styles } from "../../../../styles/producer/producerUtils/EditEventStyles";

const PoliciesForm = ({ data, onNestedInputChange, saveChanges }) => {
  const refundPolicy = data.refundPolicy || {};
  const eventRules = data.eventRules || {};
  const isPublished = data.status === "Publicado";

  const handlePartialRefundToggle = (value) => {
    onNestedInputChange("refundPolicy.allowPartialRefund", value);
    if (!value) {
      onNestedInputChange("refundPolicy.partialRefundRate", "");
    }
  };

  return (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <Ionicons
          name="document-lock"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Editar Políticas</Text>
      </View>
      <View style={styles.sectionDivider} />
      <ScrollView contentContainerStyle={styles.detailsContainer}>
        {/* Política de Idade Mínima */}
        <Text style={styles.label}>Idade Mínima</Text>
        <View style={styles.switchRow}>
          <Text style={styles.switchLabel}>Livre Para Todos</Text>
          <Switch
            style={styles.switch}
            trackColor={{ false: "#767577", true: "#202940" }}
            thumbColor={
              eventRules.minimumAge === "Livre" ? "#052552" : "#f4f3f4"
            }
            ios_backgroundColor="#202940"
            value={eventRules.minimumAge === "Livre"}
            onValueChange={(value) =>
              onNestedInputChange("eventRules.minimumAge", value ? "Livre" : "")
            }
            disabled={isPublished}
          />
        </View>
        {eventRules.minimumAge !== "Livre" && (
          <View>
            <Text style={styles.label}>Insira a Idade Mínima</Text>
            <TextInput
              style={styles.input}
              placeholderTextColor="#FFF9"
              placeholder="18"
              keyboardType="numeric"
              value={eventRules.minimumAge || ""}
              editable={!isPublished}
              onChangeText={(text) =>
                onNestedInputChange("eventRules.minimumAge", text)
              }
            />
          </View>
        )}

        {/* Sistema de Reembolso */}
        <Text style={styles.label}>Política de Reembolso</Text>
        <Text style={styles.refundText}>
          Devolução{" "}
          {refundPolicy.allowPartialRefund ? "total ou parcial" : "total"} até
          48 horas antes do evento, se fora do prazo de 7 dias após a compra.
        </Text>
        <Text style={styles.refundText}>
          Devolução 100% até 7 dias após a compra.
        </Text>
        <Text style={styles.label}>Permitir Reembolso Parcial</Text>
        <View style={styles.switchRow}>
          <Text style={styles.switchLabel}>Reter Porcentagem da Devolução</Text>
          <Switch
            style={styles.switch}
            trackColor={{ false: "#767577", true: "#202940" }}
            thumbColor={refundPolicy.allowPartialRefund ? "#052552" : "#f4f3f4"}
            ios_backgroundColor="#202940"
            value={refundPolicy.allowPartialRefund || false}
            onValueChange={handlePartialRefundToggle}
            disabled={isPublished}
          />
        </View>
        {refundPolicy.allowPartialRefund && (
          <View>
            <Text style={styles.label}>Insira a Taxa de Devolução</Text>
            <TextInput
              style={styles.input}
              placeholderTextColor="#FFF9"
              placeholder="Taxa de devolução parcial (%)"
              keyboardType="numeric"
              value={refundPolicy.partialRefundRate || ""}
              editable={!isPublished}
              onChangeText={(text) =>
                onNestedInputChange("refundPolicy.partialRefundRate", text)
              }
            />
          </View>
        )}

        {/* Regras do Evento */}
        <Text style={styles.label}>Regras do Evento</Text>
        <TextInput
          style={[styles.input, styles.textArea]}
          placeholderTextColor="#FFF9"
          placeholder="Adicione regras específicas"
          multiline
          value={eventRules.rules || ""}
          editable={!isPublished}
          onChangeText={(text) => onNestedInputChange("eventRules.rules", text)}
        />

        {!isPublished ? (
          <TouchableOpacity onPress={saveChanges} style={styles.saveButton}>
            <Text style={styles.saveButtonText}>Salvar Alterações</Text>
          </TouchableOpacity>
        ) : (
          <View style={styles.detailsContainerSticker}>
            <Text style={styles.labelWarning}>
              Edição bloqueada após publicação
            </Text>
          </View>
        )}
      </ScrollView>
    </View>
  );
};

export default PoliciesForm;
