import React, { Component } from "react";
import {
  View,
  Text,
  TextInput,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  Alert,
  Image,
  Modal,
  ActivityIndicator,
  BackHandler,
} from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import IconMaterial from "react-native-vector-icons/MaterialCommunityIcons";
import * as ImagePicker from "expo-image-picker";
import moment from "moment-timezone";
import { styles } from "../../../styles/profile/profileUtils/EditProfileStyles";
import { auth } from "../../../services/utils/firebase/firebaseConfig";
import { onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
import { getUser } from "../../../services/cruds/users/getUser";
import {
  updateUser,
  followUser,
  cancelFollowRequest,
} from "../../../services/cruds/users/updateUser";
import SaveImageModal from "../../../components/profile/profileUtils/modalSaveImage";
import ProgressBar from "../../../components/profile/profileUtils/progressBar";
import CustomList from "../../../components/profile/profileUtils/completeList";
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";
import Constants from "expo-constants";
import { decripCpf } from "../../../services/utils/secondary/AutenticacaoFuncs";

export default class EditProfile extends Component {
  state = {
    userId: "",
    username: "",
    fullName: "",
    searchName: "",
    showModal: false,
    showImageModal: false,
    descriptionProfile: "",
    accountType: "",
    avatarUrl: "",
    activeSection: null,
    updateData: false,
    phoneNumber: "",
    phoneNumberOk: false,
    phoneCp: "",
    progressProfile: 0,
    address: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      zip: "",
    },
    addressCopy: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      zip: "",
    },
    reviews: {
      rating: 0,
      usersRating: 0,
    },
    email: "",
    cpf: "",
    birthDate: "",
    gender: "",
    isPublic: false,
    registrationDate: "",
    eventHistory: [],
    hiddenEvents: [],
    socialMedia: {
      instagram: "",
      twitter: "",
      whatsapp: "",
      facebook: "",
      telegram: "",
      reddit: "",
      other: "",
    },
    contactEmail: "",
    loadingCep: false,
    changesMade: false,
    paymentMethods: {
      pix: {
        pixKeyType: "",
        pixKey: "",
        accountName: "",
      },
      transferenciaBancaria: {
        bank: "",
        agency: "",
        accountNumber: "",
        cpfCnpj: "",
        accountName: "",
      },
    },
    selectedPaymentMethod: "",
    showPixKeyTypeModal: false,
    currentVersion: "",
  };

  removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  cleanName = (name) => {
    return this.removeAccents(name)
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, "");
  };

  prepararDetalhes = () => {
    const {
      accountType,
      showModal,
      activeSection,
      userId,
      email,
      birthDate,
      cpf,
      registration,
      registrationDate,
      eventHistory,
      username,
      updateData,
      phoneNumberOk,
      addressCopy,
      reviews,
      avatarUrl,
      showImageModal,
      phoneCp,
      loadingCep,
      changesMade,
      showPixKeyTypeModal,
      selectedPaymentMethod,
      currentVersion,
      ...rest
    } = this.state;
    return {
      ...rest,
      paymentMethods: this.state.paymentMethods,
      selectedPaymentMethod: this.state.selectedPaymentMethod,
    };
  };

  componentDidMount() {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        this.setState({
          userId: uid,
          email: user.email,
          currentVersion: Constants.expoConfig.version,
        });
        this.loadUserProfile(uid);
      }
    });

    this.props.navigation.addListener("focus", () => {
      const section = this.props.route.params?.section;
      if (section) {
        this.setActiveSection(section);
      }
    });

    this.props.navigation.addListener("beforeRemove", (e) => {
      if (!this.state.changesMade) {
        return;
      }
      e.preventDefault();
      Alert.alert(
        "Salvar alterações?",
        "Você tem alterações não salvas. Deseja salvar antes de sair?",
        [
          {
            text: "Não",
            style: "cancel",
            onPress: () => this.props.navigation.dispatch(e.data.action),
          },
          { text: "Sim", onPress: () => this.handleSaveAndExit(e.data.action) },
        ]
      );
    });

    this.backHandler = BackHandler.addEventListener("hardwareBackPress", () => {
      if (this.state.activeSection !== null) {
        this.setActiveSection(null);
        return true; // Evitar sair do app
      }
      return false; // Permitir sair do app
    });
  }

  componentWillUnmount() {
    if (this.backHandler) {
      this.backHandler.remove();
    }
  }

  handleSaveAndExit = (action) => {
    this.saveChanges();
    this.props.navigation.dispatch(action);
  };

  saveChanges = async () => {
    await updateUser(this.state.userId, this.prepararDetalhes());
    this.setState({ changesMade: false, updateData: true });
    Alert.alert("Sucesso", "Perfil atualizado com sucesso!");
  };

  toggleEventVisibility = (event) => {
    const { hiddenEvents } = this.state;
    if (hiddenEvents.includes(event)) {
      this.setState({
        hiddenEvents: hiddenEvents.filter((e) => e !== event),
        changesMade: true,
      });
    } else {
      this.setState({
        hiddenEvents: [...hiddenEvents, event],
        changesMade: true,
      });
    }
  };

  isEventHidden = (event) => {
    return this.state.hiddenEvents.includes(event);
  };

  togglePublicProfile = () => {
    const { isPublic } = this.state;
    const newValue = !isPublic;
  
    Alert.alert(
      newValue ? "Tornar perfil público" : "Tornar perfil privado",
      newValue
        ? "Seu perfil ficará visível ao público e todos os pedidos para seguir serão aceitos automaticamente."
        : "Seu perfil ficará privado e será necessário solicitar para seguir.",
      [
        {
          text: "Cancelar",
          style: "cancel",
        },
        {
          text: "Confirmar",
          onPress: async () => {
            if (newValue) {
              const userData = await getUser(this.state.userId);
              const pendingFollowers = userData.pendingFollowers || [];
              pendingFollowers.forEach(async (followerId) => {
                await followUser(followerId, this.state.userId);
                await cancelFollowRequest(followerId, this.state.userId);
              });
  
              await updateUser(this.state.userId, { 
                isPublic: newValue,
              });
  
              this.setState({ isPublic: newValue, changesMade: true });
            } else {
              this.setState({ isPublic: newValue, changesMade: true });
              await updateUser(this.state.userId, { isPublic: newValue });
            }
          },
        },
      ]
    );
  };  

  handleImagePicker = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      Alert.alert(
        "Permissão necessária",
        "Precisamos da permissão para acessar a galeria de fotos do seu dispositivo."
      );
      return;
    }

    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0.5,
      });

      if (!result.canceled) {
        this.setState({
          avatarUrl: result.assets[0].uri,
          showImageModal: true,
          changesMade: true,
        });
      } else {
        Alert.alert(
          "Carregamento cancelado",
          "A escolha da imagem foi cancelada."
        );
      }
    } catch (error) {
      console.error("Erro ao selecionar imagem: ", error);
      Alert.alert(
        "Erro",
        "Ocorreu um erro ao tentar acessar a galeria de imagens. Por favor, tente novamente."
      );
    }
  };

  validAddress = () => {
    const { street, number, neighborhood, city, state, zip } =
      this.state.address;
    return street && number && neighborhood && city && state && zip;
  };

  updateProgressProfile = () => {
    const isAddressValid = this.validAddress();
    const isPhoneNumberValid = this.state.phoneNumberOk;

    let progress = 0;
    if (isAddressValid) progress += 1;
    if (isPhoneNumberValid) progress += 1;

    this.setState({ progressProfile: progress });
  };

  loadUserProfile = async (userId) => {
    const userData = await getUser(userId, [
      "username",
      "fullName",
      "accountType",
      "avatarUrl",
      "descriptionProfile",
      "phoneNumber",
      "address",
      "progressProfile",
      "phoneNumberOk",
      "registration",
      "birthDate",
      "gender",
      "isPublic",
      "registrationDate",
      "eventHistory",
      "hiddenEvents",
      "socialMedia",
      "contactEmail",
      "paymentMethods",
      "selectedPaymentMethod",
    ]);
    if (userData) {
      this.setState(
        {
          username: userData.username,
          fullName: userData.fullName,
          searchName: this.cleanName(userData.fullName),
          accountType: userData.accountType,
          avatarUrl: userData.avatarUrl,
          descriptionProfile: userData.descriptionProfile,
          phoneNumber: userData.phoneNumber,
          address: userData.address,
          addressCopy: userData.address,
          progressProfile: userData.progressProfile,
          phoneNumberOk: userData.phoneNumberOk || false,
          phoneCp: userData.phoneNumber,
          cpf: userData.registration,
          birthDate: moment(userData.birthDate.toDate()).format("DD/MM/YYYY"),
          gender: userData.gender,
          isPublic: userData.isPublic,
          registrationDate: moment(userData.registrationDate.toDate()).format(
            "DD/MM/YYYY"
          ),
          eventHistory: userData.eventHistory,
          hiddenEvents: userData.hiddenEvents || [],
          socialMedia: userData.socialMedia || {},
          contactEmail: userData.contactEmail || "",
          paymentMethods: userData.paymentMethods || {
            pix: {
              pixKeyType: "",
              pixKey: "",
              accountName: "",
            },
            transferenciaBancaria: {
              bank: "",
              agency: "",
              accountNumber: "",
              cpfCnpj: "",
              accountName: "",
            },
          },
          selectedPaymentMethod: userData.selectedPaymentMethod || "pix",
        },
        this.updateProgressProfile
      );
    }
  };

  fetchAddress = async (cep) => {
    const url = `https://viacep.com.br/ws/${cep}/json/`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.erro) {
        console.error("CEP não encontrado.");
        return null;
      }
      return data;
    } catch (error) {
      console.error("Erro ao buscar o CEP:", error);
      return null;
    }
  };

  savePhoneNumber = async () => {
    try {
      let { phoneNumber } = this.state;
      phoneNumber = phoneNumber.replace(/\D/g, "");
      if (phoneNumber !== this.state.phoneCp) {
        if (phoneNumber.length === 10 || phoneNumber.length === 11) {
          await updateUser(this.state.userId, {
            phoneNumber: phoneNumber,
            phoneNumberOk: true,
          });
          this.setState(
            {
              phoneNumberOk: true,
              updateData: true,
              phoneCp: phoneNumber,
              changesMade: true,
            },
            this.updateProgressProfile
          );
          Alert.alert("Sucesso", "Número de telefone salvo com sucesso!");
        } else {
          Alert.alert("Erro", "Número de telefone inválido");
        }
      } else {
        Alert.alert("Erro", "Número de telefone já está salvo");
      }
    } catch (error) {
      console.error("Erro ao salvar o número de telefone:", error);
      Alert.alert("Erro", "Não foi possível salvar o número de telefone.");
    }
  };

  handleCepChange = async (text) => {
    this.handleInputChange("address.zip", text);
    if (text.length === 8) {
      const addressData = await this.fetchAddress(text);
      if (addressData) {
        this.fillAddressFields(addressData);
      }
    }
  };

  fillAddressFields = (data) => {
    this.handleInputChange("address.street", data.logradouro);
    this.handleInputChange("address.neighborhood", data.bairro);
    this.handleInputChange("address.city", data.localidade);
    this.handleInputChange("address.state", data.uf);
    this.updateProgressProfile();
  };

  handleInputChange = (field, value) => {
    const keys = field.split(".");
    if (keys.length > 1) {
      const [key, subkey] = keys;
      const updatedSubfields = { ...this.state[key], [subkey]: value };
      this.setState(
        { [key]: updatedSubfields, changesMade: true },
        this.updateProgressProfile
      );
    } else {
      this.setState(
        { [field]: value, changesMade: true },
        this.updateProgressProfile
      );
    }
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleCloseImageModal = () => {
    this.setState({ showImageModal: false });
  };

  setActiveSection = (section) => {
    this.setState({ activeSection: section });
  };

  handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, this.state.email);
      Alert.alert(
        "Sucesso",
        "Email para redefinição de senha enviado com sucesso."
      );
    } catch (error) {
      console.error("Erro ao enviar email de redefinição de senha:", error);
      Alert.alert("Erro", "Erro ao enviar email de redefinição de senha.");
    }
  };

  renderActiveSection = () => {
    const { activeSection } = this.state;
    switch (activeSection) {
      case "general":
        return this.GeneralDataForm();
      case "contacts":
        return this.ContactsForm();
      case "address":
        return this.AddressForm();
      case "privacy":
        return this.PrivacyForm();
      case "payment":
        return this.PaymentMethodForm();
      default:
        return null;
    }
  };

  GeneralDataForm = () => (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <MaterialIcons
          name="personal-injury"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Informações Gerais</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        <Text style={styles.label}>Nome</Text>
        <TextInput
          style={styles.input}
          value={this.state.fullName}
          onChangeText={(text) => {
            this.handleInputChange("fullName", text);
            this.setState({ searchName: this.cleanName(text) });
          }}
        />

        <Text style={styles.label}>Descrição</Text>
        <TextInput
          style={[styles.input, styles.descriptionInput]}
          value={this.state.descriptionProfile}
          onChangeText={(text) =>
            this.handleInputChange("descriptionProfile", text)
          }
          multiline
          numberOfLines={4}
        />

        <Text style={styles.label}>Gênero</Text>
        <TextInput
          style={styles.input}
          placeholder="Especifique o seu gênero"
          placeholderTextColor="#FFF9"
          value={this.state.gender}
          onChangeText={(text) => this.handleInputChange("gender", text)}
        />

        <Text style={[styles.label, styles.labelBlocked]}>CPF</Text>
        <TextInput
          style={[styles.input, styles.inputBlocked]}
          value={decripCpf(this.state.cpf)}
          editable={false}
        />

        <Text style={[styles.label, styles.labelBlocked]}>
          Data de Aniversário
        </Text>
        <TextInput
          style={[styles.input, styles.inputBlocked]}
          value={this.state.birthDate}
          editable={false}
        />

        <Text style={[styles.label, styles.labelBlocked]}>
          Data de Criação da Conta
        </Text>
        <TextInput
          style={[styles.input, styles.inputBlocked]}
          value={this.state.registrationDate}
          editable={false}
        />

        <TouchableOpacity
          onPress={() => {
            this.saveChanges();
          }}
          style={styles.saveButton}
        >
          <Text style={styles.saveButtonText}>Salvar Alterações</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  ContactsForm = () => (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <MaterialCommunityIcons
          name="phone-log"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Contato</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        <Text style={styles.label}>Telefone</Text>
        <View style={styles.phoneInputContainer}>
          <Text style={styles.countryCode}>+55</Text>
          <TextInput
            style={styles.inputPhone}
            value={this.state.phoneNumber}
            onChangeText={(text) => this.handleInputChange("phoneNumber", text)}
            keyboardType="phone-pad"
            placeholder="32 91234-5678"
            placeholderTextColor="#FFF9"
          />
          <TouchableOpacity
            onPress={() => this.savePhoneNumber()}
            style={styles.phoneButton}
          >
            <Text style={styles.saveButtonText}>
              {this.state.phoneNumberOk ? "Verificar" : "Verificar"}
            </Text>
          </TouchableOpacity>
        </View>

        {this.state.accountType === "Producer" && (
          <>
            <Text style={styles.label}>Email de Contato</Text>
            <TextInput
              style={styles.input}
              value={this.state.contactEmail}
              onChangeText={(text) =>
                this.handleInputChange("contactEmail", text)
              }
              placeholder="email@contato.com"
              placeholderTextColor="#FFF9"
              keyboardType="email-address"
            />
          </>
        )}

        <Text style={[styles.label, styles.labelBlocked]}>Email da Conta</Text>
        <TextInput
          style={[styles.input, styles.inputBlocked]}
          value={this.state.email}
          editable={false}
        />

        {this.state.accountType === "Producer" && (
          <>
            <View style={styles.sectionDivider} />
            <View style={styles.settingItemForm}>
              <MaterialCommunityIcons
                name="phone-voip"
                size={24}
                style={styles.settingIconForm}
              />
              <Text style={styles.screenTitle}>Redes Sociais</Text>
            </View>
            <View style={styles.sectionDivider} />

            <Text style={styles.label}>Instagram</Text>
            <View style={styles.socialMediaContainer}>
              <Text style={styles.linkPreview}>https://instagram.com/</Text>
              <TextInput
                style={styles.socialMediaInput}
                placeholder="seuusuario"
                placeholderTextColor="#FFF9"
                value={this.state.socialMedia.instagram}
                onChangeText={(text) =>
                  this.handleInputChange("socialMedia.instagram", text)
                }
              />
            </View>

            <Text style={styles.label}>Twitter</Text>
            <View style={styles.socialMediaContainer}>
              <Text style={styles.linkPreview}>https://twitter.com/</Text>
              <TextInput
                style={styles.socialMediaInput}
                placeholder="seuusuario"
                placeholderTextColor="#FFF9"
                value={this.state.socialMedia.twitter}
                onChangeText={(text) =>
                  this.handleInputChange("socialMedia.twitter", text)
                }
              />
            </View>

            <Text style={styles.label}>WhatsApp</Text>
            <View style={styles.socialMediaContainer}>
              <Text style={styles.linkPreview}>https://wa.me/</Text>
              <TextInput
                style={styles.socialMediaInput}
                placeholder="5511999999999"
                placeholderTextColor="#FFF9"
                value={this.state.socialMedia.whatsapp}
                onChangeText={(text) =>
                  this.handleInputChange("socialMedia.whatsapp", text)
                }
              />
            </View>

            <Text style={styles.label}>Facebook</Text>
            <View style={styles.socialMediaContainer}>
              <Text style={styles.linkPreview}>https://facebook.com/</Text>
              <TextInput
                style={styles.socialMediaInput}
                placeholder="seuusuario"
                placeholderTextColor="#FFF9"
                value={this.state.socialMedia.facebook}
                onChangeText={(text) =>
                  this.handleInputChange("socialMedia.facebook", text)
                }
              />
            </View>

            <Text style={styles.label}>Telegram</Text>
            <View style={styles.socialMediaContainer}>
              <Text style={styles.linkPreview}>https://t.me/</Text>
              <TextInput
                style={styles.socialMediaInput}
                placeholder="seuusuario"
                placeholderTextColor="#FFF9"
                value={this.state.socialMedia.telegram}
                onChangeText={(text) =>
                  this.handleInputChange("socialMedia.telegram", text)
                }
              />
            </View>

            <Text style={styles.label}>Reddit</Text>
            <View style={styles.socialMediaContainer}>
              <Text style={styles.linkPreview}>https://reddit.com/user/</Text>
              <TextInput
                style={styles.socialMediaInput}
                placeholder="seuusuario"
                placeholderTextColor="#FFF9"
                value={this.state.socialMedia.reddit}
                onChangeText={(text) =>
                  this.handleInputChange("socialMedia.reddit", text)
                }
              />
            </View>

            <Text style={styles.label}>Outros</Text>
            <View style={styles.socialMediaContainer}>
              <Text style={styles.linkPreview}></Text>
              <TextInput
                style={styles.socialMediaInput}
                placeholder="https://outrosocial.com/seuusuario"
                placeholderTextColor="#FFF9"
                value={this.state.socialMedia.other}
                onChangeText={(text) =>
                  this.handleInputChange("socialMedia.other", text)
                }
              />
            </View>

            <TouchableOpacity
              onPress={() => {
                this.saveChanges();
              }}
              style={styles.saveButton}
            >
              <Text style={styles.saveButtonText}>Salvar Alterações</Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    </View>
  );

  AddressForm = () => (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <MaterialCommunityIcons
          name="map-legend"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Endereço</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        <Text style={styles.label}>Nome</Text>
        <TextInput
          style={styles.input}
          value={this.state.address.name}
          onChangeText={(text) => this.handleInputChange("address.name", text)}
          placeholder="Nome"
          placeholderTextColor="#FFF9"
        />

        <Text style={styles.label}>CEP</Text>
        <TextInput
          style={styles.input}
          value={this.state.address.zip}
          onChangeText={this.handleCepChange}
          keyboardType="numeric"
          placeholder="CEP"
          placeholderTextColor="#FFF9"
        />
        {this.state.loadingCep && (
          <ActivityIndicator size="small" color="#FFF" />
        )}

        <Text style={styles.label}>Rua/Av</Text>
        <TextInput
          style={styles.input}
          value={this.state.address.street}
          onChangeText={(text) =>
            this.handleInputChange("address.street", text)
          }
          placeholder="Rua/Av"
          placeholderTextColor="#FFF9"
        />

        <Text style={styles.label}>Número</Text>
        <TextInput
          style={styles.input}
          value={this.state.address.number}
          onChangeText={(text) =>
            this.handleInputChange("address.number", text)
          }
          keyboardType="numeric"
          placeholder="Número"
          placeholderTextColor="#FFF9"
        />

        <Text style={styles.label}>Complemento</Text>
        <TextInput
          style={styles.input}
          value={this.state.address.complement}
          onChangeText={(text) =>
            this.handleInputChange("address.complement", text)
          }
          placeholder="Complemento"
          placeholderTextColor="#FFF9"
        />

        <Text style={styles.label}>Bairro</Text>
        <TextInput
          style={styles.input}
          value={this.state.address.neighborhood}
          onChangeText={(text) =>
            this.handleInputChange("address.neighborhood", text)
          }
          placeholder="Bairro"
          placeholderTextColor="#FFF9"
        />

        <Text style={[styles.label, styles.labelBlocked]}>Cidade</Text>
        <TextInput
          style={[styles.input, styles.inputBlocked]}
          value={this.state.address.city}
          editable={false}
        />

        <Text style={[styles.label, styles.labelBlocked]}>Estado</Text>
        <TextInput
          style={[styles.input, styles.inputBlocked]}
          value={this.state.address.state}
          editable={false}
        />

        <TouchableOpacity
          onPress={() => {
            this.saveChanges();
          }}
          style={styles.saveButton}
        >
          <Text style={styles.saveButtonText}>Salvar Alterações</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  PrivacyForm = () => (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <MaterialCommunityIcons
          name="lock"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Privacidade</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        {this.state.accountType === "User" && (
          <>
            <Text style={styles.label}>Visibilidade do Perfil</Text>
            <TouchableOpacity
              onPress={this.togglePublicProfile}
              style={[
                styles.saveButton,
                {
                  backgroundColor: this.state.isPublic ? "#2B2B2B" : "#121212",
                },
              ]}
            >
              <View style={styles.isPublicContainer}>
                <Ionicons
                  name={this.state.isPublic ? "eye" : "eye-off"}
                  size={18}
                  color="white"
                  style={styles.publicIcon}
                />
                <Text style={styles.publicButtonText}>
                  {this.state.isPublic ? "Público" : "Privado"}
                </Text>
              </View>
            </TouchableOpacity>
          </>
        )}
        <Text style={styles.label}>Solicitar Alteração de Senha</Text>
        <TouchableOpacity
          onPress={() => this.setState({ showModal: true })}
          style={[styles.saveButton, { backgroundColor: "#1A2035" }]}
        >
          <Text style={styles.saveButtonText}>Alterar Senha</Text>
        </TouchableOpacity>

        {this.state.accountType === "User" &&  (this.state.eventHistory.length > 0) && (
          <>
            <Text style={styles.label}>Ocultar Eventos Comprados</Text>
            <ScrollView style={styles.eventList}>
              {this.state.eventHistory.map((event) => (
                <View key={event} style={styles.eventItem}>
                  <Text style={styles.eventText}>@{event}</Text>
                  <TouchableOpacity
                    onPress={() => this.toggleEventVisibility(event)}
                    style={[
                      styles.visibilityButton,
                      this.isEventHidden(event)
                        ? styles.hiddenEventButton
                        : styles.visibleEventButton,
                    ]}
                  >
                    <Text style={styles.visibilityButtonText}>
                      {this.isEventHidden(event) ? "Oculto" : "Visível"}
                    </Text>
                  </TouchableOpacity>
                </View>
              ))}
            </ScrollView>
            <TouchableOpacity
              onPress={this.saveChanges}
              style={styles.saveButton}
            >
              <Text style={styles.saveButtonText}>Salvar Alterações</Text>
            </TouchableOpacity>
          </>
        )}

        <Modal
          visible={this.state.showModal}
          transparent={true}
          animationType="slide"
          onRequestClose={this.handleCloseModal}
        >
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>Alterar Senha</Text>
              <Text style={styles.modalDescription}>
                Enviaremos um email para {this.state.email} com instruções para
                alterar sua senha.
              </Text>
              <TouchableOpacity
                style={styles.modalButton}
                onPress={this.handlePasswordReset}
              >
                <Text style={styles.modalButtonText}>Enviar Email</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, styles.modalButtonCancel]}
                onPress={this.handleCloseModal}
              >
                <Text style={styles.modalButtonText}>Cancelar</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>
    </View>
  );

  PaymentMethodForm = () => (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <Ionicons
          name="cash-outline"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Método de Recebimento</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        <Text style={styles.label}>Método de Pagamento</Text>
        <View style={styles.buttonGroup}>
          <TouchableOpacity
            style={[
              styles.optionButton,
              this.state.selectedPaymentMethod === "pix" &&
                styles.selectedOptionButton,
            ]}
            onPress={() => this.setState({ selectedPaymentMethod: "pix" })}
          >
            <Text
              style={[
                styles.optionButtonText,
                this.state.selectedPaymentMethod === "pix" &&
                  styles.selectedOptionButtonText,
              ]}
            >
              Pix
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.optionButtonSecond,
              this.state.selectedPaymentMethod === "transferenciaBancaria" &&
                styles.selectedOptionButton,
            ]}
            onPress={() =>
              this.setState({ selectedPaymentMethod: "transferenciaBancaria" })
            }
          >
            <Text
              style={[
                styles.optionButtonText,
                this.state.selectedPaymentMethod === "transferenciaBancaria" &&
                  styles.selectedOptionButtonText,
              ]}
            >
              Transferência Bancária
            </Text>
          </TouchableOpacity>
        </View>

        {this.state.selectedPaymentMethod === "pix" && (
          <>
            <View style={styles.sectionDivider} />
            <Text style={styles.label}>Tipo de Chave Pix</Text>
            <TouchableOpacity
              style={styles.input}
              onPress={() => this.setState({ showPixKeyTypeModal: true })}
            >
              <Text style={styles.optionButtonText}>
                {this.state.paymentMethods.pix.pixKeyType ||
                  "Selecione o tipo de chave Pix"}
              </Text>
            </TouchableOpacity>

            <Modal
              visible={this.state.showPixKeyTypeModal}
              transparent={true}
              animationType="slide"
              onRequestClose={() =>
                this.setState({ showPixKeyTypeModal: false })
              }
            >
              <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                  <Text style={styles.modalTitle}>
                    Selecione o Tipo de Chave Pix
                  </Text>
                  {["CPF", "CNPJ", "Email", "Telefone", "Aleatória"].map(
                    (type) => (
                      <TouchableOpacity
                        key={type}
                        style={[
                          styles.modalOptionButton,
                          this.state.paymentMethods.pix.pixKeyType === type &&
                            styles.modalSelectedOptionButton,
                        ]}
                        onPress={() =>
                          this.setState({
                            paymentMethods: {
                              ...this.state.paymentMethods,
                              pix: {
                                ...this.state.paymentMethods.pix,
                                pixKeyType: type,
                              },
                            },
                            showPixKeyTypeModal: false,
                          })
                        }
                      >
                        <Text
                          style={[
                            styles.modalOptionButtonText,
                            this.state.paymentMethods.pix.pixKeyType === type &&
                              styles.modalSelectedOptionButtonText,
                          ]}
                        >
                          {type}
                        </Text>
                      </TouchableOpacity>
                    )
                  )}
                  <TouchableOpacity
                    style={[styles.modalButton, styles.modalButtonCancel]}
                    onPress={() =>
                      this.setState({ showPixKeyTypeModal: false })
                    }
                  >
                    <Text style={styles.modalButtonText}>Cancelar</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Modal>

            <Text style={styles.label}>Chave Pix</Text>
            <TextInput
              style={styles.input}
              placeholder="Insira sua chave Pix"
              placeholderTextColor="#FFF9"
              value={this.state.paymentMethods.pix.pixKey}
              onChangeText={(text) =>
                this.setState({
                  paymentMethods: {
                    ...this.state.paymentMethods,
                    pix: {
                      ...this.state.paymentMethods.pix,
                      pixKey: text,
                    },
                  },
                  changesMade: true,
                })
              }
            />
            <Text style={styles.label}>Nome do Titular</Text>
            <TextInput
              style={styles.input}
              placeholder="Nome do titular da conta"
              placeholderTextColor="#FFF9"
              value={this.state.paymentMethods.pix.accountName}
              onChangeText={(text) =>
                this.setState({
                  paymentMethods: {
                    ...this.state.paymentMethods,
                    pix: {
                      ...this.state.paymentMethods.pix,
                      accountName: text,
                    },
                  },
                  changesMade: true,
                })
              }
            />
          </>
        )}

        {this.state.selectedPaymentMethod === "transferenciaBancaria" && (
          <>
            <View style={styles.sectionDivider} />
            <Text style={styles.label}>Banco</Text>
            <TextInput
              style={styles.input}
              placeholder="Nome do banco"
              placeholderTextColor="#FFF9"
              value={this.state.paymentMethods["transferenciaBancaria"].bank}
              onChangeText={(text) =>
                this.setState({
                  paymentMethods: {
                    ...this.state.paymentMethods,
                    transferenciaBancaria: {
                      ...this.state.paymentMethods["transferenciaBancaria"],
                      bank: text,
                    },
                  },
                  changesMade: true,
                })
              }
            />
            <Text style={styles.label}>Agência</Text>
            <TextInput
              style={styles.input}
              placeholder="Número da agência"
              placeholderTextColor="#FFF9"
              value={this.state.paymentMethods["transferenciaBancaria"].agency}
              onChangeText={(text) =>
                this.setState({
                  paymentMethods: {
                    ...this.state.paymentMethods,
                    transferenciaBancaria: {
                      ...this.state.paymentMethods["transferenciaBancaria"],
                      agency: text,
                    },
                  },
                  changesMade: true,
                })
              }
            />
            <Text style={styles.label}>Conta</Text>
            <TextInput
              style={styles.input}
              placeholder="Número da conta"
              placeholderTextColor="#FFF9"
              value={
                this.state.paymentMethods["transferenciaBancaria"].accountNumber
              }
              onChangeText={(text) =>
                this.setState({
                  paymentMethods: {
                    ...this.state.paymentMethods,
                    transferenciaBancaria: {
                      ...this.state.paymentMethods["transferenciaBancaria"],
                      accountNumber: text,
                    },
                  },
                  changesMade: true,
                })
              }
            />
            <Text style={styles.label}>CPF/CNPJ do Titular</Text>
            <TextInput
              style={styles.input}
              placeholder="CPF ou CNPJ do titular"
              placeholderTextColor="#FFF9"
              value={this.state.paymentMethods["transferenciaBancaria"].cpfCnpj}
              onChangeText={(text) =>
                this.setState({
                  paymentMethods: {
                    ...this.state.paymentMethods,
                    transferenciaBancaria: {
                      ...this.state.paymentMethods["transferenciaBancaria"],
                      cpfCnpj: text,
                    },
                  },
                  changesMade: true,
                })
              }
            />
            <Text style={styles.label}>Nome do Titular</Text>
            <TextInput
              style={styles.input}
              placeholder="Nome do titular da conta"
              placeholderTextColor="#FFF9"
              value={
                this.state.paymentMethods["transferenciaBancaria"].accountName
              }
              onChangeText={(text) =>
                this.setState({
                  paymentMethods: {
                    ...this.state.paymentMethods,
                    transferenciaBancaria: {
                      ...this.state.paymentMethods["transferenciaBancaria"],
                      accountName: text,
                    },
                  },
                  changesMade: true,
                })
              }
            />
          </>
        )}

        <TouchableOpacity
          onPress={() => {
            this.saveChanges();
          }}
          style={styles.saveButton}
        >
          <Text style={styles.saveButtonText}>Salvar Alterações</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  render() {
    const { accountType, activeSection, progressProfile } = this.state;
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.containerHeader}>
          <TouchableOpacity
            onPress={() => {
              if (this.state.activeSection !== null) {
                this.setActiveSection(null);
              } else {
                this.props.navigation.navigate("Profile", {
                  updatedUserData: this.state.updateData,
                });
              }
            }}
            style={styles.backButtonHeader}
          >
            <Ionicons name="arrow-back" size={25} color="#FFFFFF" />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => this.props.navigation.navigate("Home")}
          >
            <Image
              source={logoTexto}
              resizeMode="contain"
              style={styles.logoHeader}
            />
          </TouchableOpacity>

          <Ionicons
            name="person-outline"
            size={25}
            color="#000"
            //onPress={() => this.props.navigation.navigate("Profile")}
            style={styles.goBackButton}
          />
        </View>
        <ScrollView>
          <View style={styles.eventInfoContainer}>
            <TouchableOpacity onPress={() => this.handleImagePicker()}>
              <Image
                source={
                  this.state.avatarUrl !== ""
                    ? { uri: this.state.avatarUrl }
                    : require("../../../../assets/imgs/Avatar_Macaco.png")
                }
                style={styles.profileImage}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.editImageButton}
              onPress={() => this.handleImagePicker()}
            >
              <IconMaterial name="pencil" size={14} color="#FFFD" />
            </TouchableOpacity>
            <View style={styles.eventDetails}>
              <Text style={styles.eventName}>
                {this.state.fullName ? this.state.fullName : "Carregando..."}
              </Text>
              <Text style={styles.eventDescription}>
                Tela de Gerência do Perfil
              </Text>
            </View>
          </View>
          <SaveImageModal
            isVisible={this.state.showImageModal}
            uri={this.state.avatarUrl}
            onClose={this.handleCloseImageModal}
            username={this.state.username}
            uid={this.state.userId}
            changeData={() => this.setState({ updateData: true })}
          />
          {accountType && activeSection == null ? (
            <>
              <TouchableOpacity
                style={styles.settingItem}
                onPress={() => this.setActiveSection("general")}
              >
                <MaterialIcons
                  name="personal-injury"
                  size={24}
                  style={styles.settingIcon}
                />
                <View style={styles.settingTextContainer}>
                  <Text style={styles.settingTitle}>Informações Gerais</Text>
                  <Text style={styles.settingSubtitle}>
                    Altere suas informações do perfil
                  </Text>
                </View>
                <Ionicons
                  name="chevron-forward"
                  size={24}
                  style={styles.settingArrow}
                />
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.settingItem}
                onPress={() => this.setActiveSection("contacts")}
              >
                <MaterialCommunityIcons
                  name="phone-log"
                  size={24}
                  style={styles.settingIcon}
                />
                <View style={styles.settingTextContainer}>
                  <Text style={styles.settingTitle}>Contato</Text>
                  <Text style={styles.settingSubtitle}>
                    Altere suas informações de contato
                  </Text>
                </View>
                <Ionicons
                  name="chevron-forward"
                  size={24}
                  style={styles.settingArrow}
                />
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.settingItem}
                onPress={() => this.setActiveSection("address")}
              >
                <MaterialCommunityIcons
                  name="map-legend"
                  size={24}
                  style={styles.settingIcon}
                />
                <View style={styles.settingTextContainer}>
                  <Text style={styles.settingTitle}>Endereço</Text>
                  <Text style={styles.settingSubtitle}>
                    Altere suas informações de endereço
                  </Text>
                </View>
                <Ionicons
                  name="chevron-forward"
                  size={24}
                  style={styles.settingArrow}
                />
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.settingItem}
                onPress={() => this.setActiveSection("payment")}
              >
                <Ionicons
                  name="cash-outline"
                  size={24}
                  style={styles.settingIcon}
                />
                <View style={styles.settingTextContainer}>
                  <Text style={styles.settingTitle}>Método de Recebimento</Text>
                  <Text style={styles.settingSubtitle}>
                    Configure seu método de recebimento
                  </Text>
                </View>
                <Ionicons
                  name="chevron-forward"
                  size={24}
                  style={styles.settingArrow}
                />
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.settingItem}
                onPress={() => this.setActiveSection("privacy")}
              >
                <MaterialCommunityIcons
                  name="lock"
                  size={24}
                  style={styles.settingIcon}
                />
                <View style={styles.settingTextContainer}>
                  <Text style={styles.settingTitle}>Privacidade</Text>
                  <Text style={styles.settingSubtitle}>
                    Configure suas opções de privacidade
                  </Text>
                </View>
                <Ionicons
                  name="chevron-forward"
                  size={24}
                  style={styles.settingArrow}
                />
              </TouchableOpacity>

              {progressProfile < 2 && (
                <View style={styles.container2}>
                  <Text style={styles.textStyle2}>
                    Complete seu Cadastro:{" "}
                    {Math.round((100 * (progressProfile + 1)) / 3)}%
                  </Text>
                  <ProgressBar
                    level={Math.round((100 * (progressProfile + 1)) / 3)}
                  />
                  <CustomList
                    isFirstItemComplete={this.validAddress()}
                    isSecondItemComplete={this.state.phoneNumberOk}
                  />
                </View>
              )}
            </>
          ) : (
            this.renderActiveSection()
          )}
        </ScrollView>
        <View style={styles.versionContainer}>
          <Text style={styles.versionText}>
            Versão {this.state.currentVersion}
          </Text>
        </View>
      </SafeAreaView>
    );
  }
}
