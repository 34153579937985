import React, { Component } from "react";
import {
  View,
  ScrollView,
  Image,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Alert,
  Modal,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { CheckBox } from "react-native-elements";
import Toast from "react-native-toast-message";
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";
import AuthInput from "../../../components/autenticacao/AuthInput";
import ToggleSwitch from "../../../components/autenticacao/Toggle";
import AuthDateInput from "../../../components/autenticacao/AuthDateInput";
import { styles } from "../../../styles/utils/authentication/RegisterStyles";
import {
  cadastrar,
  verificarSenha,
  isUsernameValid,
  verificarCpf,
  verificarCNPJ,
  verificaIdade,
  verificarUsernameNoBanco,
  verificarEmailNoBanco,
  verificarCpfNoBanco,
} from "../../../services/utils/secondary/AutenticacaoFuncs";
import toastConfig from "../../../components/utils/ToastConfig";
import { sendEmailVerification, deleteUser } from "firebase/auth";
import { SafeAreaView } from "react-native-safe-area-context";

export default class Register extends Component {
  state = {
    fullName: "",
    registration: "",
    email: "",
    password: "",
    confirmPassword: "",
    username: "",
    dataDeNascimento: "",
    stageNew: true,
    showPassword: false,
    contaUser: true,
    termsAcept: false,
    contaProdutor: false,
    selectValue: "User",
    dataDeNascimentoFocused: false,
    inputErrors: {},
    emailModalVisible: false,
    emailVerificationTimer: null,
    emailVerificationAlertShown: false,
    verificationSecondsLeft: 300, // 5 minutes in seconds
    currentUser: null,
    isCancelling: false,
    lastEmailSent: 0, // Adicionado para controle de tempo
  };

  handleSignUp = async () => {
    const normalizedUsername = this.state.username
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s/g, "");
    this.setState({ username: normalizedUsername });

    const error = await this.verificacaoDeCampos();
    if (error) {
      Toast.show({
        type: "error",
        text1: "Erro no Registro",
        text2: error,
        position: "bottom",
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
    } else {
      try {
        let user = await cadastrar(
          this.state.email,
          this.state.password,
          this.state.confirmPassword,
          this.state.fullName,
          this.state.registration,
          normalizedUsername,
          this.state.dataDeNascimento,
          this.exibirErro,
          this.termsAcept,
          this.state.selectValue,
          () => this.props.navigation.navigate("Home")
        );
        if (user !== undefined && user !== null) {
          this.setState({ emailModalVisible: true, currentUser: user });
          this.startEmailVerificationTimer(user);
        }
      } catch (error) {
        Toast.show({
          type: "error",
          text1: "Erro no Registro",
          text2: error.message,
          position: "bottom",
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        });
      }
    }
  };

  startEmailVerificationTimer = (user) => {
    this.clearVerificationTimer();
    let retries = 0;
    const maxRetries = 300; // 5 minutes

    const interval = setInterval(async () => {
      try {
        await user.reload();
      } catch (error) {
        if (error.code === "auth/user-token-expired") {
          this.clearVerificationTimer();
          return;
        }
      }

      retries++;

      this.setState((prevState) => ({
        verificationSecondsLeft: prevState.verificationSecondsLeft - 1,
      }));

      if (user.emailVerified) {
        clearInterval(interval);
        this.setState({ emailModalVisible: false, currentUser: null });
      }

      if (retries >= maxRetries) {
        clearInterval(interval);
        await this.cancelRegistration();
      }
    }, 1000);

    this.setState({ emailVerificationTimer: interval });
  };

  clearVerificationTimer = () => {
    if (this.state.emailVerificationTimer) {
      clearInterval(this.state.emailVerificationTimer);
      this.setState({
        emailVerificationTimer: null,
        verificationSecondsLeft: 300,
      });
    }
  };

  toggleSelection = (selectedKey) => {
    this.setState({
      contaUser: selectedKey === "User",
      contaProdutor: selectedKey === "Producer",
      selectValue: selectedKey,
    });
  };

  verificacaoDeCampos = async () => {
    let erro = null;
    const errors = {};

    if (!this.state.fullName) {
      errors.fullName = "O nome é obrigatório.";
    }

    if (!this.state.email) {
      errors.email = "O email é obrigatório.";
    } else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
      errors.email = "O email é inválido.";
    } else {
      const emailExists = await verificarEmailNoBanco(this.state.email);
      if (!emailExists) {
        errors.email = "Email já utilizado.";
      }
    }

    if (!this.state.password) {
      errors.password = "A senha é obrigatória.";
    } else if (
      !verificarSenha(this.state.password, this.state.confirmPassword)
    ) {
      errors.password =
        "A senha deve possuir no mínimo 8 dígitos e incluir pelo menos um caractere especial, um número, uma letra maiúscula e uma letra minúscula.";
    }

    if (!this.state.confirmPassword) {
      errors.confirmPassword = "A confirmação de senha é obrigatória.";
    } else if (this.state.password !== this.state.confirmPassword) {
      errors.confirmPassword = "As senhas não conferem.";
    }

    if (!this.state.username) {
      errors.username = "O username é obrigatório.";
    } else if (!isUsernameValid(this.state.username.toLowerCase())) {
      errors.username =
        "O username deve possuir pelo menos 4 caracteres e apenas caracteres especiais válidos!";
    } else {
      const usernameExists = await verificarUsernameNoBanco(
        this.state.username.toLowerCase()
      );
      if (!usernameExists) {
        errors.username = "Username já utilizado.";
      }
    }

    if (!this.state.registration) {
      if (this.state.selectValue === "User") {
        errors.registration = "O CPF é obrigatório.";
      } else {
        errors.registration = "O CPF / CNPJ é obrigatório.";
      }
    } else if (
      this.state.selectValue === "User" &&
      !verificarCpf(this.state.registration)
    ) {
      errors.registration = "O CPF é inválido!";
    } else if (
      this.state.selectValue === "Producer" &&
      !verificarCpf(this.state.registration) &&
      !verificarCNPJ(this.state.registration)
    ) {
      errors.registration = "O CPF / CNPJ é inválido!";
    } else {
      const registrationExists = await verificarCpfNoBanco(
        this.state.registration,
        this.state.selectValue
      );
      if (!registrationExists) {
        errors.registration = "CPF/CNPJ já utilizado.";
      }
    }

    const idade = verificaIdade(this.state.dataDeNascimento);
    if (!this.state.dataDeNascimento) {
      errors.dataDeNascimento = "A data de nascimento é obrigatória.";
    } else if (this.state.selectValue === "User" && idade < 13) {
      errors.dataDeNascimento =
        "Para criar uma conta de usuário é necessário ter mais de 13 anos.";
    } else if (this.state.selectValue === "Producer" && idade < 18) {
      errors.dataDeNascimento =
        "Para ser um produtor é necessário ter mais de 18 anos.";
    }

    if (!this.state.termsAcept) {
      errors.termsAcept =
        "Para criar uma conta é necessário aceitar os termos de uso.";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ inputErrors: errors });
      erro = "Por favor, verifique os campos destacados.";
    } else {
      this.setState({ inputErrors: {} });
    }

    return erro;
  };

  exibirErro = (mensagem) => {
    Toast.show({
      type: "error",
      text1: "Erro no Registro",
      text2: mensagem,
      position: "bottom",
      visibilityTime: 4000,
      autoHide: true,
      topOffset: 30,
      bottomOffset: 40,
    });
  };

  handleDateFocus = () => {
    this.setState({ dataDeNascimentoFocused: true });
  };

  handleDateBlur = () => {
    this.setState({ dataDeNascimentoFocused: false });
  };

  cancelRegistration = async () => {
    if (this.state.isCancelling) return;

    this.setState({ isCancelling: true });
    const user = this.state.currentUser;
    if (user) {
      try {
        await deleteUser(user);
      } catch (error) {
        if (error.code === "auth/requires-recent-login") {
          try {
            await user.reload();
            await deleteUser(user);
          } catch (reloadError) {
            console.error(
              "Erro ao deletar usuário após recarregar:",
              reloadError
            );
          }
        } else {
          console.error("Erro ao deletar usuário:", error);
        }
      }
      this.setState({ emailModalVisible: false, currentUser: null });
      Alert.alert("Registro Cancelado", "Sua conta foi cancelada.");
      this.props.navigation.goBack();
    }
    this.setState({ isCancelling: false });
  };

  resendVerificationEmail = async () => {
    const currentTime = Date.now();
    const timeSinceLastEmail = currentTime - this.state.lastEmailSent;

    if (timeSinceLastEmail < 30000) { 
      Toast.show({
        type: "info",
        text1: "Aguarde",
        text2: "Por favor, aguarde um minuto antes de reenviar o email.",
        position: "bottom",
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
      return;
    }

    try {
      await sendEmailVerification(this.state.currentUser);
      this.setState({ lastEmailSent: currentTime });
      Toast.show({
        type: "success",
        text1: "Email Reenviado",
        text2: "Um novo email de verificação foi enviado.",
        position: "bottom",
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
    } catch (error) {
      console.error("Erro ao reenviar email de verificação:", error);
      Toast.show({
        type: "error",
        text1: "Erro ao Reenviar",
        text2: "Não foi possível reenviar o email de verificação.",
        position: "bottom",
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
    }
  };

  formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  render() {
    return (
      <SafeAreaView style={styles.container}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={styles.container}
        >
          <View style={styles.containerHeader}>
            <TouchableOpacity
              onPress={() => this.props.navigation.goBack()}
              style={styles.goBackButton}
            >
              <Icon name="arrow-back" size={25} color="#FFF" />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => this.props.navigation.navigate("Home")}
            >
              <Image
                source={logoTexto}
                resizeMode="contain"
                style={styles.logoHeader}
              />
            </TouchableOpacity>

            <TouchableOpacity>
              <Icon
                name="person-outline"
                size={25}
                color="#000"
                style={styles.goBackButton}
              />
            </TouchableOpacity>
          </View>
          <ScrollView contentContainerStyle={styles.contentContainer}>
            {this.state.stageNew && (
              <ToggleSwitch
                options={[
                  { key: "User", label: "Usuário" },
                  { key: "Producer", label: "Produtor" },
                ]}
                value={this.state.selectValue}
                onSelect={this.toggleSelection}
                style={styles.toggleSwitch}
              />
            )}

            {this.state.stageNew && (
              <View style={styles.inputContainer}>
                <AuthInput
                  icon="user"
                  iconColor="#fff"
                  placeholder="Nome"
                  placeholderTextColor="#fff"
                  value={this.state.fullName}
                  style={[
                    styles.input,
                    this.state.inputErrors.fullName && styles.inputError,
                  ]}
                  onChangeText={(fullName) => this.setState({ fullName })}
                  error={this.state.inputErrors.fullName}
                />
                {this.state.inputErrors.fullName && (
                  <Text style={styles.errorText}>
                    - {this.state.inputErrors.fullName}
                  </Text>
                )}
              </View>
            )}
            {this.state.stageNew && (
              <View style={styles.inputContainer}>
                <AuthInput
                  icon="tag"
                  iconColor="#fff"
                  placeholder="username"
                  placeholderTextColor="#fff"
                  value={this.state.username}
                  style={[
                    styles.input,
                    this.state.inputErrors.username && styles.inputError,
                  ]}
                  onChangeText={(username) => this.setState({ username })}
                  error={this.state.inputErrors.username}
                />
                {this.state.inputErrors.username && (
                  <Text style={styles.errorText}>
                    - {this.state.inputErrors.username}
                  </Text>
                )}
              </View>
            )}
            {this.state.stageNew && (
              <View style={styles.inputContainer}>
                <AuthInput
                  icon="address-card"
                  iconColor="#fff"
                  placeholder={this.state.contaProdutor ? "CPF / CNPJ" : "CPF"}
                  placeholderTextColor="#fff"
                  value={this.state.registration}
                  style={[
                    styles.input,
                    this.state.inputErrors.registration && styles.inputError,
                  ]}
                  onChangeText={(registration) => this.setState({ registration })}
                  error={this.state.inputErrors.registration}
                />
                {this.state.inputErrors.registration && (
                  <Text style={styles.errorText}>
                    - {this.state.inputErrors.registration}
                  </Text>
                )}
              </View>
            )}
            {this.state.stageNew && (
              <View style={styles.inputContainer}>
                <AuthDateInput
                  value={this.state.dataDeNascimento}
                  onDateChange={(date) =>
                    this.setState({ dataDeNascimento: date })
                  }
                  focused={this.state.dataDeNascimentoFocused}
                  onFocus={this.handleDateFocus}
                  onBlur={this.handleDateBlur}
                  error={this.state.inputErrors.dataDeNascimento}
                  idadeMinima={this.state.selectValue === "User" ? 13 : 18}
                />
                {this.state.inputErrors.dataDeNascimento && (
                  <Text style={styles.errorText}>
                    - {this.state.inputErrors.dataDeNascimento}
                  </Text>
                )}
              </View>
            )}
            <View style={styles.inputContainer}>
              <AuthInput
                icon="at"
                iconColor="#fff"
                placeholder="Email"
                placeholderTextColor="#fff"
                value={this.state.email}
                style={[
                  styles.input,
                  this.state.inputErrors.email && styles.inputError,
                ]}
                onChangeText={(email) => this.setState({ email })}
                error={this.state.inputErrors.email}
              />
              {this.state.inputErrors.email && (
                <Text style={styles.errorText}>
                  - {this.state.inputErrors.email}
                </Text>
              )}
            </View>
            <View style={styles.inputContainer}>
              <AuthInput
                icon="lock"
                iconColor="#fff"
                placeholder="Senha"
                placeholderTextColor="#fff"
                value={this.state.password}
                style={[
                  styles.input,
                  this.state.inputErrors.password && styles.inputError,
                ]}
                secureTextEntry={!this.state.showPassword}
                onChangeText={(password) => this.setState({ password })}
                error={this.state.inputErrors.password}
              />
              {this.state.inputErrors.password && (
                <Text style={styles.errorText}>
                  - {this.state.inputErrors.password}
                </Text>
              )}
            </View>
            {this.state.stageNew && (
              <View style={styles.inputContainer}>
                <AuthInput
                  icon="lock"
                  iconColor="#fff"
                  placeholder="Confirmar Senha"
                  placeholderTextColor="#fff"
                  value={this.state.confirmPassword}
                  style={[
                    styles.input,
                    this.state.inputErrors.confirmPassword && styles.inputError,
                  ]}
                  secureTextEntry={!this.state.showPassword}
                  onChangeText={(confirmPassword) =>
                    this.setState({ confirmPassword })
                  }
                  error={this.state.inputErrors.confirmPassword}
                />
                {this.state.inputErrors.confirmPassword && (
                  <Text style={styles.errorText}>
                    - {this.state.inputErrors.confirmPassword}
                  </Text>
                )}
                <Text style={styles.passwordHelperText}>
                  A senha deve possuir no mínimo 8 dígitos, incluindo pelo menos um caractere especial, um número, uma letra maiúscula e uma letra minúscula.
                </Text>
              </View>
            )}

            <View style={styles.checkboxContainer}>
              <CheckBox
                title=""
                checked={this.state.termsAcept}
                onPress={() =>
                  this.setState({ termsAcept: !this.state.termsAcept })
                }
                containerStyle={[
                  styles.checkbox,
                  this.state.inputErrors.termsAcept && styles.checkboxError,
                ]}
              />
              <TouchableOpacity
                onPress={() => this.props.navigation.navigate("Terms")}
              >
                <View style={styles.termsRow}>
                  <Text style={styles.termsText}>Eu aceito </Text>
                  <Text style={styles.termsTextUnderline}>os termos de uso</Text>
                  <Text style={styles.termsText}> do RayV</Text>
                </View>
              </TouchableOpacity>
            </View>

            <TouchableOpacity style={styles.button} onPress={this.handleSignUp}>
              <Text style={styles.buttonText}>Registrar</Text>
            </TouchableOpacity>
          </ScrollView>
          <Toast config={toastConfig} ref={Toast.setRef} />

          <Modal
            visible={this.state.emailModalVisible}
            animationType="slide"
            transparent={true}
          >
            <View style={styles.modalContainer}>
              <View style={styles.modalContent}>
                <Text style={styles.modalTitle}>Email de Verificação</Text>
                <Text style={styles.modalText}>
                  Um email de verificação foi enviado para {this.state.email}. Por
                  favor, verifique sua caixa de entrada e confirme seu email
                  dentro de 5 minutos.
                </Text>
                <Text style={styles.modalText}>
                  Tempo restante:{" "}
                  {this.formatTime(this.state.verificationSecondsLeft)}
                </Text>
                <TouchableOpacity
                  onPress={this.cancelRegistration}
                  style={styles.modalButton}
                >
                  <Text style={styles.modalButtonText}>Cancelar Conta</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={this.resendVerificationEmail}
                  style={styles.modalButton}
                >
                  <Text style={styles.modalButtonText}>Reenviar Email</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        </KeyboardAvoidingView>
      </SafeAreaView>
    );
  }
}
