import React, { useEffect, useState, useContext } from "react";
import { SafeAreaView, Text, View, ActivityIndicator } from "react-native";
import { FollowProvider, FollowContext } from "../../../contexts/FollowContext";

// Components
import VisitHeader from "../../../components/profile/profileView/VisitHeader";
import ProfileTop from "../../../components/profile/profileGeneral/ProfileTop";
import ProfileBody from "../../../components/profile/profileGeneral/ProfileBody";
import { onUserProfileSnapshot } from "../../../services/cruds/users/getUser";

// Styles
import { styles } from "../../../styles/profile/profileView/ProfileStyles";

const VisitProfile = (props) => {
  const followContext = useContext(FollowContext);
  const { route, navigation } = props;
  const [visitedUser, setVisitedUser] = useState(route.params.usuario);
  const [currentUser] = useState(route.params.currentUser);
  const [isHeader] = useState(route.params.isHeader ? route.params.isHeader : false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async (userId) => {
      setLoading(true);
      const unsubscribe = onUserProfileSnapshot(userId, (data) => {
        setVisitedUser(data);
        setLoading(false);
        if (data && data.followers && data.followers.includes(currentUser)) {
          followContext.setIsFollowing(true);
        } else {
          followContext.setIsFollowing(false);
        }
      });

      return () => unsubscribe && unsubscribe();
    };

    if (route.params.usuario && route.params.usuario.userId) {
      fetchUserProfile(route.params.usuario.userId);
    } else {
      setLoading(false);
    }
  }, [route.params.usuario]);

  if (loading) {
    return (
      <SafeAreaView style={styles.container}>
        <VisitHeader
          navigation={navigation}
          isHeader={isHeader}
          visitedUserId={visitedUser.userId}
          visitedUserUsername={visitedUser.username}
        />
        <ActivityIndicator size="large" color="#FFF" />
      </SafeAreaView>
    );
  }

  if (!visitedUser) {
    return (
      <SafeAreaView style={styles.container}>
        <Text>Perfil do usuário não encontrado.</Text>
      </SafeAreaView>
    );
  }

  const isVisited = visitedUser.userId !== currentUser;
  const isCurrentUser = visitedUser.userId === currentUser;

  return (
    <SafeAreaView style={styles.container}>
      <VisitHeader
        navigation={navigation}
        isHeader={isHeader}
        visitedUserId={visitedUser.userId}
        visitedUserUsername={visitedUser.username}
      />
      <ProfileTop
        isVisited={isVisited}
        userId={visitedUser.userId}
        avatarUrl={visitedUser.avatarUrl}
        fullName={visitedUser.fullName}
        username={visitedUser.username}
        progressProfile={visitedUser.progressProfile}
        descriptionProfile={visitedUser.descriptionProfile}
        accountType={visitedUser.accountType}
        eventHistory={visitedUser.eventHistory}
        hiddenEvents={visitedUser.hiddenEvents}
        followers={visitedUser.followers}
        following={visitedUser.following}
        pendingFollowers={visitedUser.pendingFollowers}
        registrationDate={visitedUser.registrationDate}
        rewardsPoints={visitedUser.rewardsPoints}
        reviews={visitedUser.reviews}
        isPublic={visitedUser.isPublic}
        navigation={navigation}
        currentUser={currentUser}
        isCurrentUser={isCurrentUser}
      />
      <ProfileBody
        navigation={navigation}
        accountType={visitedUser.accountType}
        avatarUrl={visitedUser.avatarUrl}
        hiddenEvents={visitedUser.hiddenEvents}
        stickers={visitedUser.stickers}
        rewardsPoints={visitedUser.rewardsPoints}
        challenges={visitedUser.challenges}
        address={visitedUser.address}
        contactEmail={visitedUser.contactEmail}
        phoneNumber={visitedUser.phoneNumber}
        isVisited={isVisited}
        isPublic={visitedUser.isPublic}
        isCurrentUser={isCurrentUser}
      />
    </SafeAreaView>
  );
};

export default function VisitProfileWrapper(props) {
  return (
    <FollowProvider>
      <VisitProfile {...props} />
    </FollowProvider>
  );
}
