import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, Alert } from "react-native";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import moment from "moment-timezone";
import { styles } from "../../../../styles/producer/producerUtils/EditEventStyles";
import { Timestamp } from "firebase/firestore";

const DatesForm = ({ data, onInputChange, saveChanges }) => {
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const [isStartTimePickerVisible, setStartTimePickerVisible] = useState(false);
  const [isEndDatePickerVisible, setEndDatePickerVisible] = useState(false);
  const [isEndTimePickerVisible, setEndTimePickerVisible] = useState(false);
  const [pickerType, setPickerType] = useState("");
  const [startDate, setStartDate] = useState(
    data.dates.start ? data.dates.start.toDate() : null
  );
  const [endDate, setEndDate] = useState(
    data.dates.end ? data.dates.end.toDate() : null
  );

  useEffect(() => {
    setStartDate(data.dates.start ? data.dates.start.toDate() : null);
    setEndDate(data.dates.end ? data.dates.end.toDate() : null);
  }, [data.dates.start, data.dates.end]);

  const formatDate = (date) => {
    if (!date) return "";
    return moment(date).tz("America/Sao_Paulo").format("DD/MM/YYYY");
  };

  const formatEventDuration = (date) => {
    if (!date) return "";
    return moment(date).tz("America/Sao_Paulo").format("HH:mm");
  };

  const handleDatePicking = (type, date) => {
    const isPublished = data.status === "Publicado";
    const changeDatePermission = data.dates.changeDate;

    if (isPublished && !changeDatePermission) {
      Alert.alert(
        "Requerimento Necessário",
        "O evento já foi publicado. Para alterar a data, entre em contato com o suporte.",
        [
          { text: "Cancelar", style: "cancel" },
          {
            text: "Contatar Suporte",
            onPress: () => {
              onInputChange("dates.changeDate", false);
              Alert.alert(
                "Requerimento Enviado",
                "Seu pedido foi enviado para análise."
              );
            },
          },
        ]
      );
    } else {
      const updatedDate = Timestamp.fromDate(date);
      onInputChange(`dates.${type}`, updatedDate);
      if (type === "start") setStartDate(date);
      if (type === "end") setEndDate(date);
    }
  };

  const handleSaveChanges = async () => {
    if (data.dates.changeDate) {
      await onInputChange("dates.changeDate", null, false); // pass third argument as true to remove field
    }
    saveChanges();
  };

  const togglePicker = (type) => {
    setPickerType(type);
    switch (type) {
      case "startDate":
        setDatePickerVisible(!isDatePickerVisible);
        break;
      case "startTime":
        setStartTimePickerVisible(!isStartTimePickerVisible);
        break;
      case "endDate":
        setEndDatePickerVisible(!isEndDatePickerVisible);
        break;
      case "endTime":
        setEndTimePickerVisible(!isEndTimePickerVisible);
        break;
      default:
        break;
    }
  };

  const today = new Date();

  return (
    <View style={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <MaterialCommunityIcons
          name="calendar-month"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Editar Datas</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        <Text style={styles.label}>Data do Evento:</Text>
        <TouchableOpacity
          style={styles.datePickerButton}
          onPress={() => togglePicker("startDate")}
          disabled={data.status === "Publicado" && !data.dates.changeDate}
        >
          <Text style={styles.buttonText}>
            {startDate ? formatDate(startDate) : "Selecionar Data"}
          </Text>
          <Icon
            name="calendar"
            size={20}
            color="#FFF"
            style={styles.calendarIcon}
          />
        </TouchableOpacity>
        <DateTimePickerModal
          isVisible={isDatePickerVisible && pickerType === "startDate"}
          mode="date"
          minimumDate={today}
          onConfirm={(date) => {
            handleDatePicking(
              "start",
              moment(startDate)
                .set({
                  year: date.getFullYear(),
                  month: date.getMonth(),
                  date: date.getDate(),
                })
                .toDate()
            );
            setDatePickerVisible(false);
          }}
          onCancel={() => setDatePickerVisible(false)}
        />

        <Text style={styles.label}>Horário de Início:</Text>
        <TouchableOpacity
          style={styles.datePickerButton}
          onPress={() => togglePicker("startTime")}
          disabled={data.status === "Publicado" && !data.dates.changeDate}
        >
          <Text style={styles.buttonText}>
            {startDate ? formatEventDuration(startDate) : "Selecionar Horário"}
          </Text>
          <Icon
            name="clock-outline"
            size={20}
            color="#FFF"
            style={styles.calendarIcon}
          />
        </TouchableOpacity>
        <DateTimePickerModal
          isVisible={isStartTimePickerVisible && pickerType === "startTime"}
          mode="time"
          onConfirm={(time) => {
            handleDatePicking(
              "start",
              moment(startDate)
                .set({ hour: time.getHours(), minute: time.getMinutes() })
                .toDate()
            );
            setStartTimePickerVisible(false);
          }}
          onCancel={() => setStartTimePickerVisible(false)}
        />

        <Text style={styles.label}>Data do Término:</Text>
        <TouchableOpacity
          style={styles.datePickerButton}
          onPress={() => togglePicker("endDate")}
          disabled={data.status === "Publicado" && !data.dates.changeDate}
        >
          <Text style={styles.buttonText}>
            {endDate ? formatDate(endDate) : "Selecionar Data"}
          </Text>
          <Icon
            name="calendar"
            size={20}
            color="#FFF"
            style={styles.calendarIcon}
          />
        </TouchableOpacity>
        <DateTimePickerModal
          isVisible={isEndDatePickerVisible && pickerType === "endDate"}
          mode="date"
          minimumDate={today}
          onConfirm={(date) => {
            handleDatePicking(
              "end",
              moment(endDate)
                .set({
                  year: date.getFullYear(),
                  month: date.getMonth(),
                  date: date.getDate(),
                })
                .toDate()
            );
            setEndDatePickerVisible(false);
          }}
          onCancel={() => setEndDatePickerVisible(false)}
        />

        <Text style={styles.label}>Horário de Término:</Text>
        <TouchableOpacity
          style={styles.datePickerButton}
          onPress={() => togglePicker("endTime")}
          disabled={data.status === "Publicado" && !data.dates.changeDate}
        >
          <Text style={styles.buttonText}>
            {endDate ? formatEventDuration(endDate) : "Selecionar Horário"}
          </Text>
          <Icon
            name="clock-outline"
            size={20}
            color="#FFF"
            style={styles.calendarIcon}
          />
        </TouchableOpacity>
        <DateTimePickerModal
          isVisible={isEndTimePickerVisible && pickerType === "endTime"}
          mode="time"
          onConfirm={(time) => {
            handleDatePicking(
              "end",
              moment(endDate)
                .set({ hour: time.getHours(), minute: time.getMinutes() })
                .toDate()
            );
            setEndTimePickerVisible(false);
          }}
          onCancel={() => setEndTimePickerVisible(false)}
        />

        {data.status === "Publicado" && !data.dates.changeDate ? (
          <View>
            <Text style={styles.labelWarning}>
              O evento está publicado. Para alterar a data, entre em contato com
              o suporte.
            </Text>
            <TouchableOpacity
              onPress={() => {
                onInputChange("dates.changeDate", false);
                Alert.alert(
                  "Contato com Suporte",
                  "Seu pedido foi enviado para análise."
                );
              }}
              style={styles.requestButton}
            >
              <Text style={styles.requestButtonText}>Pedir Análise</Text>
            </TouchableOpacity>
          </View>
        ) : data.dates.changeDate ? (
          <View>
            <Text style={styles.labelWarning}>
              Você pode alterar a data uma única vez.
            </Text>
            <TouchableOpacity
              onPress={handleSaveChanges}
              style={styles.saveButton}
            >
              <Text style={styles.saveButtonText}>Salvar Alterações</Text>
            </TouchableOpacity>
          </View>
        ) : (
          <TouchableOpacity
            onPress={handleSaveChanges}
            style={styles.saveButton}
          >
            <Text style={styles.saveButtonText}>Salvar Alterações</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default DatesForm;
