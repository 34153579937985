import React, { useState, useEffect } from "react";
import { View, Text, ScrollView } from "react-native";
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";
import { getChat } from "../../../services/cruds/eventsChats/getEventsChats";
import moment from 'moment-timezone';
import 'moment/locale/pt-br';

const Communication = ({ eventId }) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const chatData = await getChat(eventId, "Geral", null);
        if (chatData && chatData.messages) {
          setMessages(chatData.messages);
        }
      } catch (error) {
        console.error("Erro ao obter mensagens:", error);
      }
    };
    fetchMessages();
  }, [eventId]);

  const formatDateToBrasilia = (timestamp) => {
    const date = moment(timestamp.toDate()).tz("America/Sao_Paulo").locale("pt-br");
    return date.format("DD/MM/YYYY [às] HH:mm");
  };

  return (
    <ScrollView style={styles.tabBeginContainer}>
      {messages.length > 0 ? (
        messages.map((message, index) => (
          <View key={index} style={styles.messageContainer}>
            <Text style={styles.messageText}>{message.message}</Text>
            <Text style={styles.messageTimestamp}>{formatDateToBrasilia(message.timestamp)}</Text>
          </View>
        ))
      ) : (
        <Text style={styles.noMessageText}>Nenhum comunicado disponível.</Text>
      )}
    </ScrollView>
  );
};

export default Communication;
