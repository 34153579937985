import axios from "axios";
import { auth } from "../../utils/firebase/firebaseConfig";

const API_URL = "https://us-central1-rayv-a7c48.cloudfunctions.net/api";

const getIdToken = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("Usuário não autenticado.");
  }
  return await user.getIdToken();
};

export const updateUser = async (userId, updatedData) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.post(
      `${API_URL}/updateUser`,
      { userId, updatedData },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log(response.data);
  } catch (error) {
    console.error("Erro ao atualizar usuário: ", error);
    throw error;
  }
};

export const updateReview = async (userId, reviewData) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.post(
      `${API_URL}/updateReview`,
      { userId, reviewData },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log(response.data);
  } catch (error) {
    console.error("Erro ao atualizar usuário: ", error);
    throw error;
  }
};

export const followUser = async (currentUserId, profileIdToFollow) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.post(
      `${API_URL}/followUser`,
      { currentUserId, profileIdToFollow },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log(response.data);
  } catch (error) {
    console.error("Erro ao seguir usuário: ", error);
    throw error;
  }
};

export const unfollowUser = async (currentUserId, profileIdToUnfollow) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.post(
      `${API_URL}/unfollowUser`,
      { currentUserId, profileIdToUnfollow },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log(response.data);
  } catch (error) {
    console.error("Erro ao deixar de seguir usuário: ", error);
    throw error;
  }
};

export const requestFollowUser = async (currentUserId, targetUserId) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.post(
      `${API_URL}/requestFollowUser`,
      { currentUserId, targetUserId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log(response.data);
  } catch (error) {
    console.error("Erro ao solicitar para seguir usuário: ", error);
    throw error;
  }
};

export const cancelFollowRequest = async (currentUserId, targetUserId) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.post(
      `${API_URL}/cancelFollowRequest`,
      { currentUserId, targetUserId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log(response.data);
  } catch (error) {
    console.error("Erro ao cancelar solicitação de seguimento: ", error);
    throw error;
  }
};

export const approveFollowRequest = async (currentUserId, targetUserId) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.post(
      `${API_URL}/approveFollowRequest`,
      { currentUserId, targetUserId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log(response.data);
  } catch (error) {
    console.error("Erro ao aprovar solicitação de seguidor: ", error);
    throw error;
  }
};

export const declineFollowRequest = async (currentUserId, targetUserId) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.post(
      `${API_URL}/declineFollowRequest`,
      { currentUserId, targetUserId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log(response.data);
  } catch (error) {
    console.error("Erro ao recusar solicitação de seguidor: ", error);
    throw error;
  }
};

export const removeFollower = async (currentUserId, followerId) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.post(
      `${API_URL}/removeFollower`,
      { currentUserId, followerId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log(response.data);
  } catch (error) {
    console.error("Erro ao remover seguidor: ", error);
    throw error;
  }
};
