import React, { useState } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Switch,
  FlatList,
  Alert,
  Modal,
} from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import moment from "moment";
import { styles } from "../../../../styles/producer/producerUtils/EditEventStyles";
import { SafeAreaView } from "react-native-safe-area-context";

const formatCurrency = (value) => {
  return `R$ ${parseFloat(value).toFixed(2).replace(".", ",")}`;
};

const calculateTotal = (price, absorbFee, paymentMethod) => {
  let fee;
  switch (paymentMethod) {
    case "cartao":
      fee = 0.08;
      break;
    case "boleto":
      fee = 0.08 + 3.5 / price;
      break;
    case "pix":
      fee = 0.05;
      break;
    default:
      fee = 0.08;
  }
  return absorbFee ? price - (price * (1 + fee) - price) : price * (1 + fee);
};

const formatDate = (timestamp) => {
  if (!timestamp) return "N/A";
  let date = timestamp;
  if (typeof timestamp.toDate === "function") {
    date = timestamp.toDate();
  }
  return moment(date).format("DD/MM/YYYY HH:mm");
};

const LotsForm = ({ data, onInputChange, saveChanges }) => {
  const [isDateTimePickerVisible, setDateTimePickerVisible] = useState(false);
  const [currentDateField, setCurrentDateField] = useState(null);
  const [expandedLots, setExpandedLots] = useState([]);
  const [expandedHalfPriceLots, setExpandedHalfPriceLots] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [currentLotType, setCurrentLotType] = useState("main"); // "main" ou "halfPrice"
  const [isEditing, setIsEditing] = useState(false);
  const [newLot, setNewLot] = useState({
    name: "",
    quantity: "",
    sold: 0,
    price: "",
    halfPrice: false,
    mainLotId: null,
    visible: true,
    turnType: "quantity",
    start: null,
    end: null,
    previousLot: "Primeira Remessa", // nova variável
  });
  const [editingLotName, setEditingLotName] = useState(null);

  const handleDateConfirm = (date) => {
    const timestamp = new Date(date);
    setNewLot({
      ...newLot,
      [currentDateField]: timestamp,
    });
    setDateTimePickerVisible(false);
  };

  const formatPriceInput = (input) => {
    return input.replace(",", ".");
  };

  const handleAddLot = () => {
    if (newLot.name && newLot.quantity && newLot.price) {
      const updatedLots = { ...data.lots };
      if (isEditing) {
        delete updatedLots[editingLotName];
        setIsEditing(false);
      }
      updatedLots[newLot.name] = newLot;
      setNewLot({
        name: "",
        quantity: "",
        sold: 0,
        price: "",
        halfPrice: false,
        mainLotId: null,
        visible: true,
        turnType: "quantity",
        start: null,
        end: null,
        previousLot: "Primeira Remessa", // nova variável
      });
      setEditingLotName(null);
      onInputChange("lots", updatedLots);
    } else {
      Alert.alert("Erro", "Preencha todos os campos obrigatórios.");
    }
  };

  const handleEditLot = (name) => {
    const lot = data.lots[name];
    setNewLot({
      ...lot,
      start: lot.start ? new Date(lot.start.seconds * 1000) : null,
      end: lot.end ? new Date(lot.end.seconds * 1000) : null,
    });
    setEditingLotName(name);
    setIsEditing(true);
  };

  const handleRemoveLot = (name) => {
    const mainLot = data.lots[name];
    if (
      data.status === "Publicado" &&
      (mainLot.sold > 0 ||
        (mainLot.halfPriceLot && data.lots[mainLot.halfPriceLot].sold > 0))
    ) {
      Alert.alert(
        "Erro",
        "Não é possível remover um lote que já possui vendas ou cujo lote de meia entrada já possui vendas."
      );
      return;
    }
    const updatedLots = { ...data.lots };
    if (mainLot.halfPriceLot) {
      delete updatedLots[mainLot.halfPriceLot];
    }
    delete updatedLots[name];
    onInputChange("lots", updatedLots);
  };

  const handleAddHalfPriceLot = (name) => {
    const mainLot = data.lots[name];
    if (mainLot.halfPriceLot) {
      Alert.alert("Erro", "Este lote já possui um lote de meia entrada.");
      return;
    }
    const halfPriceLot = {
      ...mainLot,
      name: `${mainLot.name} - Meia Entrada`,
      price: (mainLot.price / 2).toFixed(2),
      halfPrice: true,
      mainLotId: name,
      previousLot: "Primeira Remessa",
    };
    const updatedLots = { ...data.lots, [halfPriceLot.name]: halfPriceLot };
    mainLot.halfPriceLot = halfPriceLot.name;
    onInputChange("lots", updatedLots);
  };

  const handleRemoveHalfPriceLot = (name) => {
    const mainLot = data.lots[name];
    if (mainLot.halfPriceLot && data.lots[mainLot.halfPriceLot].sold > 0) {
      Alert.alert(
        "Erro",
        "Não é possível remover um lote de meia entrada que já possui vendas."
      );
      return;
    }
    if (mainLot.halfPriceLot) {
      const updatedLots = { ...data.lots };
      delete updatedLots[mainLot.halfPriceLot];
      mainLot.halfPriceLot = null;
      onInputChange("lots", updatedLots);
    }
  };

  const toggleExpandLot = (name) => {
    if (expandedLots.includes(name)) {
      setExpandedLots(expandedLots.filter((lot) => lot !== name));
    } else {
      setExpandedLots([...expandedLots, name]);
    }
  };

  const toggleExpandHalfPriceLot = (name) => {
    if (expandedHalfPriceLots.includes(name)) {
      setExpandedHalfPriceLots(
        expandedHalfPriceLots.filter((lot) => lot !== name)
      );
    } else {
      setExpandedHalfPriceLots([...expandedHalfPriceLots, name]);
    }
  };

  const renderLotItem = ({ item }) => {
    const itemName = item[0];
    const itemDetails = item[1];
    const isExpanded = expandedLots.includes(itemName);
    const isExpandedHalfPrice = expandedHalfPriceLots.includes(
      itemDetails.halfPriceLot
    );

    if (itemDetails.halfPrice) {
      return null; // Não renderizar lotes de meia entrada separadamente
    }
    const totalValueCartao = calculateTotal(
      itemDetails.price,
      data.absorbFee,
      "cartao"
    );
    const totalValueBoleto = calculateTotal(
      itemDetails.price,
      data.absorbFee,
      "boleto"
    );
    const totalValuePix = calculateTotal(
      itemDetails.price,
      data.absorbFee,
      "pix"
    );

    return (
      <View style={styles.lotContainer}>
        <View style={styles.lotHeader}>
          <Text style={styles.lotName}>{itemDetails.name}</Text>
          <View style={styles.lotActions}>
            <TouchableOpacity
              onPress={() => handleEditLot(itemName)}
              style={styles.lotActionButton}
            >
              <Icon name="pencil" size={20} color="#FFF" />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => handleRemoveLot(itemName)}
              style={styles.lotActionButton}
            >
              <Icon name="trash-can" size={20} color="#FFF" />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => toggleExpandLot(itemName)}
              style={styles.lotActionButton}
            >
              <Icon
                name={isExpanded ? "chevron-up" : "chevron-down"}
                size={20}
                color="#FFF"
              />
            </TouchableOpacity>
          </View>
        </View>
        <Text style={styles.lotDetails}>
          Quantidade: {itemDetails.quantity}
        </Text>
        <Text style={styles.lotDetails}>
          Preço: {formatCurrency(itemDetails.price)}
        </Text>
        <Text style={styles.lotDetails}>
          Valor com Taxa (Cartão): {formatCurrency(totalValueCartao)}
        </Text>
        {isExpanded && (
          <>
            <Text style={styles.lotDetails}>
              Valor com Taxa (Pix): {formatCurrency(totalValuePix)}
            </Text>
            <Text style={styles.lotDetails}>
              Valor com Taxa (Boleto): {formatCurrency(totalValueBoleto)}
            </Text>
            <Text style={styles.lotDetails}>
              Visível: {itemDetails.visible ? "Sim" : "Não"}
            </Text>
            <Text style={styles.lotDetails}>
              Virada por:{" "}
              {itemDetails.turnType === "quantity" ? "Quantidade" : "Data"}
            </Text>
            {itemDetails.turnType === "date" && (
              <>
                <Text style={styles.lotDetails}>
                  Início:{" "}
                  {itemDetails.start ? formatDate(itemDetails.start) : "N/A"}
                </Text>
                <Text style={styles.lotDetails}>
                  Fim: {itemDetails.end ? formatDate(itemDetails.end) : "N/A"}
                </Text>
              </>
            )}
            {itemDetails.turnType === "quantity" &&
              itemDetails.previousLot &&
              itemDetails.previousLot !== "Primeira Remessa" && (
                <Text style={styles.lotDetails}>
                  Lote Anterior: {itemDetails.previousLot}
                </Text>
              )}
          </>
        )}
        <TouchableOpacity
          onPress={() => handleAddHalfPriceLot(itemName)}
          style={styles.addHalfPriceButton}
        >
          <Text style={styles.buttonText}>Adicionar Meia Entrada</Text>
        </TouchableOpacity>
        {itemDetails.halfPriceLot && (
          <View style={[styles.lotContainer, styles.halfPriceLotContainer]}>
            <View style={styles.lotHeader}>
              <Text style={styles.lotName}>
                {data.lots[itemDetails.halfPriceLot].name}
              </Text>
              <View style={styles.lotActions}>
                <TouchableOpacity
                  onPress={() => handleEditLot(itemDetails.halfPriceLot)}
                  style={styles.lotActionButton}
                >
                  <Icon name="pencil" size={20} color="#FFF" />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => handleRemoveHalfPriceLot(itemName)}
                  style={styles.lotActionButton}
                >
                  <Icon name="trash-can" size={20} color="#FFF" />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() =>
                    toggleExpandHalfPriceLot(itemDetails.halfPriceLot)
                  }
                  style={styles.lotActionButton}
                >
                  <Icon
                    name={isExpandedHalfPrice ? "chevron-up" : "chevron-down"}
                    size={20}
                    color="#FFF"
                  />
                </TouchableOpacity>
              </View>
            </View>
            <Text style={styles.lotDetails}>
              Quantidade: {data.lots[itemDetails.halfPriceLot].quantity}
            </Text>
            <Text style={styles.lotDetails}>
              Preço: {formatCurrency(data.lots[itemDetails.halfPriceLot].price)}
            </Text>
            <Text style={styles.lotDetails}>
              Valor com Taxa (Cartão):{" "}
              {formatCurrency(
                calculateTotal(
                  data.lots[itemDetails.halfPriceLot].price,
                  data.absorbFee,
                  "cartao"
                )
              )}
            </Text>
            {isExpandedHalfPrice && (
              <>
                <Text style={styles.lotDetails}>
                  Valor com Taxa (Pix):{" "}
                  {formatCurrency(
                    calculateTotal(
                      data.lots[itemDetails.halfPriceLot].price,
                      data.absorbFee,
                      "pix"
                    )
                  )}
                </Text>
                <Text style={styles.lotDetails}>
                  Valor com Taxa (Boleto):{" "}
                  {formatCurrency(
                    calculateTotal(
                      data.lots[itemDetails.halfPriceLot].price,
                      data.absorbFee,
                      "boleto"
                    )
                  )}
                </Text>
                <Text style={styles.lotDetails}>
                  Visível:{" "}
                  {data.lots[itemDetails.halfPriceLot].visible ? "Sim" : "Não"}
                </Text>
                <Text style={styles.lotDetails}>
                  Virada por:{" "}
                  {data.lots[itemDetails.halfPriceLot].turnType === "quantity"
                    ? "Quantidade"
                    : "Data"}
                </Text>
                {data.lots[itemDetails.halfPriceLot].turnType === "date" && (
                  <>
                    <Text style={styles.lotDetails}>
                      Início:{" "}
                      {data.lots[itemDetails.halfPriceLot].start
                        ? formatDate(data.lots[itemDetails.halfPriceLot].start)
                        : "N/A"}
                    </Text>
                    <Text style={styles.lotDetails}>
                      Fim:{" "}
                      {data.lots[itemDetails.halfPriceLot].end
                        ? formatDate(data.lots[itemDetails.halfPriceLot].end)
                        : "N/A"}
                    </Text>
                  </>
                )}
                {data.lots[itemDetails.halfPriceLot].turnType === "quantity" &&
                  data.lots[itemDetails.halfPriceLot].previousLot &&
                  data.lots[itemDetails.halfPriceLot].previousLot !==
                    "Primeira Remessa" && (
                    <Text style={styles.lotDetails}>
                      Lote Anterior:{" "}
                      {data.lots[itemDetails.halfPriceLot].previousLot}
                    </Text>
                  )}
              </>
            )}
          </View>
        )}
      </View>
    );
  };

  return (
    <SafeAreaView contentContainerStyle={styles.scrollViewContent}>
      <View style={styles.sectionDivider} />
      <View style={styles.settingItemForm}>
        <Ionicons
          name="ticket-sharp"
          size={24}
          style={styles.settingIconForm}
        />
        <Text style={styles.screenTitle}>Editar Lotes</Text>
      </View>
      <View style={styles.sectionDivider} />
      <View style={styles.detailsContainer}>
        <Text style={styles.label}>Nome do Ingresso:</Text>
        <TextInput
          style={styles.inputLots}
          placeholderTextColor="#FFF9"
          placeholder="Nome do Ingresso"
          value={newLot.name}
          editable={!isEditing || data.status !== "Publicado"}
          onChangeText={(text) => setNewLot({ ...newLot, name: text })}
        />

        <View style={styles.row}>
          <View style={styles.inputWrapper}>
            <Text style={styles.label}>Quantidade:</Text>
            <TextInput
              style={styles.inputLots}
              placeholderTextColor="#FFF9"
              placeholder="50"
              keyboardType="numeric"
              value={newLot.quantity}
              editable={!isEditing || data.status !== "Publicado"}
              onChangeText={(text) => setNewLot({ ...newLot, quantity: text })}
            />
          </View>

          <View style={styles.inputWrapperSecond}>
            <Text style={styles.label}>Preço:</Text>
            <View style={styles.row}>
              <Text style={[styles.currencySymbol, styles.inputPrice]}>R$</Text>
              <TextInput
                style={[styles.inputPriceSecond, styles.priceInput]}
                placeholderTextColor="#FFF9"
                placeholder="100.00"
                keyboardType="numeric"
                value={newLot.price}
                editable={!isEditing || data.status !== "Publicado"}
                onChangeText={(text) =>
                  setNewLot({ ...newLot, price: formatPriceInput(text) })
                }
              />
            </View>
          </View>
        </View>

        <View style={styles.switchRow}>
          <Text style={styles.label}>Defina se seu lote será visível:</Text>
          <Switch
            style={styles.switch}
            trackColor={{ false: "#767577", true: "#202940" }}
            thumbColor={newLot.visible ? "#052552" : "#f4f3f4"}
            ios_backgroundColor="#202940"
            value={newLot.visible}
            onValueChange={(value) => setNewLot({ ...newLot, visible: value })}
          />
        </View>

        <View style={styles.switchRow}>
          <Text style={styles.label}>Virada do lote por:</Text>
          <View style={styles.radioButtonContainer}>
            <TouchableOpacity
              style={styles.radioButton}
              onPress={() => setNewLot({ ...newLot, turnType: "quantity" })}
            >
              <Icon
                name={
                  newLot.turnType === "quantity"
                    ? "radiobox-marked"
                    : "radiobox-blank"
                }
                size={24}
                color="#FFF"
              />
              <Text style={styles.radioLabel}>Quantidade</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.radioButton}
              onPress={() => setNewLot({ ...newLot, turnType: "date" })}
            >
              <Icon
                name={
                  newLot.turnType === "date"
                    ? "radiobox-marked"
                    : "radiobox-blank"
                }
                size={24}
                color="#FFF"
              />
              <Text style={styles.radioLabel}>Data</Text>
            </TouchableOpacity>
          </View>
        </View>

        {newLot.turnType === "date" && (
          <>
            <Text style={styles.label}>Data de Início:</Text>
            <TouchableOpacity
              style={styles.datePickerButton}
              onPress={() => {
                setCurrentDateField("start");
                setDateTimePickerVisible(true);
              }}
            >
              <Text style={styles.buttonText}>
                {newLot.start ? formatDate(newLot.start) : "Selecionar Data"}
              </Text>
              <Icon
                name="calendar"
                size={20}
                color="#FFF"
                style={styles.calendarIcon}
              />
            </TouchableOpacity>
            <DateTimePickerModal
              isVisible={isDateTimePickerVisible}
              mode="datetime"
              minimumDate={new Date()} // Limitar para não permitir selecionar datas anteriores
              onConfirm={handleDateConfirm}
              onCancel={() => setDateTimePickerVisible(false)}
            />

            <Text style={styles.label}>Data de Fim:</Text>
            <TouchableOpacity
              style={styles.datePickerButton}
              onPress={() => {
                setCurrentDateField("end");
                setDateTimePickerVisible(true);
              }}
            >
              <Text style={styles.buttonText}>
                {newLot.end ? formatDate(newLot.end) : "Selecionar Data"}
              </Text>
              <Icon
                name="calendar"
                size={20}
                color="#FFF"
                style={styles.calendarIcon}
              />
            </TouchableOpacity>
          </>
        )}

        {newLot.turnType === "quantity" && (
          <>
            <Text style={styles.label}>Lote Anterior:</Text>
            <TouchableOpacity
              style={styles.datePickerButton}
              onPress={() => {
                setModalVisible(true);
                setCurrentLotType("main");
              }}
            >
              <Text style={styles.buttonText}>
                {newLot.previousLot && newLot.previousLot !== "Primeira Remessa"
                  ? newLot.previousLot
                  : "Não possui lote anterior"}
              </Text>
              <Icon
                name="chevron-down"
                size={20}
                color="#FFF"
                style={styles.calendarIcon}
              />
            </TouchableOpacity>
            <Modal
              visible={isModalVisible}
              transparent={true}
              animationType="slide"
              onRequestClose={() => setModalVisible(false)}
            >
              <View style={styles.modalContainerLot}>
                <View style={styles.modalContentLot}>
                  <Text style={styles.modalTitleLot}>
                    Selecione o Lote Anterior
                  </Text>
                  <TouchableOpacity
                    style={styles.modalOptionButtonLot}
                    onPress={() => {
                      setNewLot({ ...newLot, previousLot: "Primeira Remessa" });
                      setModalVisible(false);
                    }}
                  >
                    <Text style={styles.modalOptionButtonTextLot}>
                      Não possui lote anterior
                    </Text>
                  </TouchableOpacity>
                  {Object.keys(data.lots)
                    .filter(
                      (lotName) =>
                        data.lots[lotName].turnType === "quantity" &&
                        lotName !== newLot.name
                    )
                    .map((lotName) => (
                      <TouchableOpacity
                        key={lotName}
                        style={styles.modalOptionButtonLot}
                        onPress={() => {
                          setNewLot({ ...newLot, previousLot: lotName });
                          setModalVisible(false);
                        }}
                      >
                        <Text style={styles.modalOptionButtonTextLot}>
                          {lotName}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  <TouchableOpacity
                    style={[styles.modalButtonLot, styles.modalButtonCancelLot]}
                    onPress={() => setModalVisible(false)}
                  >
                    <Text style={styles.modalButtonTextLot}>Cancelar</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Modal>
          </>
        )}

        <View style={styles.sectionDivider} />

        <TouchableOpacity onPress={handleAddLot} style={styles.addLotButton}>
          <Text style={styles.buttonText}>
            {editingLotName !== null ? "Atualizar Lote" : "Adicionar Lote"}
          </Text>
        </TouchableOpacity>

        <FlatList
          data={Object.entries(data.lots)}
          renderItem={renderLotItem}
          keyExtractor={(item) => item[0]}
        />

        <View style={styles.sectionDivider} />

        <View style={styles.switchRow}>
          <Text style={styles.label}>Absorver Taxa de Serviço:</Text>
          <Switch
            style={styles.switch}
            trackColor={{ false: "#767577", true: "#202940" }}
            thumbColor={data.absorbFee ? "#052552" : "#f4f3f4"}
            ios_backgroundColor="#202940"
            value={data.absorbFee}
            disabled={data.status === "Publicado"}
            onValueChange={(value) => onInputChange("absorbFee", value)}
          />
        </View>
        <Text style={styles.absorbFeeText}>
          {data.status === "Publicado"
            ? "Não é possível alterar a taxa de serviço após a publicação."
            : data.absorbFee
            ? "Você está aceitando pagar a taxa de serviço."
            : "O cliente pagará a taxa de serviço."}
        </Text>
        <TouchableOpacity onPress={saveChanges} style={styles.saveButton}>
          <Text style={styles.buttonText}>Salvar Lotes</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

export default LotsForm;
