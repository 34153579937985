import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  headerContainer: {
    backgroundColor: "#000",
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  restContainer: {
    backgroundColor: "#000",
    paddingHorizontal: 10,
    flex: 1,
  },
  containerBlack: {
    flex: 1,
    backgroundColor: "#000",
  },
  feed: {
    flex: 1,
    padding: 10,
  },
  sectionHeader: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 5,
    paddingTop: 5,
    paddingBottom: 3,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  sectionDivider: {
    flex: 1,
    height: 1,
    backgroundColor: "#444",
    marginLeft: 10,
  },
  fixedAddEventButton: {
    position: "absolute",
    bottom: 20,
    right: 20,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#1A1A1A",
    padding: 10,
    borderRadius: 50,
    zIndex: 1000,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
  },
  fixedAddEventButtonText: {
    color: "#FFF",
    fontWeight: "bold",
    fontSize: 16,
    marginLeft: 10,
  },
  statusContainer: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    padding: 5,
    paddingHorizontal: 45,
    borderRadius: 8,
    backgroundColor: "#000",
  },
  statusButton: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: "#000",
    borderRadius: 10,
    paddingHorizontal: 25,
  },
  statusButtonText: {
    color: "#FFF",
    fontWeight: "bold",
    fontSize: 14,
    marginHorizontal: 5,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  emptyText: {
    fontSize: 18,
    color: "#bbb",
    textAlign: "center",
  },
  loadMoreButton: {
    padding: 10,
    backgroundColor: "#000",
    alignItems: "center",
    justifyContent: "center",
    borderTopWidth: 0.5,
    borderColor: "#333",
  },
  loadMoreText: {
    color: "#bbb",
    fontWeight: "bold",
    textAlign: "center",
  },
  finalSpace: {
    backgroundColor: "#000",
    paddingBottom: 60,
  },
});
