import { doc, setDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const createChat = async (eventId, chatType, eventStatus) => {
  if (eventStatus !== "Publicado") {
    console.error("Não é possível criar chat para eventos não públicos.");
    throw new Error("Evento não é público");
  }

  try {
    const chatId = `${eventId}${chatType}`;
    const chatRef = doc(db, "eventsChats", chatId);
    await setDoc(chatRef, { messages: [] });
    console.log(`Chat ${chatId} criado com sucesso`);
  } catch (error) {
    console.error("Erro ao criar chat:", error);
    throw error;
  }
};
