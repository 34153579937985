import axios from 'axios';
import { auth } from "../../utils/firebase/firebaseConfig";

const API_URL = "https://us-central1-rayv-a7c48.cloudfunctions.net/api";

const getIdToken = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("Usuário não autenticado.");
  }
  return await user.getIdToken();
};

export const createEvent = async (eventData) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.post(`${API_URL}/createEvent`, eventData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`
      }
    });
    console.log(response.data);
  } catch (error) {
    console.error("Erro ao criar evento:", error);
    throw error;
  }
};
