import React, { useState } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import ChatManager from "./ChatManager";
import Header from "../../../components/producer/producerUtils/Header";
import { SafeAreaView } from "react-native-safe-area-context";

const EventChatManagement = ({ route, navigation }) => {
  const { evento, userId } = route.params;
  const [selectedChat, setSelectedChat] = useState(null);
  const [disabledChatMessage, setDisabledChatMessage] = useState("");

  const handleChatSelection = (chat) => {
    if (chat === "Restrito" || chat === "Bate-papo") {
      setDisabledChatMessage("Chat desativado no momento");
      setSelectedChat(null);
    } else {
      setDisabledChatMessage("");
      setSelectedChat(chat);
    }
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <Header navigation={navigation} />
      <View style={styles.container}>
        <View style={styles.tabsContainer}>
          <TouchableOpacity style={styles.tab} onPress={() => handleChatSelection("Geral")}>
            <Text style={styles.tabText}>Geral</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.tab, styles.disabledTab]} onPress={() => handleChatSelection("Restrito")}>
            <Text style={styles.tabTextDisabled}>Restrito</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.tab, styles.disabledTab]} onPress={() => handleChatSelection("Bate-papo")}>
            <Text style={styles.tabTextDisabled}>Bate-papo</Text>
          </TouchableOpacity>
        </View>
        <Text style={styles.descriptionText}>
          {selectedChat === "Geral" && "Comunicado disponível na página do evento."}
          {selectedChat === "Restrito" && "Comunicado para quem comprou o evento."}
          {selectedChat === "Bate-papo" && "Bate-papo para os membros que compraram o evento."}
        </Text>
        {selectedChat ? (
          <ChatManager eventId={evento.username} chatType={selectedChat} userId={userId} eventStatus={evento.status} />
        ) : (
          <Text style={styles.placeholderText}>
            {disabledChatMessage || "Selecione um chat para gerenciar"}
          </Text>
        )}
      </View>
    </SafeAreaView>
  );
};

export default EventChatManagement;

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: "#000",
  },
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: "#000",
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#fff",
    marginBottom: 10,
  },
  tabsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 10,
  },
  tab: {
    flex: 1,
    alignItems: "center",
    padding: 10,
    marginHorizontal: 5,
    backgroundColor: "#121212",
    borderRadius: 5,
  },
  disabledTab: {
    backgroundColor: "#000",
  },
  tabText: {
    color: "#fff",
    fontSize: 14,
  },
  tabTextDisabled: {
    color: "#fff8",
    fontSize: 14,
  },
  descriptionText: {
    textAlign: "center",
    marginBottom: 10,
    fontSize: 14,
    color: "#ccc",
  },
  placeholderText: {
    textAlign: "center",
    marginTop: 20,
    fontSize: 16,
    color: "#666",
  },
});
