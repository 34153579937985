import React from "react";
import { Text, Image, TouchableOpacity, } from "react-native";
import * as Print from "expo-print";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import { styles } from "../../../../styles/ticket/ticketUtils/TicketResultStyles";
import macaquinho from "../../../../../assets/imgs/Logo_Macaco2.png";

const PDFGenerator = ({
  eventImage,
  ticketId,
  eventName,
  userName,
  eventDate,
  eventTime,
  eventLocation,
  price,
  lote,
  qrCodeBase64,
  macaquinhoBase64,
  formatPrice,
}) => {
  const generatePDF = async () => {
    const eventImageBase64 = await loadImageAsBase64(eventImage);

    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Event Ticket</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 0;
                  background-color: #f0f0f0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100vh;
              }
              .container {
                  width: 100%;
                  max-width: 800px;
                  background: #fff;
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                  border-radius: 10px;
                  overflow: hidden;
              }
              .header {
                  background-color: #000;
                  color: #fff;
                  padding: 20px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
              }
              .header img {
                  width: 50px;
                  height: auto;
              }
              .header h1 {
                  font-size: 24px;
                  margin: 0;
              }
              .event-image {
                  width: 100%;
                  height: auto;
              }
              .content {
                  padding: 20px;
                  display: flex;
                  flex-direction: row;
              }
              .left-content {
                  flex: 3;
                  padding-right: 20px;
              }
              .event-title {
                  font-size: 24px;
                  font-weight: bold;
                  margin-bottom: 20px;
                  text-align: center;
              }
              .event-details p {
                  margin: 5px 0;
                  font-size: 16px;
              }
              .qr-code-section {
                  flex: 2;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  border-left: 2px dashed #000;
                  padding-left: 20px;
              }
              .qr-code-section img {
                  width: 150px;
                  height: 150px;
                  margin-bottom: 10px;
              }
              .footer {
                  padding: 20px;
                  font-size: 12px;
                  color: #555;
              }
              .footer h3 {
                  font-size: 14px;
                  margin-bottom: 5px;
              }
              .footer p {
                  margin: 5px 0;
              }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="header">
                  <img src="${macaquinhoBase64}" alt="Logo">
                  <h1>RAYV</h1>
              </div>
              ${
                eventImageBase64
                  ? `<img src="${eventImageBase64}" class="event-image" alt="Event Image">`
                  : ""
              }
              <div class="content">
                  <div class="left-content">
                      <div class="event-title">${eventName}</div>
                      <div class="event-details">
                          <p>Data: ${eventDate}</p>
                          <p>Horário: ${eventTime}</p>
                          <p>Local: ${eventLocation.replace(",", "<br/>")}</p>
                          <p>Lote: ${lote}</p>
                          <p>Valor: ${formatPrice(price)}</p>
                          <p>Comprador: ${userName}</p>
                          <p>ID do Ingresso: ${ticketId}</p>
                      </div>
                  </div>
                  <div class="qr-code-section">
                      <img src="data:image/png;base64,${qrCodeBase64}" alt="QR Code">
                      <p>${ticketId}</p>
                  </div>
              </div>
              <div class="footer">
                  <h3>INFORMAÇÕES DE SEGURANÇA</h3>
                  <p>• Não compartilhe seu ingresso em rede sociais, fraudadores poderão copiar QRCode de seu ingresso e seu acesso será negado.</p>
                  <p>• Este ingresso será validado apenas uma vez no acesso, cópias ou fotos terão o acesso negado.</p>
                  <p>• Ingressos detectados em atividade de revenda serão cancelados e os dados do comprador encaminhados para as autoridades competentes.</p>
                  <p>• A RayV é responsável somente pelas compras em canais oficiais.</p>
                  <p>• Trate este ingresso como dinheiro.</p>
                  <p>Esperamos que você curta o evento!</p>
                  <h3>DICAS IMPORTANTES</h3>
                  <p>1. POLÍTICA DE REEMBOLSO E CANCELAMENTO:</p>
                  <p>Informe-se sobre a política de reembolso e cancelamento do evento. Saber os prazos e condições pode ser crucial caso haja uma mudança nos seus planos.</p>
                  <p>2. SEGURANÇA NO EVENTO:</p>
                  <p>Siga todas as instruções de segurança fornecidas pela organização do evento. Familiarize-se com as saídas de emergência e os procedimentos em caso de evacuação.</p>
              </div>
          </div>
      </body>
      </html>
    `;

    try {
      const { uri } = await Print.printToFileAsync({
        html: htmlContent,
        width: 800,
        height: 1120,
        base64: false,
      });
      console.log("PDF gerado com sucesso:", uri);
      return uri;
    } catch (error) {
      console.error("Erro ao gerar PDF: ", error);
      return null;
    }
  };

  const downloadPDF = async () => {
    const pdfPath = await generatePDF();
    if (pdfPath) {
      const fileUri = `${FileSystem.documentDirectory}Ingresso_${eventName}_${userName}.pdf`;
      await FileSystem.moveAsync({
        from: pdfPath,
        to: fileUri,
      });

      try {
        if (await Sharing.isAvailableAsync()) {
          await Sharing.shareAsync(fileUri);
        } else {
          console.error("Compartilhamento não disponível");
        }
      } catch (error) {
        console.error("Erro ao abrir o PDF: ", error);
      }
    }
  };

  const loadImageAsBase64 = async (image) => {
    try {
      const imageUri = Image.resolveAssetSource(image)?.uri;
      if (!imageUri) throw new Error("Imagem não encontrada");
      const response = await fetch(imageUri);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Erro ao carregar a imagem em base64:", error);
      return "";
    }
  };

  return (
    <TouchableOpacity style={styles.downloadButton} onPress={downloadPDF}>
      <Image source={macaquinho} style={styles.mascotImage} />
      <Text style={styles.downloadButtonText}>Baixar Ingresso</Text>
    </TouchableOpacity>
  );
};

export default PDFGenerator;
