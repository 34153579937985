import React, { createContext, useState } from "react";

export const FollowContext = createContext();

export const FollowProvider = ({ children }) => {
  const [isFollowing, setIsFollowing] = useState(false);
  const [isPending, setIsPending] = useState(false);

  return (
    <FollowContext.Provider
      value={{ isFollowing, setIsFollowing, isPending, setIsPending }}
    >
      {children}
    </FollowContext.Provider>
  );
};
