import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
  },
  containerMain: {
    flex: 1,
    // justifyContent: "center",
    // alignItems: "center",
    backgroundColor: "#000",
  },
  logo: {
    width: 180,
    height: 60,
    resizeMode: "contain",
    marginBottom: 30,
  },
  input: {
    width: "80%",
    backgroundColor: "#2B2B2B",
    color: "#FFF",
    marginTop: 5,
    marginBottom: 10,
    borderRadius: 15,
    padding: 10,
  },
  inputError: {
    borderColor: "red",
    borderWidth: 1,
  },
  button: {
    backgroundColor: "#1A2035",
    marginTop: 20,
    paddingVertical: 12,
    paddingHorizontal: 25,
    borderRadius: 10,
    alignItems: "center",
    width: "60%",
  },
  buttonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  linkText: {
    color: "#FFFD",
    marginTop: 20,
    textDecorationLine: "underline",
  },
  linkText2: {
    color: "#FFFD",
    marginTop: 10,
  },
  goBackButton: {
    position: "absolute",
    top: 15,
    left: 20,
  },
  admButton: {
    position: "absolute",
    top: 15,
    right: 20,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "#1C1C1C",
    borderRadius: 10,
    padding: 20,
    width: "80%",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 18,
    color: "#FFFFFF",
    marginBottom: 15,
    marginRight: 10,
  },
  modalInput: {
    width: "100%",
    backgroundColor: "#3D3D3D",
    color: "#FFF",
    borderRadius: 10,
    padding: 10,
    marginBottom: 20,
  },
  modalButton: {
    backgroundColor: "#1A2035",
    padding: 12,
    borderRadius: 10,
    alignItems: "center",
    width: "100%",
    marginBottom: 10,
  },
  modalButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  modalCloseButton: {
    position: "absolute",
    top: 8,
    right: 8,
    zIndex: 1,
  },
  testButton: {
    backgroundColor: "#121212",
    marginTop: 10,
    paddingVertical: 8,
    paddingHorizontal: 25,
    marginLeft: 5,
    borderRadius: 10,
    alignItems: "center",
  },
  testButtonProducer: {
    backgroundColor: "#191919",
    marginTop: 10,
    paddingVertical: 8,
    paddingHorizontal: 25,
    marginLeft: 5,
    borderRadius: 10,
    alignItems: "center",
  },
  testButtonText: {
    color: "#FFF",
    fontSize: 10,
  },
  containerADM: {
    bottom: 0,
    position: "absolute",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
