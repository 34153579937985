import React, { Component } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  Alert,
  RefreshControl,
} from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import ListEventUCard from "../../components/general/home_search/ListEventUCard";
import ListUserCard from "../../components/general/search/ListUserCard";
import ListEventPCard from "../../components/general/home_search/ListEventPCard";
import SearchBar from "../../components/general/search/SearchBar";
import Header from "../../components/general/home_search/Header";
import { styles } from "../../styles/general/SearchStyles";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../services/utils/firebase/firebaseConfig";
import { getUser, buscarUsuariosPorTermo } from "../../services/cruds/users/getUser";
import { buscarEventosPorTermo } from "../../services/cruds/events/getEvent";
import { updateUser } from "../../services/cruds/users/updateUser";
import ListUserSkeleton from "../../components/general/search/ListUserSkeleton";
import ListEventUSkeleton from "../../components/general/home_search/ListEventUSkeleton";

const Tab = createMaterialTopTabNavigator();

export default class Search extends Component {
  state = {
    userId: "",
    userData: "",
    refreshing: false,
    isOrganizacao: false,
    termoPesquisa: "",
    eventosBusca: [],
    usuariosBusca: [],
    carregandoMais: false,
    loadingInitial: true,
    activeTab: "Eventos",
    hasLoadedOnce: false, // Novo estado para rastrear se a tela já foi carregada
    hasLoadedEventos: false, // Novo estado para rastrear se os eventos já foram carregados
    hasLoadedUsuarios: false, // Novo estado para rastrear se os usuários já foram carregados
  };

  componentDidMount() {
    this.authenticateAndLoadEvents();
    this.focusListener = this.props.navigation.addListener(
      "focus",
      () => {
        if (!this.state.hasLoadedOnce) {
          this.onRefresh();
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.focusListener) {
      this.focusListener();
    }
  }

  authenticateAndLoadEvents = async () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({ userId: user.uid }, this.verificarTipoDeUsuario);
      } else {
        this.buscarEventos(this.state.termoPesquisa);
        this.buscarUsuarios(this.state.termoPesquisa);
        console.log("Nenhum usuário conectado");
      }
    });
  };

  verificarTipoDeUsuario = async () => {
    const userData = await getUser(this.state.userId);
    if (userData.accountType === "Producer") {
      this.setState({ isOrganizacao: true });
    } else {
      this.setState({ isOrganizacao: false });
    }
    this.setState({ userData: userData, loadingInitial: false, hasLoadedOnce: true });
    this.onRefresh(); // Refresh data after setting user type
  };

  handleFavoriteToggle = async (eventoId, eventoUsername) => {
    if (this.state.userId === eventoId || this.state.userId === "") {
      Alert.alert("Sem acesso", "Você não tem acesso a essa funcionalidade.");
      return;
    }

    try {
      const isFavorite =
        this.state.userData.favoriteEvents.includes(eventoUsername);
      const updatedFavorites = isFavorite
        ? this.state.userData.favoriteEvents.filter(
            (username) => username !== eventoUsername
          )
        : [...this.state.userData.favoriteEvents, eventoUsername];

      // Atualizar o estado localmente
      this.setState((prevState) => ({
        userData: {
          ...prevState.userData,
          favoriteEvents: updatedFavorites,
        },
        eventosGerais: prevState.eventosGerais
          ? prevState.eventosGerais.map((evento) =>
              evento.id === eventoId
                ? { ...evento, isFavorite: !isFavorite }
                : evento
            )
          : [],
      }));

      // Atualizar no servidor
      await updateUser(this.state.userId, {
        favoriteEvents: updatedFavorites,
      });
    } catch (error) {
      console.error("Erro ao atualizar favoritos", error);
      Alert.alert("Erro", "Não foi possível atualizar os favoritos.");
    }
  };

  buscarEventos = async (termoPesquisa) => {
    if (this.state.hasLoadedEventos && termoPesquisa === this.state.termoPesquisa) return;
    this.setState({ loadingInitial: true });
    try {
      const response = await buscarEventosPorTermo(
        termoPesquisa,
        this.state.isOrganizacao,
        this.state.userId
      );
      const { eventosBusca } = response;

      // Ordenar os eventos
      const sortedEventos = eventosBusca.sort((a, b) => {
        const order = ["Rascunho", "Em Análise", "Publicado", "Finalizado"];
        return order.indexOf(a.status) - order.indexOf(b.status);
      });

      this.setState({
        eventosBusca: sortedEventos,
        loadingInitial: false,
        hasLoadedOnce: true, // Marcar como carregado
        hasLoadedEventos: true,
      });
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
      this.setState({ loadingInitial: false });
    }
  };

  buscarUsuarios = async (termoPesquisa) => {
    if (this.state.hasLoadedUsuarios && termoPesquisa === this.state.termoPesquisa) return;
    this.setState({ loadingInitial: true });
    try {
      const response = await buscarUsuariosPorTermo(termoPesquisa);
      const { usuariosBusca } = response;

      this.setState({
        usuariosBusca,
        loadingInitial: false,
        hasLoadedOnce: true, // Marcar como carregado
        hasLoadedUsuarios: true,
      });
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
      this.setState({ loadingInitial: false });
    }
  };

  renderCard = (item) => {
    if (!item) return null;
    if (this.state.isOrganizacao) {
      return (
        <View style={styles.background}>
          <ListEventPCard
            evento={item}
            onPressView={() =>
              this.props.navigation.navigate("Ticket", { evento: item })
            }
            onPressEdit={() =>
              this.props.navigation.navigate("EditEvent", { evento: item })
            }
            onPressStatistics={() =>
              this.props.navigation.navigate("EventStatistics", {
                evento: item,
                onGoBack: this.authenticateAndLoadEvents,
              })
            }
            onPressQRCode={() =>
              this.props.navigation.navigate("QRCodeScanner", { item })
            }
            onPressManageChat={() =>
              this.props.navigation.navigate("EventChatManagement", { evento: item, userId: this.state.userId })
            }
          />
        </View>
      );
    } else {
      const isFavorite =
        this.state.userData.favoriteEvents == null
          ? false
          : this.state.userData.favoriteEvents.includes(item.username);
      return (
        <View style={styles.background}>
          <ListEventUCard
            evento={item}
            onPressCard={() =>
              this.props.navigation.navigate("Ticket", { evento: item })
            }
            onPressFavorite={() =>
              this.handleFavoriteToggle(item.id, item.username)
            }
            isFavorite={isFavorite}
          />
        </View>
      );
    }
  };

  renderFooter = () => {
    if (!this.state.carregandoMais) return null;
  };

  renderEmpty = () => {
    const { activeTab, loadingInitial } = this.state;
    if (loadingInitial) {
      return null;
    }
    return (
      <View style={styles.emptyContainer}>
        <Text style={styles.emptyText}>
          {activeTab === "Eventos"
            ? "Nenhum evento encontrado."
            : "Nenhum usuário encontrado."}
        </Text>
      </View>
    );
  };

  onSettingsPress = async () => {
    this.props.navigation.navigate("Settings");
  };

  onHomePress = () => {
    this.props.navigation.navigate("Home");
  };

  onTicketPress = async () => {
    if (this.state.isOrganizacao) {
      this.props.navigation.navigate("ProducerStatistics", {
        producerId: this.state.userId,
      });
    } else if (this.state.userId != "") {
      this.props.navigation.navigate("MyTickets");
    } else {
      this.props.navigation.navigate("Login");
    }
  };

  handleTabChange = (tabName) => {
    this.setState({ activeTab: tabName });
    this.atualizarTermoPesquisa(this.state.termoPesquisa, tabName);
  };

  atualizarTermoPesquisa = (termo, tabName = this.state.activeTab) => {
    this.setState({ termoPesquisa: termo }, () => {
      if (this.state.isOrganizacao || tabName === "Eventos") {
        this.buscarEventos(termo);
      } else {
        this.buscarUsuarios(termo);
      }
    });
  };

  onRefresh = () => {
    this.setState({ refreshing: true }, async () => {
      await this.buscarEventos(this.state.termoPesquisa);
      await this.buscarUsuarios(this.state.termoPesquisa);
      this.setState({ refreshing: false });
    });
  };

  render() {
    const { isOrganizacao, eventosBusca, usuariosBusca, refreshing, loadingInitial, userId, activeTab } = this.state;
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.headerContainer}>
          <Header
            onSettingsPress={this.onSettingsPress}
            onHomePress={this.onHomePress}
            onTicketPress={this.onTicketPress}
            visible="true"
          />
        </View>
        <View
          style={!isOrganizacao ? styles.searchBar : styles.searchBarSecond}
        >
          <SearchBar
            placeholder={isOrganizacao ? "Pesquisar Eventos" :
              activeTab === "Eventos"
                ? "Pesquisar Eventos"
                : "Pesquisar Usuários"
            }
            value={this.state.termoPesquisa}
            onChangeText={(text) => {
              this.setState({ termoPesquisa: text, hasLoadedEventos: false, hasLoadedUsuarios: false }, () => {
                this.atualizarTermoPesquisa(text);
              });
            }}
          />
        </View>
        {!isOrganizacao ? (
          <Tab.Navigator
            screenOptions={{
              tabBarStyle: { backgroundColor: "#000" },
              tabBarActiveTintColor: "#FFF",
              tabBarInactiveTintColor: "#A7A7A7",
              tabBarIndicatorStyle: {
                backgroundColor: "#FFF",
                height: 1,
                bottom: 10,
              },
              tabBarLabelStyle: {
                fontSize: 13,
                fontWeight: "bold",
                paddingBottom: 0,
              },
            }}
            screenListeners={({ navigation, route }) => ({
              state: (e) => {
                const index = e.data.state.index;
                const tabName = index === 0 ? "Eventos" : "Usuários";
                this.handleTabChange(tabName);
              },
            })}
          >
            <Tab.Screen
              name="Eventos"
              listeners={{
                tabPress: () => {
                  this.handleTabChange("Eventos");
                },
              }}
              children={() => (
                <View style={styles.container}>
                  <FlatList
                    data={loadingInitial ? [1, 2, 3, 4, 5] : eventosBusca}
                    renderItem={({ item }) =>
                      loadingInitial ? (
                        <ListEventUSkeleton />
                      ) : (
                        this.renderCard(item)
                      )
                    }
                    keyExtractor={(item, index) =>
                      loadingInitial ? index.toString() : item.username.toString()
                    }
                    ListFooterComponent={this.renderFooter}
                    ListEmptyComponent={this.renderEmpty}
                    refreshControl={
                      <RefreshControl
                        refreshing={refreshing}
                        onRefresh={this.onRefresh}
                      />
                    }
                  />
                </View>
              )}
            />
            {!isOrganizacao && (
              <Tab.Screen
                name="Usuários"
                listeners={{
                  tabPress: () => {
                    this.handleTabChange("Usuários");
                  },
                }}
                children={() => (
                  <View style={styles.container}>
                    <FlatList
                      data={loadingInitial ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : usuariosBusca}
                      renderItem={({ item }) =>
                        loadingInitial ? (
                          <ListUserSkeleton />
                        ) : (
                          <ListUserCard
                            usuario={item}
                            onPressItem={() =>
                              this.props.navigation.navigate("VisitProfile", {
                                usuario: item,
                                currentUser: userId,
                              })
                            }
                          />
                        )
                      }
                      keyExtractor={(item, index) =>
                        loadingInitial ? index.toString() : item.username.toString()
                      }
                      ListFooterComponent={this.renderFooter}
                      ListEmptyComponent={this.renderEmpty}
                      refreshControl={
                        <RefreshControl
                          refreshing={refreshing}
                          onRefresh={this.onRefresh}
                        />
                      }
                    />
                  </View>
                )}
              />
            )}
          </Tab.Navigator>
        ) : (
          <View style={styles.container}>
            <FlatList
              data={loadingInitial ? [1, 2, 3, 4, 5] : eventosBusca}
              renderItem={({ item }) =>
                loadingInitial ? (
                  <ListEventUSkeleton />
                ) : (
                  this.renderCard(item)
                )
              }
              keyExtractor={(item, index) =>
                loadingInitial ? index.toString() : item.username.toString()
              }
              ListFooterComponent={this.renderFooter}
              ListEmptyComponent={this.renderEmpty}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={this.onRefresh}
                />
              }
            />
          </View>
        )}
      </SafeAreaView>
    );
  }
}
