import React from "react";
import {
  Platform,
  View,
  Image,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  StatusBar,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons";

// Assets
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";

export default function Header({ navigation }) {
  return (
    <SafeAreaView style={styles.safeArea}>
      <StatusBar barStyle="light-content" />
      <View style={styles.container}>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <Icon name="arrow-back" size={25} color="#FFFFFF" />
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigation.navigate("Home")}>
          <Image source={logoTexto} resizeMode="contain" style={styles.logo} />
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.helpButton}
        >
          <MaterialCommunityIcons name="help-network" size={25} color="#000" />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    backgroundColor: "#000",
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  logo: {
    width: 150,
    height: 30,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
});
