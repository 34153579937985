import React, { Component } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
  Alert,
  RefreshControl,
  Platform,
} from "react-native";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../services/utils/firebase/firebaseConfig";
import Icon from "react-native-vector-icons/Ionicons";
import ListEventUCard from "../../components/general/home_search/ListEventUCard";
import FeaturedEventCard from "../../components/general/home/FeaturedEventCard";
import ListEventPCard from "../../components/general/home_search/ListEventPCard";
import Header from "../../components/general/home_search/Header";
import CitySelector from "../../components/general/home/CitySelector";
import { styles } from "../../styles/general/HomeStyles";
import { getUser } from "../../services/cruds/users/getUser";
import { updateUser } from "../../services/cruds/users/updateUser";
import {
  getProducerIDCity,
  getEventCity,
  getEventStatus,
  getDraftEventCount,
} from "../../services/cruds/events/getEvent";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import FeaturedEventSkeleton from "../../components/general/home/FeaturedEventSkeleton";
import ListEventPSkeleton from "../../components/general/home_search/ListEventPSkeleton";
import ListEventUSkeleton from "../../components/general/home_search/ListEventUSkeleton";

const Tab = createMaterialTopTabNavigator();

class Home extends Component {
  state = {
    userId: "",
    userData: "",
    eventosGerais: [],
    eventosDestaque: [],
    refreshing: false,
    isOrganizacao: false,
    cidadeSelecionada: "Todas as Cidades",
    citySelectorVisible: false,
    ultimosDocumentosGerais: null,
    carregandoMais: false,
    loadingInitial: true,
    isIOS: false,
    statusSelecionado: "Publicado",
    hasLoadedOnce: false,
  };

  async componentDidMount() {
    const ios = Platform.OS === "ios";
    this.setState({ isIOS: ios });
    await this.authenticateAndLoadEvents();
    this.focusListener = this.props.navigation.addListener("focus", async () => {
      if (!this.state.hasLoadedOnce) {
        await this.authenticateAndLoadEvents();
      }
    });
  }

  componentWillUnmount() {
    if (this.focusListener) {
      this.focusListener();
    }
    if (this.authListener) {
      this.authListener();
    }
  }

  authenticateAndLoadEvents = async () => {
    if (this.authListener) {
      this.authListener();
    }
    this.authListener = onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.setState({ userId: user.uid }, this.verificarTipoDeUsuario);
      } else {
        console.log("Nenhum usuário conectado");
        this.loadDataForNonAuthenticatedUser();
      }
    });
  };

  verificarTipoDeUsuario = async () => {
    try {
      const userData = await getUser(this.state.userId);
      if (!userData) {
        console.error(`Nenhum documento encontrado para o usuário: ${this.state.userId}`);
        return;
      }
      this.setState(
        { userData, isOrganizacao: userData.accountType === "Producer" },
        () => {
          if (this.state.isOrganizacao) {
            this.buscarEventosGerais();
          } else {
            this.buscarEventosDestaque();
            this.buscarEventosGerais();
          }
        }
      );
    } catch (error) {
      console.error("Erro ao verificar tipo de usuário:", error);
    }
  };

  loadDataForNonAuthenticatedUser = () => {
    this.buscarEventosDestaque();
    this.buscarEventosGerais();
  };

  onRefresh = () => {
    this.setState(
      { refreshing: true, eventosGerais: [], ultimosDocumentosGerais: null, loadingInitial: true },
      this.authenticateAndLoadEvents
    );
  };

  navigateToAddEvent = async () => {
    try {
      const draftCount = await getDraftEventCount(this.state.userId);
      if (draftCount >= 5) {
        Alert.alert("Limite de rascunhos atingido", "Você não pode ter mais de 5 eventos como rascunho.");
      } else {
        this.props.navigation.navigate("AddEvent", {
          onGoBack: this.authenticateAndLoadEvents,
        });
      }
    } catch (error) {
      console.error("Erro ao verificar a contagem de eventos em rascunho:", error);
      Alert.alert("Erro", "Não foi possível verificar a contagem de eventos em rascunho.");
    }
  };

  capitalizeWords = (str) => {
    const lowercaseWords = [
      "das",
      "des",
      "dos",
      "a",
      "e",
      "i",
      "o",
      "u",
      "as",
      "os",
      "da",
      "de",
      "do",
      "du",
    ];

    return str
      .split(" ")
      .map((word) => {
        if (lowercaseWords.includes(word.toLowerCase())) {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  handleSelectCity = (city) => {
    const capitalizedCity = this.capitalizeWords(city);
    this.setState(
      { cidadeSelecionada: capitalizedCity, citySelectorVisible: false },
      this.buscarEventosGerais
    );
  };

  handleCitySelectorToggle = () => {
    this.setState((prevState) => ({
      citySelectorVisible: !prevState.citySelectorVisible,
    }));
  };

  handleFavoriteToggle = async (eventoId, eventoUsername) => {
    if (this.state.userId === eventoId || this.state.userId === "") {
      Alert.alert("Sem acesso", "Você não tem acesso a essa funcionalidade.");
    } else {
      try {
        const isFavorite =
          this.state.userData.favoriteEvents.includes(eventoUsername);
        const updatedFavorites = isFavorite
          ? this.state.userData.favoriteEvents.filter(
              (username) => username !== eventoUsername
            )
          : [...this.state.userData.favoriteEvents, eventoUsername];

        this.setState((prevState) => ({
          userData: {
            ...prevState.userData,
            favoriteEvents: updatedFavorites,
          },
          eventosGerais: prevState.eventosGerais.map((evento) =>
            evento.id === eventoId
              ? { ...evento, isFavorite: !isFavorite }
              : evento
          ),
        }));

        await updateUser(this.state.userId, {
          favoriteEvents: updatedFavorites,
        });
      } catch (error) {
        console.error("Erro ao atualizar favoritos", error);
        Alert.alert("Erro", "Não foi possível atualizar os favoritos.");
      }
    }
  };

  onSettingsPress = () => {
    this.props.navigation.navigate("Settings");
  };

  onTicketPress = () => {
    const { userData } = this.state;
    if (this.state.isOrganizacao) {
      this.props.navigation.navigate("ProducerStatistics", {
        producerId: userData.userId,
      });
    } else if (this.state.userId !== "") {
      this.props.navigation.navigate("MyTickets");
    } else {
      this.props.navigation.navigate("Login");
    }
  };

  buscarEventosDestaque = async () => {
    try {
      const eventosDestaque = await getEventStatus("Publicado");
      this.setState({ eventosDestaque, loadingInitial: false });
    } catch (error) {
      console.error("Erro ao buscar eventos em destaque:", error);
    }
  };

  buscarEventosGerais = async (statusSelecionado = this.state.statusSelecionado) => {
    this.setState({ loadingInitial: true });
    try {
      let response;
      if (this.state.isOrganizacao) {
        response = await getProducerIDCity(this.state.userId, statusSelecionado);
      } else {
        response = await getEventCity(this.state.cidadeSelecionada);
      }
      const { eventos, lastDocument } = response;
      this.setState({
        eventosGerais: eventos,
        ultimosDocumentosGerais: lastDocument,
        loadingInitial: false,
        refreshing: false,
        hasLoadedOnce: true,
      });
    } catch (error) {
      console.error("Erro ao buscar eventos gerais:", error);
      this.setState({ loadingInitial: false, refreshing: false });
    }
  };

  carregarMaisEventosGerais = async () => {
    if (!this.state.ultimosDocumentosGerais || this.state.carregandoMais) {
      return;
    }
    this.setState({ carregandoMais: true });

    try {
      let response;
      if (this.state.isOrganizacao) {
        response = await getProducerIDCity(
          this.state.userId,
          this.state.statusSelecionado,
          this.state.ultimosDocumentosGerais
        );
      } else {
        response = await getEventCity(
          this.state.cidadeSelecionada,
          this.state.ultimosDocumentosGerais
        );
      }
      const { eventos, lastDocument } = response;
      this.setState((prevState) => ({
        eventosGerais: [...prevState.eventosGerais, ...eventos],
        ultimosDocumentosGerais: lastDocument,
        carregandoMais: false,
      }));
    } catch (error) {
      console.error("Erro ao carregar mais eventos gerais:", error);
      this.setState({ carregandoMais: false });
    }
  };

  renderFeaturedEvents = () => {
    const { eventosDestaque, loadingInitial } = this.state;
    if (loadingInitial && !this.state.hasLoadedOnce) {
      return (
        <FlatList
          horizontal
          data={[1, 2, 3, 4, 5]}
          renderItem={() => <FeaturedEventSkeleton />}
          keyExtractor={(item, index) => index.toString()}
          showsHorizontalScrollIndicator={false}
        />
      );
    }
    return (
      <FlatList
        horizontal
        data={eventosDestaque}
        renderItem={({ item }) => (
          <FeaturedEventCard
            evento={item}
            onPressMore={() =>
              this.props.navigation.navigate("Ticket", { evento: item })
            }
          />
        )}
        keyExtractor={(item) => item.id.toString()}
        showsHorizontalScrollIndicator={false}
      />
    );
  };

  renderEventsList = ({ route }) => {
    const { eventosGerais, loadingInitial, isOrganizacao } = this.state;
    const eventosFiltrados = eventosGerais.filter(
      (evento) => evento.status === route.name
    );

    if (loadingInitial && !this.state.hasLoadedOnce) {
      return (
        <View style={styles.containerBlack}>
          <FlatList
            data={[1, 2, 3, 4, 5]}
            renderItem={() =>
              isOrganizacao ? <ListEventPSkeleton /> : <ListEventUSkeleton />
            }
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      );
    }

    return (
      <View style={styles.containerBlack}>
        <FlatList
          data={eventosFiltrados}
          renderItem={({ item }) => this.renderCard(item)}
          keyExtractor={(item) => item.id.toString()}
          ListFooterComponent={this.renderFooter}
          ListEmptyComponent={this.renderEmpty}
          refreshControl={
            <RefreshControl
              refreshing={this.state.refreshing}
              onRefresh={this.onRefresh}
            />
          }
        />
      </View>
    );
  };

  handleEndReached = () => {
    if (!this.state.carregandoMais && this.state.eventosGerais.length > 0) {
      this.carregarMaisEventosGerais();
    }
  };

  renderCard = (item) => {
    if (!item) return null;
    if (this.state.isOrganizacao) {
      return (
        <ListEventPCard
          evento={item}
          onPressView={() =>
            this.props.navigation.navigate("Ticket", { evento: item })
          }
          onPressEdit={() => {
            if (item.status !== "Finalizado") {
              this.props.navigation.navigate("EditEvent", {
                evento: item,
                onGoBack: this.authenticateAndLoadEvents,
              });
            }
          }}
          onPressStatistics={() =>
            this.props.navigation.navigate("EventStatistics", {
              evento: item,
              onGoBack: this.authenticateAndLoadEvents,
            })
          }
          onPressQRCode={() => {
            if (item.status !== "Finalizado") {
              this.props.navigation.navigate("QRCodeScanner", { evento: item });
            }
          }}
          onPressManageChat={() =>
            this.props.navigation.navigate("EventChatManagement", { evento: item, userId: this.state.userId })
          }
        />
      );
    } else {
      const isFavorite =
        this.state.userData.favoriteEvents == null
          ? false
          : this.state.userData.favoriteEvents.includes(item.username);
      return (
        <ListEventUCard
          evento={item}
          onPressCard={() =>
            this.props.navigation.navigate("Ticket", { evento: item })
          }
          onPressFavorite={() =>
            this.handleFavoriteToggle(item.id, item.username)
          }
          isFavorite={isFavorite}
        />
      );
    }
  };

  renderFooter = () => {
    if (this.state.carregandoMais) {
      return <ActivityIndicator style={{ color: "#000" }} />;
    }

    if (this.state.isOrganizacao) {
      if (this.state.ultimosDocumentosGerais && (this.state.statusSelecionado === "Publicado" || this.state.statusSelecionado === "Finalizado") ) {
        return (
          <View style={styles.finalSpace}>
            <TouchableOpacity
              onPress={this.handleEndReached}
              style={styles.loadMoreButton}
            >
              <Text style={styles.loadMoreText}>Carregar Mais</Text>
            </TouchableOpacity>
          </View>
        );
      } else {
        return <View style={styles.finalSpace}></View>;
      }
    }
  };

  renderEmpty = () => {
    const { loadingInitial, hasLoadedOnce, isOrganizacao, eventosGerais, refreshing } = this.state;
  
    if (!loadingInitial && !refreshing && eventosGerais.length === 0) {
      return (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>Nenhum evento encontrado.</Text>
        </View>
      );
    }
  
    return (
      <View style={styles.containerBlack}>
        <FlatList
          data={[1, 2, 3, 4, 5]}
          renderItem={() =>
            isOrganizacao ? <ListEventPSkeleton /> : <ListEventUSkeleton />
          }
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
    );
  };
  

  renderSectionTitle = (title) => (
    <View style={styles.sectionHeader}>
      <Text style={styles.sectionTitle}>{title}</Text>
      <View style={styles.sectionDivider} />
    </View>
  );

  render() {
    const {
      isOrganizacao,
      cidadeSelecionada,
      citySelectorVisible,
      eventosGerais,
    } = this.state;

    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.headerContainer}>
          <Header
            onSettingsPress={this.onSettingsPress}
            onHomePress={this.onHomePress}
            onTicketPress={this.onTicketPress}
            visible="true"
          />
        </View>
        <View style={styles.restContainer}>
          {isOrganizacao && (
            <TouchableOpacity
              onPress={this.navigateToAddEvent}
              style={styles.fixedAddEventButton}
            >
              <Icon name={"add"} size={20} color="#FFF" />
              <Text style={styles.fixedAddEventButtonText}>
                Adicionar Evento
              </Text>
            </TouchableOpacity>
          )}
          {isOrganizacao && (
            <Tab.Navigator
              screenOptions={({ route }) => ({
                tabBarIcon: ({ focused }) => {
                  let iconName;
                  switch (route.name) {
                    case "Rascunho":
                      iconName = focused
                        ? "document-text"
                        : "document-text-outline";
                      break;
                    case "Em Análise":
                      iconName = focused ? "clipboard" : "clipboard-outline";
                      break;
                    case "Publicado":
                      iconName = focused ? "eye" : "eye-outline";
                      break;
                    case "Finalizado":
                      iconName = focused
                        ? "checkmark-done"
                        : "checkmark-done-outline";
                      break;
                  }
                  return <Icon name={iconName} size={20} color="#FFF" />;
                },
                tabBarActiveTintColor: "#FFF",
                tabBarInactiveTintColor: "#A7A7A7",
                tabBarStyle: { backgroundColor: "#000" },
                tabBarLabelStyle: { fontSize: 10 },
                tabBarIndicatorStyle: { backgroundColor: "#FFF" },
              })}
              initialRouteName="Publicado"
              screenListeners={({ route }) => ({
                focus: () => {
                  this.setState(
                    { statusSelecionado: route.name, loadingInitial: true },
                    this.buscarEventosGerais
                  );
                },
              })}
            >
              <Tab.Screen name="Rascunho">
                {(props) => (
                  <this.renderEventsList
                    {...props}
                    route={{ name: "Rascunho" }}
                  />
                )}
              </Tab.Screen>
              <Tab.Screen name="Em Análise">
                {(props) => (
                  <this.renderEventsList
                    {...props}
                    route={{ name: "Em Análise" }}
                  />
                )}
              </Tab.Screen>
              <Tab.Screen name="Publicado">
                {(props) => (
                  <this.renderEventsList
                    {...props}
                    route={{ name: "Publicado" }}
                  />
                )}
              </Tab.Screen>
              <Tab.Screen name="Finalizado">
                {(props) => (
                  <this.renderEventsList
                    {...props}
                    route={{ name: "Finalizado" }}
                  />
                )}
              </Tab.Screen>
            </Tab.Navigator>
          )}
          {!isOrganizacao && (
            <FlatList
              data={this.state.loadingInitial ? [1, 2, 3, 4, 5] : eventosGerais}
              renderItem={({ item }) =>
                this.state.loadingInitial ? (
                  <ListEventUSkeleton />
                ) : (
                  this.renderCard(item)
                )
              }
              keyExtractor={(item, index) =>
                this.state.loadingInitial ? index.toString() : item.id.toString()
              }
              onEndReached={() => {
                if (!this.state.loadingInitial) {
                  this.handleEndReached();
                  console.log("Carregou mais!!");
                }
              }}
              onEndReachedThreshold={0.1}
              ListFooterComponent={this.renderFooter}
              ListEmptyComponent={this.renderEmpty}
              refreshControl={
                <RefreshControl
                  refreshing={this.state.refreshing}
                  onRefresh={this.onRefresh}
                />
              }
              ListHeaderComponent={
                <>
                  {!isOrganizacao && (
                    <CitySelector
                      onSelectCity={this.handleSelectCity}
                      visible={citySelectorVisible}
                      onRequestClose={this.handleCitySelectorToggle}
                      selectedCity={cidadeSelecionada}
                    />
                  )}
                  {!isOrganizacao &&
                    this.renderSectionTitle("Eventos em Destaque")}
                  {!isOrganizacao && this.renderFeaturedEvents()}
                  {!isOrganizacao &&
                    this.renderSectionTitle(
                      cidadeSelecionada === "Todas as Cidades"
                        ? "Todos os Eventos"
                        : `Eventos de ${cidadeSelecionada}`
                    )}
                </>
              }
            />
          )}
        </View>
      </SafeAreaView>
    );
  }
}

export default Home;
