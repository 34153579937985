import React from "react";
import {
  View,
  Text,
  Modal,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import { styles } from "../../../../styles/ticket/ticketUtils/TicketResultStyles";

const ConfirmationModal = ({
  visible,
  refundAmount,
  serviceFee,
  formatPrice,
  processRefund,
  toggleConfirmationModal,
}) => (
  <Modal visible={visible} transparent={true} animationType="slide">
    <TouchableWithoutFeedback onPress={toggleConfirmationModal}>
      <View style={styles.modalContainer}>
        <TouchableWithoutFeedback>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Confirmação de Reembolso</Text>
            <Text style={styles.confirmationText}>Valor do Reembolso: {formatPrice(refundAmount)}</Text>
            <Text style={styles.confirmationText}>Taxa de Serviço: {formatPrice(serviceFee)}</Text>
            <TouchableOpacity style={styles.confirmButton} onPress={processRefund}>
              <Text style={styles.confirmButtonText}>Confirmar</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.confirmButton, styles.cancelButton]} onPress={toggleConfirmationModal}>
              <Text style={styles.confirmButtonText}>Cancelar</Text>
            </TouchableOpacity>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  </Modal>
);

export default ConfirmationModal;
